import IconButton from "components/atoms/button/IconButton";
import Headline from "components/atoms/headline/Headline";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import DashboardBoxTitle from "components/atoms/title/DashboardBoxTitle";
import Accordion from "components/molecules/accordion/Accordion";
import AccordionBody from "components/molecules/accordion/AccordionBody/AccordionBody";
import AccordionTitle from "components/molecules/accordion/AccordionTitle/AccordionTitle";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import { IconType } from "lib/helpers/constants/iconTypes";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import Flag from "react-world-flags";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import NewCompany from "./new-company/NewCompany";
import AddTemplate from "./add-template/AddTemplate";
import { useResources } from "lib/hooks/queries/Documents/useResources";
import { useGetTemplates } from "lib/hooks/queries/Documents/useGetTemplates";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import NewOverview from "./new-overview/NewOverview";
import NewBaseOverview from "./new-overview/NewBaseOverview";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import UpdateCompany from "./update-company/UpdateCompany";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import DocumentDeleteModal from "./delete-modal/DocumentDeleteModal";

export interface InsuranceDocumentsViewProps { }

const InsuranceDocuments: FC<InsuranceDocumentsViewProps> = () => {
  const { t } = useTranslation();

  const [ResourceSearch, setResourceSearch] = useState("");

  const { data: resources, isLoading: isLoadingResources } = useResources({
    search: ResourceSearch,
  });

  const [TemplateSearch, setTemplateSearch] = useState("");
  const { data: templates, isLoading: isLoadingTemplates } = useGetTemplates({
    search: TemplateSearch,
  });

  const [NewCompanyModalOpen, setNewCompanyModalOpen] = useState(false);
  const [AddTemplateModalOpen, setAddTemplateModalOpen] = useState(false);

  const [OverViewDocumentModalOpen, setOverViewDocumentModalOpen] =
    useState(null);

  const [OverViewModalOpen, setDocumentModalOpen] = useState(null);
  const [UpdateCompanyModalOpen, setUpdateCompanyModalOpen] = useState<any>(null);

  const hasPermissionToCreateResources = usePermissions([
    "manage_resources_create",
  ]);

  const hasPermissionToEditResources = usePermissions([
    "manage_resources_edit",
  ]);

  const hasPermissionToDeleteResources = usePermissions([
    "manage_resources_delete",
  ]);

  const hasPermissionToDeleteTemplates = usePermissions([
    "manage_templates_delete",
  ]);

  const token: any = localStorage.getItem("token");

  const DownloadFile = (url: any) => {
    const file = url?.file;
    const fileName = url?.name ?? "";

    if (!file) {
      console.error("File is undefined or empty");
      return;
    }

    fetch(`https://devmainhub.insusales.com/api/insurance-management/${file}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/octet-stream",
      },
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => {
            console.error("Server returned an error:", json);
            throw new Error(`Server returned an error: ${json.message}`);
          });
        }
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
          return response.json().then((json) => {
            console.error("Server returned JSON instead of file:", json);
            throw new Error(
              `Server returned JSON instead of file: ${json.message}`
            );
          });
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch((error) =>
        console.error("There was an error with the fetch operation:", error)
      );
  };

  const [DeleteModalData, setDeleteModalData] = useState<any>()
  return (
    <>
      <Headline title={t("Documents")} />
      <div className="flex gap-5">
        <ConditionalRender
          condition={usePermissions(["manage_resources_view"])}
        >
          <div className="basis-2/3">
            <DashboardBoxLayout>
              <div className="flex gap-4 justify-between items-center">
                <DashboardBoxTitle title={`Resources`} />
                <ConditionalRender
                  condition={hasPermissionToCreateResources}
                >
                  <IconButton
                    onClick={() => setNewCompanyModalOpen(true)}
                    icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
                  >
                    {t("New Company")}
                  </IconButton>
                </ConditionalRender>
              </div>
              <div className="flex-1 my-[25px]">
                <Input
                  icon={IconType.SEARCH_ICON}
                  className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
                  classes={{ icon: "left-4 !top-[30%]" }}
                  placeholder={t("Search")}
                  value={ResourceSearch}
                  onChange={(e) => setResourceSearch(e.target.value)}
                />
              </div>
              {isLoadingResources ? (
                <div className="relative py-10">
                  <LoadingScreen />
                </div>
              ) : (
                <NoDataBoundary
                  condition={resources && (resources as any).data.length > 0}
                  fallback={
                    <p className="text-[#7D8592] font-inter-regular mx-auto my-20 flex justify-center">
                      {t("There is no Resources")}.
                    </p>
                  }
                >
                  {(resources as any)?.data.map((item: any, index: number) => (
                    <Accordion
                      classes={{ wrapper: "!border-[#F2F4F7] p-[25px]" }}
                      key={index}
                    >
                      <AccordionTitle subTitleClass="w-full">
                        <div className="flex gap-4 items-center w-full relative">
                          <ConditionalRender condition={item.file}>
                            <img
                              src={`https://devmainhub.insusales.com/api/insurance-management/${item.file}`}
                              alt=""
                              className="w-[44px] h-[44px] flex-none rounded-full"
                            />
                          </ConditionalRender>
                          <div className="flex-1 text-start">
                            <p className="font-inter-semibold text-primaryTextColor">
                              {item.name}
                            </p>
                            <span className="text-sm font-inter-regular text-[#7D8592]">
                              {new Date(item.created_at).toLocaleDateString(
                                "de"
                              )}
                            </span>
                          </div>
                          <div className="pe-10">
                            <a
                              href={item.link_to}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <IconButton>{t("Login to portal")}</IconButton>
                            </a>
                          </div>
                          <ConditionalRender condition={hasPermissionToEditResources}>
                            <div
                              className="absolute top-[-17px] right-[-35px] cursor-pointer"
                              onClick={() => {
                                setUpdateCompanyModalOpen(item);
                              }}
                            >
                              <Icon iconType={IconType.OPTIONS_ICON} />
                            </div>
                          </ConditionalRender>
                        </div>
                      </AccordionTitle>
                      <AccordionBody>
                        <div className="flex flex-col gap-5">
                          {item.overview_docs.map((doc: any, index: number) => (
                            <div key={index}>
                              <div className="text-xs text-primaryTextColor font-inter-semibold pb-3">
                                {doc.name}
                              </div>
                              <div className="flex gap-3">
                                {doc?.files !== null &&
                                  doc.files.map((file: any, index: number) => (
                                    <div className="relative" key={index}>
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://devmainhub.insusales.com/api/insurance-management/${file.file}`}
                                      >
                                        <div className="flex gap-2 bg-[#F2F4F7] px-5 py-3 rounded-[8px] items-center relative">
                                          <Flag
                                            code={
                                              file.country_flag === "en"
                                                ? "us"
                                                : file.country_flag
                                            }
                                            height={30}
                                            width={30}
                                          />
                                          <span className="text-sm text-primaryTextColor font-inter-regular">
                                            {file.name}
                                          </span>

                                        </div>
                                      </a>
                                      <ConditionalRender condition={hasPermissionToDeleteResources}>
                                      <div
                                        className="absolute -top-[5px] -right-[5px] cursor-pointer"
                                        onClick={() => {
                                          setDeleteModalData({ id: doc.id + '/' + file.file, name: file.name, deleteType: "overviewDocument" });
                                        }}
                                      >
                                        <Icon
                                          iconType={IconType.CLOSE_X_SM_ICON}
                                          color="var(--theme)"
                                          className="h-[17px] w-[17px]"
                                        />
                                      </div>
                                      </ConditionalRender>
                                    </div>
                                  ))}
                                <ConditionalRender condition={hasPermissionToCreateResources}>
                                  <div
                                    className="h-[44px] w-[44px] bg-[#F2F4F7] rounded-[8px] flex items-center justify-center cursor-pointer"
                                    onClick={() => {
                                      setOverViewDocumentModalOpen(doc.id);
                                    }}
                                  >
                                    <Icon
                                      iconType={IconType.PLUS_ICON}
                                      color="var(--theme)"
                                    />
                                  </div>
                                </ConditionalRender>
                                <ConditionalRender condition={hasPermissionToDeleteResources}>
                                <div
                                  className="h-[44px] w-[44px] bg-[#F2F4F7] rounded-[8px] flex items-center justify-center cursor-pointer"
                                  onClick={() => {
                                    setDeleteModalData({ id: doc.id, name: doc.name, deleteType: "overview" });
                                  }}
                                  >
                                  <Icon
                                    iconType={IconType.DELETE_ICON}
                                    color="var(--theme)"
                                    />
                                </div>
                                </ConditionalRender>
                              </div>
                            </div>
                          ))}
                          <ConditionalRender
                            condition={hasPermissionToCreateResources}
                          >
                            <div className="flex gap-2 items-center">
                              <Icon
                                iconType={IconType.PLUS_ICON}
                                color="#50B8E7"
                              />
                              <span
                                onClick={() => {
                                  setDocumentModalOpen(item.id);
                                }}
                                className="font-inter-semibold text-[--theme] cursor-pointer"
                              >
                                {t("Add new insurance overview")}
                              </span>
                            </div>
                          </ConditionalRender>
                        </div>
                      </AccordionBody>
                    </Accordion>
                  ))}
                </NoDataBoundary>
              )}
            </DashboardBoxLayout>
          </div>
        </ConditionalRender>
        <ConditionalRender
          condition={usePermissions(["manage_templates_view"])}
        >
          <div className="basis-1/3">
            <DashboardBoxLayout>
              <div className="flex justify-between gap-4 items-center">
                <DashboardBoxTitle title={`Templates`} />
                <ConditionalRender condition={usePermissions(['manage_templates_create'])}>
                  <div
                    className="bg-[--theme] max-w-[50px] w-full h-[30px] grid place-items-center rounded-[8px] cursor-pointer"
                    onClick={() => {
                      setAddTemplateModalOpen(true);
                    }}
                  >
                    <Icon iconType={IconType.PLUS_ICON} />
                  </div>
                </ConditionalRender>
              </div>
              <div className="flex-1 my-[25px]">
                <Input
                  icon={IconType.SEARCH_ICON}
                  className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
                  classes={{ icon: "left-4 !top-[30%]" }}
                  placeholder={t("Search")}
                  value={TemplateSearch}
                  onChange={(e) => setTemplateSearch(e.target.value)}
                />
              </div>
              <div className="flex flex-col gap-6">
                {isLoadingTemplates ? (
                  <div className="relative py-10">
                    <LoadingScreen />
                  </div>
                ) : (
                  <NoDataBoundary
                    condition={templates && templates.length > 0}
                    fallback={
                      <p className="text-[#7D8592] font-inter-regular mx-auto my-20 flex justify-center">
                        {t("There is no Templates")}.
                      </p>
                    }
                  >
                    {templates?.map((item: any, index: number) => (
                      <div className="flex gap-3 items-center" key={index}>
                        <Icon
                          iconType={
                            item.file.split(".")[
                              item.file.split(".").length - 1
                            ] === "pdf"
                              ? IconType.FILE_PDF_ICON
                              : item.file.split(".")[
                                item.file.split(".").length - 1
                              ] === "csv" ||
                                item.file.split(".")[
                                item.file.split(".").length - 1
                                ] === "xlsx" ||
                                item.file.split(".")[
                                item.file.split(".").length - 1
                                ] === "xls"
                                ? IconType.UPLOADED_CSV_ICON
                                : item.file.split(".")[
                                  item.file.split(".").length - 1
                                ] === "doc" ||
                                  item.file.split(".")[
                                  item.file.split(".").length - 1
                                  ] === "docx"
                                  ? IconType.FILE_WORD_ICON
                                  : IconType.FILE_JPG_ICON
                          }
                          className="flex-none"
                        />
                        <div className="flex-1">
                          <p className="text-primaryTextColor font-inter-medium">
                            {item.name}
                          </p>
                          <span className="text-xs text-[#7D8592] font-inter-regular">
                            {new Date(item.created_at).toLocaleDateString("de")}
                          </span>
                        </div>
                        <div>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://devmainhub.insusales.com/api/insurance-management/${item.file}`}
                          >
                            <Icon
                              iconType={IconType.SHOW_PASSWORD_ICON}
                              color="#7D8592"
                              className="flex-none"
                            />
                          </a>
                        </div>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            DownloadFile(item);
                          }}
                        >
                          <Icon
                            iconType={IconType.UPLOAD_FILE_ICON}
                            color="#7D8592"
                            className="flex-none"
                          />
                        </div>
                        <ConditionalRender condition={hasPermissionToDeleteTemplates}>
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setDeleteModalData({ id: item.id, name: item.name, deleteType: "template" });
                          }}
                        >
                          <Icon
                            iconType={IconType.DELETE_ICON}
                            color="#7D8592"
                            className="flex-none"
                          />
                        </div>
                        </ConditionalRender>
                      </div>
                    ))}
                  </NoDataBoundary>
                )}
              </div>
            </DashboardBoxLayout>
          </div>
        </ConditionalRender>
      </div>
      <DocumentDeleteModal
        onConfirm={() => {
          setDeleteModalData(undefined);
        }}
        data={DeleteModalData}
        clearData={true}
        hocisOpen={DeleteModalData !== undefined}
        hocCurrentStep={1}
        hocToggleModal={() => setDeleteModalData(undefined)}
        hocTitle={t("")}
        hocClasses={{
          modalClassName: "!max-w-[484px] w-full",
        }}
      />
      <NewCompany
        onConfirm={() => {
          setNewCompanyModalOpen(false);
        }}
        clearData={true}
        hocisOpen={NewCompanyModalOpen}
        hocCurrentStep={1}
        hocToggleModal={() => setNewCompanyModalOpen(false)}
        hocTitle={t("Add New Company")}
        hocClasses={{
          modalClassName: "!max-w-[484px] w-full",
        }}
      />
      <AddTemplate
        onConfirm={() => {
          setAddTemplateModalOpen(false);
        }}
        clearData={true}
        hocisOpen={AddTemplateModalOpen}
        hocCurrentStep={1}
        hocToggleModal={() => setAddTemplateModalOpen(false)}
        hocTitle={t("Add Template")}
        hocClasses={{
          modalClassName: "!max-w-[484px] w-full",
        }}
      />
      <NewOverview
        onConfirm={() => {
          setOverViewDocumentModalOpen(null);
        }}
        clearData={true}
        id={OverViewDocumentModalOpen as unknown as string}
        hocisOpen={OverViewDocumentModalOpen !== null}
        hocCurrentStep={1}
        hocToggleModal={() => setOverViewDocumentModalOpen(null)}
        hocTitle={t("Add Service Overview File")}
        hocClasses={{
          modalClassName: "!max-w-[484px] w-full",
        }}
      />
      <NewBaseOverview
        onConfirm={() => {
          setDocumentModalOpen(null);
        }}
        clearData={true}
        id={OverViewModalOpen as unknown as string}
        hocisOpen={OverViewModalOpen !== null}
        hocCurrentStep={1}
        hocToggleModal={() => setDocumentModalOpen(null)}
        hocTitle={t("Add new insurance overview")}
        hocClasses={{
          modalClassName: "!max-w-[484px] w-full",
        }}
      />
      <UpdateCompany
        onConfirm={() => {
          setUpdateCompanyModalOpen(null);
        }}
        clearData={true}
        data={UpdateCompanyModalOpen}
        hocisOpen={UpdateCompanyModalOpen !== null}
        hocCurrentStep={1}
        hocToggleModal={() => setUpdateCompanyModalOpen(null)}
        hocTitle={UpdateCompanyModalOpen?.name}
        hocClasses={{
          modalClassName: "!max-w-[484px] w-full",
        }}
      />
    </>
  );
};

export default InsuranceDocuments;
