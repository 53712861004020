import React, { FC, useCallback, useMemo, useState } from 'react'
import { DashboardLayoutProps } from './Layout1'
import AddWidgets from '../add-widgets/AddWidgets'
import DeleteWidget from '../delete-widget/DeleteWidget'
import SemiCircleChart from 'components/molecules/widgets/small/SemiCircleChart'
import EmptyWidget from '../empty-widget/EmptyWidget'
import DoubleBarWidget from 'components/molecules/widgets/medium/DoubleBarWidget'
import ListWidget from 'components/molecules/widgets/medium/ListWidget'
import ReminderWidget from 'components/molecules/widgets/medium/ReminderWidget'
import BarChartWidget from 'components/molecules/widgets/medium/BarChartWidget'
import TopSourceWidget from 'components/molecules/widgets/medium/TopSourceWidget'

const Layout5: FC<DashboardLayoutProps> = ({ isEditing, data }) => {

    const [AddWidgetModalData, setAddWidgetModalData] = useState<any>(null)

    const widgets = useMemo(() => {

        return data?.widgets
    }, [data])


    const getWidgetFromPosition = useCallback(
        (position: number) => {
            return widgets?.find((widget: any) => widget.position === position)
        },
        [widgets]
    );



    return (
        <div className={`grid  grid-cols-4 min-h-[85vh] gap-5`}>
            <div className="col-span-1 row-span-1 flex-1 rounded-xl relative">

                {widgets && getWidgetFromPosition(1) ? (
                    <>

                        <DeleteWidget isEditing={isEditing} data={{ id: data.id, widget_id: getWidgetFromPosition(1).widget_id, position: 1 }} />
                        <SemiCircleChart
                            api={getWidgetFromPosition(1).api_endpoint}
                            icon={getWidgetFromPosition(1).icon}
                            color={getWidgetFromPosition(1).color}
                            status={getWidgetFromPosition(1).widget_name}
                            name={getWidgetFromPosition(1).type}
                        />
                    </>
                ) : (
                    <EmptyWidget
                        setAddWidgetModalData={setAddWidgetModalData}
                        data={{ id: data.id, position: 1, size: '25x25' }}
                    />

                )}


            </div>
            <div className="col-span-1 row-span-1 flex-1 rounded-xl relative">
                {widgets && getWidgetFromPosition(2) ? (
                    <>

                        <DeleteWidget isEditing={isEditing} data={{ id: data.id, widget_id: getWidgetFromPosition(2).widget_id, position: 2 }} />
                        <SemiCircleChart
                            api={getWidgetFromPosition(2).api_endpoint}
                            icon={getWidgetFromPosition(2).icon}
                            color={getWidgetFromPosition(2).color}
                            status={getWidgetFromPosition(2).widget_name}
                            name={getWidgetFromPosition(2).type}
                        />
                    </>
                ) : (
                    <EmptyWidget
                        setAddWidgetModalData={setAddWidgetModalData}
                        data={{ id: data.id, position: 2, size: '25x25' }}
                    />

                )}
            </div>
            <div className="col-span-1 row-span-1 flex-1 rounded-xl relative">
                {widgets && getWidgetFromPosition(3) ? (
                    <>

                        <DeleteWidget isEditing={isEditing} data={{ id: data.id, widget_id: getWidgetFromPosition(3).widget_id, position: 3 }} />
                        <SemiCircleChart
                            api={getWidgetFromPosition(3).api_endpoint}
                            icon={getWidgetFromPosition(3).icon}
                            color={getWidgetFromPosition(3).color}
                            status={getWidgetFromPosition(3).widget_name}
                            name={getWidgetFromPosition(3).type}
                        />
                    </>
                ) : (
                    <EmptyWidget
                        setAddWidgetModalData={setAddWidgetModalData}
                        data={{ id: data.id, position: 3, size: '25x25' }}
                    />

                )}
            </div>
            <div className="col-span-1 row-span-1 flex-1 rounded-xl relative">
                {widgets && getWidgetFromPosition(4) ? (
                    <>

                        <DeleteWidget isEditing={isEditing} data={{ id: data.id, widget_id: getWidgetFromPosition(4).widget_id, position: 4 }} />
                        <SemiCircleChart
                            api={getWidgetFromPosition(4).api_endpoint}
                            icon={getWidgetFromPosition(4).icon}
                            color={getWidgetFromPosition(4).color}
                            status={getWidgetFromPosition(4).widget_name}
                            name={getWidgetFromPosition(4).type}
                        />
                    </>
                ) : (
                    <EmptyWidget
                        setAddWidgetModalData={setAddWidgetModalData}
                        data={{ id: data.id, position: 4, size: '25x25' }}
                    />

                )}
            </div>
            <div className="col-span-3 row-span-1 flex-1 rounded-xl relative">
                {widgets && getWidgetFromPosition(5) ? (
                    <>
                        <DeleteWidget isEditing={isEditing} data={{ id: data.id, widget_id: getWidgetFromPosition(5).widget_id, position: 5 }} />
                        {
                            (
                                getWidgetFromPosition(5).widget_name === 'Appointments Converted To Customer' ||
                                getWidgetFromPosition(5).widget_name === 'Pendings Done On Periods'
                            ) ? (
                                <DoubleBarWidget
                                    api={getWidgetFromPosition(5).api_endpoint}
                                    name={getWidgetFromPosition(5).widget_name}
                                    type={getWidgetFromPosition(5).type}

                                />
                            ) : (
                                <ListWidget
                                    api={getWidgetFromPosition(5).api_endpoint}
                                    name={getWidgetFromPosition(5).widget_name}
                                />
                            )
                        }

                    </>
                ) : (
                    <EmptyWidget
                        setAddWidgetModalData={setAddWidgetModalData}
                        data={{ id: data.id, position: 5, size: '75x25' }} />
                )}
            </div>
            <div className="col-span-1 row-span-2 flex-1 rounded-xl relative">
                {widgets && getWidgetFromPosition(6) ? (
                    <>
                        <DeleteWidget isEditing={isEditing} data={{ id: data.id, widget_id: getWidgetFromPosition(6).widget_id, position: 6 }} />
                    
                        {
                            (
                                getWidgetFromPosition(6).widget_name === 'Top Sources By Leads' ||
                                getWidgetFromPosition(6).widget_name === 'Top Sources By Appointments'
                            ) && (
                                <TopSourceWidget
                                    api={getWidgetFromPosition(6).api_endpoint}
                                    name={getWidgetFromPosition(6).widget_name}
                                    type={getWidgetFromPosition(6).type}
                                />
                            )
                        }
                        {
                            (getWidgetFromPosition(6).widget_name === 'Leads Created On Periods' ||
                                getWidgetFromPosition(6).widget_name === 'Appointments Created On Periods' ||
                                getWidgetFromPosition(6).widget_name === 'Customers Created On Periods' ||
                                getWidgetFromPosition(6).widget_name === 'Pendings Created On Periods'
                            ) && (
                                <BarChartWidget
                                    api={getWidgetFromPosition(6).api_endpoint}
                                    name={getWidgetFromPosition(6).widget_name}
                                    type={getWidgetFromPosition(6).type}
                                />
                            )
                        }
                        {
                            (getWidgetFromPosition(6).widget_name === 'Customers Birthday Reminder' ||
                                getWidgetFromPosition(6).widget_name === 'Pendings Reminder'
                            ) && (
                                <ReminderWidget
                                    api={getWidgetFromPosition(6).api_endpoint}
                                    name={getWidgetFromPosition(6).widget_name}
                                    type={getWidgetFromPosition(6).type}
                                />
                            )
                        }

                    </>
                ) : (
                    <EmptyWidget
                        setAddWidgetModalData={setAddWidgetModalData}
                        data={{ id: data.id, position: 6, size: '25x75' }}
                    />
                )}
            </div>
            <div className="col-span-3 row-span-1 flex-1 rounded-xl relative">
                {widgets && getWidgetFromPosition(7) ? (
                    <>
                        <DeleteWidget isEditing={isEditing} data={{ id: data.id, widget_id: getWidgetFromPosition(7).widget_id, position: 7 }} />
                        {
                            (
                                getWidgetFromPosition(7).widget_name === 'Appointments Converted To Customer' ||
                                getWidgetFromPosition(7).widget_name === 'Pendings Done On Periods'
                            ) ? (
                                <DoubleBarWidget
                                    api={getWidgetFromPosition(7).api_endpoint}
                                    name={getWidgetFromPosition(7).widget_name}
                                    type={getWidgetFromPosition(7).type}

                                />
                            ) : (
                                <ListWidget
                                    api={getWidgetFromPosition(7).api_endpoint}
                                    name={getWidgetFromPosition(7).widget_name}
                                />
                            )
                        }

                    </>
                ) : (
                    <EmptyWidget
                        setAddWidgetModalData={setAddWidgetModalData}
                        data={{ id: data.id, position: 7, size: '75x25' }} />
                )}
            </div>
            <AddWidgets
                data={AddWidgetModalData}
                hocisOpen={AddWidgetModalData !== null}
                hocToggleModal={() => setAddWidgetModalData(null)}
                hocCurrentStep={1}
                hocClasses={{
                    modalClassName: `sm:max-w-[900px] sm:w-full w-full `,
                }}
                hocTitle="Edit Dashboard"
                onConfirm={() => setAddWidgetModalData(null)}
            />
        </div>
    )
}

export default Layout5