import { memo } from "react";
interface Props {
  className?: string;
  color?: string;
}
function DotsIcon({ className, color }: Props) {
  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 52 52"
      enableBackground="new 0 0 52 52"
      xmlSpace="preserve"
      className={className}
    >
      <path
        d="M8,20c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6S4.7,20,8,20z M26,20c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6
   S22.7,20,26,20z M44,20c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6S40.7,20,44,20z"
      />
    </svg>
  );
}
export default memo(DotsIcon);
