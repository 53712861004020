import { FunctionComponent, Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import RoutesApp from "../Routes";
import Layout from "components/templates/credential-management-layout/Layout";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import Leads from "pages/Leads/Leads";
import SourceView from "pages/Source/SourceView";
import CampaignView from "pages/Campaign/CampaignView";
import { Security } from "pages/Security/Security";
import withPermission from "lib/helpers/hoc/withPermission";
import EmployeesManagement from "pages/EmployeesManagement/EmployeesManagement";
import CreateEmployeesManagement from "components/organisms/employees-management/create-employees-management/CreateEmployeesManagement";
import Customers from "pages/Customers/Customers";
import EmployeeProfile from "pages/EmployeeProfile/EmployeeProfile";
import CustomerSecurity from "pages/CustomerSecurity/CustomerSecurity";
import SidebarContextProvider from "lib/context/SidebarContext/SidebarContextProvider";
import Overview from "pages/Overview/Overview";
import UpdateEmployeesManagement from "components/organisms/employees-management/update-employees-management/UpdateEmployeesManagement";
import Partners from "pages/Partners/Partners";
import Pendings from "pages/Pendings/Pendings";
import StatisticsView from "pages/Statistics/StatisticsView";
import InsuranceDocumentsView from "pages/InsuranceDocuments/InsuranceDocumentsView";
import Finances from "pages/Finances/Finances";
import MonthlySuccess from "pages/Finances/MonthlySuccess";
import AnalysisCost from "pages/Finances/AnalysisCost";
import FinanceComparison from "pages/Finances/FinanceComparison";
import FinanceCommission from "pages/Finances/FinanceCommission";
import FinanceSalaries from "pages/Finances/FinanceSalaries";
import EmployeeRoles from "pages/EmployeeRoles/EmployeeRoles";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import CounterToolView from "pages/StatisticsCounterTool/CounterToolView";
import ApiToken from "pages/ApiToken/ApiToken";
import AllCommissions from "components/organisms/employee-profile/all-commissions/AllCommissions";

const AppointmentManagement = lazy(
  () => import("pages/AppointmentManagement/AppointmentManagement")
);

export interface IAuthenticatedRoutes { }
const AuthenticatedRoutes: FunctionComponent<IAuthenticatedRoutes> = () => {


  const hasPermissionToViewCostManagement = usePermissions(["cost_management_view"]);
  const hasPermissionToViewPerformaceTracking = usePermissions(["performance_tracking_view"]);
  const hasPermissionToViewCostAnalysis = usePermissions(["cost_analysis_view"]);
  const hasPermissionToViewPlanedActual = usePermissions(["planned_actual_soll_ist_view"]);
  const hasPermissionToViewCommissionModels = usePermissions(["commission_models_view"]);


  const router = createBrowserRouter([
    {
      path: "/dashboard",
      element: (
        <Layout>
          <Overview />
        </Layout>
      ),
      children: [],
    },
    {
      path: "/external-source",
      element: (
        <Layout>
          <ApiToken />
        </Layout>
      ),
      children: [],
    },
    {
      path: "/leads",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(Leads, ["lead_view_view_own"])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/leads/source",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(SourceView, [
              "source_management_view_own",
              "source_management_view",
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/appointments",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(
              AppointmentManagement,
              ["apointment_view_view", "apointment_view_view_own"]
            )({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/leads/campaign",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(CampaignView, [
              "campaign_management_view",
              "campaign_management_view_own",
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/leads/security",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(Security, [
              "export_leads_view",
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: `hr/update/:id`,
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            <CreateEmployeesManagement />
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/hr/employee/update",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            <EmployeeProfile />
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/customers",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(Customers, [
              "customers_view_view",
              "customers_view_view_own",
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/customers/security",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(CustomerSecurity, ["export_customers_view"])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/hr",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(
              usePermissions([
                "employee_management_view",
                "employee_management_view_view_own",
                "onboarding_management_view",
                "onboarding_management_view_view_own",
                "offboarding_management_view",
                "offboarding_management_view_view_own",
              ])
                ? EmployeesManagement
                : EmployeeRoles,
              [
                "employee_management_view",
                "employee_management_view_view_own",
                "roles_management_view",
                "onboarding_management_view",
                "onboarding_management_view_view_own",
                "offboarding_management_view",
                "offboarding_management_view_view_own",
              ]
            )({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/hr/roles",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(EmployeeRoles, ["roles_management_view"])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/hr/employe-profile/:id",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            <EmployeeProfile />
            {/* {withPermission(EmployeeProfile, [
              "employee_management_view",
              "employee_management_view_view_own",
            ])({})} */}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/hr/update-employee/:id",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(UpdateEmployeesManagement, [
              'employee_management_edit'
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/hr/employe-profile/:id/commissions",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(AllCommissions, [
               "employee_management_view",
               "employee_management_view_view_own",
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/partners",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(Partners, [
              'manage_partners_view'
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/pendings",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(Pendings, [
              "pending_hr_view_view",
              "pending_hr_view_view_own",
              "pending_customer_view_view",
              "pending_customer_view_view_own",
              "pending_myown_view_view",
              "pending_other_view_view",
              "pending_other_view_view_own",
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/statistics",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            <StatisticsView />
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/statistics/counter-tool",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            <CounterToolView />
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/finances",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(
              hasPermissionToViewCostManagement ? Finances
                : hasPermissionToViewPerformaceTracking ? MonthlySuccess
                  : hasPermissionToViewCostAnalysis ? AnalysisCost
                    : hasPermissionToViewPlanedActual ? FinanceComparison
                      : hasPermissionToViewCommissionModels ? FinanceCommission
                        : FinanceSalaries,
              [
                'cost_management_view',
                'performance_tracking_view',
                'cost_analysis_view',
                'planned_actual_soll_ist_view',
                'commission_models_view',
                'salaries_view'
              ]
            )({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/finances/success",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(MonthlySuccess, [
              'performance_tracking_view'
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/finances/analysis",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(AnalysisCost, [
              'cost_analysis_view'
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/finances/comparison",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(FinanceComparison, [
              'planned_actual_soll_ist_view'
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/finances/commission",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(FinanceCommission, [
              'commission_models_view'
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/finances/salaries",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(FinanceSalaries, [
              'salaries_view'
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
    {
      path: "/insurance-documents",
      element: (
        <Layout>
          <Suspense fallback={<LoadingScreen />}>
            {withPermission(InsuranceDocumentsView, [
              "manage_resources_view",
              "manage_templates_view",
            ])({})}
          </Suspense>
        </Layout>
      ),
      children: [],
    },
  ]);
  return (
    <SidebarContextProvider>
      <RoutesApp appRoutes={router} />
    </SidebarContextProvider>
  );
};
export default AuthenticatedRoutes;
