import { customStyles } from 'lib/helpers/configs/customStyles'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'
import ApexCharts from "react-apexcharts";
import { useWidgetData } from 'lib/hooks/queries/Dashboard/useWidgetData';
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen';


interface Props {
    name: string
    api: string
    type: string
}

const DoubleBarWidget: FC<Props> = ({ name, api, type }) => {

    const { t } = useTranslation()
    const [Filter, setFilter] = useState('This week')

    const { data, isLoading } = useWidgetData({ endpoint: api, params: { filter_on: Filter }, type: name })

    if (isLoading) return <LoadingScreen />

    const options = {
        chart: {
            type: "bar",
            stacked: false, // Disable stacking to show bars side by side
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                columnWidth: "40%", // Adjust bar width
                borderRadius: 8,
                borderRadiusApplication: 'end',
            },
        },
        colors: ["var(--theme)", "#2CC95B"], // Blue for total appointments, Green for converted appointments
        dataLabels: {
            enabled: false, // Disable data labels inside the bars
        },
        grid: {
            show: true,
            borderColor: '#F2F2F2',
            strokeDashArray: 5,
            position: 'back',


        },
        yaxis: {
            tickAmount: 5, // Adjust this based on your data range
            min: 0,
            max: 1, // Ensure the max value aligns with the last grid line
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
        },
        xaxis: {
            categories: (data as any)?.data.map((item: any, index: number) => {
                return item.x
            }),
        },

        legend: {
            position: "bottom",
            horizontalAlign: "center",
            markers: {
                width: 11,
                height: 11,
                shape: "circle"
            },
            itemMargin: {
                horizontal: 10,
                vertical: 5,
            },
        },

    };

    const series = [
        {
            name: {
                'Appointments Converted To Customer': t('Total Appointments'),
                'Pendings Done On Periods': t('Total Pendings'),

            }[name],
            data: (data as any)?.data.map((item: any) => {
                return item.total
            })
        },
        {
            name: {
                'Appointments Converted To Customer': t('Appointments Converted To Customers'),
                'Pendings Done On Periods': t('Recorded Pendings'),
            }[name],
            data: (data as any)?.data.map((item: any) => {
                return item.won
            })
        },
    ];

    return (
        <div className='w-full h-full bg-white border border-[#F2F4F7] rounded-2xl p-5'>
            <div className='pb-5 flex items-center gap-4 justify-between'>
                <div className='text-primaryTextColor font-inter-bold xl:text-lg 2xl:text-xl'>
                    {t(name)}
                </div>
                <div>
                    <CreatableSelect<any, false>
                        options={[
                            { value: "Today", label: t("Today") },
                            { value: "This week", label: t("This week") },
                            { value: "This month", label: t("This month") },
                        ]}
                        value={{
                            value: Filter,
                            label: t(Filter)
                        }}
                        className="cursor-pointer font-inter-regular react-select-container 3xl:min-w-[150px]"
                        classNamePrefix="react-select"
                        onChange={(newValue: any, actionMeta: any) => {
                            if (
                                actionMeta.action === "select-option" &&
                                newValue?.value
                            ) {
                                setFilter(newValue.value)
                            }
                        }}
                        styles={customStyles}
                        isValidNewOption={() => false}
                        menuPosition={"fixed"}
                        menuPortalTarget={document.body}
                    />
                </div>
            </div>
            <div className='overflow-hidden'>
                <ApexCharts options={options as any} series={series} type="bar" height={200} />
            </div>
        </div>
    )
}

export default DoubleBarWidget