import Headline from "components/atoms/headline/Headline";
import TabsSwitch from "components/molecules/tabs/TabsSwitch";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TargetDetails from "./TargetDetails";
import OtherBonuses from "./OtherBonuses";
import { useSalaryGoals } from "lib/hooks/queries/Employee/useSalaryGoals";
import { useParams } from "react-router-dom";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { useSalaryBonuses } from "lib/hooks/queries/Employee/useSalaryBonuses";

const TargetAndBonusTab = () => {

    const { t } = useTranslation();
    const [selectedTabSwitch, setSelectedTabSwitch] = useState<number>(0);

    const params = useParams()

    const { data: GoalsData, isLoading: GoalsLoading } = useSalaryGoals(params)
    const { data: BonusesData, isLoading: BonusesLoading } = useSalaryBonuses(params)



    return (
        <div>
            <Headline title={t("Targets & Bonus")} className="!mt-0 !mb-[15px]" />
            <TabsSwitch
                ActiveTab={selectedTabSwitch}
                setSelectedTab={(id) => setSelectedTabSwitch(id)}
                classes={{
                    iconAndTextWrapperClass:
                        "text-[13px] lg:text-[14px] xl:text-[16px] flex items-center",
                    text: "!pl-1 !pb-[8px]",
                    unActiveTabClass: "!pl-1 !pb-[8px] w-full",
                    tabsClass: 'gap-4'
                }}
                tabs={[
                    {
                        id: 0,
                        name: "Target Details",
                    },

                    {
                        id: 1,
                        name: "Other Bonuses",
                    },

                ]}
            />

            {
                (GoalsLoading || BonusesLoading) ? (
                    <div className="relative py-16">
                        <LoadingScreen />
                    </div>
                ) : (
                    {
                        0: <TargetDetails data={GoalsData.goals} />,
                        1: <OtherBonuses data={BonusesData.bonuses} />,
                    }[selectedTabSwitch]
                )
            }
        </div>
    );
};

export default TargetAndBonusTab;

