import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getTodayTomorrowAppointments } from "api/Statistics";



export const useTTAppointments = () => {

    const { data, isLoading } = useQuery<Array<any>>(
        ['ttAppointments'],
        () => {
            return getTodayTomorrowAppointments();
        }, {
        onError() {
            toast.error(`Something went wrong. Please try again later.`);
        },
    }
    );

    return {
        data,
        isLoading,
    };
};
