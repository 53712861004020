import { registerNewUser } from "api/User";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useRegisterUser = () => {
    const queryClient = useQueryClient();

    return useMutation(registerNewUser, {
        onSuccess: () => {
            queryClient.invalidateQueries('customers');
            toast.success('Created successfully');

        },
        onError: (error: any) => {
            toast.error(error.response.data.error);
        },
    });
};
