import Button from "components/atoms/button/Button";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import Headline from "components/atoms/headline/Headline";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import Tooltip from "components/molecules/tooltip/Tooltip";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useHover } from "lib/hooks/shared/useHover";
import { FC, Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

interface ISalaryDetailsProps {
  salaryLoading: boolean;
  salaryData: any;
}

const SalaryDetails: FC<ISalaryDetailsProps> = ({
  salaryLoading,
  salaryData,
}) => {
  const { t } = useTranslation();

  const { id } = useParams();
  const { isHovered, onMouseEnter, onMouseLeave } = useHover();
  const { isHovered:isHoveredFixedSalary, onMouseEnter:onMouseEnterFixedSalary, onMouseLeave:onMouseLeaveFixedSalary } = useHover();

  const FixedSalary = useMemo(() => {
    if (salaryLoading) {
      return;
    }

    if (salaryData.salary_frequency !== "monthly") {
      return 0;
    }

    return salaryData.fixed_salary;
  }, [salaryData, salaryLoading]);

  const brutoSalary = useMemo(() => {
    if (salaryLoading) {
      return;
    }

    return (
      FixedSalary +
      salaryData.totalAmountOtherBonusesMonthly +
      salaryData.provisioned_total -
      salaryData.canceled_total
    );
  }, [salaryLoading, salaryData]);

  const CalculatedSocialExpenses = useMemo(() => {
    if (salaryLoading) {
      return;
    }

    let totalSocialExpenses = 0;

    salaryData.expenses.forEach((element: any) => {
      if (element.type === "Social Insurance") {
        let expensesPercentageCalc =
          ((brutoSalary as any) * parseFloat(element.amount)) / 100;
        let socialExpensesCalculation =
          element.measurement_type === "CHF"
            ? parseFloat(element.amount)
            : expensesPercentageCalc;
        totalSocialExpenses += socialExpensesCalculation;
      }
    });

    return totalSocialExpenses;
  }, [salaryData, salaryLoading]);

  const CalculatedOtherExpenses = useMemo(() => {
    if (salaryLoading) {
      return;
    }

    const netSalaryPlusCompensation =
      (brutoSalary as any) -
      (CalculatedSocialExpenses as any) +
      salaryData.total_compensation_amount;

    let totalOtherExpenses = 0;

    salaryData.expenses.forEach((element: any) => {
      if (element.type === "Other Expenses") {
        let expensesPercentageCalc =
          (netSalaryPlusCompensation * parseFloat(element.amount)) / 100;
        let socialExpensesCalculation =
          element.measurement_type === "CHF"
            ? parseFloat(element.amount)
            : expensesPercentageCalc;
        totalOtherExpenses += socialExpensesCalculation;
      }
    });

    return totalOtherExpenses;
  }, [salaryData, salaryLoading]);

  if (salaryLoading) {
    return (
      <div className="relative py-20">
        <LoadingScreen />
      </div>
    );
  }

  if (
    salaryData?.fixed_salary === undefined ||
    salaryData?.fixed_salary === null
  ) {
    return <EmptyEntity name="Salaries" />;
  }
  return (
    <div className="flex flex-col gap-5">
      <div className="border border-border rounded-[20px] p-5">
        <Headline
          title={t("Fixed Salary")}
          className="!mt-0 !mb-[15px] !text-[20px]"
        />
        <div className="grid grid-cols-1  lg:grid-cols-2 gap-[25px]">
          <Input
            label={t("Salary Model Name")}
            classes={{
              label:
                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
            }}
            className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
            value={salaryData.salary_name}
            disabled={true}
          />
          <div>
            <div className="relative flex gap-2 items-center">
              <Label>{t("Fixed Salary")}</Label>
              {salaryData.salary_frequency !== 'monthly' && (
              <>
              <Button
                className="mb-2"
                onMouseLeave={onMouseLeaveFixedSalary}
                onMouseEnter={onMouseEnterFixedSalary}
              >
                <Icon iconType={IconType.ALERT_ICON} color="var(--theme)" />
              </Button>
              <Tooltip
                isOpen={isHoveredFixedSalary}
                className="absolute w-[250px] h-auto bg-white p-[10px] rounded-[25px] shadow-custom  top-[-10px] !z-[99999]"
                content={
                  <Fragment>
                    <ul className="list-disc text-[14px]  ml-4 font-inter-regular">
                      <li>
                        {t(
                          "This fixed salary is not for a monthly period and will not be included in the monthly salary calculation"
                        )}
                        .
                      </li>
                    </ul>
                  </Fragment>
                }
              />
              </>
              )}
            </div>

            <Input
              classes={{
                label:
                  "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
              }}
              className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
              value={salaryData?.fixed_salary.toFixed(2) + " CHF"}
              disabled={true}
            />
          </div>
          
          <Input
            label={t("13th Month Salary")}
            classes={{
              label:
                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
            }}
            className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
            value={
              salaryData.salary_thirteenth_month_salary === "yes" ? "Yes" : "No"
            }
            disabled={true}
          />
          <Input
            label={t("Payment Interval")}
            classes={{
              label:
                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
            }}
            className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
            value={
              salaryData.salary_frequency.charAt(0).toUpperCase() +
              salaryData.salary_frequency.slice(1)
            }
            disabled={true}
          />
        </div>
      </div>
      {salaryData.otherMonthlyBonuses?.length > 0 && (
        <div className="border border-border rounded-[20px] p-5">
          <Headline
            title={t("Monthly Bonuses")}
            className="!mt-0 !mb-[15px] !text-[20px]"
          />
          <div className="grid grid-cols-1  lg:grid-cols-2 gap-[25px]">
            {salaryData.otherMonthlyBonuses.filter(
              (item: any) => item.type === "Other Bonus"
            ).length > 0 && (
              <>
                <div className="col-span-2 -mb-3">
                  <span className="text-primaryTextColor font-inter-semibold">
                    {t("Other Bonus")}
                  </span>
                </div>
                {salaryData?.otherMonthlyBonuses?.map(
                  (item: any, index: number) =>
                    item.type === "Other Bonus" ? (
                      <>
                        <Input
                          key={index}
                          label={item.name}
                          classes={{
                            label:
                              "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                          }}
                          className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                          value={item.bonus_value + " CHF"}
                          disabled={true}
                        />
                        <Input
                          label={t("Description")}
                          classes={{
                            label:
                              "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                          }}
                          className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                          value={item.description || "-"}
                          disabled={true}
                        />
                      </>
                    ) : null
                )}
              </>
            )}
          </div>
        </div>
      )}
      <div className="border border-border rounded-[20px] p-5">
        <Headline
          title={t("Expenses")}
          className="!mt-0 !mb-[15px] !text-[20px]"
        />
        <div className="grid grid-cols-1  lg:grid-cols-2 gap-[25px]">
          {salaryData.expenses.filter(
            (item: any) => item.type === "Social Insurance"
          ).length > 0 && (
            <>
              <div className="col-span-2 -mb-3">
                <span className="text-primaryTextColor font-inter-semibold">
                  {t("Social Insurance")}
                </span>
              </div>
              {salaryData?.expenses?.map((item: any, index: number) =>
                item.type === "Social Insurance" ? (
                  <Input
                    key={index}
                    label={item.name}
                    classes={{
                      label:
                        "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                    }}
                    className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                    value={item.amount + " " + item.measurement_type}
                    disabled={true}
                  />
                ) : null
              )}
            </>
          )}
          {salaryData.expenses.filter(
            (item: any) => item.type === "Other Expenses"
          ).length > 0 && (
            <>
              <div className="col-span-2 -mb-3">
                <span className="text-primaryTextColor font-inter-semibold">
                  {t("Other Expenses")}
                </span>
              </div>
              {salaryData?.expenses?.map((item: any, index: number) =>
                item.type === "Other Expenses" ? (
                  <Input
                    key={index}
                    label={item.name}
                    classes={{
                      label:
                        "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
                    }}
                    className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                    value={item.amount + " " + item.measurement_type}
                    disabled={true}
                  />
                ) : null
              )}
            </>
          )}
        </div>
      </div>
      <div className="border border-border rounded-[20px] p-5">
        <Headline
          title={t("Commission")}
          className="!mt-0 !mb-[15px] !text-[20px]"
        />
        <div className="grid grid-cols-1  lg:grid-cols-2 gap-[25px]">
          <Input
            label={t("Provisioned Total")}
            classes={{
              label:
                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
            }}
            className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
            value={salaryData?.provisioned_total.toFixed(2) + " CHF"}
            disabled={true}
          />
          <Input
            label={t("Canceled Previous Months")}
            classes={{
              label:
                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
            }}
            className="!border-0 text-[#F45B69] !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
            value={salaryData?.canceled_total.toFixed(2) + " CHF"}
            disabled={true}
          />
          <div>
            <div className="relative flex gap-2 items-center">
              <Label>{t("Canceled This Month")}</Label>
              <Button
                className="mb-2"
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter}
              >
                <Icon iconType={IconType.ALERT_ICON} color="var(--theme)" />
              </Button>
              <Tooltip
                isOpen={isHovered}
                className="absolute w-[250px] h-auto bg-white p-[10px] rounded-[25px] shadow-custom  top-[-10px] right-[170px] !z-[99999]"
                content={
                  <Fragment>
                    <ul className="list-disc text-[14px]  ml-4 font-inter-regular">
                      <li>
                        {t(
                          "This shows all cancellations that were provisioned and canceled in the same month"
                        )}
                        .
                      </li>
                    </ul>
                  </Fragment>
                }
              />
            </div>

            <Input
              classes={{
                label:
                  "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
              }}
              className="!border-0 text-[#F45B69] !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
              value={salaryData?.canceledThisMonth.toFixed(2) + " CHF"}
              disabled={true}
            />
          </div>

          <div className="col-span-2">
            <Link
              to={`/hr/employe-profile/${id}/commissions`}
              className="flex items-center gap-1 justify-start cursor-pointer"
            >
              <div className="text-sm font-inter-medium text-theme">
                {t("View All Commissions")}
              </div>
              <Icon iconType={IconType.RIGHT_ARROW_ICON} color="var(--theme)" />
            </Link>
          </div>
        </div>
      </div>
      <div className="border border-border rounded-[20px] p-5">
        <Headline
          title={t("Net Salary")}
          className="!mt-0 !mb-[15px] !text-[20px]"
        />
        <div className="grid grid-cols-1  lg:grid-cols-2 gap-[25px]">
          <Input
            label={t("Fixed Salary")}
            classes={{
              label:
                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
            }}
            className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
            value={FixedSalary.toFixed(2) + " CHF"}
            disabled={true}
          />
          <Input
            label={t("Commission")}
            classes={{
              label:
                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
            }}
            className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
            style={{
              color:
                salaryData.provisioned_total - salaryData.canceled_total >= 0
                  ? "#2CC95B"
                  : "#F45B69",
            }}
            value={
              (
                salaryData.provisioned_total - salaryData.canceled_total
              )?.toFixed(2) + " CHF"
            }
            disabled={true}
          />
          {salaryData.otherMonthlyBonuses?.length > 0 && (
            <Input
              label={t("Monthly Bonuses")}
              classes={{
                label:
                  "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
              }}
              className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
              style={{
                color:
                  salaryData.totalAmountOtherBonusesMonthly >= 0
                    ? "#2CC95B"
                    : "#F45B69",
              }}
              value={
                salaryData.totalAmountOtherBonusesMonthly?.toFixed(2) + " CHF"
              }
              disabled={true}
            />
          )}
          <Input
            label={t("Expenses")}
            classes={{
              label:
                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
            }}
            className="!border-0 text-[#F45B69] !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
            value={
              (CalculatedSocialExpenses as any) +
              CalculatedOtherExpenses +
              " CHF"
            }
            disabled={true}
          />
          <Input
            label={t("Compensation")}
            classes={{
              label:
                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
            }}
            className="!border-0 text-[#2CC95B] !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
            value={salaryData?.total_compensation_amount.toFixed(2) + " CHF"}
            disabled={true}
          />

          <Input
            label={t("Net Salary")}
            classes={{
              label:
                "text-[#A2A1A8] font-inet-regular text-[14px] leading-[20px] !mb-0",
            }}
            className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
            value={
              FixedSalary -
              (CalculatedSocialExpenses as any) -
              (CalculatedOtherExpenses as any) +
              salaryData.total_compensation_amount +
              salaryData.provisioned_total -
              salaryData.canceled_total +
              salaryData.totalAmountOtherBonusesMonthly +
              " CHF"
            }
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};

export default SalaryDetails;
