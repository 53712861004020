import { getGeneralStats } from "api/Statistics";
import { useQuery } from "react-query";
import { toast } from "react-toastify";


interface FiltersProps {
    from_date_of_creation?: string,
    to_date_of_creation?: string,
    filter_on?: string,
    type_of_statistic?: string
}
export const useGeneralStats = (
    filters?: FiltersProps
) => {

    const { data, isLoading } = useQuery<Array<any>>(
        ['generalStats', filters?.from_date_of_creation, filters?.to_date_of_creation, filters?.filter_on],
        () => {
            return getGeneralStats({
                from_date_of_creation: filters?.filter_on === 'Individual' ? filters?.from_date_of_creation : null,
                to_date_of_creation: filters?.filter_on === 'Individual' ? filters?.to_date_of_creation : null,
                filter_on: filters?.filter_on === 'Individual' ? null : filters?.filter_on,
            });
        }, {
        onError() {
            toast.error(`Something went wrong. Please try again later.`);
        },
    }
    );

    return {
        data,
        isLoading,
    };
};
