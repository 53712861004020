import Headline from "components/atoms/headline/Headline";
import { FC, useState } from "react";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import Input from "components/atoms/input/Input";
import Button from "components/atoms/button/Button";
import TabsSwitch from "components/molecules/tabs/TabsSwitch";
import ImageDropZone from "components/molecules/image-dropzone/ImageDropZone";
import { Controller, useForm } from "react-hook-form";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import CustomSelect, { OptionsType } from "components/atoms/custom-select/CustomSelect";
import FileDropZone from "components/molecules/file-dropzone/FileDropZone";
import { useNavigate, useParams } from "react-router-dom";
import { useEmployeSelfProfile } from "lib/hooks/queries/Employee/useEmployeSelfProfile";
import { useUpdateEmployee } from "lib/hooks/mutations/Employee/useUpdateEmployee";
import Form from "components/atoms/form/Form";
import UploadedFile from "components/molecules/uploaded-file/UploadedFile";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import ErrorState from "components/atoms/error-state/ErrorState";
import { useTranslation } from "react-i18next";

export interface IUpdateEmployeesManagement {
  onConfirm?: () => void;
}

const UpdateEmployeesManagement: FC<IUpdateEmployeesManagement> = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { id } = useParams();
  const { data } = useEmployeSelfProfile(id);
  const { t } = useTranslation();
  const [updateProfilePicture, setUpdateProfilePicture] =
    useState<boolean>(false);

  const {
    mutateAsync: updateEmployee,
    isLoading: updateLoading,
    isError,
    error,
  } = useUpdateEmployee();

  const {
    formState: { errors },
    handleSubmit,
    control,
    register,
    setValue,
  } = useForm({
    values: {
      ...data,
      ...(data && data?.data?.user_information),
      name: data?.data?.name,
      last_name: data?.data?.last_name,
      phone: data?.data?.user_information?.phone,
      email: data?.data?.email,
      marital_status: data?.data?.user_information?.marital_status,
      gender: data?.data?.user_information?.gender,
      birthdate: data?.data?.user_information?.birthdate,
      city: data?.data?.user_information?.city,
      address: data?.data?.user_information?.address,
      state: data?.data?.user_information?.state,
      zip_code: data?.data?.user_information?.zip_code,
      id: data?.data?.id,
      employee_type: data?.data?.user_information?.employee_type,
      department: data?.data?.user_information?.department,
      working_days: data?.data?.user_information?.working_days,
      office_location: data?.data?.user_information?.office_location,
      position: data?.data?.user_information?.position,
      strato_id: data?.data?.user_information?.strato_id,
      skype_id: data?.data?.user_information?.skype_id,
      github_id: data?.data?.user_information?.github_id,
      appointment_letter_file:
        data?.data?.user_information?.appointment_letter_file,
      experience_letter_file:
        data?.data?.user_information?.experience_letter_file,
      reliving_letter_file: data?.data?.user_information?.reliving_letter_file,
      salary_slips_file: data?.data?.user_information?.salary_slips_file,
    },
  });

  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    const necessaryFields = [
      "id",
      "name",
      "last_name",
      "email",
      "phone",
      "birthdate",
      "gender",
      "marital_status",
      "nationality",
      "address",
      "city",
      "state",
      "zip_code",
      "working_days",
      "employee_type",
      "position",
      "department",
      "state",
      "office_location",
      "strato_id",
      "skype_id",
      "github_id",
      "experience_letter_file",
      "reliving_letter_file",
      "appointment_letter_file",
      "salary_slips_file",
      "profil_picture",
    ];

    const formData = new FormData();

    for (const key of necessaryFields) {
      if (data[key] !== null && data[key] !== undefined) {
        if (
          [
            "experience_letter_file",
            "reliving_letter_file",
            "appointment_letter_file",
            "salary_slips_file",
          ].includes(key) &&
          data[key] instanceof FileList
        ) {
          for (let i = 0; i < data[key].length; i++) {
            formData.append(key, data[key][i]);
          }
        } else if (
          [
            "experience_letter_file",
            "reliving_letter_file",
            "appointment_letter_file",
            "salary_slips_file",
          ].includes(key) &&
          typeof data[key] === "string"
        ) {
          continue;
        } else {
          formData.append(key, data[key]);
        }
      }
    }
    updateEmployee(formData)
      .then(() => {
        navigate(`/hr`);
      })
      .catch((error) => {
        console.error("Update failed", error);
      });
  };

  const { themeColors } = useThemeContext();

  const renderTabs = () => {
    switch (selectedTab) {
      case 0:
        return (
          <div>
            {updateProfilePicture ? (
              <div className="relative" style={{ width: "fit-content" }}>
                <ImageDropZone
                  {...register("profil_picture")}
                  name="profil_picture"
                  control={control}
                  error={errors?.profil_picture}
                  classes={{ container: "max-w-[150px]", subContainer: "ml-0" }}
                  acceptParameters="image/png, image/jpg, image/jpeg"
                />
                <div
                  className="absolute top-4 right-4 cursor-pointer"
                  onClick={() => {
                    setUpdateProfilePicture(false);
                  }}
                >
                  <Icon iconType={IconType.CLOSE_X_SM_ICON} />
                </div>
              </div>
            ) : (
              <div
                className="relative m-4 ml-0"
                style={{ width: "fit-content" }}
              >
                <img
                  src={
                    data?.data?.user_information?.profil_picture
                      ? `https://devmainhub.insusales.com/api/profile-picture/${data?.data?.user_information?.profil_picture}`
                      : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
                  }
                  className="object-cover rounded-[10px] w-[130px] h-[130px]"
                  alt="img"
                  loading="lazy"
                />
                <div
                  className="absolute top-1 right-1 cursor-pointer"
                  onClick={() => {
                    setValue("profil_picture", null);
                    setUpdateProfilePicture(true);
                  }}
                >
                  <Icon
                    iconType={IconType.EDIT_SOURCE_ICON}
                    color={themeColors.primary}
                  />
                </div>
              </div>
            )}
            <div className="grid grid-cols-2 gap-5">
              <Input
                placeholder={t("First Name")}
                {...register("name")}
                label={t("First Name")}
                className="h-[48px]"
              />
              <Input
                placeholder={t("Last Name")}
                {...register("last_name")}
                label={t("Last Name")}
                className="h-[48px]"
              />
              <Input
                placeholder={t("Mobile Number")}
                label={t("Mobile Number")}
                {...register("phone")}
                className="h-[48px]"
              />
              <Input
                placeholder={t("Email Address")}
                {...register("email")}
                label={t("Email Address")}
                className="h-[48px]"
              />
              <Controller
                control={control}
                name="birthdate"
                rules={{ required: `${t("Date of birth is required")}` }}
                render={({ field: { onChange, value } }) => (
                  <PrimaryDatePicker
                    startDate={value}
                    setStartDate={onChange}
                    className="h-[48px]"
                    iconClassName="mt-[5px]"
                    label={t("Birthdate")}
                    {...register("birthdate")}
                  />
                )}
              />
              <CustomSelect
                customOptions={[
                  { label: `${t("Married")}`, value: "Married" },
                  { label: `${t("Single")}`, value: "Single" },
                  { label: `${t("Divorced")}`, value: "Divorced" },
                  { label: `${t("Widowed")}`, value: "Widowed" },
                ]}
                {...register("marital_status")}
                className="h-[48px]"
                label={t("Marital Status")}
              />
              <CustomSelect
                customOptions={[
                  { label: `${t("Yes")}`, value: "yes" },
                  { label: `${t("No")}`, value: "no" },

                ]}
                {...register("children")}
                className="h-[48px]"
                label={t("Children")}
              />
              <CustomSelect
                customOptions={[
                  { label: `${t("Male")}`, value: "Male" },
                  { label: `${t("Female")}`, value: "Female" },
                ]}
                className="h-[48px]"
                {...register("gender")}
                label={t("Gender")}
              />
              <Input
                placeholder={t("Nationality")}
                className="!p-[16px] h-[48px]"
                {...register("nationality")}
                label={t("Nationality")}
              />
              <CustomSelect
                optionsType={OptionsType.LANGUAGE_OPTIONS}
                className="h-[48px]"
                {...register("language")}
                label={t("Language")}
              />
              <Input
                placeholder={t("Address")}
                className="mb-5 h-[48px]"
                {...register("address")}
                label={t("Address")}
              />
              <Input
                placeholder={t("Zip Code")}
                {...register("zip_code")}
                className="h-[48px]"
                label={t("Zip Code")}
              />
            </div>
            {/*             
            <div className="grid grid-cols-3 gap-5">
              <Input
                placeholder={t("City")}
                className="h-[48px]"
                label={t("City")}
                {...register("city")}
              />
              <Input
                placeholder={t("State")}
                {...register("state")}
                className="h-[48px]"
                label={t("State")}
              />
             
            </div> */}
            <div className="flex items-center justify-end gap-5 mb-[50px]">
              <Button
                onClick={() => navigate(`/hr/employe-profile/${id}`)}
                type="button"
                className="text-[#91929E] bg-[#F4F9FD] flex items-center min-w-[130px]  mt-[47px] justify-center  p-3 rounded-[8px] font-inter-bold"
              >
                {t("Cancel")}
              </Button>
              <Button
                onClick={() => setSelectedTab(1)}
                type="button"

                className="flex items-center min-w-[270px]  mt-[47px] justify-center font-inter-bold text-white p-3 rounded-[8px] gap-2 bg-[--theme]"
              >
                {t("Next Step")}
                <Icon iconType={IconType.RIGHT_ARROW_ICON} />
              </Button>
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <div className="grid grid-cols-2 gap-5 mb-5">
              <Input
                label={t("Employee Number")}
                className="h-[48px]"
                placeholder={t("Employee Number")}
                {...register("employee_number")}
              />

              <CustomSelect
                customOptions={[
                  {
                    label: `${t("Regular")}`,
                    value: "Regular",
                  },
                  {
                    label: `${t("Hybrid")}`,
                    value: "Hybrid",
                  },
                ]}
                {...register("employee_type")}
                label={t("Employee Type")}
                className="h-[48px]"
              />
              <Input
                placeholder={t("Departament")}
                {...register("department")}
                className="h-[48px]"
                label={t("Departament")}
              />
              <Input
                placeholder={t("Enter Position")}
                className="!p-[16px] h-[48px]"
                {...register("position")}
                label={t("Position")}
              />
              <CustomSelect
                customOptions={[
                  { label: "1", value: "1" },
                  { label: "2", value: "2" },
                  { label: "3", value: "3" },
                  { label: "4", value: "4" },
                  { label: "5", value: "5" },
                  { label: "6", value: "6" },
                  { label: "7", value: "7" },
                ]}
                {...register("working_days")}
                label={t("Working Days")}
                className="h-[48px]"
              />

              <Input
                placeholder={t("Office Location")}
                {...register("office_location")}
                label={t("Office Location")}
                className="h-[48px]"
              />
              <CustomSelect
                customOptions={[
                  { label: "Yes", value: "yes" },
                  { label: "No", value: "no" },
       
                ]}
                {...register("betreibung")}
                label={t("Betreibung")}
                className="h-[48px]"
              />
              
              <Input
                label={t("AHV Number")}
                className="h-[48px]"
                {...register("ahv_number")}

              />

              <Input
                label={t("Finma Number")}
                className="h-[48px]"
                {...register("finma_number")}

              />
              <Input
                label={t("Bank Name")}
                className="h-[48px]"
                {...register("bank_name")}

              />
              <Input
                label={t("Bank Number")}
                className="h-[48px]"
                {...register("bank_number")}

              />
            </div>
            <div className="flex items-center justify-end gap-5 mb-[50px]">
              <Button
                onClick={() => navigate(`/hr/employe-profile/${id}`)}
                type="button"

                className="text-[#91929E] bg-[#F4F9FD]  flex items-center min-w-[130px]  mt-[47px] justify-center  p-3 rounded-[8px] font-inter-bold"
              >
                {t("Cancel")}
              </Button>
              <Button
                onClick={() => setSelectedTab(2)}
                type="button"

                className="flex items-center min-w-[270px]  mt-[47px] justify-center font-inter-bold text-white p-3 rounded-[8px] gap-2 bg-[--theme]"
              >
                {t("Next Step")}
                <Icon iconType={IconType.RIGHT_ARROW_ICON} />
              </Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="flex flex-col gap-5">
            <div className="grid grid-cols-2 gap-5 items-start">
              <Controller
                control={control}
                name="appointment_letter_file"
                rules={{ required: `${t("File is required")}` }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div>
                      <FileDropZone
                        formats={".pdf,.jpeg,.jpg"}
                        setFile={onChange}
                        label={t("Upload Appointment Letter")}
                        classes={{
                          label: "bg-transparent",
                          dragTextClass: "text-[11px] xl:text-[14px]",
                        }}
                        name="appointment_letter_file"
                        error={errors.appointment_letter_file}
                        control={control}
                      />
                      <div
                        className={
                          value ||
                            data?.data?.user_information?.appointment_letter_file
                            ? "mt-2"
                            : ""
                        }
                      >
                        {value && value[0]?.name ? (
                          <div>
                            <UploadedFile
                              icon={
                                value[0].name.split(".")[1] === "pdf"
                                  ? IconType.FILE_PDF_ICON
                                  : ["csv", "xlsx", "xls"].includes(
                                    value[0].name.split(".")[1]
                                  )
                                    ? IconType.UPLOADED_CSV_ICON
                                    : IconType.FILE_JPG_ICON
                              }
                              title={value[0].name}
                              size={`${(
                                Math.round(+value[0].size / 1024) / 1000
                              ).toFixed(2)} MB`}
                              isEditable={false}
                            />
                          </div>
                        ) : data?.data?.user_information
                          .appointment_letter_file ? (
                          <div
                            className={
                              value ||
                                data?.data?.user_information
                                  ?.appointment_letter_file
                                ? "mt-2"
                                : ""
                            }
                          >
                            <UploadedFile
                              icon={
                                data?.data?.user_information?.appointment_letter_file.split(
                                  "."
                                )[1] === "pdf"
                                  ? IconType.FILE_PDF_ICON
                                  : ["csv", "xlsx", "xls"].includes(
                                    data?.data?.user_information?.appointment_letter_file.split(
                                      "."
                                    )[1]
                                  )
                                    ? IconType.UPLOADED_CSV_ICON
                                    : IconType.FILE_JPG_ICON
                              }
                              title={`${t("Appointment Letter")}`}
                              size="N/A"
                              isEditable={false}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                }}
              />
              <Controller
                control={control}
                name="salary_slips_file"
                rules={{ required: `${t("File is required")}` }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div>
                      <FileDropZone
                        formats={".pdf,.jpeg,.jpg"}
                        setFile={onChange}
                        label={t("Upload Salary Slips")}
                        classes={{
                          label: "bg-transparent",
                          dragTextClass: "text-[11px] xl:text-[14px]",
                        }}
                        name="salary_slips_file"
                        error={errors.salary_slips_file}
                        control={control}
                      />
                      <div
                        className={
                          value ||
                            data?.data?.user_information?.salary_slips_file
                            ? "mt-2"
                            : ""
                        }
                      >
                        {value && value[0]?.name ? (
                          <div>
                            <UploadedFile
                              icon={
                                value[0].name.split(".")[1] === "pdf"
                                  ? IconType.FILE_PDF_ICON
                                  : ["csv", "xlsx", "xls"].includes(
                                    value[0].name.split(".")[1]
                                  )
                                    ? IconType.UPLOADED_CSV_ICON
                                    : IconType.FILE_JPG_ICON
                              }
                              title={value[0].name}
                              size={`${(
                                Math.round(+value[0].size / 1024) / 1000
                              ).toFixed(2)} MB`}
                              isEditable={false}
                            />
                          </div>
                        ) : data?.data?.user_information.salary_slips_file ? (
                          <div
                            className={
                              value ||
                                data?.data?.user_information?.salary_slips_file
                                ? "mt2"
                                : ""
                            }
                          >
                            <UploadedFile
                              icon={
                                data?.data?.user_information?.salary_slips_file.split(
                                  "."
                                )[1] === "pdf"
                                  ? IconType.FILE_PDF_ICON
                                  : ["csv", "xlsx", "xls"].includes(
                                    data?.data?.user_information?.salary_slips_file.split(
                                      "."
                                    )[1]
                                  )
                                    ? IconType.UPLOADED_CSV_ICON
                                    : IconType.FILE_JPG_ICON
                              }
                              title={`${t("Salary Slips")}`}
                              size="N/A"
                              isEditable={false}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                }}
              />
              <Controller
                control={control}
                name="reliving_letter_file"
                rules={{ required: `${t("File is required")}` }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div>
                      <FileDropZone
                        formats={".pdf,.jpeg,.jpg"}
                        setFile={onChange}
                        label={t("Upload Reliving Letter")}
                        classes={{
                          label: "bg-transparent",
                          dragTextClass: "text-[11px] xl:text-[14px]",
                        }}
                        name="reliving_letter_file"
                        error={errors.reliving_letter_file}
                        control={control}
                      />
                      <div
                        className={
                          value ||
                            data?.data?.user_information?.reliving_letter_file
                            ? "mt-2"
                            : ""
                        }
                      >
                        {value && value[0]?.name ? (
                          <div>
                            <UploadedFile
                              icon={
                                value[0].name.split(".")[1] === "pdf"
                                  ? IconType.FILE_PDF_ICON
                                  : ["csv", "xlsx", "xls"].includes(
                                    value[0].name.split(".")[1]
                                  )
                                    ? IconType.UPLOADED_CSV_ICON
                                    : IconType.FILE_JPG_ICON
                              }
                              title={value[0].name}
                              size={`${(
                                Math.round(+value[0].size / 1024) / 1000
                              ).toFixed(2)} MB`}
                              isEditable={false}
                            />
                          </div>
                        ) : data?.data?.user_information
                          .reliving_letter_file ? (
                          <div
                            className={
                              value ||
                                data?.data?.user_information?.reliving_letter_file
                                ? "mt-2"
                                : ""
                            }
                          >
                            <UploadedFile
                              icon={
                                data?.data?.user_information?.reliving_letter_file.split(
                                  "."
                                )[1] === "pdf"
                                  ? IconType.FILE_PDF_ICON
                                  : ["csv", "xlsx", "xls"].includes(
                                    data?.data?.user_information?.reliving_letter_file.split(
                                      "."
                                    )[1]
                                  )
                                    ? IconType.UPLOADED_CSV_ICON
                                    : IconType.FILE_JPG_ICON
                              }
                              title={`${t("Reliving Letter")}`}
                              size="N/A"
                              isEditable={false}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                }}
              />
              <Controller
                control={control}
                name="experience_letter_file"
                rules={{ required: `${t("File is required")}` }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div>
                      <FileDropZone
                        formats={".pdf,.jpeg,.jpg"}
                        setFile={onChange}
                        label={t("Upload Experience Letter")}
                        classes={{
                          label: "bg-transparent",
                          dragTextClass: "text-[11px] xl:text-[14px]",
                        }}
                        name="experience_letter_file"
                        error={errors.experience_letter_file}
                        control={control}
                      />
                      <div
                        className={
                          value ||
                            data?.data?.user_information?.experience_letter_file
                            ? "mt-2"
                            : ""
                        }
                      >
                        {value && value[0]?.name ? (
                          <div>
                            <UploadedFile
                              icon={
                                value[0].name.split(".")[1] === "pdf"
                                  ? IconType.FILE_PDF_ICON
                                  : ["csv", "xlsx", "xls"].includes(
                                    value[0].name.split(".")[1]
                                  )
                                    ? IconType.UPLOADED_CSV_ICON
                                    : IconType.FILE_JPG_ICON
                              }
                              title={value[0].name}
                              size={`${(
                                Math.round(+value[0].size / 1024) / 1000
                              ).toFixed(2)} MB`}
                              isEditable={false}
                            />
                          </div>
                        ) : data?.data?.user_information
                          .experience_letter_file ? (
                          <div
                            className={
                              value ||
                                data?.data?.user_information
                                  ?.experience_letter_file
                                ? "mt-2"
                                : ""
                            }
                          >
                            <UploadedFile
                              icon={
                                data?.data?.user_information?.experience_letter_file.split(
                                  "."
                                )[1] === "pdf"
                                  ? IconType.FILE_PDF_ICON
                                  : ["csv", "xlsx", "xls"].includes(
                                    data?.data?.user_information?.experience_letter_file.split(
                                      "."
                                    )[1]
                                  )
                                    ? IconType.UPLOADED_CSV_ICON
                                    : IconType.FILE_JPG_ICON
                              }
                              title={`${t("Experience Letter")}`}
                              size="N/A"
                              isEditable={false}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                }}
              />
            </div>
            <div className="flex items-center justify-end gap-5 mb-[50px]">
              <Button
                onClick={() => navigate(`/hr/employe-profile/${id}`)}
                type="button"
                className="text-[#91929E] bg-[#F4F9FD] flex items-center min-w-[130px]  mt-[47px] justify-center  p-3 rounded-[8px] font-inter-bold"
              >
                {t("Cancel")}
              </Button>
              <button
                type="button"
                onClick={(e: any) => {
                  setSelectedTab(3);
                  e.preventDefault();
                }}
                className="flex items-center min-w-[270px]  mt-[47px] justify-center font-inter-bold text-white p-3 rounded-[8px] gap-2 bg-[--theme]"
              >
                {t("Next Step")}
                <Icon iconType={IconType.RIGHT_ARROW_ICON} />
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <div className="grid grid-cols-2 gap-5">
              <Input
                placeholder={t("Enter Strato ID")}
                className="!p-[16px] h-[48px]"
                {...register("strato_id")}
                name="strato_id"
                label={t("Strato ID")}
              />
              <Input
                placeholder={t("Enter Skype ID")}
                className="!p-[16px] h-[48px]"
                {...register("skype_id")}
                name="skype_id"
                label={t("Skype ID")}
              />
              <Input
                placeholder={t("Enter Github ID")}
                className="!p-[16px] h-[48px]"
                {...register("github_id")}
                name="github_id"
                label={t("Github ID")}
              />
            </div>
            <div className="flex items-center justify-end gap-5 mb-[50px]">
              <Button
                onClick={() => navigate(`/hr/employe-profile/${id}`)}
                type="button"

                className="text-[#91929E] bg-[#F4F9FD]  flex items-center min-w-[130px]  mt-[47px] justify-center  p-3 rounded-[8px] font-inter-bold"
              >
                {t("Cancel")}
              </Button>
              <Button
                type="submit"
                disabled={updateLoading ? true : false}
                className="flex items-center min-w-[270px]  mt-[47px] justify-center font-inter-bold text-white p-3 rounded-[8px] gap-2 bg-[--theme]"
              >
                {updateLoading ? "Updating..." : "Update"}
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Headline title={t("Update Employee")} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <DashboardBoxLayout className="mt-[26px]">
          <TabsSwitch
            ActiveTab={selectedTab}
            setSelectedTab={(id) => setSelectedTab(id)}
            tabs={[
              { id: 0, name: "Personal Information" },
              { id: 1, name: "Professional Information" },
              { id: 2, name: "Documents" },
              { id: 3, name: "Account Access" },
            ]}
          ></TabsSwitch>
          {renderTabs()}
          <ErrorState
            className="max-w-[400px] mx-auto min-w-[400px]"
            error={isError}
          >
            {error?.response?.data?.error}
          </ErrorState>
        </DashboardBoxLayout>
      </Form>
    </div>
  );
};

export default UpdateEmployeesManagement;
