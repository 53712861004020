import IconButton from "components/atoms/button/IconButton";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import TextArea from "components/atoms/text-area/TextArea";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import InputsFolder from "components/molecules/inputs-folder/InputsFolder";
import { IconType } from "lib/helpers/constants/iconTypes";
import { Dispatch, FC, useEffect, useLayoutEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ChooseProducts from "./choose-products/ChooseProducts";
import { usePartners } from "lib/hooks/queries/Partners/usePartnersForFilter";
import Checkbox from "components/atoms/checkbox/Checkbox";
import { useCustomerForm } from "lib/hooks/forms/useCustomerForm";
import withModal from "lib/helpers/hoc/withModal";
import { useCreateCustomer } from "lib/hooks/mutations/Customers/useCreateCustomer";
import { useUsersByPermission } from "lib/hooks/mutations/UsersByPermission/useUsersByPermission";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { toast } from "react-toastify";
import RelatedCustomerForm from "./related-customer-form/RelatedCustomerForm";
import Button from "components/atoms/button/Button";
import { customStyles } from "lib/helpers/configs/customStyles";
import { languageData } from "lib/helpers/constants/languageData";
import Creatable from "react-select/creatable";
import { numbersCode } from "lib/helpers/constants/numbersCode";
import Flag from "react-world-flags";
import { useTranslation } from "react-i18next";
import { usePartnerSingleProduct } from "lib/hooks/queries/Partners/usePartnerSingleProduct";

export interface IProps {
  onConfirm: () => void;
  setCurrentFormStep: Dispatch<number>;
  currentStep: number;
  themeColors: {
    primary: string;
  };
}

const CreateCustomerProcces: FC<IProps> = ({
  onConfirm,
  setCurrentFormStep,
  currentStep,
  themeColors,
}) => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [mapAddress, setMapAddress] = useState({
    postal_code: "",
    address: "",
    nr: "",
    city: "",
  });
  const { data: partnersData } = usePartners();
  const { t } = useTranslation();
  const [formData, setFormData] = useState<any>({});
  const { mutate: create } = useCreateCustomer();
  const { mutateAsync: getUsersByPermission } = useUsersByPermission();
  const [fetchedUsers, setFetchedUsers] = useState<Array<string>>([]);
  const hasPermissionToAssign = usePermissions(['assign_customers_assign']);
  const hasPermissionToEditRelationship = usePermissions(['manage_customers_relationship_edit']);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useCustomerForm();
  const defaultValue = numbersCode.find(item => item.value === '+41');

  const { handleSubmit: handleSubmitExtraField } = useForm();

  const defaultOptions = [
    {
      id: 0,
      checkboxValue: `${t('KVG')}`,
      customOptions: partnersData?.partners?.map?.((partner: any) => ({
        label: partner?.name,
        value: partner?.name,
        id: partner?.id,
      })),
    },
    {
      id: 1,
      checkboxValue: `${t('VVG')}`,
      customOptions: partnersData?.partners?.map?.((partner: any) => ({
        label: partner?.name,
        value: partner?.name,
        id: partner?.id,
      })),
    },
    {
      id: 2,
      checkboxValue: `${t('life')}`,
      customOptions: partnersData?.partners?.map?.((partner: any) => ({
        label: partner?.name,
        value: partner?.name,
        id: partner?.id,
      })),
    },
    {
      id: 3,
      checkboxValue: `${t('lawprotection')}`,
      customOptions: partnersData?.partners?.map?.((partner: any) => ({
        label: partner?.name,
        value: partner?.name,
        id: partner?.id,
      })),
    },
    {
      id: 4,
      checkboxValue: `${t('business')}`,
      customOptions: partnersData?.partners?.map?.((partner: any) => ({
        label: partner?.name,
        value: partner?.name,
        id: partner?.id,
      })),
    },
    {
      id: 5,
      checkboxValue: `${t('auto')}`,
      customOptions: partnersData?.partners?.map?.((partner: any) => ({
        label: partner?.name,
        value: partner?.name,
        id: partner?.id,
      })),

    }
  ]

  const [optionsByCheckbox, setOptionsByCheckbox] = useState<any>(defaultOptions);

  useLayoutEffect(() => {
    if (partnersData) {

      setOptionsByCheckbox(defaultOptions);
    }

    //eslint-disable-next-line
  }, [partnersData]);



  const [PartnerParams, setPartnerParams] = useState<any>({ base_product: null, partner_id: null });

  const { data: partnerProductsData, isLoading } = usePartnerSingleProduct(PartnerParams);

  const [PartnerProductsState, setPartnerProductsState] = useState<Record<string, any>>({});


  useEffect(() => {
    if (partnerProductsData && PartnerParams.base_product) {
      setPartnerProductsState((prev) => ({
        ...prev,
        [PartnerParams.base_product]: partnerProductsData
      }));
    }
  }, [partnerProductsData, PartnerParams.base_product]);

  const [LastChangedSelectId, setLastChangedSelectId] = useState(null);

  useEffect(() => {
    if (PartnerParams.base_product) {
      setValue(`product.${LastChangedSelectId}`, null);

    }
    if (LastChangedSelectId) {
      handleProductChange(LastChangedSelectId, null);
    }
    // eslint-disable-next-line
  }, [partnerProductsData]);

  const addNewOption = (checkboxValue: string) => {
    setOptionsByCheckbox((prevOptions: any) => {
      // Find the index of the option that matches the checkboxValue
      const index = prevOptions.findIndex((option: any) => option.checkboxValue === checkboxValue);

      if (index !== -1) {
        // Create the new option with an updated ID and custom options
        const newOption = {
          ...prevOptions[index],
          id: prevOptions.length, // Assign a new unique ID
          customOptions: prevOptions[index].customOptions || [], // Ensure it's an array
        };

        // Insert the new option directly after the found option
        const updatedOptions = [
          ...prevOptions.slice(0, index + 1), // Keep everything before the matched option
          newOption, // Add the new option
          ...prevOptions.slice(index + 1), // Keep everything after the matched option
        ];

        return updatedOptions;
      }

      // If the checkboxValue isn't found, return the original options without changes
      return prevOptions;
    });
  };

  const removeOption = (id: number) => {
    setOptionsByCheckbox((prevOptions: any) => {
      // Filter out the option with the matching ID
      const updatedOptions = prevOptions.filter((option: any) => option.id !== id);


      return updatedOptions;
    });

    setFormData((prevFormData: any) => {
      const updatedFormData = { ...prevFormData };

      delete updatedFormData[id];

      return updatedFormData;
    })
  }

  useEffect(() => {
    getUsersByPermission({
      permissions: ['customers_view_view', 'customers_view_view_own'],
    })
      .then((res) => {
        setFetchedUsers(res.users);
      })
      .catch(() => { });
    // eslint-disable-next-line
  }, []);

  const handleCheckboxChange = (checkboxValue: string, checked: boolean) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [checkboxValue]: {
        checked: checked,
        selected: checked ? prevFormData[checkboxValue]?.selected || "" : "",
      },
    }));

  };
  const [relatedCustomers, setRelatedCustomers] = useState<any[]>([]);

  const handleSelectChange = (checkboxValue: string, selected: string) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [checkboxValue]: {
        ...prevFormData[checkboxValue],
        selected: selected,
      },
    }));

  };
  const handleProductChange = (checkboxValue: string, selected: string | null) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [checkboxValue]: {
        ...prevFormData[checkboxValue],
        product_name: selected,
      },
    }));

  };

  const formatOptionLabel = (data: any, { context }: any) => {
    if (context === "menu") {
      return (
        <div className="flex items-center">
          <Flag code={data.icon} height={14} width={14} />
          <span className="ml-2">{data.label}</span>
        </div>
      );
    } else {
      return (
        <div className="text-center mx-auto">
          {data.label}
        </div>
      )
    }
  };
  const [onSubmitFormData, setOnSubmiFormData] = useState<any>([]);
  const [productsFormData, setProductsFormData] = useState<any>([]);


  const onSubmit = (data: Record<string, any>) => {
    const combinedPhoneNumber = `${data.code} ${data.phone}`;
    const combinedData = {
      ...data,
      phone: combinedPhoneNumber,
      products: formData.products,
      costumer_relations_id: relatedCustomers?.[0]?.id || null,
    };

    setOnSubmiFormData(combinedData);
    setCurrentFormStep(2);

    // onConfirm();
  };

  const onSubmitExtraFields = async (data: any) => {


    const selectedProducts = Object.keys(formData)
      .filter((key) => formData[key].checked)
      .map((key) => ({
        name_of_products: optionsByCheckbox.filter((option: any) => option.id === parseInt(key))[0].checkboxValue,
        company_of_product: formData[key].selected,
        product_name: formData[key].product_name,
      }));

    const existingProducts = formData.products || [];

    const hasDuplicates = selectedProducts.some((selectedProduct) =>
      existingProducts.some(
        (existingProduct: any) =>
          existingProduct.name_of_products === selectedProduct.name_of_products
      )
    );

    if (hasDuplicates) {
      toast.error(t('Duplicate products are not allowed'));
      return;
    }

    const combinedData = {
      ...data,
      products: [...existingProducts, ...selectedProducts],
    };

    await setFormData(combinedData);

    const resetFormData = Object.keys(formData).reduce((acc: any, key) => {
      acc[key] = { checked: false, selected: "" };
      return acc;
    }, {});

    setFormData({
      ...resetFormData,
      products: combinedData.products,
    });
    setProductsFormData({
      products: combinedData.products,
    });

    setCurrentFormStep(1);
    setActiveTab(2);
  };

  const handleAddCustomer = (customer: any) => {
    setRelatedCustomers([
      { id: customer.id, name: `${customer.first_name} ${customer.last_name}` },
    ]);
  };


  return (
    <div>
      <ConditionalRender condition={currentStep === 1}>
        <div className="flex flex-col lg:flex-row gap-10  !md:min-w-auto md-max-w-[750px] lg:max-w-[1200px]">
          <div className="flex-none">
            <div className="w-full lg:w-[250px]  sticky top-0">
              <div className="rounded-[10px] border border-[#A2A1A833]">
                <div
                  onClick={() => {
                    setActiveTab(1);
                  }}
                  className={`px-6 py-4 rounded-t-[10px] flex gap-3 items-center ${activeTab === 1
                    ? `text-[#fff] bg-[--theme] font-inter-semibold`
                    : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                    }`}
                >
                  <Icon
                    iconType={IconType.PROFILE_ICON}
                    color={activeTab === 1 ? "#fff" : undefined}
                  />
                  <span className="whitespace-nowrap">
                    {t('Customer Information')}
                  </span>
                </div>
                <div
                  onClick={() => {
                    setActiveTab(2);
                  }}
                  className={`px-6 py-4 rounded-b-[10px] flex gap-3 items-center ${activeTab === 2
                    ? `text-[#fff] bg-[--theme] font-inter-semibold`
                    : "text-primaryTextColor font-inter-light bg-white cursor-pointer"
                    }`}
                >
                  <Icon
                    iconType={IconType.PRODUCTS_ICON}
                    color={activeTab === 2 ? "#fff" : undefined}
                  />

                  <span>{t('Products')}</span>
                </div>
              </div>
              <ConditionalRender
                condition={relatedCustomers?.length <= 0 && activeTab === 1 && hasPermissionToEditRelationship}
              >
                <div className="mt-[60px]">
                  <h1 className="text-[#282D46] font-inter-bold">
                    {t('Related Customers')}
                  </h1>
                  <div>
                    <IconButton
                      className="!text-[--theme] bg-transparent border-none !p-0 !mt-[27px] sticky top-0"
                      secondary
                      onClick={() => {
                        setCurrentFormStep(3);
                      }}
                      icon={
                        <Icon
                          iconType={IconType.INCRASE_ICON}
                          color={themeColors.primary}
                        />
                      }
                    >
                      {t('')}
                    </IconButton>
                  </div>
                </div>
              </ConditionalRender>
            </div>
          </div>
          <div className="flex-1">
            <ConditionalRender condition={activeTab === 1}>
              <Form
                className="flex flex-col gap-5 mb-0"
                onSubmit={handleSubmit(onSubmit)}
              >
                <InputsFolder title={t('Personal information')}>
                  <div className="pb-2">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
                      <Input
                        label={t('First Name')}
                        placeholder={t('First Name')}
                        {...register("first_name")}
                        error={errors?.first_name}
                      />
                      <Input
                        label={t('Last Name')}
                        placeholder={t('Last Name')}
                        {...register("last_name")}
                        error={errors?.last_name}
                      />
                      <Input
                        label={t('Nationality')}
                        placeholder={t('Nationality')}
                        {...register("nationality")}
                        error={errors?.nationality}
                      />
                      <Controller
                        control={control}
                        name="birthdate"
                        rules={{ required: t("Date of birth is required") }}
                        render={({ field: { onChange, value } }) => (
                          <PrimaryDatePicker
                            label={t('Date Of Birth')}
                            startDate={value}
                            {...register("birthdate")}
                            error={errors?.birthdate}
                            setStartDate={(date: string) => onChange(date)}
                          />
                        )}
                      />
                      <div className="relative">
                        <Label text={t('Language')} />
                        <Controller
                          name="language"
                          control={control}
                          rules={{ required: t("Language is required") }}
                          render={({ field }) => (
                            <Creatable<any, false>
                              {...field}
                              placeholder={t('Choose language')}
                              options={languageData.map((language) => ({
                                value: language.value,
                                label: t(language.label),
                              }))}
                              value={
                                field.value
                                  ? { value: field.value, label: t(languageData.find((lang) => lang.value === field.value)?.label as string) }
                                  : null
                              }
                              className="cursor-pointer font-inter-regular react-select-container"
                              classNamePrefix="react-select"
                              onChange={(newValue: any, actionMeta: any) => {
                                if (
                                  actionMeta.action === "select-option" &&
                                  newValue?.value
                                ) {
                                  field.onChange(newValue.value);
                                }
                              }}
                              styles={customStyles}
                              isValidNewOption={() => false}
                              menuPosition={"fixed"}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                        {errors.language?.message && (
                          <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                            <>{t(errors.language?.message as string)}</>
                          </div>
                        )}
                      </div>
                      <div className="flex gap-3">
                        <div className="grid grid-cols-[100px,auto] gap-[16px] items-end w-full">
                          <Controller
                            name="code"
                            control={control}
                            defaultValue={defaultValue?.value}
                            render={({ field }) => (
                              <Creatable<any, false>
                                {...field}
                                options={numbersCode.map((item) => ({
                                  value: item.value,
                                  label: item.label,
                                  icon: item.icon
                                }))}
                                value={
                                  field.value
                                    ? numbersCode?.find(item => item.value === field.value)
                                    : defaultValue || null
                                }
                                className="cursor-pointer font-inter-regular react-select-container"
                                classNamePrefix="react-select"
                                onChange={(newValue: any, actionMeta: any) => {
                                  if (
                                    actionMeta.action === "select-option" &&
                                    newValue?.value
                                  ) {
                                    field.onChange(newValue.value);
                                  }
                                }}
                                styles={customStyles}
                                isValidNewOption={() => false}
                                menuPosition={"fixed"}
                                menuPortalTarget={document.body}
                                formatOptionLabel={formatOptionLabel}
                              />
                            )}
                          />
                          <Input
                            placeholder={t('Enter phone number')}
                            {...register("phone")}
                            error={errors?.phone}
                            className="w-full"
                          />
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <div className="flex-1">
                          <Input
                            placeholder={t('Enter email')}
                            label={t('Email')}
                            {...register("email")}
                            error={errors?.email}
                          />
                        </div>
                        <div className="flex-none pt-[35px]">
                          <Icon iconType={IconType.EMAIL_ICON} />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-5 mt-5">
                      <div className="grid grid-cols-2 gap-5">
                        <Input
                          placeholder={t('Enter street')}
                          label={t('Street')}
                          icon={IconType.LOCATION_ICON}
                          {...register("address")}
                          error={errors?.address}
                          classes={{ icon: "!bg-white right-[8px]" }}
                          onChange={(e) => {
                            setMapAddress({
                              ...mapAddress,
                              address: e.target.value,
                            });
                          }}
                        />
                        <Input
                          placeholder={t('Enter Nr')}
                          label={t('Nr')}
                          {...register("nr")}
                          error={errors?.nr}
                          onChange={(e) => {
                            setMapAddress({
                              ...mapAddress,
                              nr: e.target.value,
                            });
                          }}
                        />
                        <Input
                          placeholder={t('Enter PLZ')}
                          label={t('PLZ')}
                          {...register("postal_code")}
                          error={errors?.postal_code}
                          onChange={(e) => {
                            setMapAddress({
                              ...mapAddress,
                              postal_code: e.target.value,
                            });
                          }}
                        />
                        <Input
                          placeholder={t('Enter City')}
                          label={t('City')}
                          {...register("city")}
                          error={errors?.city}
                          onChange={(e) => {
                            setMapAddress({
                              ...mapAddress,
                              city: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div>
                        <iframe
                          className="border-inputborder border rounded-[8px]"
                          title={t('map')}
                          src={`https://maps.google.com/maps?q='+${mapAddress.postal_code} ${mapAddress.address} ${mapAddress.city}  ${mapAddress.nr}+'&t=&z=14&ie=UTF8&iwloc=&output=embed`}
                          style={{ width: "100%", height: "100%" }}
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </InputsFolder>

                <ConditionalRender condition={relatedCustomers?.length >= 1}>
                  <InputsFolder title={t('Related Customers')}>
                    <div className="grid grid-cols-2 gap-5 items-center">
                      {relatedCustomers.map((customer, index) => (
                        <div key={index} className="mb-4">
                          <Input
                            label={t('Family member')}
                            type="text"
                            readOnly
                            disabled={true}
                            defaultValue={customer.name}
                          />
                        </div>
                      ))}
                      <Button
                        className="mt-3"
                        onClick={() => {
                          setRelatedCustomers([]);
                        }}
                      >
                        <Icon iconType={IconType.DELETE_ICON} color="#979797" />
                      </Button>
                    </div>
                  </InputsFolder>
                </ConditionalRender>
                <div
                  className={
                    hasPermissionToAssign
                      ? "grid gap-5 lg:grid-cols-2 sm:grid-cols-1 lg:gap-0"
                      : "gap-5 lg:grid-cols-1 lg:gap-0"
                  }
                >
                  <InputsFolder
                    title={t('Related details')}
                    childrenClassName="rounded-r-none max-h-[180px] h-full"
                  >
                    <div>
                      <Label>{t('Note')}</Label>
                      <TextArea
                        {...register("note")}
                        rows={4}
                        className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
                        placeholder={t('Write note')}
                        error={errors?.note}
                      />
                    </div>
                  </InputsFolder>

                  <ConditionalRender condition={hasPermissionToAssign}>
                    <InputsFolder
                      title={t('Assignement')}
                      childrenClassName="max-h-[180px] h-full rounded-l-none"
                    >
                      <div>
                        <div>
                          <Label>{t('Consultant')}</Label>
                        </div>
                        <div>
                          <Controller
                            name="from_user"
                            control={control}
                            render={({ field }) => (
                              <Creatable<any, false>
                                {...field}
                                placeholder={t('Choose consultant')}
                                options={fetchedUsers?.map?.((source: any) => ({
                                  label: source.name ?? "",
                                  value: source.id,
                                }))}
                                value={
                                  field?.value
                                    ? {
                                      value: field?.value,
                                      label:
                                        (
                                          fetchedUsers?.find?.(
                                            (item: any) =>
                                              item.id === field.value
                                          ) as { name: string } | undefined
                                        )?.name || field.value,
                                    }
                                    : null
                                }
                                className="cursor-pointer font-inter-regular react-select-container"
                                classNamePrefix="react-select"
                                onChange={(newValue: any, actionMeta: any) => {
                                  if (
                                    actionMeta.action === "select-option" &&
                                    newValue?.value
                                  ) {
                                    field.onChange(newValue.value);
                                  }
                                }}
                                styles={customStyles}
                                isValidNewOption={() => false}
                                menuPosition={"fixed"}
                                menuPortalTarget={document.body}
                              />
                            )}
                          />
                        </div>
                        <div className="flex flex-col gap-[15px] 2xl:gap-[24px]"></div>
                      </div>
                    </InputsFolder>
                  </ConditionalRender>
                </div>
                <IconButton
                  className="flex justify-end ml-auto mt-[40px] lg:mt-0 whitespace-nowrap"
                >
                  {t('Next Step')}
                </IconButton>
              </Form>
            </ConditionalRender>
            <ConditionalRender condition={activeTab === 2}>
              <ChooseProducts
                data={formData.products as any[]}
                setFormStep={setCurrentFormStep}
                setFormData={setFormData}
                onSubmitFormData={onSubmitFormData}
                themeColors={themeColors}
                create={create}
                onConfirm={onConfirm}
                productsFormData={productsFormData}
              />
            </ConditionalRender>
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 2}>
        <Form
          onSubmit={handleSubmitExtraField(onSubmitExtraFields)}
          className="pt-[30px]"
        >
          {optionsByCheckbox.map(({ checkboxValue, customOptions, id }: any) => (
            <div key={id} className="flex items-center gap-4 mb-3">
              <div className="basis-1/3 flex items-center gap-4">
                <Checkbox
                  value={checkboxValue}
                  isChecked={
                    formData[id] && formData[id].checked
                      ? formData[id].checked
                      : false
                  }
                  onChange={(e) =>
                    handleCheckboxChange(id, e.target.checked)
                  }
                />
                <Label className="capitalize !mb-0">{checkboxValue}</Label>
              </div>
              <div className="flex-1">
                <Controller
                  name="Company"
                  control={control}
                  render={({ field }) => (
                    <Creatable<any, false>
                      {...field}
                      options={customOptions}
                      value={field.value}
                      className="cursor-pointer font-inter-regular react-select-container"
                      classNamePrefix="react-select"
                      onChange={(selectedOption) => {
                        handleSelectChange(id, selectedOption ? selectedOption.value : null)
                        setPartnerParams({
                          base_product: checkboxValue,
                          partner_id: optionsByCheckbox.filter(
                            (option: any) => option.checkboxValue === checkboxValue)[0]
                            ?.customOptions?.find((option: any) => option.value === selectedOption?.value
                            )?.id
                        })
                        setLastChangedSelectId(id);
                      }
                      }
                      styles={customStyles}
                      placeholder={t('Choose Company')}
                      isValidNewOption={() => false}
                      menuPosition={"fixed"}
                      menuPortalTarget={document.body}
                      isDisabled={!formData[id]?.checked}
                    />
                  )}
                />
              </div>
              <div className="flex-1">

                <Controller
                  control={control}
                  name={`product.${id}`}
                  render={({ field }) => (
                    <Creatable<any, false>
                      {...field}
                      placeholder={t('Choose Product')}
                      options={PartnerProductsState[checkboxValue]?.map?.((product: any) => ({
                        label: product?.product,
                        value: product?.product,
                      }))}

                      value={
                        field.value
                          ? { value: field.value, label: field.value }
                          : null
                      }
                      isDisabled={isLoading}
                      className="cursor-pointer !w-full font-inter-regular react-select-container"
                      classNamePrefix="react-select !w-full"
                      onChange={(newValue: any, actionMeta: any) => {
                        if (actionMeta.action === "select-option" && newValue?.value) {
                          handleProductChange(id, newValue.value)
                          field.onChange(newValue.value);
                        }
                      }}
                      styles={customStyles}
                      isValidNewOption={() => false}
                      menuPosition={"fixed"}
                      menuPortalTarget={document.body}
                    />
                  )}
                />
              </div>
              {id < 6 ? (

                <div className="cursor-pointer w-[20px] flex justify-center flex-none" onClick={() => { addNewOption(checkboxValue) }}>
                  <Icon iconType={IconType.PLUS_ICON} color="#7D8592" />
                </div>
              ) : (
                <div className="cursor-pointer" onClick={() => { removeOption(id) }}>
                  <Icon iconType={IconType.DELETE_ICON} />
                </div>
              )}
            </div>
          ))}
          <IconButton
            type="submit"
            className="max-w-[200px] mt-[30px]  flex justify-end ml-auto"
          >
            {t('Add Product')}
          </IconButton>
        </Form>
      </ConditionalRender>
      <ConditionalRender condition={currentStep === 3}>
        <RelatedCustomerForm
          onAddCustomer={handleAddCustomer}
          setCurrentStep={setCurrentFormStep}
        />
      </ConditionalRender>
    </div>
  );
};

export default withModal(CreateCustomerProcces);
