import IconButton from "components/atoms/button/IconButton";
import { FC, useState } from "react";
import InputsFolder from "components/molecules/inputs-folder/InputsFolder";
import { useTranslation } from "react-i18next";
import Form from "components/atoms/form/Form";
import Label from "components/atoms/input/input-label/InputLabel";
import TextArea from "components/atoms/text-area/TextArea";
import RangeSlider from "components/atoms/range-slider/RangeSlider";

export interface IUpdateQualityCheckProps {

}

const UpdateQualityCheck: FC<IUpdateQualityCheckProps> = () => {

    const { t } = useTranslation();

    const [Value, setValue] = useState<any>(1)
    return (
        <Form className="flex flex-col gap-5 mb-0"
        >
            <InputsFolder title={t('Personal information')}>
                <div className="pb-2">

                    <RangeSlider value={Value} onChange={setValue} step={1} min={1} max={10} />
                </div>
            </InputsFolder>
            <InputsFolder
                title={t('Related details')}
                childrenClassName="max-h-[180px] h-full"
            >
                <div>
                    <Label>{t('Note')}</Label>
                    <TextArea
                        rows={4}
                        className="resize-none border-[1px] border-inputborder rounded-[8px] w-full p-[7px] 2xl:p-[10px] outline-0 indent-2 placeholder-[#667085] font-inter-regular"
                        placeholder={t('Write note')}
                    />
                </div>
            </InputsFolder>
            <IconButton className="max-w-[138px] flex justify-end ml-auto">
                {t("Update")}
            </IconButton>
        </Form>
    );
};

export default UpdateQualityCheck
