import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getCounterTool } from "api/Statistics";


interface FiltersProps {
    insurance_company?: string,
    from_date_of_creation?: string,
    to_date_of_creation?: string,
    filter_on?: string,
    source?: string
}
export const useCounterTool = (
    filters?: FiltersProps
) => {

    const { data, isLoading } = useQuery<Array<any>>(
        ['appointments', filters?.insurance_company, filters?.from_date_of_creation, filters?.to_date_of_creation, filters?.filter_on, filters?.source],
        () => {
            return getCounterTool({
                insurance_company: filters?.insurance_company,
                from_date_of_creation: filters?.filter_on === 'Individual' ? filters?.from_date_of_creation : null,
                to_date_of_creation: filters?.filter_on === 'Individual' ? filters?.to_date_of_creation : null,
                filter_on: filters?.filter_on === 'Individual' ? null : filters?.filter_on,
                source: filters?.source
            });
        }, {
        onError() {
            toast.error(`Something went wrong. Please try again later.`);
        },
        enabled: true,
        refetchOnMount: true,
    }
    );

    return {
        data,
        isLoading,
    };
};
