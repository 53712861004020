import DashboardBoxTitle from "components/atoms/title/DashboardBoxTitle";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import { customStylesLightBorder } from "lib/helpers/configs/customStyles";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Creatable from "react-select/creatable";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import PendingsChart from "../AllCharts/PendingsChart";
import { useCustomerFeedbacks } from "lib/hooks/queries/Statistics/CustomerFeedbacks/useCustomerFeedbacks";
import { usePartners } from "lib/hooks/queries/Partners/usePartnersForFilter";
import FilterBorderedWrapper from "components/atoms/filter-bordered-wrapper/FilterBorderedWrapper";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import DynamicDropdown from "components/molecules/dynamic-dropdown/DynamicDropdown";
import Filter from "../Dropdowns/Filter";

export interface IStatisticsProps {
    fetchedUsers: string[]
}

const CustomerStatistic: FC<IStatisticsProps> = ({ fetchedUsers }) => {

    const { t } = useTranslation();

    const [Filters, setFilters] = useState({
        worker: 'All agents',
        filter_on: 'Today',
        product: 'All products',
        company: 'All companies'
    })

    const { data, isLoading } = useCustomerFeedbacks(Filters);

    const [hoveredSlice, setHoveredSlice] = useState<string | null>(null);

    const { data: partnersData, isLoading: isLoadingPartner } = usePartners();

    const partners = useMemo(() => partnersData?.partners ? [...partnersData?.partners] : [], [partnersData]);


    const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);


    return (
        <DashboardBoxLayout >
            <div className="flex flex-row gap-3 items-center justify-between mb-5">
                <div className="flex-1">
                    <DashboardBoxTitle
                        title={`Customers`}
                    />
                </div>
                <div className="flex-none">
                    <div className={`${filterTableOpen && "relative"}`}>
                        <FilterBorderedWrapper
                            className="h-[48px] w-[48px] cursor-pointer"
                            onClick={() => {
                                setFilterTableOpen(!filterTableOpen);
                            }}
                        >
                            <Icon iconType={IconType.FILTER_ICON} />
                        </FilterBorderedWrapper>
                        <ConditionalRender condition={filterTableOpen}>
                            <DynamicDropdown
                                setIsOpen={setFilterTableOpen}
                                showCloseButton={true}
                                title={t('Filter')}
                                className="sm:!w-[350px]"
                            >
                                <Filter
                                    setIsOpen={setFilterTableOpen}
                                    setFilterData={setFilters}
                                    FilterData={Filters}
                                />
                            </DynamicDropdown>
                        </ConditionalRender>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-3 items-center mb-5 md:flex-row relative">
                <Creatable<any, false>
                    placeholder={t('Choose Agent')}
                    options={[
                        ...[
                            { name: t('All agents'), id: 'All agents' },
                            ...fetchedUsers,
                        ].map((user: any) => ({
                            label: user?.name,
                            value: user?.id,
                        })),
                    ]}
                    value={{
                        value: Filters.worker,
                        label: (fetchedUsers as any).find((user: any) => user.id === Filters.worker)?.name || t('All agents'),
                    }}
                    className="cursor-pointer font-inter-regular react-select-container min-w-[300px]"
                    classNamePrefix="react-select"
                    onChange={(newValue: any) => {
                        setFilters((prevFilters) => ({
                            ...prevFilters,
                            worker: newValue.value,
                        }));
                    }}
                    styles={customStylesLightBorder}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                />
                <Creatable<any, false>
                    placeholder={t('All products')}
                    options={[
                        {
                            label: t('All products'),
                            value: t('All products')
                        },
                        {
                            label: t('KVG'),
                            value: 'KVG'
                        },
                        {
                            label: t('VVG'),
                            value: 'VVG'
                        },
                        {
                            label: t('lawprotection'),
                            value: 'lawprotection'
                        },
                        {
                            label: t('auto'),
                            value: 'auto'
                        },
                        {
                            label: t('business'),
                            value: 'business'
                        },
                        {
                            label: t('life'),
                            value: 'life'
                        }
                    ]}

                    value={{
                        value: Filters.product,
                        label: t(Filters.product) || t('All products'),
                    }}
                    className="cursor-pointer font-inter-regular react-select-container w-full max-w-[300px]"
                    classNamePrefix="react-select"
                    onChange={(newValue: any) => {
                        setFilters((prevFilters) => ({
                            ...prevFilters,
                            product: newValue.value,
                        }));
                    }}
                    styles={customStylesLightBorder}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                />

                <Creatable<any, false>
                    placeholder={t('All companies')}

                    options={[...[
                        { name: t('All companies'), id: 'All companies' },
                        ...partners,
                    ].map((user: any) => ({
                        label: user?.name,
                        value: user?.name,
                    }))]}
                    value={{
                        value: Filters.company,
                        label: t(Filters.company) || t('All companies'),
                    }}
                    className="cursor-pointer font-inter-regular react-select-container w-full max-w-[300px]"
                    classNamePrefix="react-select"
                    onChange={(newValue: any) => {
                        setFilters((prevFilters) => ({
                            ...prevFilters,
                            company: newValue.value,
                        }));
                    }}
                    styles={customStylesLightBorder}
                    isValidNewOption={() => false}
                    menuPosition={"fixed"}
                    menuPortalTarget={document.body}
                />

            </div>

            <div className="flex flex-col gap-20 items-center mb-5 md:flex-row relative">
                {(isLoading || isLoadingPartner) ? <div className="py-10"> <LoadingScreen /></div> : (
                    <ConditionalRender condition={data !== undefined}>
                        <div className="basis-1/3">
                            <PendingsChart
                                setHoveredSlice={setHoveredSlice}
                                color={['#50B8E7']}
                                data={[
                                    { name: 'Open (Consultant)', y: ((data as any)?.data?.open_consultant / (data as any)?.data?.total) * 100, z: 100 },
                                    { name: 'Accepted', y: ((data as any)?.data?.accepted / (data as any)?.data?.total) * 100, z: 100 },
                                    { name: 'Open (Backoffice)', y: ((data as any)?.data?.open_office / (data as any)?.data?.total) * 100, z: 100 },
                                    { name: 'Rejected', y: ((data as any)?.data?.rejected / (data as any)?.data?.total) * 100, z: 100 },
                                    { name: 'Submitted', y: ((data as any)?.data?.submitted / (data as any)?.data?.total) * 100, z: 100 },
                                    { name: 'Provisioned', y: ((data as any)?.data?.provisioned / (data as any)?.data?.total) * 100, z: 100 },
                                    { name: 'Retreat', y: ((data as any)?.data?.retreat / (data as any)?.data?.total) * 100, z: 100 },
                                    { name: 'Canceled', y: ((data as any)?.data?.canceled / (data as any)?.data?.total) * 100, z: 100 }
                                ]} />
                        </div>
                        <div className="basis-2/3">
                            <div className="grid-cols-2 grid gap-3">
                                {[
                                    { status: 'Open (Consultant)', count: (data as any)?.data?.open_consultant },
                                    { status: 'Accepted', count: (data as any)?.data?.accepted },
                                    { status: 'Open (Backoffice)', count: (data as any)?.data?.open_office },
                                    { status: 'Rejected', count: (data as any)?.data?.rejected },
                                    { status: 'Submitted', count: (data as any)?.data?.submitted },
                                    { status: 'Provisioned', count: (data as any)?.data?.provisioned },
                                    { status: 'Retreat', count: (data as any)?.data?.retreat },
                                    { status: 'Canceled', count: (data as any)?.data?.canceled },
                                    { status: 'Total Products', count: (data as any)?.data?.total },
                                    { status: 'Total Customers', count: (data as any)?.data?.totalCostumer },
                                ].map((item: { status: string, count: number }, index: number) => (
                                    <div
                                        onMouseEnter={() => setHoveredSlice(item.status)}
                                        onMouseLeave={() => setHoveredSlice(null)}
                                        key={index}
                                        className={`flex justify-between gap-3 items-center transition-colors rounded-[8px] py-[10px] px-[20px]
                                            ${item.status === 'Total Customers' || item.status === 'Total Products' ? 'col-span-2' : 'col-span-1'} ${item.status === hoveredSlice
                                                ? 'text-white bg-[#50B8E7] border-[#50B8E7]'
                                                : 'text-primaryTextColor border border-[#F2F4F7]'} `
                                        }>
                                        <div className="font-inter-medium">
                                            {t(item.status)}
                                        </div>
                                        <div className="font-inter-bold">
                                            {item.count}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </ConditionalRender>
                )}
            </div>
        </DashboardBoxLayout>
    );
};

export default CustomerStatistic;
