import { Dispatch, FC, Fragment, SetStateAction, useState } from "react";
import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import InputsFolder from "components/molecules/inputs-folder/InputsFolder";
import Tooltip from "components/molecules/tooltip/Tooltip";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useHover } from "lib/hooks/shared/useHover";
import { Controller, useForm } from "react-hook-form";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { useSendComment } from "lib/hooks/mutations/Pendings/useSendComment";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import Button from "components/atoms/button/Button";
import { format, parseISO } from "date-fns";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import { useMakeDonePending } from "lib/hooks/mutations/Pendings/useMakeDonePending";
import ErrorState from "components/atoms/error-state/ErrorState";
import Comments from "components/molecules/comments/Comments";
import { useTranslation } from "react-i18next";

export interface IUpdatePendingsProps {
  setCurrentUpdatePending: Dispatch<SetStateAction<number>>;
  userData: any;
  isLoadingFetchingUser?: boolean;
  setDocumentData: any;
  setProduct: any;
  setIsUpdatePendingModalOpen: (value: boolean) => void;
  refetchDocument: any;
  themeColors: {
    primary: string;
  };
}

const UpdatePendings: FC<IUpdatePendingsProps> = ({
  setCurrentUpdatePending,
  userData,
  isLoadingFetchingUser,
  setDocumentData,
  setProduct,
  refetchDocument,
  themeColors,
  setIsUpdatePendingModalOpen,
}) => {
  const { isHovered, onMouseEnter, onMouseLeave } = useHover();

  const { mutate: sendComment, isLoading: sendCommentLoading } =
    useSendComment();
  const [comment, setComment] = useState<string>("");
  const token: any = localStorage.getItem("token");
  const [imagePreview, setImagePreview] = useState<any>();
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);

  const { t } = useTranslation();
  const hasPermissionToEditCostumerPending = usePermissions([
    "pending_customer_view_edit",
  ]);

  const hasPermissionsToViewAll = usePermissions(['pending_customer_view_view']);
  const hasPermissionsToFeedbackFile = usePermissions(['pending_customer_view_feedbackfile']);

  const hasPermissionToViewOwne = usePermissions(['pending_customer_view_view_own']);
  const { mutate: donePending, isLoading, isError } = useMakeDonePending();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = () => {
    donePending({
      pending_id: userData?.data?.id,
    });
    setIsUpdatePendingModalOpen(false);
  };

  const handleSendComment = () => {
    const formData = new FormData();
    formData.append("pending_id", userData?.data?.id);
    formData.append("comment", comment);

    if (imageBlob) {
      const reader = new FileReader();

      reader.readAsDataURL(imageBlob);

      reader.onloadend = () => {
        formData.append("image", reader.result as string);
        sendComment(formData);
        setComment("");
        setImagePreview(null);
        setImageBlob(null);
      };
    } else {
      sendComment(formData);
      setComment("");
      setImagePreview(null);
      setImageBlob(null);
    }
  };

  return (
    <div>
      {isLoadingFetchingUser ? (
        <LoadingScreen />
      ) : (
        <Form className="flex flex-col gap-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-0 flex flex-col gap-7 w-full">
            <Button
              type="button"
              className="absolute top-[26px] left-[30px] items-center capitalize sm:top-[31px] md:top-[45px] flex gap-4 font-inter-semibold text-[#282D46] text-[22px]"
            >
              {userData?.data?.costumer?.first_name}{" "}
              {userData?.data?.costumer?.last_name} : {userData?.data?.title}
              <p
                className={`min-w-[120px] cursor-pointer w-[max-content]  text-[16px] !font-inter-regular flex items-center justify-center rounded-[4px] capitalize 
                  ${
                    userData?.data?.status === "Done"
                      ? "bg-[#2DE28B] bg-opacity-[10%] text-[#2DE28B]"
                      : userData?.data?.status === "Processing"
                      ? "bg-[#50B8E7] bg-opacity-[10%] text-[#50B8E7]"
                      : userData?.data?.status === "Pending Sales" ||
                        userData?.data?.status === "Pending Backoffice"
                      ? "bg-[#EFBE12] bg-opacity-[10%] text-[#EFBE12]"
                      : userData?.data?.status === "Pending"
                      ? "bg-[#F88532] bg-opacity-[10%] text-[#F88532]"
                      : ""
                  }`}
              >
                {t(userData?.data?.status)}
              </p>
            </Button>
          </div>
          <InputsFolder title={t("Pending Information")}>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-[20px]">
              <Input
                label={t("Category")}
                value={userData?.data?.category}
                readOnly
                disabled
              />
              <Controller
                control={control}
                name="created_at"
                render={({ field: { onChange, value } }) => {
                  const formattedDate = userData?.data?.created_at
                    ? format(parseISO(userData.data.created_at), "yyyy-MM-dd")
                    : "";

                  return (
                    <PrimaryDatePicker
                      label={t("Creation Date")}
                      startDate={formattedDate}
                      isDisabled={true}
                      value={formattedDate}
                      setStartDate={(date: string) => onChange(date)}
                      error={errors.created_at}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="new_date"
                render={({
                  field: { onChange, value },
                }: {
                  field: { onChange: (date: string) => void; value: string };
                }) => (
                  <PrimaryDatePicker
                    label={t("Deadline *")}
                    startDate={userData?.data?.deadline}
                    isDisabled={true}
                    wrapperClassName="!left-[2px]"
                    value={userData?.data?.deadline}
                    setStartDate={(date: string) => onChange(date)}
                  />
                )}
              />
            </div>
          </InputsFolder>
          <InputsFolder title={t("Pending Details")}>
            <div>
              <p className="font-inter-regular text-[13px] text-[#282D46] pt-[25px] pb-[36px]">
                {t(
                  "Check and confirm all documents to complete this open pending"
                )}
                .
              </p>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
                <Input
                  label="ID"
                  value={"#" + userData?.data?.costumer?.id}
                  readOnly
                  disabled
                />
                <Input
                  label={t("First Name")}
                  value={userData?.data?.costumer?.first_name}
                  readOnly
                  disabled
                />
                <Input
                  label={t("Last Name")}
                  value={userData?.data?.costumer?.last_name}
                  readOnly
                  disabled
                />
                <Controller
                  control={control}
                  name="new_date"
                  render={({
                    field: { onChange, value },
                  }: {
                    field: { onChange: (date: string) => void; value: string };
                  }) => (
                    <PrimaryDatePicker
                      label={t("Date Of Birth")}
                      startDate={userData?.data?.costumer?.birthdate}
                      wrapperClassName="!left-[2px]"
                      isDisabled={true}
                      value={userData?.data?.costumer?.birthdate}
                      setStartDate={(date: string) => onChange(date)}
                    />
                  )}
                />
                <Input
                  label={t("Consultant")}
                  value={userData?.data?.costumer?.from_user}
                  readOnly
                  disabled
                />
              </div>
              <ConditionalRender
                condition={userData?.data?.requests?.length > 0}
              >
                {userData?.data?.requests?.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={
                        index === 0
                          ? "mt-[43px]"
                          : index === 1
                          ? "mt-[22px]"
                          : ""
                      }
                      onClick={() => {
                        setProduct(item);
                      }}
                    >
                      <div className="flex items-center gap-[14px] my-[24px]">
                        <h1 className="font-inter-bold">{item?.product}</h1>
                        
                        <p className="font-inter-regular text-[#282D46]">
                          {item?.company}
                        </p>
                      </div>
                      <div className="flex flex-wrap lg:flex-row	items-center gap-[12px]">
                        {item?.documents?.map((doc: any, docIndex: number) => {
                          return (
                            <div key={docIndex} >
                              <div 
                                role="button"
                                
                                onClick={(e: any) => {
                                  if(userData?.data?.status === "Done") {
                                    return
                                  }
                                  if(hasPermissionToEditCostumerPending){
                                    e.preventDefault();
                                    if (!doc.file) {
                                      setCurrentUpdatePending(2);
                                      setDocumentData(doc);
                                    }
                                  
                                  }
                                }}
                                className="w-[120px] flex flex-col items-center justify-center h-[120px] relative border-[1px] border-[#D0D5DD] border-dashed rounded-[10px] bg-white"
                              >
                                <div className="flex items-center absolute top-0 pl-2 pt-1 pr-2 justify-between w-full">
                                  <ConditionalRender condition={!!doc?.file}>
                                    <Button 
                                      type="button"
                                      onClick={() => {
                                        if (doc.file) {
                                          setCurrentUpdatePending(4);
                                          setDocumentData(doc);
                                        }
                                      }}
                                    >
                                      <Icon
                                        iconType={IconType.SHOW_PASSWORD_ICON}
                                        className="w-[18px]"
                                      />
                                    </Button>
                                    <div className="flex items-center gap-1">
                                      <ConditionalRender
                                        condition={hasPermissionsToViewAll}
                                      >
                                        <Button
                                          type="button"
                                          onClick={() => {
                                            const file = doc?.file;
                                            const fileName = doc?.name ?? "";

                                            if (!file) {
                                              console.error(
                                                "File is undefined or empty"
                                              );
                                              return;
                                            }

                                            fetch(
                                              `https://devmainhub.insusales.com/api/costumers/pendings/show-file/${file}`,
                                              {
                                                method: "GET",
                                                headers: {
                                                  Authorization: `Bearer ${token}`,
                                                  "Content-Type":
                                                    "application/octet-stream",
                                                },
                                              }
                                            )
                                              .then((response) => {
                                                if (!response.ok) {
                                                  return response
                                                    .json()
                                                    .then((json) => {
                                                      console.error(
                                                        "Server returned an error:",
                                                        json
                                                      );
                                                      throw new Error(
                                                        `Server returned an error: ${json.message}`
                                                      );
                                                    });
                                                }
                                                const contentType =
                                                  response.headers.get(
                                                    "Content-Type"
                                                  );
                                                if (
                                                  contentType &&
                                                  contentType.includes(
                                                    "application/json"
                                                  )
                                                ) {
                                                  return response
                                                    .json()
                                                    .then((json) => {
                                                      console.error(
                                                        "Server returned JSON instead of file:",
                                                        json
                                                      );
                                                      throw new Error(
                                                        `Server returned JSON instead of file: ${json.message}`
                                                      );
                                                    });
                                                }
                                                return response.blob();
                                              })
                                              .then((blob) => {
                                                const url =
                                                  window.URL.createObjectURL(
                                                    blob
                                                  );
                                                const a =
                                                  document.createElement("a");
                                                a.style.display = "none";
                                                a.href = url;
                                                a.download = fileName;
                                                document.body.appendChild(a);
                                                a.click();
                                                window.URL.revokeObjectURL(url);
                                                document.body.removeChild(a);
                                              })
                                              .catch((error) =>
                                                console.error(
                                                  "There was an error with the fetch operation:",
                                                  error
                                                )
                                              );
                                          }}
                                        >
                                          <Icon
                                            iconType={
                                              IconType.DOWNLOAD_PDF_ICON
                                            }
                                            className="w-[18px]"
                                          />
                                        </Button>
                                      </ConditionalRender>
                                      <ConditionalRender
                                        condition={
!((hasPermissionToViewOwne && (userData?.data?.status === "Done" || userData?.data?.status === "Pending")) || doc?.status === "approved" || (hasPermissionsToViewAll && userData?.data?.status === "Done") ||
                                            doc?.status === "approved"
                                          )
                                        }
                                      >
                                        <Button
                                          type="button"
                                          onClick={() => {
                                            if (doc.file) {
                                              setCurrentUpdatePending(2);
                                              setDocumentData(doc);
                                            }
                                          }}
                                        >
                                          <Icon
                                            iconType={IconType.CLOSE_ICON}
                                            className="!w-[10px]"
                                            color="#7D8592"
                                          />
                                        </Button>
                                      </ConditionalRender>
                                    </div>
                                  </ConditionalRender>
                                  <ConditionalRender
                                    condition={
                                      item?.status === "rejected" &&
                                      userData?.data?.status !== "Done"
                                    }
                                  >
                                    <Button>
                                      <Icon
                                        iconType={IconType.CLOSE_ICON}
                                        className="w-[12px]"
                                      />
                                    </Button>
                                  </ConditionalRender>
                                </div>
                                <div
                                  className={`${
                                    !doc?.file
                                      ? "bg-[#D0D5DD] w-[40px] h-[40px] rounded-[10px] flex items-center justify-center"
                                      : ""
                                  }`}
                                >
                                  <Icon
                                    iconType={
                                      doc?.file
                                        ? IconType.PDFUPLOAD_ICON
                                        : IconType.UPLOAD_FILE_ICON
                                    }
                                    color={
                                      doc?.status === "rejected"
                                        ? "#D61616"
                                        : doc?.status === "uploaded"
                                        ? "#F88532"
                                        : doc?.status === "approved"
                                        ? "#2CC95B"
                                        : ""
                                    }
                                  />
                                </div>
                                <p className="mt-[10px] text-[14px] font-inter-regular capitalize text-center">
                                  {t(doc?.name)} <div className="text-[10px]">{doc?.belongs_to_product === "" ? '' : `(${doc?.belongs_to_product})`}</div>
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </ConditionalRender>
            </div>
          </InputsFolder>
          <ConditionalRender condition={userData?.data?.status !== "Done"}>
            <div className="pb-8">
              <div className={`relative`} onMouseLeave={onMouseLeave}>
                <Tooltip
                  isOpen={isHovered}
                  className={`absolute ${
                    hasPermissionToEditCostumerPending
                      ? "right-[13rem]"
                      : "right-[1.5rem]"
                  }  w-[auto]   bg-white  px-[15px]  py-[15px] flex items-center justify-center shadow-custom z-[99] rounded-[8px] top-[-2px]`}
                  content={
                    <Fragment>
                      <h1 className="font-inter-medium text-[#667085] text-[14px]">
                        {!hasPermissionToEditCostumerPending
                          ? t(
                              "You don't have permission to edit or to make any changes here!"
                            )
                          : hasPermissionsToViewAll
                          ? t(
                              "You need to verify all documents to complete your pending."
                            )
                          : t(
                              "You need to upload all documents to complete your pending."
                            )}
                      </h1>
                    </Fragment>
                  }
                />

                <div className="flex items-center justify-end ml-auto gap-[15px]">
                  <div onMouseEnter={onMouseEnter} className="cursor-pointer">
                    <Icon iconType={IconType.ALERT_ICON} color="#D0D5DD" />
                  </div>
                  <ConditionalRender
                    condition={hasPermissionToEditCostumerPending}
                  >
                    <IconButton
                      disabled={
                        hasPermissionsToFeedbackFile
                          ? !userData?.hasApprovedAllFiles ||
                            !userData?.hasUploadAllFiles
                          : !userData?.hasUploadAllFiles ||
                            userData?.data?.status === "Pending"
                      }
                      type="submit"
                    >
                      {isLoading
                        ? hasPermissionsToFeedbackFile
                          ? "Pending Verified..."
                          : "Pending Done..."
                        : hasPermissionsToFeedbackFile
                        ? "Pending Verified"
                        : "Pending Done"}
                    </IconButton>
                  </ConditionalRender>
                </div>
              </div>
            </div>
          </ConditionalRender>
          <InputsFolder title={t("Comments")}>
            <Comments
              handleSend={handleSendComment}
              data={userData?.data?.comments}
              setImageBlob={setImageBlob}
              comment={comment}
              setComment={setComment}
              imagePreview={imagePreview}
              setImagePreview={setImagePreview}
              isLoading={sendCommentLoading}
            />
          </InputsFolder>
          
          <ErrorState error={isError}>
            {t("Something went wrong please try again later")}
          </ErrorState>
        </Form>
      )}
    </div>
  );
};

export default UpdatePendings;
