import Icon from "components/atoms/icon/Icon";
import { IconType } from "../iconTypes";
import ToggleSwitchComponent from "components/molecules/toggle-switch/ToggleSwitch";
import { useModalContext } from "lib/context/ModalContext/ModalContext";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import ReactDOM from "react-dom";
import UpdatePartner from "components/organisms/partners/update-partner/UpdatePartner";
import DeletePartner from "components/organisms/partners/delete-partner/DeletePartner";
import { useUpdatePartnerStatus } from "lib/hooks/mutations/Partners/useUpdatePartnerStatus";
import { useTranslation } from "react-i18next";
import { usePermissions } from "lib/hooks/shared/usePermissions";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";


export const ActionButton = ({ original, id }: any) => {
  const [ThreeDotsOpen, setThreeDotsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef<any>(null);
  const { confirm } = useModalContext();
  const { themeColors } = useThemeContext();
  const dropdownRef = useRef<any>(null);


  const hasPermissionToEditPartners = usePermissions(['manage_partners_edit']);
  const hasPermissionToDeletePartners = usePermissions(['manage_partners_delete']);

  const handleClick = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const dropdownWidth = 200;
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.right + window.scrollX - dropdownWidth,
      });
    }
    setThreeDotsOpen(!ThreeDotsOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        ThreeDotsOpen &&
        buttonRef.current &&
        dropdownRef.current &&
        !buttonRef.current.contains(event.target) &&
        !dropdownRef.current.contains(event.target)
      ) {
        setThreeDotsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ThreeDotsOpen]);


  const updateModal = async (data: any) => {
    try {
      await confirm({
        title: data?.name,
        classes: {
          modal: `max-w-[551px] md-max-w-full lg:max-w-[551px] w-full`,
          title: 'ml-[35px]'
        },
        showCloseButton: true,
        customComponent: (props) => {
          return (
            <UpdatePartner
              data={data}
              onConfirm={props.onConfirm}
              themeColors={themeColors}
            />
          );
        },
      });
      handleClick();
    } catch (err) {
      return null;
    }
  };

  const { t } = useTranslation();

  const OpenDeleteModal = async () => {
    try {
      await confirm({
        title: t("Are you sure?"),
        classes: {
          modal: "max-w-[551px] md-max-w-full lg:max-w-[551px] w-full",
        },
        showCloseButton: true,
        customComponent: (props) => {
          return (
            <DeletePartner
              themeColors={themeColors}
              onCancel={props.onCancel}
              id={id}
              onConfirm={props.onConfirm}
              data={original}
            />
          );
        },
      });
      handleClick();
    } catch (err) {
      return null;
    }
  };

  return (
    <div className="flex flex-row items-center w-fit">
      <div className="relative">
        <button
          ref={buttonRef}
          type="button"
          className="pl-5"
          onClick={handleClick}
        >
          <Icon iconType={IconType.OPTIONS_ICON} />
        </button>
        {ThreeDotsOpen &&
          ReactDOM.createPortal(
            <div
              style={{
                position: "absolute",
                top: `${dropdownPosition.top}px`,
                left: `${dropdownPosition.left}px`,
              }}
              ref={dropdownRef}
            >
              <div className="shadow-custom py-[15px] px-[21px]  w-[193px]  bg-white flex flex-col text-[14px] gap-1 font-inter-medium rounded-[12px]">
                <div>
                  <ConditionalRender condition={hasPermissionToEditPartners}>
                    <div className={`flex flex-row items-center cursor-pointer ${hasPermissionToDeletePartners ? 'pb-3' : 'pb-0'}`}>
                      <div>
                        <Icon iconType={IconType.EDIT_SOURCE_ICON} />
                      </div>
                      <div
                        className="px-4 text-[14px] font-inter-medium"
                        onClick={() => {
                          setThreeDotsOpen(false);
                          updateModal(original);
                        }}
                      >
                        Edit
                      </div>
                    </div>
                  </ConditionalRender>
                  <ConditionalRender condition={hasPermissionToDeletePartners}>
                    <div
                      className=" flex flex-row items-center cursor-pointer"
                      onClick={() => {
                        setThreeDotsOpen(false);
                        OpenDeleteModal();
                      }}
                    >
                      <div>
                        <Icon iconType={IconType.DELETE_SOURCE_ICON} />
                      </div>
                      <div className="px-3 text-[14px] font-inter-medium">
                        Delete
                      </div>
                    </div>
                  </ConditionalRender>

                </div>
              </div>
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};

export const ToggleSwitchComp = ({ id, value, original }: any) => {
  const { mutateAsync: updateSourceStatus } = useUpdatePartnerStatus();
  const [IsChecked, setIsChecked] = useState(value === 1);

  React.useEffect(() => {
    setIsChecked(value === 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  const toggleStatus = async (e: any) => {
    e.preventDefault(); // Prevent the default form submission behavior
    updateSourceStatus({ id })
      .then((res: any) => {
        if (res.status !== undefined) {
          setIsChecked(res.status === 1); // Assuming res.status being 1 means "checked"
        }
      })
      .catch(() => { });
  };

  return (
    <div>
      <ToggleSwitchComponent
        checked={IsChecked}
        initialValue={"" as any}
        onChange={toggleStatus}
        classes={{ label: "!mb-0" }}
      />
    </div>
  );
};
