export const leadsList = [
    {
        Header: "Name",
        accessor: (row: any) =>
            row.info.first_name + ' ' + row.info.last_name,
        id: 1,
        isChecked: true,
    },
    {
        Header: "Consultant",
        accessor: (row: any) =>
            row.assigned_to || "Not available",
        id: 2,
        isChecked: true,
    },
    {
        Header: "Birthdate",
        accessor: (row: any) =>
            row.info.birthdate || "Not available",
        id: 3,
        isChecked: true,
    },
    {
        Header: "Address",
        accessor: (row: any) =>
            row.info.address || "Not available",
        id: 4,
        isChecked: true,
    },
    {
        id: 5,
        Header: "Email",
        accessor: (row: any) =>
            row.info.email || "Not available",
        isChecked: true,
    },
    {
        id: 6,
        Header: "Phone",
        accessor: (row: any) =>
            row.info.phone || "Not available",
        isChecked: true,
    }
]
