import Headline from 'components/atoms/headline/Headline'
import CounterTool from 'components/molecules/counter-tool/CounterTool'
import React from 'react'

const StatisticsCounterTool = () => {
  return (
    <>
      <Headline title={'Counter Tool'} />

      <CounterTool />
      
    </>
  )
}

export default StatisticsCounterTool