import { FC, useState } from "react";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import withModal from "lib/helpers/hoc/withModal";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "components/atoms/icon/export";
import Step1FixedSalary from "./steps/Step1FixedSalary";
import Step2TargetPerformance from "./steps/Step2TargetPerformance";
import Step3Commissions from "./steps/Step3Commissions";
import IconButton from "components/atoms/button/IconButton";
import Form from "components/atoms/form/Form";
import { useForm } from "react-hook-form";
import { useAddSalaries } from "lib/hooks/mutations/Finances/useAddSalaries";
import { Link } from "react-router-dom";

interface Props {
  onConfirm: () => void;
  setFormCurrentStep: any;
  currentStep: number;
  canGoBackHr?: boolean;
}

const AddNewSalaryModule: FC<Props> = ({
  onConfirm,
  currentStep,
  setFormCurrentStep,
  canGoBackHr = false,
}) => {
  const { t } = useTranslation();

  const {
    control: first_step_control,
    handleSubmit: handleSubmitFirstStep,
    formState: { errors: first_step_errors },
    reset: resetStep1,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  const {
    control: second_step_control,
    handleSubmit: handleSubmitSecondStep,
    formState: { errors: second_step_errors },
    reset: resetStep2,
  } = useForm({
    defaultValues: {
      salary_type: "fixed",
      effective_salary: "",
      salary_frequency: "monthly",
      thirteenth_month_salary: "no",
    },
  });

  const {
    control: third_step_control,
    handleSubmit: handleSubmitThirdStep,
    formState: { errors: third_step_errors },
    reset: resetStep3,
  } = useForm({
    defaultValues: {
      commission_id: null,
    },
  });

  const [AllData, setAllData] = useState<any>({});

  const handleFirstStepSubmit = (data: any) => {
    setAllData({ ...AllData, ...data });
    setFormCurrentStep(currentStep + 1);
  };

  const handleSecondStepSubmit = (data: any) => {
    setAllData({ ...AllData, ...data });
    setFormCurrentStep(currentStep + 1);
  };
  const { mutateAsync, isLoading } = useAddSalaries();

  const handleThirdStepSubmit = (data: any) => {
    // setAllData({ ...AllData, ...data })
    // setFormCurrentStep(currentStep + 1);
    const finalData = { ...AllData, ...data };

    mutateAsync(finalData).then(() => {
      onConfirm();
    });
  };

  const handleReset = () => {
    switch (currentStep) {
      case 1:
        resetStep1();
        break;
      case 2:
        resetStep2();
        break;
      case 3:
        resetStep3();
        break;
      default:
        break;
    }
  };

  return (
    <div className="mb-0 flex flex-col gap-7">
      {currentStep !== 4 && (
        <>
        
      
      <button
        type="button"
        className="absolute top-[26px] sm:top-[31px] md:top-[45px]"
        onClick={() => {
          if (canGoBackHr && currentStep === 1) {
            onConfirm();
          } else {
            setFormCurrentStep(currentStep > 1 ? currentStep - 1 : 1);
          }
        }}
      >
        <Icon iconType={IconType.BACK_BUTTON_MODAL} color={"var(--theme)"} />
      </button>
      <div className="pl-4">
        <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-5 relative bg-transparent">
          <div className="flex gap-2 items-center pb-5">
            <div className="pl-2 text-[14px] font-inter-medium text-primaryTextColor">
              {t("Model Name")}
            </div>
          </div>
          <ConditionalRender condition={currentStep === 1}>
            <Form
              id="step_form_1"
              onSubmit={handleSubmitFirstStep(handleFirstStepSubmit)}
            >
              <Step1FixedSalary
                control={first_step_control}
                errors={first_step_errors}
              />
            </Form>
          </ConditionalRender>
          <div className="absolute top-[0] left-[-12px] z-9999">
            {currentStep <= 1 ? (
              <div className="w-[24px] h-[24px] rounded-full bg-[--theme] grid place-items-center font-inter-medium text-white text-sm">
                1
              </div>
            ) : (
              <CheckIcon />
            )}
          </div>
        </div>
        <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-5 relative bg-transparent">
          <div className="flex gap-2 items-center pb-5">
            <div
              className={`pl-2 text-[14px] font-inter-medium text-primaryTextColor ${
                currentStep < 2 ? "opacity-40" : ""
              }`}
            >
              {t("Base Salary")}
            </div>
          </div>
          <ConditionalRender condition={currentStep === 2}>
            <Form
              id="step_form_2"
              onSubmit={handleSubmitSecondStep(handleSecondStepSubmit)}
            >
              <Step2TargetPerformance
                control={second_step_control}
                errors={second_step_errors}
              />
            </Form>
          </ConditionalRender>
          <div
            className="absolute top-[0] left-[-12px] z-9999"
            style={{ opacity: currentStep < 2 ? 0.4 : 1 }}
          >
            {currentStep <= 2 ? (
              <div className="w-[24px] h-[24px] rounded-full bg-[--theme] grid place-items-center font-inter-medium text-white text-sm">
                2
              </div>
            ) : (
              <CheckIcon />
            )}
          </div>
        </div>

        <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 relative bg-transparent">
          <div className="flex gap-2 items-center">
            <div
              className={`pl-2 text-[14px] font-inter-medium text-primaryTextColor ${
                currentStep < 3 ? "opacity-40" : ""
              }`}
            >
              {t("Commissions")}
            </div>

            {currentStep === 3 && (
              <div className="ml-auto">
                <span
                  className="font-inter-medium text-[--theme] cursor-pointer text-sm underline"
                  onClick={() => {
                    setFormCurrentStep(4);
                  }}
                >
                  {t("Create New Commission")} -{">"}
                </span>
              </div>
            )}
          </div>
          <ConditionalRender condition={currentStep === 3}>
            <Form
              onSubmit={handleSubmitThirdStep(handleThirdStepSubmit)}
              id="step_form_3"
              className="pt-5"
            >
              <Step3Commissions
                control={third_step_control}
                errors={third_step_errors}
              />
            </Form>
          </ConditionalRender>

          <div
            className="absolute top-[0] left-[-12px] z-9999"
            style={{ opacity: currentStep < 4 ? 0.4 : 1 }}
          >
            {currentStep <= 3 ? (
              <div className="w-[24px] h-[24px] rounded-full bg-[--theme] grid place-items-center font-inter-medium text-white text-sm">
                3
              </div>
            ) : (
              <CheckIcon />
            )}
          </div>
        </div>
        
      </div>
      <div className="pt-5 flex justify-end gap-3">
        <IconButton
          secondary
          className="max-w-[185px] w-full"
          disabled={isLoading}
          onClick={handleReset}
        >
          {t("Reset")}
        </IconButton>
        <IconButton
          className="max-w-[185px] w-full"
          form={`step_form_${currentStep}`}
          disabled={isLoading}
        >
          {currentStep === 3 ? (
            t("Submit")
          ) : (
            <span>
              {t("Next Step")} -{">"}
            </span>
          )}
        </IconButton>
      </div>
      </>
      )}
      <ConditionalRender condition={currentStep === 4}>
          <div className="w-full">
            
            <p className="font-inter-medium text-[#282D46] text-[20px] mt-[10px] max-w-[475px]">
              {t(
                "Are you sure you want to leave this page, your progress will be lost"
              )}.
            </p>
            <div className="flex justify-end gap-5 mt-[53px]">
              <Link to="/finances/commission">
                <IconButton
                  onClick={() => {
                    onConfirm();
                  }}
                  secondary={true}
                  type="button"
                  className="w-full max-w-[150px]"
                >
                  {t("Leave")}
                </IconButton>
              </Link>
              <IconButton
                onClick={() => setFormCurrentStep(3)}
                type="button"
                className="w-full max-w-[150px] text-white"
              >
                {t("Stay")}
              </IconButton>
            </div>
          </div>
        </ConditionalRender>
    </div>
  );
};

export default withModal(AddNewSalaryModule);
