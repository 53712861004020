import { customStyles } from 'lib/helpers/configs/customStyles'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'
import ApexCharts from "react-apexcharts";
import { useWidgetData } from 'lib/hooks/queries/Dashboard/useWidgetData';
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen';


interface Props {
    name: string
    api: string
    type: string
}
const BarChartWidget: FC<Props> = ({ name, api, type }) => {

    const { t } = useTranslation()
    const [Filter, setFilter] = useState('This week')

    const { data, isLoading } = useWidgetData({ endpoint: api, params: { filter_on: Filter }, type: name })

    if (isLoading) return <LoadingScreen />

    const options = {
        chart: {
            height: 350,
            type: "bar",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "50%", // Adjusts the width of the bars
                borderRadius: 5,
                borderRadiusApplication: 'end',
            },
        },
        dataLabels: {
            enabled: false, // Disable data labels inside the bars
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
        },
        xaxis: {
            categories: (data as any)?.data.map((item: any, index: number) => {
                return item.x
            }),

        },
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
                return `
                        <div class="custom-chart-tooltip">
                            <div class="tooltip-content">
                                <span class="tooltip-text">${series[seriesIndex][dataPointIndex]} ${t('Pendings')}</span>
                            </div>
                        </div>
                `

            }
        },
        fill: {
            opacity: 0.3,
        },
        colors: ["var(--theme)"], // Bar color
        grid: {
            show: true,
            borderColor: "#f1f1f1", // Grid border color
            strokeDashArray: 3,
        },
    };

    const series = [
        {
            name: t(name),
            data: (data as any)?.data.map((item: any, index: number) => {
                return item.y
            }) // Example data points for each month
        },
    ];

    return (
        <div className='w-full h-full bg-white border border-[#F2F4F7] rounded-2xl p-5'>
            <div className='pb-5 flex items-center gap-4 justify-between'>
                <div className='text-primaryTextColor font-inter-bold xl:text-lg 2xl:text-xl'>
                    {t(name)}
                </div>
                <div>
                    <CreatableSelect<any, false>
                        options={[
                            { value: "Today", label: t("Today") },
                            { value: "This week", label: t("This week") },
                            { value: "This month", label: t("This month") },
                        ]}
                        value={{
                            value: Filter,
                            label: t(Filter)
                        }}
                        className="cursor-pointer font-inter-regular react-select-container 3xl:min-w-[150px]"
                        classNamePrefix="react-select"
                        onChange={(newValue: any, actionMeta: any) => {
                            if (
                                actionMeta.action === "select-option" &&
                                newValue?.value
                            ) {
                                setFilter(newValue.value)
                            }
                        }}
                        styles={customStyles}
                        isValidNewOption={() => false}
                        menuPosition={"fixed"}
                        menuPortalTarget={document.body}
                    />
                </div>
            </div>
            <div className='overflow-hidden'>
                <ApexCharts options={options as any} series={series} type="bar" height={300} />
            </div>
        </div>
    )
}

export default BarChartWidget