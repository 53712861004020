import Icon from 'components/atoms/icon/Icon'
import { IconType } from 'lib/helpers/constants/iconTypes'
import { useDeleteWidgetFromLayout } from 'lib/hooks/mutations/Dashboard/useDeleteWidgetFromLayout'
import React, { FC } from 'react'


interface Props {
    isEditing?: boolean
    data: any
}

const DeleteWidget: FC<Props> = ({ isEditing, data }) => {

    const { mutateAsync } = useDeleteWidgetFromLayout()

    const DeleteWidget = (id: number, widget_id: number, position: number) => {

        mutateAsync({ id: id, widget_id: widget_id, position: position }).then(() => {

        })
    }

    if (!isEditing) return null
    return (

        <div className='absolute -top-3 -right-2 z-10 h-7 w-7 grid place-items-center rounded-full bg-theme cursor-pointer'
            onClick={() => DeleteWidget(data.id, data.widget_id, data.position)}
        >
            <Icon iconType={IconType.CLOSE_ICON} color='white' className='w-3 h-3' />

        </div>
    )
}

export default DeleteWidget