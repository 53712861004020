import IconButton from 'components/atoms/button/IconButton';
import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender';
import FilterBorderedWrapper from 'components/atoms/filter-bordered-wrapper/FilterBorderedWrapper';
import Headline from 'components/atoms/headline/Headline';
import Icon from 'components/atoms/icon/Icon';
import Input from 'components/atoms/input/Input';
import DashboardBoxTitle from 'components/atoms/title/DashboardBoxTitle';
import DynamicDropdown from 'components/molecules/dynamic-dropdown/DynamicDropdown';
import DashboardBoxLayout from 'components/templates/dashboard-box-layout/DashboardBoxLayout';
import { IconType } from 'lib/helpers/constants/iconTypes';
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ConfigureTableDropdown from '../leads/dropdowns/ConfigureTableDropdown';
import NoDataBoundary from 'components/atoms/no-data-boundary/NoDataBoundary';
import EmptyEntity from 'components/atoms/empty-entity/EmptyEntity';
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen';
import ApiTokenListView from './api-token-list-view/ApiTokenListView';
import NewApiModal from './new-api-modal/NewApiModal';
import { useExternalSources } from 'lib/hooks/queries/ExternalSources/useExternalSources';
import ViewApiModal from './view-api-modal/ViewApiModal';


const StatusLabel = ({ value }: any) => {
    const [Color, setColor] = useState<string>("");
    const { t } = useTranslation();

    React.useEffect(() => {
        switch (value) {
            case t('Connected'):
                setColor("#2CC95B");
                break;
            case t('Not Connected'):
                setColor("#EFBE12");
                break;
            case t('Deactivated'):
                setColor("#F64E60");
                break;

        }

    }, [value, t]);

    return (
        <span
            className={`text-[12px] bg-opacity-10 font-inter-light px-[8px] py-[3px] rounded-[4px] whitespace-nowrap`}
            style={{ background: Color + "33", color: Color }}
        >
            {value}
        </span>
    );
};

const ApiTokenView = () => {

    const { t } = useTranslation()


    const [configureTableOpen, setConfigureTableOpen] = useState(false)

    const [filterData, setFilterData] = useState({
        search: "",
    });

    const { data: tableData, isLoading } = useExternalSources(filterData)

    const [ViewModalOpen, setViewModalOpen] = useState<any>()

    const [ModalHeaders, setModalHeaders] = useState<any>()




    useEffect(() => {
        if (!tableData) {
            return
        }


        setModalHeaders({
            lead_headers: (tableData as any).lead_headers,
            app_headers: (tableData as any).app_headers
        })


        //eslint-disable-next-line
    }, [tableData])



    const TableHeaders = useMemo(() => {
        return [

            {
                id: 1,
                Header: 'Creation Date',
                accessor: (row: any) =>
                    new Date(row.created_at).toLocaleDateString('de'),
                isChecked: true
            },
            {
                id: 2,
                Header: 'Company',
                accessor: (row: any) =>
                    row.company,
                isChecked: true
            },
            {
                id: 3,
                Header: 'API Name',
                accessor: (row: any) =>
                    row.name,
                isChecked: true
            },
            {
                id: 4,
                Header: 'Created From',
                accessor: (row: any) =>
                    row.created_from.name,
                isChecked: true
            },

            {
                id: 5,
                Header: 'Status',
                accessor: (row: any) =>
                    row.status,
                Cell: ({ row, value }: any) => (
                    <StatusLabel value={row.original.is_active === 0 ? 'Deactivated' : value} />
                ),
                isChecked: true
            },
            {
                id: 6,
                Header: '',
                accessor: (row: any) =>
                    row.status,
                Cell: ({ row }: any) => (
                    <div className="cursor-pointer"

                        onClick={() => {
                            setViewModalOpen({
                                api: row.original.api,
                                api_key: row.original.api_key,
                                module: row.original.module,
                                is_active: row.original.is_active,
                                id: row.original.id
                            })
                        }}
                    >
                        <Icon iconType={IconType.TOOLTIP_THREE_DOTS} className='w-1 h-auto' />
                    </div>
                ),
                isChecked: true
            }

        ];

        //eslint-disable-next-line
    }, [tableData])

    const [cardsOrder, setCardsOrder] = useState(TableHeaders);

    const [NewModalOpen, setNewModalOpen] = useState(false)

    const [NewModalStep, setNewModalStep] = useState(1)


    return (
        <>
            <div className="flex flex-col items-center justify-between gap-3 md:flex-row pb-none ">
                <Headline title="API Management" />
            </div>

            <DashboardBoxLayout >
                <div className="flex flex-col gap-3 items-center justify-between mb-5 md:flex-row">
                    <div className="flex-1">
                        <DashboardBoxTitle
                            title={`${t('API Token List')}`}
                        />
                    </div>

                    <IconButton
                        icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
                        onClick={() => setNewModalOpen(true)}
                    >
                        {t('New API Token')}
                    </IconButton>

                </div>
                <div className="flex flex-col gap-3 items-center mb-5 md:flex-row">
                    <div className="flex-1">
                        <Input
                            icon={IconType.SEARCH_ICON}
                            className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
                            classes={{ icon: "left-4 !top-[30%]" }}
                            placeholder={t("Search")}
                            value={filterData?.search ?? ""}
                            onChange={(e) =>
                                setFilterData((prevFormData: any) => ({
                                    ...prevFormData,
                                    search: e.target.value
                                }))
                            }
                            maxLength={20}
                        />
                    </div>
                    <div className="flex flex-row gap-2 items-center">
                        <div className="flex-none">
                            <div className={`relative`}>
                                <FilterBorderedWrapper
                                    className="cursor-pointer h-[48px] w-[48px] "
                                    onClick={() => {
                                        setConfigureTableOpen(!configureTableOpen);
                                    }}
                                >
                                    <Icon iconType={IconType.FILTER_BARS_ICON} />
                                </FilterBorderedWrapper>
                                <ConditionalRender condition={configureTableOpen}>
                                    <DynamicDropdown
                                        setIsOpen={setConfigureTableOpen}
                                        showCloseButton={true}
                                        title={t('Configure Table')}
                                    >
                                        <ConfigureTableDropdown
                                            setCardsOrder={setCardsOrder}
                                            cardsOrder={cardsOrder}
                                        />
                                    </DynamicDropdown>
                                </ConditionalRender>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="relative">
                    {isLoading ? (
                        <div className='relative py-10'>
                            <LoadingScreen />
                        </div>
                    ) : (
                        <NoDataBoundary
                            condition={(tableData as any).data?.length > 0}
                            fallback={<EmptyEntity name="External Sources" />}
                        >
                            <ApiTokenListView
                                data={(tableData as any)?.data}
                                dataCols={cardsOrder}
                            />
                        </NoDataBoundary>

                    )}

                </div>
            </DashboardBoxLayout>
            <NewApiModal
                hocisOpen={NewModalOpen}
                onConfirm={() => { setNewModalOpen(false); setNewModalStep(1) }}
                hocToggleModal={() => setNewModalOpen(false)}
                hocCurrentStep={NewModalStep}
                hocClasses={{ modalClassName: "max-w-[1000px] w-full", titleClassName: "ml-[35px]" }}
                hocTitle='Generate API Token'
                setNewModalStep={setNewModalStep}
                NewModalStep={NewModalStep}
            />
            <ViewApiModal
                hocisOpen={ViewModalOpen !== undefined}
                onConfirm={() => { setViewModalOpen(undefined) }}
                hocToggleModal={() => setViewModalOpen(undefined)}
                hocCurrentStep={NewModalStep}
                hocClasses={{ modalClassName: "max-w-[1000px] w-full" }}
                hocTitle='API Token'
                data={ViewModalOpen}
                headers={ModalHeaders}
            />
        </>
    )
}

export default ApiTokenView