import Input from 'components/atoms/input/Input'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Step2Headers = ({ Headers }: any) => {

    const { t } = useTranslation()

    return (
        <div className='rounded-[20px] bg-[#F6FBFE] p-4 pb-[30px]'>
            <div className="grid grid-cols-2 gap-6">
                <div>
                    <div className='text-sm font-inter-medium text-primaryTextColor pb-5'>
                        {t(' Required fields for this API')}
                    </div>
                    <div className="space-y-2"> 
                        {Headers.required.map((header: any, index: number) => (
                            <Input key={index} type="text" value={header} className='disabled: bg-white' disabled />
                        ))}
                    </div>


                </div>
                <div>
                    <div className='text-sm font-inter-medium text-primaryTextColor pb-5'>
                        {t('Optional fields')}
                    </div>
                    <div className="space-y-2"> 
                        {Headers.optional.map((header: any, index: number) => (
                            <Input key={index} type="text" value={header} className='disabled: bg-white' disabled />
                        ))}
                    </div>

                </div>
            </div>


        </div>
    )
}

export default Step2Headers