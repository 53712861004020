import { FC } from "react";
import Card, { ICard } from "components/molecules/card/Card";
import { CardType } from "lib/helpers/constants/cardTypes";
import { IInfoCardProps } from "components/molecules/card/info-card/InfoCard";
import { JSX } from "react/jsx-runtime";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useThemeContext } from "lib/context/ThemeContext/ThemeContext";
import { useTranslation } from "react-i18next";
import { useEmployeeStatisticCard } from "lib/hooks/queries/Employee/useEmployeeStatisticCard";

export interface IEmployeeCardsProps {

}

const EmployeeCards: FC<IEmployeeCardsProps> = () => {
    const { themeColors } = useThemeContext();
    const { t } = useTranslation();

    const {
        data
    } = useEmployeeStatisticCard();

    const cardsData = [
        {
            title: `${t('Open On-Boardings')}`,
            color: themeColors.primary,
            count: 0,
            icon: IconType.OPEN_ON_BOARDING_ICON,
            updatedate: new Date().toLocaleDateString('de'),

        },
        {
            title: `${t('Open Off-Boardings')}`,
            color: themeColors.primary,
            count: 0,
            icon: IconType.OPEN_OFF_BOARDING_ICON,
            updatedate: new Date().toLocaleDateString('de'),

        },
        {
            title: `${t('Active Employees')}`,
            count: data?.activeWorkers,
            color: themeColors.primary,
            icon: IconType.ACTIVE_EMPLOYEE_ICON,
            updatedate: new Date().toLocaleDateString('de'),

        },
        {
            title: `${t('Inactive Employee')}`,
            count: data?.deactiveWorkers,
            color: themeColors.primary,
            icon: IconType.INACTIVE_EMPLOYEE_ICON,
            updatedate: new Date().toLocaleDateString('de'),

        },
    ];

    return (
        <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 2xl:grid-cols-4 items-center justify-between gap-5">
            {cardsData?.map(
                (
                    item: JSX.IntrinsicAttributes & ICard<IInfoCardProps>,
                    index: number
                ) => (
                    <Card<IInfoCardProps>
                        cardType={CardType.INFO_CARD}
                        {...item}
                        key={`finances-cards-key-${index}`}
                    />
                )
            )}
        </div>
    );
};

export default EmployeeCards;
