import { addPartnerProducts } from "api/Partners";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useAddPartnerProduct = () => {
    const queryClient = useQueryClient();
    return useMutation(addPartnerProducts, {
        onSuccess: () => {
            queryClient.invalidateQueries('partnerProducts');
            toast.success("Products added successfully");
        },
        onError: () => {
            toast.error(`Something went wrong`);
        },
    });
};
