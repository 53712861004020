import Icon from 'components/atoms/icon/Icon';
import Select from 'react-select';
import Input from 'components/atoms/input/Input';
import Label from 'components/atoms/input/input-label/InputLabel';
import { IconType } from 'lib/helpers/constants/iconTypes';
import React, { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface Props {
    control: any;
    errors: any;
    fields: any;
    append: any;
    setValue: any;
}

const Step2ProductNameAndFiles: FC<Props> = ({ control, errors, fields, append, setValue }) => {

    const { t } = useTranslation();

    const [Options, setOptions] = useState([
        { value: 'ID', label: 'ID' },
        { value: 'Passport', label: 'Passport' },
        { value: 'Contract File', label: 'Contract File' },
    ])

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (value: string) => {
        setInputValue(value);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (inputValue) {
                setOptions([...Options, { value: inputValue, label: inputValue }])
            }
        }, 300); // Adjust delay as needed

        return () => clearTimeout(timeout);

        // eslint-disable-next-line
    }, [inputValue]);





    return (
        <div className="rounded-[20px] bg-[#F6FBFE] p-4">
            <div className="grid grid-cols-1 gap-4">
                {fields.map((field: any, index: number) => (
                    <React.Fragment key={field.id}>
                        {/* Product Name Input */}
                        <Controller
                            name={`products.${index}.product_name`}
                            rules={{ required: t('This field is required') }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    label="Product Name"
                                    placeholder={t('Enter product name')}
                                    error={errors.products?.[index]?.product_name}
                                />
                            )}
                        />

                        {/* Multiple Select for Files */}
                        <div>
                            <Label>{t('File Name')}</Label>
                            <Controller
                                name={`products.${index}.product_file`}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        isMulti
                                        options={Options}
                                        onChange={(selectedOptions, actionMeta) => {
                                            field.onChange(selectedOptions); // Update form state

                                            if (actionMeta.action === "select-option") {
                                                setOptions([
                                                    { value: 'ID', label: 'ID' },
                                                    { value: 'Passport', label: 'Passport' },
                                                    { value: 'Contract File', label: 'Contract File' },
                                                ])
                                            }
                                        }}
                                        onInputChange={(e) => { handleInputChange(e) }}
                                        placeholder={t('Choose or add files')}
                                        classNamePrefix="react-select-multiple"
                                    />
                                )}
                            />

                        </div>
                    </React.Fragment>
                ))}

                {/* Add New Product */}
                <div className="flex justify-end">
                    <div
                        className="flex items-center gap-2 cursor-pointer pt-4"
                        onClick={() => append({ product_name: '', product_file: [] })}
                    >
                        <Icon iconType={IconType.PLUS_ICON} color="var(--theme)" />
                        <span className="text-sm font-inter-medium text-theme">
                            {t('New Product')}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step2ProductNameAndFiles;
