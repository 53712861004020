import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

type IEmptyEntityClasses = {
  childrenclass?: string;
  conatinerclass?: string;
};

export interface IEmptyEntity {
  name?: string;
  children?: React.ReactNode;
  classes?: Partial<IEmptyEntityClasses>;
  full_string?: string;
}

const EmptyEntity: FunctionComponent<IEmptyEntity> = ({
  name,
  classes = { childrenclass: "", conatinerclass: "" },
  children,
  full_string,
}) => {
  const { t } = useTranslation();
  if (children) {
    return (
      <p
        className={`text-[14px] text-[#D0D5DD] font-inter-regular ${classes.childrenclass}`}
      >
        {children}
      </p>
    );
  }
  return (
    <div className={`text-center my-20 text-xl ${classes.conatinerclass}`}>
      <p className="font-inter-regular text-primaryTextColor">{t("Oops")}!</p>
      {full_string ? (
        <p className="font-inter-regular text-primaryTextColor max-w-[500px] mx-auto">
          {t(full_string)}
        </p>
      ) : (
        <>
          <p className="font-inter-regular text-primaryTextColor">
            {t("We couldn't find any")} {t(name as string)}.
          </p>
          <p className="font-inter-regular text-primaryTextColor">
            {t("Please check back later or add new")} {t(name as string)}.
          </p>
        </>
      )}
    </div>
  );
};

export default EmptyEntity;
