import { apiRequest } from "./Api";

export const createExternalSource = (data: any) =>
    apiRequest<FormData, any>("POST", "external-api-source/store", data);

export const getExternalSources = (data: any) =>
    apiRequest<any, any>("GET", `external-api-source`, data);

export const toggleExternalSource = (data: any) =>
    apiRequest<any, any>("POST", `external-api-source/update/active-deactive`, data);

