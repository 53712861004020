import IconButton from 'components/atoms/button/IconButton'
import withModal from 'lib/helpers/hoc/withModal'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    reject: () => void
    confirmFunction: () => void
    title: string
}

const ConfirmModal: FC<Props> = ({ reject, confirmFunction, title }) => {

    const { t } = useTranslation()

    return (
        <div className="w-full">

            <p className="font-inter-medium text-[#282D46] text-[20px] mt-[10px] max-w-[475px]">
                {t(title)}
            </p>
            <div className="flex justify-end gap-5 mt-[53px]">

                <IconButton
                    onClick={() => {
                        reject();
                    }}
                    secondary={true}
                    type="button"
                    className="w-full max-w-[150px]"
                >
                    {t("Cancel")}
                </IconButton>
                <IconButton
                    onClick={() => {
                        confirmFunction();
                    }}
                    type="button"
                    className="w-full max-w-[150px] text-white"
                >
                    {t("Continue")}
                </IconButton>
            </div>
        </div>
    )
}

export default withModal(ConfirmModal)