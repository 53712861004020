import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender';
import FilterBorderedWrapper from 'components/atoms/filter-bordered-wrapper/FilterBorderedWrapper';
import Icon from 'components/atoms/icon/Icon';
import DashboardBoxTitle from 'components/atoms/title/DashboardBoxTitle';
import DynamicDropdown from 'components/molecules/dynamic-dropdown/DynamicDropdown';
import DashboardBoxLayout from 'components/templates/dashboard-box-layout/DashboardBoxLayout';
import { IconType } from 'lib/helpers/constants/iconTypes';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import Filter from '../Dropdowns/Filter';
import { useGeneralStats } from 'lib/hooks/queries/Statistics/GeneralStats/useGeneralStats';

const NumberOfLeadsAppCust = () => {
    const { t } = useTranslation()

    const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);

    const [Filters, setFilters] = useState({
        worker: 'All agents',
        filter_on: 'Today',
        product: 'All products',
        company: 'All companies'
    })

    const { data } = useGeneralStats(Filters)


    return (
        <DashboardBoxLayout className='h-full'>
            <div className="flex flex-row gap-3 items-center justify-between mb-5">
                <div className="flex-1">
                    <DashboardBoxTitle
                        title={`General`}
                    />
                </div>
                <div className="flex-none">
                    <div className={`${filterTableOpen && "relative"}`}>
                        <FilterBorderedWrapper
                            className="h-[48px] w-[48px] cursor-pointer"
                            onClick={() => {
                                setFilterTableOpen(!filterTableOpen);
                            }}
                        >
                            <Icon iconType={IconType.FILTER_ICON} />
                        </FilterBorderedWrapper>
                        <ConditionalRender condition={filterTableOpen}>
                            <DynamicDropdown
                                setIsOpen={setFilterTableOpen}
                                showCloseButton={true}
                                title={t('Filter')}
                                className="sm:!w-[350px]"
                            >
                                <Filter
                                    setIsOpen={setFilterTableOpen}
                                    setFilterData={setFilters}
                                    FilterData={Filters}
                                />
                            </DynamicDropdown>
                        </ConditionalRender>
                    </div>
                </div>
            </div>
            <div className="border border-border rounded-xl">
                <div className="flex gap-3 items-center p-4 border-b border-b-border">
                    <div className="flex-none ">
                        <div className="bg-themeOpacity rounded h-[40px] w-[40px] grid place-items-center">
                            <Icon iconType={IconType.APPOINTMENT_CALENDAR_ICON} color='var(--theme)' />
                        </div>
                    </div>
                    <div className='flex-1'>
                        <div className="text-primaryTextColor font-inter-semibold">{t('Appointments')}</div>

                    </div>
                    <div className="flex-none">
                        <div className="text-sm text-primaryTextColor font-inter-medium">{(data as any)?.appointment_statistics.appointment}</div>
                    </div>
                </div>
                <div className="flex gap-3 items-center p-4 border-b border-b-border">
                    <div className="flex-none ">
                        <div className="bg-themeOpacity rounded h-[40px] w-[40px] grid place-items-center">
                            <Icon iconType={IconType.CONTRACT_ICON} color='var(--theme)' />
                        </div>
                    </div>
                    <div className='flex-1'>
                        <div className="text-primaryTextColor font-inter-semibold">{t('Contracts')}</div>

                    </div>
                    <div className="flex-none">
                        <div className="text-sm text-primaryTextColor font-inter-medium">{(data as any)?.customer_statistics.total_contracts}</div>
                    </div>
                </div>
                <div className="flex gap-3 items-center p-4">
                    <div className="flex-none ">
                        <div className="bg-themeOpacity rounded h-[40px] w-[40px] grid place-items-center">
                            <Icon iconType={IconType.CUSTOMERS_ICON} color='var(--theme)' />
                        </div>
                    </div>
                    <div className='flex-1'>
                        <div className="text-primaryTextColor font-inter-semibold">{t('Customers')}</div>

                    </div>
                    <div className="flex-none">
                        <div className="text-sm text-primaryTextColor font-inter-medium">{(data as any)?.customer_statistics.total_customers}</div>
                    </div>
                </div>
            </div>

        </DashboardBoxLayout>
    )
}

export default NumberOfLeadsAppCust