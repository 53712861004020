import { getStatisticCardsData } from "api/Employee"
import { useQuery } from "react-query"


export const useEmployeeStatisticCard = () => {
    return useQuery<Array<any> | any>(
        ['customers'],
        () => getStatisticCardsData(),
        {
            onError(error: any) {
                console.error(error)
            }
        }
    )
}