import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'


interface Props {
    currentStep: number;
}

const StepsCounter: FC<Props> = ({ currentStep }) => {
    const { t } = useTranslation()


    return (
        <div>
            <div className='py-10 flex'>
                <div className='w-[90px] flex flex-col items-center gap-5'>
                    <div className={`h-6 w-6 text-white text-sm font-inter-medium rounded-full flex items-center justify-center 
                    ${currentStep >= 1 ? 'bg-theme' : 'bg-secondaryTextColor'}`
                    }>
                        1
                    </div>
                    <div className={`text-center font-inter-regular  ${currentStep >= 1 ? 'text-theme' : 'text-secondaryTextColor'}`}>
                        {t('Employee Information')}
                    </div>
                </div>
                <div className="flex-1 pt-2 -ml-3">
                    <div className={`h-1 w-full  rounded-full ${currentStep >= 2 ? 'bg-theme' : 'bg-secondaryTextColor'}`}></div>
                </div>
                <div className='w-[90px] flex flex-col items-center gap-5 -ml-3'>
                    <div className={`h-6 w-6 text-white text-sm font-inter-medium rounded-full flex items-center justify-center 
                    ${currentStep >= 2 ? 'bg-theme' : 'bg-secondaryTextColor'}`
                    }>
                        2
                    </div>
                    <div className={`text-center font-inter-regular  ${currentStep >= 2 ? 'text-theme' : 'text-secondaryTextColor'}`}>
                        {t('Choose Role Model')}
                    </div>
                </div>
                <div className="flex-1 pt-2 -ml-3">
                    <div className={`h-1 w-full  rounded-full ${currentStep >= 3 ? 'bg-theme' : 'bg-secondaryTextColor'}`}></div>
                </div>
                <div className='w-[100px] flex flex-col items-center gap-5 -ml-3'>
                    <div className={`h-6 w-6 text-white text-sm font-inter-medium rounded-full flex items-center justify-center 
                    ${currentStep >= 3 ? 'bg-theme' : 'bg-secondaryTextColor'}`
                    }>
                        3
                    </div>
                    <div className={`text-center font-inter-regular  ${currentStep >= 3 ? 'text-theme' : 'text-secondaryTextColor'}`}>
                        {t('Choose Salary Model')}
                    </div>
                </div>
                <div className="flex-1 pt-2 -ml-3">
                    <div className={`h-1 w-full  rounded-full ${currentStep >= 4 ? 'bg-theme' : 'bg-secondaryTextColor'}`}></div>
                </div>
                <div className='w-[85px] flex flex-col items-center gap-5 -ml-3'>
                    <div className={`h-6 w-6 text-white text-sm font-inter-medium rounded-full flex items-center justify-center 
                    ${currentStep >= 4 ? 'bg-theme' : 'bg-secondaryTextColor'}`
                    }>
                        4
                    </div>
                    <div className={`text-center font-inter-regular  ${currentStep >= 4 ? 'text-theme' : 'text-secondaryTextColor'}`}>
                        {t('Add Expenses')}
                    </div>
                </div>
                <div className="flex-1 pt-2 -ml-3">
                    <div className={`h-1 w-full  rounded-full ${currentStep >= 5 ? 'bg-theme' : 'bg-secondaryTextColor'}`}></div>
                </div>
                <div className='w-[90px] flex flex-col items-center gap-5 -ml-3'>
                    <div className={`h-6 w-6 text-white text-sm font-inter-medium rounded-full flex items-center justify-center 
                    ${currentStep >= 5 ? 'bg-theme' : 'bg-secondaryTextColor'}`
                    }>
                        5
                    </div>
                    <div className={`text-center font-inter-regular  ${currentStep >= 5 ? 'text-theme' : 'text-secondaryTextColor'}`}>
                        {t('Additional Compensations')}
                    </div>
                </div>
                <div className="flex-1 pt-2 -ml-3">
                    <div className={`h-1 w-full  rounded-full ${currentStep >= 6 ? 'bg-theme' : 'bg-secondaryTextColor'}`}></div>
                </div>
                <div className='w-[90px] flex flex-col items-center gap-5 -ml-3'>
                    <div className={`h-6 w-6 text-white text-sm font-inter-medium rounded-full flex items-center justify-center 
                    ${currentStep >= 6 ? 'bg-theme' : 'bg-secondaryTextColor'}`
                    }>
                        6
                    </div>
                    <div className={`text-center font-inter-regular w-[70px]  ${currentStep >= 6 ? 'text-theme' : 'text-secondaryTextColor'}`}>
                        {t('Add Goals')}
                    </div>
                </div>
                <div className="flex-1 pt-2 -ml-3">
                    <div className={`h-1 w-full  rounded-full ${currentStep >= 7 ? 'bg-theme' : 'bg-secondaryTextColor'}`}></div>
                </div>
                <div className='w-[90px] flex flex-col items-center gap-5 -ml-3'>
                    <div className={`h-6 w-6 text-white text-sm font-inter-medium rounded-full flex items-center justify-center 
                    ${currentStep >= 7 ? 'bg-theme' : 'bg-secondaryTextColor'}`
                    }>
                        7
                    </div>
                    <div className={`text-center font-inter-regular  ${currentStep >= 7 ? 'text-theme' : 'text-secondaryTextColor'}`}>
                        {t('Add Bonuses')}
                    </div>
                </div>
            </div >
            <div className='pb-7 font-inter-semibold text-lg'>
                {{
                    1: t('Employee Information'),
                    2: t('Choose Role Model'),
                    3: t('Choose Salary Model'),
                    4: t('Add Expenses'),
                    5: t('Additional Compensations'),
                    6: t('Add Goals'),
                    7: t('Add Bonuses'),
                    8: t('Create Role Model')
                }[currentStep]}
            </div>
        </div>
    )
}

export default StepsCounter