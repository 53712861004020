import { useState, useEffect } from "react";

const useScreenPercentage = (initialPercentage = 25) => {
  const getPercentage = () => (window.innerWidth > 1400 ? 21 : initialPercentage);

  const getSize = () => ({
    width: (window.innerWidth * getPercentage()) / 100,
    height: (window.innerHeight * getPercentage()) / 100,
  });

  const [size, setSize] = useState(getSize);

  useEffect(() => {
    const updateSize = () => setSize(getSize());

    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return size;
};

export default useScreenPercentage;
