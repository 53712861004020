import EmptyEntity from 'components/atoms/empty-entity/EmptyEntity'
import Input from 'components/atoms/input/Input'
import Accordion from 'components/molecules/accordion/Accordion'
import AccordionBody from 'components/molecules/accordion/AccordionBody/AccordionBody'
import AccordionTitle from 'components/molecules/accordion/AccordionTitle/AccordionTitle'
import React, { useMemo, useState } from 'react'

const TargetDetails = ({ data }: any) => {

    const Colors = useMemo(() => {
        return [
            "#50B8E7", "#2CC95B", "#884DFF", "#FF8F0D"
        ]
    }, [])


    const [Hover, setHover] = useState<null | number>(null)


    if (data?.length === 0 || data === undefined) {
        return (
            <EmptyEntity name='Targets' />
        )
    }

    return (
        <div className='space-y-7'>
            {data.map((item: any, index: number) => (
                <Accordion classes={{ wrapper: '!rounded-[20px] !p-5', title: '!items-start !text-left' }} key={item.id}>
                    <AccordionTitle subTitleClass="!text-xl !font-inter-bold !text-primaryTextColor flex-1" iconClass='mt-2'>
                        <div>
                            Target: {item.name}
                        </div>
                        <div className='pt-20 flex-1 -mr-3'>
                            <div className='w-full h-2  bg-opacity-10 rounded-full relative' style={{
                                backgroundColor: Colors[index % Colors.length] + '10',
                            }}>
                                <div className="absolute top-[-30px] right-0">
                                    <span className='text-primaryTextColor text-base font-inter-regular'>
                                        {item.category === 'Contracts' ? item.data.target_value : item.data.target_value + ' CHF'}
                                    </span>
                                </div>

                                {/* ToolTip */}
                                <div className='h-full rounded-full relative' onMouseEnter={() => { setHover(item.id) }} onMouseLeave={() => { setHover(null) }} style={{
                                    backgroundColor: Colors[index % Colors.length],
                                    width: item.data.product_result === 0 ? 5 : (item.data.product_result / item.data.target_value) * 100 + '%'
                                }}>
                                    {Hover === item.id && (
                                        <div className="absolute top-[-39px] right-[calc(0%-58px)]">
                                            <div className="relative inline-block">
                                                <div className=" text-white text-sm font-inter-regular px-3 py-1 rounded min-w-[120px] flex justify-center" style={{
                                                    backgroundColor: Colors[index % Colors.length],
                                                }}>
                                                    {item.category === 'Contracts' ? item.data.product_result + ' Contracts' : parseFloat(item.data.product_result).toFixed(2) + ' CHF'}
                                                </div>
                                                <div className="absolute left-1/2 -bottom-[5px] w-[10px] h-[10px] bg-green-500 rotate-45 transform -translate-x-1/2"
                                                    style={{
                                                        backgroundColor: Colors[index % Colors.length],
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </AccordionTitle>
                    <AccordionBody>
                        <div className='flex-1 pt-5'>

                            <div className="grid grid-cols-2 gap-4">
                                <Input
                                    label="Product Name"
                                    classes={{
                                        label:
                                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px] !mb-0",
                                    }}
                                    className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                                    value={item.data?.product || '-'}
                                    disabled={true}
                                />
                                <Input
                                    label="Product Company"
                                    classes={{
                                        label:
                                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px] !mb-0",
                                    }}
                                    className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                                    value={item.data?.product_company || '-'}
                                    disabled={true}
                                />

                                <Input
                                    label="Specific Product"
                                    classes={{
                                        label:
                                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px] !mb-0",
                                    }}
                                    className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                                    value={item.data?.specific_product || '-'}
                                    disabled={true}
                                />

                                <Input
                                    label="Category"
                                    classes={{
                                        label:
                                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px] !mb-0",
                                    }}
                                    className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                                    value={item.category}
                                    disabled={true}
                                />
                                <Input
                                    label="Period"
                                    classes={{
                                        label:
                                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px] !mb-0",
                                    }}
                                    className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                                    value={new Date(item.data.valid_from).toLocaleDateString('de') + ' - ' + new Date(item.data.valid_to).toLocaleDateString('de')}
                                    disabled={true}
                                />
                                <Input
                                    label="Target Value"
                                    classes={{
                                        label:
                                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px] !mb-0",
                                    }}
                                    className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                                    value={item.category === 'Contracts' ? item.data.target_value : item.data.target_value + ' CHF'}
                                    disabled={true}
                                />



                                {item?.bonus !== null && (
                                    <>
                                        <Input
                                            label="Measurement Type"
                                            classes={{
                                                label:
                                                    "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px] !mb-0",
                                            }}
                                            className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                                            value={item?.bonus?.calculation_method || '-'}
                                            disabled={true}
                                        />
                                        <Input
                                            label="Bonus Name"
                                            classes={{
                                                label:
                                                    "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px] !mb-0",
                                            }}
                                            className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                                            value={item.bonus.name}
                                            disabled={true}
                                        />
                                        <Input
                                            label="Bonus Amount"
                                            classes={{
                                                label:
                                                    "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px] !mb-0",
                                            }}
                                            className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                                            value={item?.bonus.calculation_method === 'Fixed Amount' ? (item?.bonus?.bonus_value + ' CHF') : (item?.bonus?.bonus_value + '%')}
                                            disabled={true}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </AccordionBody>

                </Accordion>
            ))}
        </div>
    )
}

export default TargetDetails