import { useQuery } from "react-query"
import { getSalary } from "api/Employee";
import { usePermissions } from "lib/hooks/shared/usePermissions";


export const useSalary = ({ id, month }: { id?: string, month?: string }) => {
    const permissions = usePermissions(['employee_management_view']);

    const idWithPermission = permissions ? id : null

    return useQuery<Array<any> | any>(
        ['salary', month],
        () => getSalary({ id: idWithPermission, month }),
        {
            onError(error: any) {
                console.error(error)
            }
        }
    )
}