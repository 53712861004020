import { apiRequest } from "./Api";

export const getPartnersForFilter = () =>
  apiRequest<any, any>("GET", "partners/for-filter");

export const getPartners = (params?: any) =>
  apiRequest<any, any>("GET", "partners", null, undefined, params);


export const createNewPartner = (data?: any) =>
  apiRequest<any, unknown>("POST", "partners/store", data);


export const updatePartner = (params: any) => {

  return apiRequest<FormData, any>("PUT", `partners/update/${params.id}`, params, undefined);
};

export const deletePartner = (id: any) => {
  return apiRequest<FormData, any>("DELETE", `partners/delete/${id}`);
};



export const updatePartnerStatus = (params: any) => {
  return apiRequest<FormData, any>("PUT", `partners/update-status/${params.id}`);
};


export const getStatisticCardsData = () =>
  apiRequest<any, any>("GET", "partners/info-cards");

export const getPartnerProducts = (id: string) => {
  return apiRequest<FormData, any>("get", `partners/partner-products/${id}`);
};

export const addPartnerProducts = (data: any) => {
  return apiRequest<FormData, any>("post", `partners/add-partner-product`, data);
}

export const getPartnerSingleProduct = (params: any) => {
  return apiRequest<any, any>("get", `partners/products-of-partner`, null, undefined, params);
};