import Icon from 'components/atoms/icon/Icon';
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen';
import Accordion from 'components/molecules/accordion/Accordion';
import AccordionBody from 'components/molecules/accordion/AccordionBody/AccordionBody';
import AccordionTitle from 'components/molecules/accordion/AccordionTitle/AccordionTitle';
import { IconType } from 'lib/helpers/constants/iconTypes';
import { usePartnerProducts } from 'lib/hooks/queries/Partners/usePartnerProducts';
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next';


interface IAllProductsProps {
    setIsAddingProduct: (value: boolean) => void
    data: any
}

const AllProducts: FC<IAllProductsProps> = ({ setIsAddingProduct, data }) => {

    const { t } = useTranslation()

    const { data: productData, isLoading } = usePartnerProducts(data.id)


    if (isLoading) {
        return <LoadingScreen />
    }

    return (
        <div>
            {productData?.products?.length !== 0 ? (
                productData?.products?.map((product: any, index: number) => {
                    return (
                        <Accordion classes={{ wrapper: '!rounded-2xl !p-0 !border-[#A2A1A833] mt-5', title: '!bg-[#F6FBFE] !px-4 !py-3 !rounded-2xl', body: '!px-4 !mt-0' }} key={index}>
                            <AccordionTitle subTitleClass={'font-inter-semibold text-primaryTextColor'}>{product.base_product}</AccordionTitle>
                            <AccordionBody>
                                <div className='pb-4 mt-4 w-full space-y-5'>
                                    {product?.prods?.map((prod: any, index: number) => (
                                        <div className="flex flex-col gap-3" key={index}>
                                            <div className="font-inter-medium text-primaryTextColor text-sm">
                                                &#x2022; {prod.products_name}
                                            </div>
                                            <div className="flex flex-wrap gap-2">
                                                {prod.products_file.map((file: any,index:number) => (
                                                    <div className="bg-[#F5F6F7] px-3 py-1 rounded-[20px] font-inter-medium text-primaryTextColor text-xs" key={index}>
                                                        {file}
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </AccordionBody>



                        </Accordion>
                    )
                })
            ) : (
                <div className=" text-[#7D8592] text-xs font-inter-regular">
                    <span>{t("No Products haven't been added yet.")}</span>
                </div>
            )}
            <div className='flex justify-end mt-4'>
                <div className='flex  items-center gap-2 cursor-pointer' onClick={() => { setIsAddingProduct(true) }}>
                    <Icon iconType={IconType.PLUS_ICON} color='var(--theme)' />
                    <span className='text-sm font-inter-medium text-theme'>{t('Add Product')}</span>
                </div>
            </div>

        </div>
    )
}

export default AllProducts