import Checkbox from 'components/atoms/checkbox/Checkbox'
import Icon from 'components/atoms/icon/Icon'
import Table from 'components/molecules/table/Table'
import { IconType } from 'lib/helpers/constants/iconTypes'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  PermissionData: any
  setPermissionData: (item: any) => void
}

const Step2Permissions: FC<Props> = ({ PermissionData, setPermissionData }) => {
  const { t } = useTranslation()
  const [ActiveIDs, setActiveIDs] = useState<any>([])

  useEffect(() => {
    // Extract IDs where is_active is "Yes"
    const ids = PermissionData.parent_permission.flatMap((parent: any) =>
      parent.permission
        .filter((perm: any) => perm.is_active === 'Yes')
        .map((perm: any) => perm.id)
    );
    setActiveIDs(ids)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleCheckboxChange = (id: any) => {
    if (PermissionData.permissions_id.includes(id)) {
      setPermissionData({
        ...PermissionData,
        permissions_id: PermissionData.permissions_id.filter((m: any) => m !== id),
      })
    } else {
      setPermissionData({
        ...PermissionData,
        permissions_id: [...PermissionData.permissions_id, id],
      })
    }

    if (ActiveIDs.includes(id)) {
      setActiveIDs(ActiveIDs.filter((aID: any) => aID !== id))
    } else {
      setActiveIDs([...ActiveIDs, id])
    }
  }

  const findPermissionByName = (permissions: any[], name: string) => {
    return permissions?.find((m: any) => m.name.split('_')[m.name.split('_').length - 1] === name)
  }

  const TableHeaders = [
    {
      id: 1,
      Header: <div className='font-inter-bold text-primaryTextColor text-left w-full'>{t('Functionalities')}</div>,
      accessor: "Functionalities",
      Cell: ({ row }: any) => (
        <div className='max-w-[350px] w-full'>
          <div className='flex flex-col gap-2'>
            <span className='text-sm font-inter-semibold'>{t(row.original?.name)}:</span>
            <span className='text-sm font-inter-regular'>{t(row.original?.description)}</span>
          </div>
        </div>
      ),
      isChecked: true
    },
    {
      id: 2,
      Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('View all')}</span>,
      accessor: "View",
      Cell: ({ row }: any) => (
        <div className='flex justify-center'>
          {JSON.parse(row.original?.allowed_permission_types).filter((item: any) => item.label === 'View').length > 0 ? (
            <Checkbox
              onChange={(e) => {
                handleCheckboxChange(findPermissionByName(row.original?.permission, 'view')?.id)
              }}

              checked={ActiveIDs.includes(findPermissionByName(row.original?.permission, 'view')?.id)}
            />
          ) : (
            <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
          )}
         
        </div>
      ),
      isChecked: true
    },
    {
      id: 8,
      Header: <div className='font-inter-bold text-primaryTextColor text-center -ml-6'>{t('/Own')}</div>,
      accessor: "View",
      Cell: ({ row }: any) => (
        <div className='flex justify-center -ml-6'>
          {row.original?.allowed_permission_types.includes('ViewOwn') ? (
            <Checkbox checked={ActiveIDs.includes(findPermissionByName(row.original?.permission, 'own')?.id)}
              onChange={() => {
                handleCheckboxChange(findPermissionByName(row.original?.permission, 'own')?.id)
              }}
            />
          ) : (
            <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
          )}
        </div>
      ),
      isChecked: true
    },
    {
      id: 3,
      Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Edit')}</span>,
      accessor: "Edit",
      Cell: ({ row }: any) => (
        <div className='flex justify-center'>
          {row.original?.allowed_permission_types.includes('Edit') ? (
            <Checkbox
              checked={ActiveIDs.includes(findPermissionByName(row.original?.permission, 'edit')?.id)}
              onChange={() => {
                handleCheckboxChange(findPermissionByName(row.original?.permission, 'edit')?.id)
              }}
            />
          ) : (
            <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
          )}
        </div>
      ),
      isChecked: true
    },
    {
      id: 4,
      Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Create')}</span>,
      accessor: "Create",
      Cell: ({ row }: any) => (
        <div className='flex justify-center'>
          {row.original?.allowed_permission_types.includes('Create') ? (
            <Checkbox
              checked={ActiveIDs.includes(findPermissionByName(row.original?.permission, 'create')?.id)}
              onChange={() => {
                handleCheckboxChange(findPermissionByName(row.original?.permission, 'create')?.id)
              }}
            />
          ) : (
            <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
          )}
        </div>
      ),
      isChecked: true
    },
    {
      id: 5,
      Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Delete')}</span>,
      accessor: "Delete",
      Cell: ({ row }: any) => (
        <div className='flex justify-center'>
          {row.original?.allowed_permission_types.includes('Delete') ? (
            <Checkbox
              checked={ActiveIDs.includes(findPermissionByName(row.original?.permission, 'delete')?.id)}
              onChange={() => {
                handleCheckboxChange(findPermissionByName(row.original?.permission, 'delete')?.id)
              }}
            />
          ) : (
            <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
          )}
        </div>
      ),
      isChecked: true
    },
    {
      id: 6,
      Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Assign')}</span>,
      accessor: "Assign",
      Cell: ({ row }: any) => (
        <div className='flex justify-center'>
          {row.original?.allowed_permission_types.includes('Assign') ? (
            <Checkbox
              checked={ActiveIDs.includes(findPermissionByName(row.original?.permission, 'assign')?.id)}
              onChange={(e) => {

                handleCheckboxChange(findPermissionByName(row.original?.permission, 'assign')?.id)
              }}
            />
          ) : (
            <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
          )}
        </div>
      ),
      isChecked: true
    },
    {
      id: 7,
      Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Export')}</span>,
      accessor: "Export",
      Cell: ({ row }: any) => (
        <div className='flex justify-center'>
          {row.original?.allowed_permission_types.includes('Export') ? (
            <Checkbox
              checked={ActiveIDs.includes(findPermissionByName(row.original?.permission, 'export')?.id)}
              onChange={() => {

                handleCheckboxChange(findPermissionByName(row.original?.permission, 'export')?.id)
              }}
            />
          ) : (
            <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
          )}

        </div>
      ),
      isChecked: true

    },
    ...(PermissionData.module.name === 'Pendings' ? [{
      id: 9,
      Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Feedback File')}</span>,
      accessor: "Feedback File",
      Cell: ({ row }: any) => (
        <div className='flex justify-center'>
          {row.original?.allowed_permission_types.includes('FeedbackFile') ? (
            <Checkbox
              checked={ActiveIDs.includes(findPermissionByName(row.original?.permission, 'feedbackfile')?.id)}
              onChange={() => {

                handleCheckboxChange(findPermissionByName(row.original?.permission, 'feedbackfile')?.id)
              }}
            />
          ) : (
            <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
          )}

        </div>
      ),
      isChecked: true

    }] : [])
  ]

  return (
    <div className='rounded-2xl !border-[#A2A1A833] border'>
      <div className="rounded-tl-2xl rounded-tr-2xl bg-[#F6FBFE] px-4 py-2">
        <span className='font-inter-semibold text-primaryTextColor'>{PermissionData.module.name}</span>
      </div>
      <div className='pb-6 px-2'>
        <Table
          columns={TableHeaders}
          data={PermissionData.parent_permission || []}
          search={""}
          isColumnSorted={true}
          classes={{ container: '!bg-transparent', singleHeaderClass: '!w-auto justify-center' }}
        />
      </div>
    </div>
  )
}
export default Step2Permissions