import IconButton from "components/atoms/button/IconButton";
import Icon from "components/atoms/icon/Icon";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { IconType } from "lib/helpers/constants/iconTypes";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useDeleteCustomerDocument } from "lib/hooks/mutations/Customers/useDeleteCustomerDocument";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import InputsFolder from "components/molecules/inputs-folder/InputsFolder";
import UploadedCustomerFile from "components/molecules/uploaded-customer-file/UploadedCustomerFile";
import { useTranslation } from "react-i18next";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import { usePermissions } from "lib/hooks/shared/usePermissions";

export interface IUpdateCustomerDocumentsProps<T> {
  onConfirm: () => void;
  data: T;
  setCurrentFormStep?: (value: number) => void;
  customerData: T;
  currentStep: number;
  setFileLink: SetStateAction<Dispatch<any>>;
  setHasDoucmentName: SetStateAction<Dispatch<any>>;
  isLoadingGettingDocuments: boolean;
  themeColors: {
    primary: string;
  };
}

const UpdateCustomerDocuments: FC<IUpdateCustomerDocumentsProps<any>> = ({
  data,
  setCurrentFormStep,
  customerData,
  currentStep,
  setFileLink,
  setHasDoucmentName,
  isLoadingGettingDocuments,
  themeColors,
}) => {
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const { mutateAsync: deleteCustomerDocument } = useDeleteCustomerDocument();
  const { t } = useTranslation();



  const deleteDocument = (document_id: string) => {
    const data = { document_id, costumer_id: customerData.id };

    setIsDeleteLoading(true);
    deleteCustomerDocument(data)
      .then(() => {
        setIsDeleteLoading(false);
      })
      .catch(() => { });
  };

  const groupByFileSection = (data: Array<Record<string, any>>) => {
    return data.reduce((acc, item) => {
      (acc[item.file_section] = acc[item.file_section] || []).push(item);
      return acc;
    }, {});
  };

  const hasPermissionToCreateDeocument = usePermissions(['customers_documents_create']);
  const hasPermissionToDeleteDocument = usePermissions(['customers_documents_delete']);
  if (isLoadingGettingDocuments) {
    return <LoadingScreen />;
  }
  const groupedData = groupByFileSection(data?.data || []);

  console.log(data?.pending);

  return (
    <div className="flex flex-col gap-5">
      {isDeleteLoading && <LoadingScreen />}
      <NoDataBoundary
        condition={(data?.data && data?.data?.length > 0) || (data?.pending && data?.pending?.length > 0)}
        fallback={<EmptyEntity name="Document" />}
      >
        <>

          {Object.entries(groupedData).map(([section, items], index: number) => {
            return (
              <InputsFolder
                title={t(section)}
                key={`section-key-${index}-${section}
            }`}
              >
                <div className="flex flex-col gap-4">
                  {items.map((item: Record<string, any>) => {
                    return (
                      <UploadedCustomerFile
                        setFileLink={setFileLink}
                        setCurrentFormStep={setCurrentFormStep}
                        currentStep={currentStep}
                        setHasDoucmentName={setHasDoucmentName}
                        icon={
                          item.name.split(".").pop() === "pdf"
                            ? IconType.FILE_PDF_ICON
                            : ["csv", "xlsx", "xls"].includes(
                              item.name.split(".").pop()
                            )
                              ? IconType.UPLOADED_CSV_ICON
                              : IconType.FILE_JPG_ICON
                        }
                        title={item.name}
                        size=""
                        link={item?.file ?? ""}
                        updatedate={item.created_at.substring(0, 10)}
                        linkName="costumers"
                        deleteIcon={
                          <ConditionalRender condition={hasPermissionToDeleteDocument}>
                            <IconButton
                              onClick={() => {
                                deleteDocument(item.id);
                              }}
                              type="button"
                              className="w-full bg-transparent border-none p-0"
                            >
                              <Icon
                                iconType={IconType.DELETE_ICON}
                                color="#979797"
                              />
                            </IconButton>
                          </ConditionalRender>
                        }
                      />
                    );
                  })}
                </div>
              </InputsFolder>
            );
          })}
          {data?.pending?.map((item: Record<string, any>, index: number) => {
            if(item.documents.length === 0) return null;
            
            let count = 0

            for (let i = 0; i < item.documents.length; i++) { 
              if(item.documents[i].file === '') count++;
            }
            if(count === item.documents.length) return null;
            return (
              <InputsFolder
                title={t(item.product)}
                key={`section-key-${index}-${item.product}
            }`}
              >
                <div className="flex flex-col gap-4">
                  {item.documents.map((item: Record<string, any>) => {
                    if(item.file === '') return null
                    return (
                      <UploadedCustomerFile
                        setFileLink={setFileLink}
                        setCurrentFormStep={setCurrentFormStep}
                        currentStep={currentStep}
                        setHasDoucmentName={setHasDoucmentName}
                        icon={
                          item.file.split(".").pop() === "pdf"
                            ? IconType.FILE_PDF_ICON
                            : ["csv", "xlsx", "xls"].includes(
                              item.name.split(".").pop()
                            )
                              ? IconType.UPLOADED_CSV_ICON
                              : IconType.FILE_PDF_ICON
                        }
                        title={`${t(item.name)} ${item?.belongs_to_product ? `(${item.belongs_to_product})` : ""}`}
                        size=""
                        link={item?.file ?? ""}
                        // updatedate={item.created_at.substring(0, 10)}
                        linkName="/costumers"
                        // deleteIcon={
                        //   <ConditionalRender condition={hasPermissionToDeleteDocument}>
                        //     <IconButton
                        //       onClick={() => {
                        //         deleteDocument(item.id);
                        //       }}
                        //       type="button"
                        //       className="w-full bg-transparent border-none p-0"
                        //     >
                        //       <Icon
                        //         iconType={IconType.DELETE_ICON}
                        //         color="#979797"
                        //       />
                        //     </IconButton>
                        //   </ConditionalRender>
                        // }
                      />
                    );
                  })}
                </div>
              </InputsFolder>
            )
          })}
        </>
      </NoDataBoundary>

      <div className="flex justify-end gap-[26px] mt-5">
        <ConditionalRender condition={hasPermissionToCreateDeocument}>
          <IconButton
            onClick={() => setCurrentFormStep && setCurrentFormStep(3)}
            type="button"
            icon={<Icon iconType={IconType.UPLOAD_FILE_ICON} />}
          >
            {t('Upload')}
          </IconButton>
        </ConditionalRender>
      </div>
    </div>
  );
};

export default UpdateCustomerDocuments;
