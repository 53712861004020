import Headline from "components/atoms/headline/Headline";
import { FC, useState } from "react";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import Input from "components/atoms/input/Input";
import FilterBorderedWrapper from "components/atoms/filter-bordered-wrapper/FilterBorderedWrapper";
import DynamicDropdown from "components/molecules/dynamic-dropdown/DynamicDropdown";
import FilterTableDropdown from "../leads/dropdowns/FilterTableDropdown";
import ViewChangeButtonGroup from "components/molecules/view-change/ViewChangeButtonGroup";
import ConfigureTableDropdown from "../leads/dropdowns/ConfigureTableDropdown";
import TabsSwitch from "components/molecules/tabs/TabsSwitch";
import EmployeesManagementList from "./employees-management-list/EmployeesManagementList";
import IconButton from "components/atoms/button/IconButton";
import { useNavigate } from "react-router-dom";
import Button from "components/atoms/button/Button";
import { useEmployees } from "lib/hooks/queries/Employee/useEmployees";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import PaginationControls from "components/molecules/pagination-controls/PaginationControls";
import { useTranslation } from "react-i18next";
import AddNewEmployee from "./add-new-employee/AddNewEmployee";
import EmployeeCards from "./employee-cards/EmployeeCards";
import AddNewSalaryModule from "../finance-salaries/add-new-salary-module/AddNewSalaryModule";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import { usePermissions } from "lib/hooks/shared/usePermissions";

export interface IEmployeesManagementViewProps { }

const EmployeesManagementView: FC<IEmployeesManagementViewProps> = () => {
  const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(false);
  const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);
  const [activeView, setActiveView] = useState<string>("listView");
  const { t } = useTranslation();

  const hasPermissionToviewAllEmployee = usePermissions(['employee_management_view']);
  const hasPermissionToCreateEmployee = usePermissions(['employee_management_create']);

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const {
    currentPage,
    data,
    handleItemsPerPageChange,
    handleNextPage,
    handlePreviousPage,
    hasNextPage,
    hasPreviousPage,
    isLoading,
    itemsPerPage,
  } = useEmployees();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();

  const columns = [
    {
      id: 1,
      Header: `${t("Name")}`,
      accessor: "name",
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <span onClick={() => navigate(`employe-profile/${original.id}`)} style={{ cursor: 'pointer' }}>{original.name + ' ' + original.last_name}</span>
      ),
      isChecked: true,
    },
    {
      id: 2,
      Header: `${t("Role")}`,
      accessor: "role",
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <span onClick={() => navigate(`employe-profile/${original.id}`)} style={{ cursor: 'pointer' }}>{t(original.role.name.charAt(0).toUpperCase() + original.role.name.slice(1))}</span>
      ),
      isChecked: true,
    },
    {
      id: 3,
      Header: `${t("Email")}`,
      accessor: "email",
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <span onClick={() => navigate(`employe-profile/${original.id}`)} style={{ cursor: 'pointer' }}>{original.email}</span>
      ),
      isChecked: true,
    },
    {
      id: 4,
      Header: `${t("Phone")}`,
      accessor: "phone",
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <span onClick={() => navigate(`employe-profile/${original.id}`)} style={{ cursor: 'pointer' }}>{original.phone}</span>
      ),
      isChecked: true,
    },
    {
      id: 5,
      Header: `${t("Joining Date")}`,
      accessor: "created_at",
      Cell: ({ row: { original } }: { row: { original: any } }) => (
        <span onClick={() => navigate(`employe-profile/${original.id}`)} style={{ cursor: 'pointer' }}>{new Date(original.created_at).toLocaleDateString('de')}</span>
      ),
      isChecked: true,
    },
    {
      id: 6,
      Header: `${t("Status")}`,
      accessor: "status",
      Cell: ({ row: { original } }: any) => (
        <p onClick={() => navigate(`employe-profile/${original.id}`)} style={{ cursor: 'pointer' }}> 
          {t(original.is_deactive === 1 ? 'Deactive' : 'Active')}
        </p>
      ),
      isChecked: true,
    },
    {
      id: 7,
      Header: "",
      accessor: "action",
      Cell: ({ row: { original } }: any) => (
        <Button onClick={() => navigate(`employe-profile/${original.id}`)}>
          <Icon iconType={IconType.OPTIONS_ICON} />
        </Button>
      ),
      isChecked: true,
    },
  ];
  

  const [cardsOrder, setCardsOrder] = useState(columns);

  const [IsAddEmployeeModalOpen, setIsAddEmployeeModalOpen] = useState(false);

  const [NewEmployeeModalStep, setNewEmployeeModalStep] = useState(1)

  const [IsSalaryModalOpen, setIsSalaryModalOpen] = useState(false);

  const [SalaryModalStep, setSalaryModalStep] = useState(1)

  const hasPermissionToCreateRole = usePermissions(['roles_management_create']);
  const hasPermissionToCreateSalary = usePermissions(['salaries_create']);

  return (
    <div>
      <Headline title={t("Employees Management")} />
      <EmployeeCards />
      <DashboardBoxLayout className="mt-[26px]">
        <ConditionalRender condition={hasPermissionToviewAllEmployee && hasPermissionToCreateEmployee}>
          <TabsSwitch
            ActiveTab={selectedTab}
            setSelectedTab={(id) => setSelectedTab(id)}
            tabs={[{ id: 0, name: `${t('All Employees')}` }]}
          >
            <div className="flex items-center gap-[18px]">
              <IconButton
                onClick={() => setIsAddEmployeeModalOpen(true)}
                icon={<Icon color="#fff" iconType={IconType.PLUS_ICON} />}
              >
                {t("New Employee")}
              </IconButton>
            </div>
          </TabsSwitch>
        </ConditionalRender>
        <div className="flex flex-row gap-3 items-center mb-5">
          <div className="flex-1">
            <Input
              icon={IconType.SEARCH_ICON}
              className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
              classes={{ icon: "left-4 !top-[30%]" }}
              placeholder={t("Search")}
            />
          </div>
          <div className="flex-none">
            <div className={`relative`}>
              <FilterBorderedWrapper
                className="cursor-pointer h-[48px] w-[48px] "
                onClick={() => {
                  setConfigureTableOpen(!configureTableOpen);
                }}
              >
                <Icon iconType={IconType.FILTER_BARS_ICON} />
              </FilterBorderedWrapper>
              {configureTableOpen && (
                <DynamicDropdown
                  setIsOpen={setConfigureTableOpen}
                  showCloseButton={true}
                  title={t("Configure Table")}
                >
                  <ConfigureTableDropdown
                    setCardsOrder={setCardsOrder}
                    cardsOrder={columns}
                  />
                </DynamicDropdown>
              )}
            </div>
          </div>
          <div className="flex-none">
            <div className={`${filterTableOpen && "relative"}`}>
              <FilterBorderedWrapper
                className="h-[48px] w-[48px] cursor-pointer"
                onClick={() => {
                  setFilterTableOpen(!filterTableOpen);
                }}
              >
                <Icon iconType={IconType.FILTER_ICON} />
              </FilterBorderedWrapper>
              {filterTableOpen && (
                <DynamicDropdown
                  setIsOpen={setFilterTableOpen}
                  showCloseButton={true}
                  title={t("Filter")}
                  className="sm:!w-[350px]"
                >
                  <FilterTableDropdown />
                </DynamicDropdown>
              )}
            </div>
          </div>
          <div className="flex-none">
            <ViewChangeButtonGroup
              setSelectedTab={setActiveView}
              ActiveTab={activeView}
            />
          </div>
        </div>
        <div className="relative">
          <div className="relative">
            {isLoading && <LoadingScreen />}
            <NoDataBoundary
              condition={data?.pages?.[0]?.data?.data?.length > 0}
              fallback={<EmptyEntity name="Employee" />}
            >
              <EmployeesManagementList
                data={data?.pages?.[0]?.data?.data ?? []}
                dataCols={cardsOrder}
                key={currentPage}
              />
            </NoDataBoundary>
          </div>
        </div>{" "}
      </DashboardBoxLayout>
      <PaginationControls
        itemsPerPage={itemsPerPage}
        handleItemsPerPageChange={(value: number) =>
          handleItemsPerPageChange(value)
        }
        from={data?.pages[0]?.data?.from}
        to={data?.pages[0]?.data?.to}
        total={data?.pages[0]?.data?.total}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
      />

      <AddNewEmployee
        headerComponent={
          {
            1: null,
            2:
              <ConditionalRender condition={hasPermissionToCreateRole}>
                <div className="gap-3 flex items-center cursor-pointer" onClick={() => { setNewEmployeeModalStep(8) }}>
                  <Icon iconType={IconType.PLUS_ICON} color="var(--theme)" />
                  <span className="font-inter-semibold text-[--theme]">{t('New Role Model')}</span>
                </div>
              </ConditionalRender>,
            3:
              <ConditionalRender condition={hasPermissionToCreateSalary}>
                <div className="gap-3 flex items-center cursor-pointer" onClick={() => { setIsSalaryModalOpen(true) }}>
                  <Icon iconType={IconType.PLUS_ICON} color="var(--theme)" />
                  <span className="font-inter-semibold text-[--theme]">{t('New Salary Model')}</span>
                </div>
              </ConditionalRender>
            ,
          }[NewEmployeeModalStep]
        }
        setStepOnClose={1}
        currentStep={NewEmployeeModalStep}
        setCurrentStep={setNewEmployeeModalStep}
        hocCurrentStep={NewEmployeeModalStep}
        setHocCurrentStep={setNewEmployeeModalStep}
        onConfirm={() => { setIsAddEmployeeModalOpen(false); setNewEmployeeModalStep(1) }}
        hocisOpen={IsAddEmployeeModalOpen}
        hocToggleModal={() => { setIsAddEmployeeModalOpen(false); setNewEmployeeModalStep(1) }}
        hocTitle={'Create New Employee'}
        hocClasses={{
          modalClassName: "w-full lg:max-w-[950px]",
          titleClassName: NewEmployeeModalStep === 1 ? "" : "ml-[35px]"
        }}
      />
      <AddNewSalaryModule
        canGoBackHr={true}
        clearData={true}
        setHocCurrentStep={setSalaryModalStep}
        hocCurrentStep={SalaryModalStep}
        onConfirm={() => setIsSalaryModalOpen(false)}
        setFormCurrentStep={setSalaryModalStep}
        currentStep={SalaryModalStep}
        hocisOpen={IsSalaryModalOpen}
        hocToggleModal={() => setIsSalaryModalOpen(false)}
        hocTitle={
          SalaryModalStep === 4
            ? t("Are you sure?")
            : t("Add New Salary Module")
        }
        hocClasses={{
          modalClassName: `w-full ${
            SalaryModalStep === 4 ? "md:max-w-[560px]" : "md:max-w-[1000px]"
          } `,
          titleClassName: SalaryModalStep === 4 ? "" : "ml-[35px]",
        }}
      />
    </div>
  );
};

export default EmployeesManagementView;
