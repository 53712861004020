import React, { FC } from 'react'
import { INewEmployeeStepsProps } from './Step1EmployeeInfo'
import IconButton from 'components/atoms/button/IconButton'
import { useTranslation } from 'react-i18next'
import Icon from 'components/atoms/icon/Icon'
import { IconType } from 'lib/helpers/constants/iconTypes'
import { useForm } from 'react-hook-form'
import Input from 'components/atoms/input/Input'
import Form from 'components/atoms/form/Form'

const Step5AdditionalCompensation: FC<INewEmployeeStepsProps> = ({ setCurrentStep, setAllData, AllData }) => {

    const { t } = useTranslation()

    const {
        formState: { errors },
        handleSubmit,
        register,
        reset,
    } = useForm({
        defaultValues: {
            name: '',
            amount: '',
            description: ''
        }
    })

    const SubmitForm = (data: any) => {
        setAllData({
            ...AllData,
            compensations: [...(AllData.compensations || []), data]
        });
        reset()
    }

    return (
        <Form onSubmit={handleSubmit(SubmitForm)}>
            <div className='mb-4 border border-border rounded-2xl'>
                <div className="px-4 py-2 bg-[#F6FBFE] rounded-t-2xl">
                    <div className="grid grid-cols-7 gap-3 font-inter-semibold text-primaryTextColor">
                        <div className="col-span-4">
                            {t('Compensation Name')}
                        </div>
                        <div className="col-span-2">
                            {t('Amount')}
                        </div>
                        <div className="col-span-1">
                            {t('Action')}
                        </div>
                    </div>
                </div>
                <div className="p-4">
                    <div className="grid grid-cols-7 gap-3 items-center">
                        {AllData?.compensations?.length > 0 ? (
                            AllData?.compensations?.map((expense: any, index: number) => (
                                <React.Fragment key={index}>
                                    <div className="col-span-4 text-primaryTextColor">
                                        {expense.name}
                                    </div>
                                    <div className="col-span-2 text-primaryTextColor">
                                        {expense.amount}CHF
                                    </div>
                                    <div className="col-span-1 text-primaryTextColor">
                                        <div className="cursor-pointer" onClick={() => {
                                            setAllData({
                                                ...AllData,
                                                compensations: AllData.compensations?.filter((item: any) => item.name !== expense.name)
                                            })
                                        }}>
                                            <Icon iconType={IconType.DELETE_ICON} />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))) : (
                            <>
                                <div className="col-span-4 text-primaryTextColor">-</div>
                                <div className="col-span-2 text-primaryTextColor">-</div>
                                <div className="col-span-1 text-primaryTextColor">-</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className='rounded-[20px] bg-[#F6FBFE] p-4 space-y-5'>

                <div className='grid grid-cols-1 sm:grid-cols-2 gap-5'>
                    <Input
                        {...register(`name`, {
                            required: t("This field is required"),
                            validate: (value) => {
                                if (!AllData?.compensations) {
                                    return true
                                }
                                if (AllData?.compensations.some((expense: any) => expense.name === value)) {
                                    return t("This compensation already exists");
                                }
                                return true;
                            }
                        })}
                        placeholder={t("Compensation Name")}
                        label='Enter compensation name'
                        type="text"
                        className=""
                        error={errors?.name}
                    />
                    <Input
                        {...register(`amount`, {
                            required: t("This field is required"),

                        })}
                        placeholder={t("0 CHF")}
                        label='Amount'
                        type="number"
                        error={errors?.amount}
                    />
                    <div className='col-span-2'>
                        <Input
                            {...register(`description`, {
                                required: t("This field is required"),

                            })}
                            placeholder={t("Enter the compensation description..")}
                            label='Description'
                            type="text"
                            error={errors?.description}
                        />
                    </div>

                </div>
            </div>
            <div className="pt-5 flex justify-end">
                <button className={`flex items-center justify-end gap-2 cursor-pointer`} >
                    <Icon iconType={IconType.PLUS_ICON} color='var(--theme)' />
                    <span className='text-theme font-inter-medium text-sm'>
                        {t('Add Compensation')}
                    </span>
                </button>
            </div>
            <div className="flex flex-row justify-end gap-5 pt-10">
                <IconButton className="max-w-[120px] w-full" onClick={() => { reset(); setAllData({ ...AllData, compensations: undefined }) }} type='button' secondary>
                    {t(`Reset`)}
                </IconButton>
                <IconButton
                    className=""
                    type='button'
                    onClick={() => setCurrentStep(6)}
                >
                    <div className='flex items-center gap-2'>
                        {t(`Next Step`)}
                        <Icon iconType={IconType.RIGHT_ARROW_ICON} />
                    </div>
                </IconButton>
            </div>
        </Form>
    )
}

export default Step5AdditionalCompensation