import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender'
import FilterBorderedWrapper from 'components/atoms/filter-bordered-wrapper/FilterBorderedWrapper'
import Headline from 'components/atoms/headline/Headline'
import Icon from 'components/atoms/icon/Icon'
import EmployeeEmailIcon from 'components/atoms/icon/icon-type/EmployeeEmailIcon'
import EmployeeJobIcon from 'components/atoms/icon/icon-type/EmployeeJobIcon'
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen'
import DynamicDropdown from 'components/molecules/dynamic-dropdown/DynamicDropdown'
import ConfigureTableDropdown from 'components/organisms/leads/dropdowns/ConfigureTableDropdown'
import DashboardBoxLayout from 'components/templates/dashboard-box-layout/DashboardBoxLayout'
import { useAuthContext } from 'lib/context/AuthContextContext/AuthContext'
import { commissionsTableHeaders } from 'lib/helpers/constants/commissionsTableHeaders'
import { IconType } from 'lib/helpers/constants/iconTypes'
import { useCommission } from 'lib/hooks/queries/Employee/useCommission'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import CommissionDropdownFilter from './CommissionDropdownFilter'
import NoDataBoundary from 'components/atoms/no-data-boundary/NoDataBoundary'
import EmptyEntity from 'components/atoms/empty-entity/EmptyEntity'
import SourceList from 'components/organisms/source/source-list/SourceList'
import DashboardBoxTitle from 'components/atoms/title/DashboardBoxTitle'

const AllCommissions = () => {

    const { t } = useTranslation()

    const { user } = useAuthContext()


    const { id } = useParams();

    const [filterData, setFilterData] = useState({
        month: "",
    });
    const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(false);
    const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);

    const [cardsOrder, setCardsOrder] = useState(commissionsTableHeaders);


    const { data: commissionData, isLoading: commissionLoading } = useCommission({ id: id, month: filterData.month });
    console.log(commissionData?.data);
    



    return (
        <div>
            <Headline title={t("Employees Management")} />
            <div className={`mb-5 mt-5`}>
                <div>
                    <div className="bg-white px-[20px] py-5 min-h-[150px] border-[1px] border-[#F2F4F7] md:gap-[5px]  2xl:gap-[30px] rounded-[20px] flex items-center">
                        <div className="border-[2px] border-[--theme] rounded-full border-r-[#667085] border-t-[#667085]">
                            <img
                                src={
                                    user?.user_information?.profil_picture
                                        ? `https://devmainhub.insusales.com/api/profile-picture/${user.user_information.profil_picture}`
                                        : "https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg"
                                }
                                className="object-cover rounded-full w-[70px] h-[70px] 2xl:w-[80px] 2xl:h-[80px] p-[6px]"
                                alt="Profile"
                                loading="lazy"
                            />
                        </div>
                        <div className=" flex flex-col gap-2">
                            <div>
                                <Headline className="!mb-0 !mt-0" title={user?.name + ' ' + user?.last_name} />
                            </div>
                            <div className="flex flex-row gap-2 items-center">
                                <EmployeeJobIcon />
                                <p className="text-[#7D8592] font-inter-regular">
                                    {user?.email ?? `${t("Not Available")}`}
                                </p>
                            </div>
                            <div className="flex flex-row gap-2">
                                <EmployeeEmailIcon />
                                <p className="text-[#7D8592] font-inter-regular">
                                    {user?.user_information?.phone ?? `${t("Not Available")}`}
                                </p>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <DashboardBoxLayout className="p-[20px] xl:p-[31px] relative">
                <div className='flex items-center gap-3'>
                    <div className='cursor-pointer' onClick={() => window.history.back()}>
                    <Icon iconType={IconType.BACK_BUTTON_MODAL} />
                    </div>
                    <DashboardBoxTitle title={t("Commissions List")} />
                </div>
                <div className="flex flex-col gap-3 items-center justify-end mb-5 md:flex-row">

                    <div className="flex flex-row gap-2">
                        <div className="flex-none">
                            <div className={`relative`}>
                                <FilterBorderedWrapper
                                    className="cursor-pointer h-[48px] w-[48px] "
                                    onClick={() => {
                                        setConfigureTableOpen(!configureTableOpen);
                                    }}
                                >
                                    <Icon iconType={IconType.FILTER_BARS_ICON} />
                                </FilterBorderedWrapper>
                                {configureTableOpen && (
                                    <DynamicDropdown
                                        setIsOpen={setConfigureTableOpen}
                                        showCloseButton={true}
                                        title="Configure Table"
                                    >
                                        <ConfigureTableDropdown
                                            setCardsOrder={setCardsOrder}
                                            cardsOrder={cardsOrder}
                                        />
                                    </DynamicDropdown>
                                )}
                            </div>
                        </div>
                        <div className="flex-none">
                            <div className={`${filterTableOpen && "relative"}`}>
                                <FilterBorderedWrapper
                                    className="h-[48px] w-[48px] cursor-pointer"
                                    onClick={() => {
                                        setFilterTableOpen(!filterTableOpen);
                                    }}
                                >
                                    <Icon iconType={IconType.FILTER_ICON} />
                                </FilterBorderedWrapper>
                                <ConditionalRender condition={filterTableOpen}>
                                    <DynamicDropdown
                                        setIsOpen={setFilterTableOpen}
                                        showCloseButton={true}
                                        title="Filter"
                                        className="sm:!w-[350px]"
                                    >
                                        <CommissionDropdownFilter setIsOpen={setFilterTableOpen} FilterData={filterData} setFilterData={setFilterData} />
                                    </DynamicDropdown>
                                </ConditionalRender>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="relative">
                    {commissionLoading ? (
                        <div className='py-20'>
                            <LoadingScreen />
                        </div>
                    ) : (

                        <NoDataBoundary
                            condition={commissionData?.data?.length > 0}
                            fallback={<EmptyEntity name="Commissions" />}
                        >
                            <SourceList
                                data={commissionData?.data ?? []}
                                dataCols={cardsOrder}
                            />
                        </NoDataBoundary>
                    )}
                </div>
            </DashboardBoxLayout>
        </div>

    )
}

export default AllCommissions