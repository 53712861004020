export const recentCustomers = [
    {
        Header: "Created At",
        accessor: (row: any) =>
            new Date(row.created_at).toLocaleDateString('de') || "Not available",
        id: 5,
        isChecked: true,
    },
    {
        Header: "Name",
        accessor: (row: any) =>
            row.first_name + ' ' + row.last_name,
        id: 1,
        isChecked: true,
    },
    {
        Header: "Birthdate",
        accessor: (row: any) =>
            new Date(row.birthdate).toLocaleDateString('de') || "Not available",
        id: 2,
        isChecked: true,
    },
    {
        id: 6,
        Header: "Address",
        accessor: (row: any) =>
            row.address || "Not available",
        isChecked: true,
    },
    {
        id: 3,
        Header: "Email",
        accessor: (row: any) =>
            row.email || "Not available",
        isChecked: true,
    },
    {
        id: 4,
        Header: "Phone",
        accessor: (row: any) =>
            row.phone || "Not available",
        isChecked: true,
    }
]
