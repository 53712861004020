import { useQuery } from "react-query";
import { getPartnerSingleProduct } from "api/Partners";

export const usePartnerSingleProduct = (params: any) => {
    return useQuery<Array<any> | any>(
        ['partnerSingleProduct', params],
        () => getPartnerSingleProduct(params),
        {
            enabled: params.partner_id !== null,
            refetchOnMount: false,
            onError(error: any) {
                console.error(error);
            },
        }
    );
};