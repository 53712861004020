import IconButton from 'components/atoms/button/IconButton';
import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender';
import { CheckIcon } from 'components/atoms/icon/export';
import Icon from 'components/atoms/icon/Icon';
import { IconType } from 'lib/helpers/constants/iconTypes';
import withModal from 'lib/helpers/hoc/withModal'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Step1GenerateToken from './steps/Step1GenerateToken';
import Form from 'components/atoms/form/Form';
import { useForm } from 'react-hook-form';
import { useCreateExternalSource } from 'lib/hooks/mutations/ExternalSources/useCreateExternalSource';
import Step2Headers from './steps/Step2Headers';
import Step3ApiInfo from './steps/Step3ApiInfo';


interface Props {
    setNewModalStep: (step: number) => void;
    NewModalStep: number;
    onConfirm: () => void;
}
const NewApiModal: FC<Props> = ({ NewModalStep, setNewModalStep, onConfirm }) => {

    const { t } = useTranslation()

    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        defaultValues: {
            company: '',
            name: '',
            module: 'Appointment',
        },
    });

    const { mutateAsync: createExternalSource, isLoading } = useCreateExternalSource()

    const [Headers, setHeaders] = useState([])

    const [ApiInfo, setApiInfo] = useState({})

    const onSubmit = (data: any) => {

        createExternalSource(data).then((res) => {
            setNewModalStep(2)
            setHeaders(res.headers)
            setApiInfo({
                api: res.api,
                api_key: res.api_key,
                api_method: res.api_method,
            })
        })
    }


    const HandleStepChange = () => {

        if (NewModalStep === 1) {
            return
        }

        if (NewModalStep === 2) {
            setNewModalStep(3)
            return
        }
        onConfirm()
    }

    return (
        <div className="mb-0 flex flex-col gap-7">
            <button
                type="button"
                className="absolute top-[26px] sm:top-[31px] md:top-[45px]"
                onClick={() => {
                    setNewModalStep(NewModalStep > 1 ? NewModalStep - 1 : 1);
                }}
            >
                <Icon iconType={IconType.BACK_BUTTON_MODAL} color={"var(--theme)"} />
            </button>
            <div className="pl-4">
                <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-5 relative bg-transparent">
                    <div className="pb-5">
                        <div className="pl-2 text-[14px] font-inter-medium text-primaryTextColor">
                            {t("Generate API Token")}
                        </div>

                    </div>
                    <ConditionalRender condition={NewModalStep === 1}>
                        <Form
                            onSubmit={handleSubmit(onSubmit)}
                            id='step_form_1'
                        >
                            <Step1GenerateToken control={control} errors={errors} />
                        </Form>
                    </ConditionalRender>
                    <div className="absolute top-[0] left-[-12px] z-9999">
                        {NewModalStep <= 1 ? (
                            <div className="w-[24px] h-[24px] rounded-full bg-[--theme] grid place-items-center font-inter-medium text-white text-sm">
                                1
                            </div>
                        ) : (
                            <CheckIcon />
                        )}
                    </div>
                </div>
                <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-5 relative bg-transparent">
                    <div className="flex gap-2 items-center pb-5">
                        <div
                            className={`pl-2 text-[14px] font-inter-medium text-primaryTextColor ${NewModalStep < 2 ? "opacity-40" : ""
                                }`}
                        >
                            {t("Fields Overview")}
                        </div>


                    </div>
                    <ConditionalRender condition={NewModalStep === 2}>
                        <Step2Headers Headers={Headers} />
                    </ConditionalRender>
                    <div
                        className="absolute top-[0] left-[-12px] z-9999"
                        style={{ opacity: NewModalStep < 2 ? 0.4 : 1 }}
                    >
                        {NewModalStep <= 2 ? (
                            <div className="w-[24px] h-[24px] rounded-full bg-[--theme] grid place-items-center font-inter-medium text-white text-sm">
                                2
                            </div>
                        ) : (
                            <CheckIcon />
                        )}
                    </div>
                </div>

                <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 relative bg-transparent">
                    <div className="flex gap-2 items-center ">
                        <div
                            className={`pl-2 text-[14px] font-inter-medium text-primaryTextColor ${NewModalStep < 3 ? "opacity-40" : "" }`}
                        >
                            {t("View Access Details")}
                        </div>
                    </div>
                    <ConditionalRender condition={NewModalStep === 3}>
                        <div className='pt-5'>

                        <Step3ApiInfo ApiInfo={ApiInfo} />
                        </div>
                    </ConditionalRender>

                    <div
                        className="absolute top-[0] left-[-12px] z-9999"
                        style={{ opacity: NewModalStep < 4 ? 0.4 : 1 }}
                    >
                        {NewModalStep <= 3 ? (
                            <div className="w-[24px] h-[24px] rounded-full bg-[--theme] grid place-items-center font-inter-medium text-white text-sm">
                                3
                            </div>
                        ) : (
                            <CheckIcon />
                        )}
                    </div>
                </div>

            </div>
            <div className="pt-5 flex justify-end gap-3">
                <IconButton
                    secondary
                    className="max-w-[185px] w-full"
                    disabled={isLoading}
                    onClick={() => { NewModalStep === 1 ? reset() : setNewModalStep(1); reset() }}
                >
                    {t("Reset")}
                </IconButton>
                <IconButton
                    className="max-w-[185px] w-full"
                    form={`step_form_1`}
                    type={NewModalStep === 1 ? "submit" : "button"}
                    onClick={HandleStepChange}
                    disabled={isLoading}
                >
                    {NewModalStep === 3 ? (
                        t("Submit")
                    ) : (
                        <span>
                            {t("Next Step")} -{">"}
                        </span>
                    )}
                </IconButton>
            </div>
        </div>
    )
}

export default withModal(NewApiModal)