import { createExternalSource } from "api/ExternalSource";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";


export const useCreateExternalSource = () => {
    const queryClient = useQueryClient();
    
    return useMutation(createExternalSource, {
        onSuccess: () => {
            toast.success("Created successfully!");

            queryClient.invalidateQueries('external-sources')
        },
        onError: (error: any) => {
            toast.error(error.response.data.message || 'Something went wrong')
        },
    })
}