import IconButton from "components/atoms/button/IconButton"
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender"
import Form from "components/atoms/form/Form"
import { CheckIcon } from "components/atoms/icon/export"
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import AllProducts from "./AddProductSteps.tsx/AllProducts"
import Step1SelectProduct from "./AddProductSteps.tsx/Step1SelectProduct"
import { useFieldArray, useForm } from "react-hook-form"
import Step2ProductNameAndFiles from "./AddProductSteps.tsx/Step2ProductNameAndFiles"
import Accordion from "components/molecules/accordion/Accordion"
import AccordionTitle from "components/molecules/accordion/AccordionTitle/AccordionTitle"
import AccordionBody from "components/molecules/accordion/AccordionBody/AccordionBody"
import { useAddPartnerProduct } from "lib/hooks/mutations/Partners/useAddPartnerProduct"

interface AddProductProps {
    IsAddingProduct: boolean
    setIsAddingProduct: (value: boolean) => void
    setCurrentStep: (value: number) => void
    currentStep: number
    onConfirm: () => void
    data: any
}
const Tab2AddProduct: FC<AddProductProps> = ({ IsAddingProduct, setIsAddingProduct, setCurrentStep, currentStep, onConfirm, data }) => {


    const [AllData, setAllData] = useState<any>({})

    const { t } = useTranslation()

    const { formState: { errors: firstStepErrors },
        control: firstStepControl,
        reset: firstStepReset,
        handleSubmit: firstStepSubmit
    } = useForm({
        defaultValues: {
            base_product: null,
        }
    })

    const { formState: { errors: secondStepErrors },
        control: secondStepControl,
        reset: secondStepReset,
        handleSubmit: secondStepSubmit,
        setValue,
    } = useForm({
        defaultValues: {
            products: [
                {
                    product_name: '',
                    product_file: []
                }
            ],
        }
    })

    const { append, fields } = useFieldArray({
        control: secondStepControl,
        name: 'products'
    })

    const handleSubmitFirstStep = (data: any) => {
        setCurrentStep(2)
        setAllData({ ...AllData, ...data })
    }

    const handleSecondStepSubmit = (data: any) => {
        setCurrentStep(3)
        setAllData({ ...AllData, ...data })

    }

    const handleReset = () => {

        firstStepReset()
        secondStepReset()
        setCurrentStep(1)

    }

    const { mutateAsync, isLoading } = useAddPartnerProduct()

    const AddProducts = () => {

        if (currentStep === 3) {
            let newData = {
                ...AllData,
                products: AllData.products.map((product: any) => ({
                    ...product,
                    product_file: Array.isArray(product.product_file)
                        ? product.product_file.map((item: any) => item.value)
                        : []
                }))
            };

            const finalData = {
                partner_id: data.id,
                ...newData
            }

            mutateAsync(finalData).then(() => {
                setIsAddingProduct(false)
                handleReset()
            })

        }
    }

    return (
        IsAddingProduct ? (
            <div>
                <div className="mb-0 flex flex-col gap-7">

                    <div className="pl-4">
                        <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-5 relative bg-transparent">
                            <div className="flex gap-2 items-center pb-5">
                                <div className="pl-2 text-[14px] font-inter-medium text-primaryTextColor">
                                    {t('Product')}
                                </div>

                            </div>
                            <ConditionalRender condition={currentStep === 1}>
                                <Form id="step_form_1" onSubmit={firstStepSubmit(handleSubmitFirstStep)}>
                                    <Step1SelectProduct control={firstStepControl} errors={firstStepErrors} />
                                </Form>
                            </ConditionalRender>
                            <div className="absolute top-[0] left-[-12px] z-9999" >
                                {currentStep <= 1 ? (
                                    <div className="w-[24px] h-[24px] rounded-full bg-[--theme] grid place-items-center font-inter-medium text-white text-sm">
                                        1
                                    </div>
                                ) : <CheckIcon color="var(--theme)" />}

                            </div>
                        </div>
                        <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 pb-5 relative bg-transparent">
                            <div className="flex gap-2 items-center pb-5">
                                <div className={`pl-2 text-[14px] font-inter-medium text-primaryTextColor ${currentStep < 2 ? 'opacity-40' : ''}`}>
                                    {t('Products Name and Required Files')}
                                </div>

                                {/* {currentStep === 2 && (
                                    <div className="ml-auto">
                                        <span className="font-inter-medium text-[--theme] cursor-pointer text-sm underline" onClick={() => { setFormCurrentStep(3) }}>
                                            {t("Proceed without ->")}
                                        </span>
                                    </div>
                                )} */}

                            </div>
                            <ConditionalRender condition={currentStep === 2}>
                                <Form id="step_form_2" onSubmit={secondStepSubmit(handleSecondStepSubmit)}>
                                    <Step2ProductNameAndFiles setValue={setValue} append={append} fields={fields} control={secondStepControl} errors={secondStepErrors} />
                                </Form>
                            </ConditionalRender>
                            <div className="absolute top-[0] left-[-12px] z-9999" style={{ opacity: currentStep < 2 ? 0.4 : 1 }}>
                                {currentStep <= 2 ? (
                                    <div className="w-[24px] h-[24px] rounded-full bg-[--theme] grid place-items-center font-inter-medium text-white text-sm">
                                        2
                                    </div>
                                ) : <CheckIcon color="var(--theme)" />}

                            </div>
                        </div>

                        <div className="border-l-[1.5px] border-dashed border-[#D8D8D8] pl-7 relative bg-transparent">
                            <div className="flex gap-2 items-center">
                                <div className={`pl-2 text-[14px] font-inter-medium text-primaryTextColor ${currentStep < 3 ? 'opacity-40' : ''}`}>
                                    {t('Overview')}
                                </div>

                                {/* {currentStep === 4 && (
                                    <div className="ml-auto">
                                        <span className="font-inter-medium text-[--theme] cursor-pointer text-sm underline" >
                                            {t("Proceed without ->")}
                                        </span>
                                    </div>
                                )} */}

                            </div>
                            {currentStep === 3 && (

                                <Accordion open classes={{ wrapper: '!rounded-2xl !p-0 !border-[#A2A1A833] mt-5', title: '!bg-[#F6FBFE] !px-4 !py-3 !rounded-2xl', body: '!px-4 !mt-0' }} >
                                    <AccordionTitle subTitleClass={'font-inter-semibold text-primaryTextColor'}>{AllData.base_product}</AccordionTitle>
                                    <AccordionBody>
                                        <div className='pb-4 mt-4 w-full space-y-5'>
                                            {AllData?.products.map((product: any, index: number) => (
                                                <div className="flex flex-col gap-3">
                                                    <div className="font-inter-medium text-primaryTextColor text-sm">
                                                        &#x2022; {product.product_name}
                                                    </div>
                                                    <div className="flex flex-wrap gap-2">
                                                        {product.product_file.map((file: any) => (
                                                            <div className="bg-[#F5F6F7] px-3 py-1 rounded-[20px] font-inter-medium text-primaryTextColor text-xs">
                                                                {file.label}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                            )}
                            <div className="absolute top-[0] left-[-12px] z-9999"
                                style={{ opacity: currentStep < 3 ? 0.4 : 1 }}
                            >
                                {currentStep <= 3 ? (
                                    <div className="w-[24px] h-[24px] rounded-full bg-[--theme] grid place-items-center font-inter-medium text-white text-sm">
                                        3
                                    </div>
                                ) :
                                    <CheckIcon color="var(--theme)" />
                                }

                            </div>
                        </div>
                    </div>
                    <div className="pt-5 flex justify-end gap-3">
                        <IconButton secondary className="max-w-[185px] w-full"
                            onClick={handleReset}
                            disabled={isLoading}
                        >
                            {t('Reset')}
                        </IconButton>
                        <IconButton type={currentStep === 3 ? "button" : "submit"} className="max-w-[185px] w-full"
                            form={`step_form_${currentStep}`}
                            onClick={AddProducts}
                            disabled={isLoading}

                        >
                            {
                                currentStep === 3 ? t('Submit') : (
                                    <span>
                                        {t('Next Step')} -{'>'}
                                    </span>
                                )
                            }
                        </IconButton>
                    </div>
                </div>
            </div>
        ) : (
            <AllProducts data={data} setIsAddingProduct={setIsAddingProduct} />
        )
    )
}

export default Tab2AddProduct