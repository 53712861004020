import { useEffect, FC } from 'react';
import { IThemeContext, ThemeContext } from "./ThemeContext";
import { useAuthContext } from "../AuthContextContext/AuthContext";

export interface IThemeContextProviderProps {
  children: React.ReactNode;
}

const generateThemeColors = (userThemeColor: string): any => {
  const backgroundWithOpacity = `${userThemeColor}0D`;
  const backgroundSecondOpacity = `${userThemeColor}26`;
  return {
    primary: userThemeColor,
    textColor: userThemeColor,
    backgroundWithOpacity: backgroundWithOpacity,
    backgroundSecondOpacity: backgroundSecondOpacity
  };
};


const ThemeContextProvider: FC<IThemeContextProviderProps> = ({ children }) => {
  const ctx = useAuthContext();
  const userThemeColor = ctx.user?.company?.theme_color || '#50B8E7';

  const themeColors = generateThemeColors(userThemeColor);

  useEffect(() => {
    // Set the CSS variable for the primary color
    document.documentElement.style.setProperty('--theme', themeColors.primary);
    // Set the CSS variable for the background color with opacity
    document.documentElement.style.setProperty('--theme-bg-opacity', themeColors.backgroundWithOpacity);
    document.documentElement.style.setProperty('--theme-bg-second-opacity', themeColors.backgroundSecondOpacity);

  }, [themeColors.primary, themeColors.backgroundWithOpacity, themeColors.backgroundSecondOpacity]);

  const ContextValues: IThemeContext = {
    themeColors,
  };

  return (
    <ThemeContext.Provider value={ContextValues} key={themeColors.primary}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
