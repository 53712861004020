import React, { FC } from 'react'
import { INewEmployeeStepsProps } from './Step1EmployeeInfo'
import IconButton from 'components/atoms/button/IconButton'
import { useTranslation } from 'react-i18next'
import Icon from 'components/atoms/icon/Icon'
import { IconType } from 'lib/helpers/constants/iconTypes'
import { Controller, useForm } from 'react-hook-form'
import Input from 'components/atoms/input/Input'
import Form from 'components/atoms/form/Form'
import Label from 'components/atoms/input/input-label/InputLabel'
import CreatableSelect from 'react-select/creatable'
import { customStyles } from 'lib/helpers/configs/customStyles'
import { useRegisterUser } from 'lib/hooks/mutations/RegisterUser/useRegisterUser'

interface Props extends Omit<INewEmployeeStepsProps, 'setCurrentStep'> {
    onConfirm: () => void;

}

const Step7AddBonuses: FC<Props> = ({  setAllData, AllData, onConfirm }) => {

    const { t } = useTranslation()

    const {
        formState: { errors },
        handleSubmit,
        register,
        control,
        reset,
        watch,
    } = useForm({
        defaultValues: {
            name: '',
            type: '',
            linked_to: '',
            calculation_method: '',
            receiving_period: '',
            bonus_value: '',
            description: ''
        }
    })

    const SubmitForm = (data: any) => {

        setAllData({
            ...AllData,
            bonuses: [...(AllData.bonuses || []), data]
        });
        reset()
    }

    const { mutateAsync } = useRegisterUser()

    const RegisterEmployee = () => {
        mutateAsync(AllData).then((res) => {
            onConfirm()

        })
    }



    return (
        <Form onSubmit={handleSubmit(SubmitForm)}>
            <div className='mb-4 border border-border rounded-2xl'>
                <div className="px-4 py-2 bg-[#F6FBFE] rounded-t-2xl">
                    <div className="grid grid-cols-12 gap-3 font-inter-semibold text-primaryTextColor text-sm">
                        <div className="col-span-3">
                            {t('Bonus Name')}
                        </div>
                        <div className="col-span-2">
                            {t('Type')}
                        </div>
                        <div className="col-span-2">
                            {t('Linked Goal')}
                        </div>
                        <div className="col-span-2">
                            {t('Amount')}
                        </div>
                        <div className="col-span-2">
                            {t('Period')}
                        </div>
                        <div className="col-span-1">
                            {t('Action')}
                        </div>
                    </div>
                </div>
                <div className="p-4">
                    <div className="grid grid-cols-12 gap-3 items-center text-sm">
                        {AllData?.bonuses?.length > 0 ? (
                            AllData?.bonuses?.map((bonus: any, index: number) => (
                                <React.Fragment key={index}>
                                    <div className="col-span-3 text-primaryTextColor">
                                        {bonus.name}
                                    </div>
                                    <div className="col-span-2 text-primaryTextColor">
                                        {t(bonus.type)}
                                    </div>
                                    <div className="col-span-2 text-primaryTextColor">
                                        {bonus.linked_to}
                                    </div>
                                    <div className="col-span-2 text-primaryTextColor">
                                        {bonus.bonus_value + (bonus.calculation_method === 'Fixed Amount' || bonus.type === 'Other Bonus' ? ' CHF' : ' %')}
                                    </div>
                                    <div className="col-span-2 text-primaryTextColor">
                                        {t(bonus?.receiving_period) || '-'}
                                    </div>
                                    <div className="col-span-1 text-primaryTextColor">
                                        <div className="cursor-pointer" onClick={() => {
                                            setAllData({
                                                ...AllData,
                                                bonuses: AllData.bonuses?.filter((item: any) => item.name !== bonus.name)
                                            })
                                        }}>
                                            <Icon iconType={IconType.DELETE_ICON} />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))) : (
                            <>
                                <div className="col-span-3 text-primaryTextColor">-</div>
                                <div className="col-span-2 text-primaryTextColor">-</div>
                                <div className="col-span-2 text-primaryTextColor">-</div>
                                <div className="col-span-2 text-primaryTextColor">-</div>
                                <div className="col-span-2 text-primaryTextColor">-</div>
                                <div className="col-span-1 text-primaryTextColor">-</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className='rounded-[20px] bg-[#F6FBFE] p-4 space-y-5'>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-5'>
                    <Input
                        {...register(`name`, {
                            required: t("This field is required"),
                            validate: (value) => {
                                if (!AllData?.bonuses) {
                                    return true
                                }
                                if (AllData?.bonuses.some((bonus: any) => bonus.name === value)) {
                                    return t("This bonus already exists");
                                }
                                return true;
                            }
                        })}
                        placeholder={t("Bonus Name")}
                        label='Enter bonus name'
                        type="text"
                        className=""
                        error={errors?.name}
                    />
                    <div className="relative">
                        <Label text={t("Type")} />
                        <Controller
                            name="type"
                            rules={{ required: "This field is required" }}
                            control={control}
                            render={({ field }) => (
                                <CreatableSelect<any, false>
                                    {...field}
                                    placeholder={t("Type")}
                                    options={[
                                        { label: t("Goal Bonus"), value: "Goal Bonus" },
                                        { label: t("Other Bonus"), value: "Other Bonus" },
                                    ]}
                                    value={
                                        field?.value
                                            ? {
                                                value: field?.value,
                                                label: t(field?.value),
                                            }
                                            : null
                                    }
                                    className="cursor-pointer font-inter-regular react-select-container"
                                    classNamePrefix="react-select"
                                    onChange={(newValue: any, actionMeta: any) => {
                                        if (
                                            actionMeta.action === "select-option" &&
                                            newValue?.value
                                        ) {
                                            field.onChange(newValue.value);
                                        }
                                    }}
                                    styles={customStyles}
                                    isValidNewOption={() => false}
                                    menuPosition={"fixed"}
                                    menuPortalTarget={document.body}
                                />
                            )}
                        />
                        {errors.type?.message && (
                            <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                {errors.type?.message as string}
                            </div>
                        )}
                    </div>

                    {watch('type') === 'Goal Bonus' && (
                        <>
                            <div className="relative">
                                <Label text={t("Linked to")} />
                                <Controller
                                    name="linked_to"
                                    rules={{ required: "This field is required" }}
                                    control={control}
                                    render={({ field }) => (
                                        <CreatableSelect<any, false>
                                            {...field}
                                            placeholder={t("Choose goal to link")}
                                            options={AllData?.goals?.map((goal: any) => ({
                                                label: goal.name,
                                                value: goal.name,
                                            })) || []}
                                            value={
                                                field?.value
                                                    ? {
                                                        value: field?.value,
                                                        label: t(field?.value),
                                                    }
                                                    : null
                                            }
                                            className="cursor-pointer font-inter-regular react-select-container"
                                            classNamePrefix="react-select"
                                            onChange={(newValue: any, actionMeta: any) => {
                                                if (
                                                    actionMeta.action === "select-option" &&
                                                    newValue?.value
                                                ) {
                                                    field.onChange(newValue.value);
                                                }
                                            }}
                                            styles={customStyles}
                                            isValidNewOption={() => false}
                                            menuPosition={"fixed"}
                                            menuPortalTarget={document.body}
                                        />
                                    )}
                                />
                                {errors.linked_to?.message && (
                                    <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                        {errors.linked_to?.message as string}
                                    </div>
                                )}
                            </div>
                            <div className="relative">
                                <Label text={t("Calculation Method")} />
                                <Controller
                                    name="calculation_method"
                                    rules={{ required: "This field is required" }}
                                    control={control}
                                    render={({ field }) => (
                                        <CreatableSelect<any, false>
                                            {...field}
                                            placeholder={t("Choose calculation method")}
                                            options={[
                                                // { label: t("Percentage After Goal"), value: "Percentage After Goal" },
                                                { label: t("Fixed Amount"), value: "Fixed Amount" },
                                            ]}
                                            value={
                                                field?.value
                                                    ? {
                                                        value: field?.value,
                                                        label: t(field?.value),
                                                    }
                                                    : null
                                            }
                                            className="cursor-pointer font-inter-regular react-select-container"
                                            classNamePrefix="react-select"
                                            onChange={(newValue: any, actionMeta: any) => {
                                                if (
                                                    actionMeta.action === "select-option" &&
                                                    newValue?.value
                                                ) {
                                                    field.onChange(newValue.value);
                                                }
                                            }}
                                            styles={customStyles}
                                            isValidNewOption={() => false}
                                            menuPosition={"fixed"}
                                            menuPortalTarget={document.body}
                                        />
                                    )}
                                />
                                {errors.calculation_method?.message && (
                                    <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                        {errors.calculation_method?.message as string}
                                    </div>
                                )}
                            </div>
                        </>

                    )}
                    {watch('type') === 'Other Bonus' && (

                        <div className="relative">
                            <Label text={t("Receiving Period")} />
                            <Controller
                                name="receiving_period"
                                rules={{ required: "This field is required" }}
                                control={control}
                                render={({ field }) => (
                                    <CreatableSelect<any, false>
                                        {...field}
                                        placeholder={t("Choose receiving period")}
                                        options={[
                                            { label: t("Monthly"), value: "Monthly" },
                                            { label: t("Yearly"), value: "Yearly" },
                                        ]}
                                        value={
                                            field?.value
                                                ? {
                                                    value: field?.value,
                                                    label: t(field?.value),
                                                }
                                                : null
                                        }
                                        className="cursor-pointer font-inter-regular react-select-container"
                                        classNamePrefix="react-select"
                                        onChange={(newValue: any, actionMeta: any) => {
                                            if (
                                                actionMeta.action === "select-option" &&
                                                newValue?.value
                                            ) {
                                                field.onChange(newValue.value);
                                            }
                                        }}
                                        styles={customStyles}
                                        isValidNewOption={() => false}
                                        menuPosition={"fixed"}
                                        menuPortalTarget={document.body}
                                    />
                                )}
                            />
                            {errors.receiving_period?.message && (
                                <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                    {errors.receiving_period?.message as string}
                                </div>
                            )}
                        </div>

                    )}
                    {watch('type') !== '' && (
                        <>
                            <Input
                                {...register(`bonus_value`, (watch('calculation_method') === 'Fixed Amount' || watch('type') === 'Other Bonus')
                                    ? {
                                        required: t('This field is required'),
                                        max: { value: 9999999, message: t('Please enter a valid number') },
                                        min: { value: 0, message: t('Please enter a valid number') },
                                    }
                                    : {
                                        min: { value: 0, message: t('Please enter a valid percentage') },
                                        max: { value: 100, message: t('Please enter a valid percentage') },
                                        required: t('This field is required')
                                    }
                                )}
                                placeholder={(watch('calculation_method') === 'Fixed Amount' || watch('type') === 'Other Bonus') ? '0.00 CHF' : '0%'}
                                label={'Bonus Value'}
                                type="text"
                                className=""
                                error={errors?.bonus_value}
                            />
                            <div className='col-span-2'>
                                <div className="flex gap-1"><Label text={t("Description")} /><div className='font-inter-medium text-sm text-theme mb-[8px]'>({t('optional')})</div></div>
                                <Input
                                    {...register(`description`, {
                                        required: false,

                                    })}
                                    placeholder={t("Enter the bonus description..")}
                                    type="text"
                                    error={errors?.description}
                                />

                            </div>
                        </>
                    )}

                </div>
            </div>
            <div className="pt-5 flex justify-end">
                <button className={`flex items-center justify-end gap-2 cursor-pointer`} >
                    <Icon iconType={IconType.PLUS_ICON} color='var(--theme)' />
                    <span className='text-theme font-inter-medium text-sm'>
                        {t('Add Bonuse')}
                    </span>
                </button>
            </div>
            <div className="flex flex-row justify-end gap-5 pt-10">
                <IconButton className="max-w-[120px] w-full" onClick={() => {
                    reset();
                    setAllData({ ...AllData, bonuses: undefined });
                }}
                    type='button'
                    secondary>
                    {t(`Reset`)}
                </IconButton>
                <IconButton
                    className=""
                    type='button'
                    onClick={RegisterEmployee}
                >
                    <div className='flex items-center gap-2'>
                        {t(`Next Step`)}
                        <Icon iconType={IconType.RIGHT_ARROW_ICON} />
                    </div>
                </IconButton>
            </div>
        </Form>
    )
}

export default Step7AddBonuses