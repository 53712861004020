import React, { FC, useState } from 'react'
import { INewEmployeeStepsProps } from './Step1EmployeeInfo'
import IconButton from 'components/atoms/button/IconButton'
import { useTranslation } from 'react-i18next'
import Icon from 'components/atoms/icon/Icon'
import { IconType } from 'lib/helpers/constants/iconTypes'
import { Controller, useForm } from 'react-hook-form'
import Input from 'components/atoms/input/Input'
import Form from 'components/atoms/form/Form'
import Label from 'components/atoms/input/input-label/InputLabel'
import CreatableSelect from 'react-select/creatable'
import { customStyles } from 'lib/helpers/configs/customStyles'
import { usePartners } from 'lib/hooks/queries/Partners/usePartnersForFilter'
import { usePartnerSingleProduct } from 'lib/hooks/queries/Partners/usePartnerSingleProduct'
import PrimaryDatePicker from 'components/molecules/datepicker/PrimaryDatePicker'

const Step6AddGoals: FC<INewEmployeeStepsProps> = ({ setCurrentStep, setAllData, AllData }) => {

    const { t } = useTranslation()

    const {
        formState: { errors },
        handleSubmit,
        register,
        control,
        setValue,
        reset,
        watch,
    } = useForm({
        defaultValues: {
            name: '',
            category: '',
            product: '',
            product_company: '',
            specific_product: '',
            target_value: '',
            valid_from: '',
            valid_to: '',
            description: ''
        }
    })

    const SubmitForm = (data: any) => {

        setAllData({
            ...AllData,
            goals: [...(AllData.goals || []), data]
        });
        setPartnerParams({ base_product: null, partner_id: null })
        reset()
    }

    const { data } = usePartners()

    const [PartnerParams, setPartnerParams] = useState<any>({ base_product: null, partner_id: null });

    const { data: partnerProductsData } = usePartnerSingleProduct(PartnerParams);




    return (
        <Form onSubmit={handleSubmit(SubmitForm)}>
            <div className='mb-4 border border-border rounded-2xl'>
                <div className="px-4 py-2 bg-[#F6FBFE] rounded-t-2xl">
                    <div className="grid grid-cols-8 gap-3 font-inter-semibold text-primaryTextColor text-sm">
                        <div className="col-span-1">
                            {t('Goal Name')}
                        </div>
                        <div className="col-span-1">
                            {t('Category')}
                        </div>
                        <div className="col-span-1">
                            {t('Product')}
                        </div>
                        <div className="col-span-1">
                            {t('Company')}
                        </div>
                        <div className="col-span-1">
                            {t('Amount')}
                        </div>
                        <div className="col-span-1">
                            {t('Valid From')}
                        </div>
                        <div className="col-span-1">
                            {t('Valid to')}
                        </div>
                        <div className="col-span-1">
                            {t('Action')}
                        </div>
                    </div>
                </div>
                <div className="p-4">
                    <div className="grid grid-cols-8 gap-3 items-center text-sm">
                        {AllData?.goals?.length > 0 ? (
                            AllData?.goals?.map((goal: any, index: number) => (
                                <React.Fragment key={index}>
                                    <div className="col-span-1 text-primaryTextColor">
                                        {goal.name}
                                    </div>
                                    <div className="col-span-1 text-primaryTextColor">
                                        {goal.category}
                                    </div>

                                    <div className="col-span-1 text-primaryTextColor">
                                        {goal.product}
                                    </div>
                                    <div className="col-span-1 text-primaryTextColor">
                                        {goal.product_company || '-'}
                                    </div>
                                    <div className="col-span-1 text-primaryTextColor">
                                        {goal.target_value}
                                    </div>
                                    <div className="col-span-1 text-primaryTextColor">
                                        {new Date(goal.valid_from).toLocaleDateString('de')}
                                    </div>
                                    <div className="col-span-1 text-primaryTextColor">
                                        {new Date(goal.valid_to).toLocaleDateString('de')}
                                    </div>
                                    <div className="col-span-1 text-primaryTextColor">
                                        <div className="cursor-pointer" onClick={() => {
                                            setAllData({
                                                ...AllData,
                                                goals: AllData.goals?.filter((item: any) => item.name !== goal.name)
                                            })
                                        }}>
                                            <Icon iconType={IconType.DELETE_ICON} />
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))) : (
                            <>
                                <div className="col-span-1 text-primaryTextColor">-</div>
                                <div className="col-span-1 text-primaryTextColor">-</div>
                                <div className="col-span-1 text-primaryTextColor">-</div>
                                <div className="col-span-1 text-primaryTextColor">-</div>
                                <div className="col-span-1 text-primaryTextColor">-</div>
                                <div className="col-span-1 text-primaryTextColor">-</div>
                                <div className="col-span-1 text-primaryTextColor">-</div>
                                <div className="col-span-1 text-primaryTextColor">-</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className='rounded-[20px] bg-[#F6FBFE] p-4 space-y-5'>
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-5'>
                    <Input
                        {...register(`name`, {
                            required: t("This field is required"),
                            validate: (value) => {
                                if (!AllData?.goals) {
                                    return true
                                }
                                if (AllData?.goals.some((goal: any) => goal.name === value)) {
                                    return t("This goal already exists");
                                }
                                return true;
                            }
                        })}
                        placeholder={t("Goal Name")}
                        label='Enter goal name'
                        type="text"
                        className=""
                        error={errors?.name}
                    />
                    <div className="relative">
                        <Label text={t("Category")} />
                        <Controller
                            name="category"
                            rules={{ required: "This field is required" }}
                            control={control}
                            render={({ field }) => (
                                <CreatableSelect<any, false>
                                    {...field}
                                    placeholder={t("Category")}
                                    options={[
                                        { label: t("Contracts"), value: "Contracts" },
                                        { label: t("Volume Amount"), value: "Volume Amount" },
                                    ]}
                                    value={
                                        field?.value
                                            ? {
                                                value: field?.value,
                                                label: t(field?.value),
                                            }
                                            : null
                                    }
                                    className="cursor-pointer font-inter-regular react-select-container"
                                    classNamePrefix="react-select"
                                    onChange={(newValue: any, actionMeta: any) => {
                                        if (
                                            actionMeta.action === "select-option" &&
                                            newValue?.value
                                        ) {
                                            field.onChange(newValue.value);
                                        }
                                    }}
                                    styles={customStyles}
                                    isValidNewOption={() => false}
                                    menuPosition={"fixed"}
                                    menuPortalTarget={document.body}
                                />
                            )}
                        />
                        {errors.category?.message && (
                            <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                {errors.category?.message as string}
                            </div>
                        )}
                    </div>

                    {watch('category') !== '' && (
                        <>
                            <div className="relative">
                                <Label text={t("Product")} />
                                <Controller
                                    name="product"
                                    rules={{ required: "This field is required" }}
                                    control={control}
                                    render={({ field }) => (
                                        <CreatableSelect<any, false>
                                            {...field}
                                            placeholder={t("Choose product type")}
                                            options={[
                                                { label: t("KVG"), value: "KVG" },
                                                { label: t("VVG"), value: "VVG" },
                                                { label: t("Life"), value: "Life" },
                                                { label: t("Lawprotection"), value: "Lawprotection" },
                                                { label: t("Business"), value: "Business" },
                                                { label: t("Auto"), value: "Auto" },
                                            ]}
                                            value={
                                                field?.value
                                                    ? {
                                                        value: field?.value,
                                                        label: t(field?.value),
                                                    }
                                                    : null
                                            }
                                            className="cursor-pointer font-inter-regular react-select-container"
                                            classNamePrefix="react-select"
                                            onChange={(newValue: any, actionMeta: any) => {
                                                if (
                                                    actionMeta.action === "select-option" &&
                                                    newValue?.value
                                                ) {
                                                    field.onChange(newValue.value);
                                                    setPartnerParams({ ...PartnerParams, base_product: newValue.value })
                                                    setValue('specific_product', '')
                                                }
                                            }}
                                            styles={customStyles}
                                            isValidNewOption={() => false}
                                            menuPosition={"fixed"}
                                            menuPortalTarget={document.body}
                                        />
                                    )}
                                />
                                {errors.product?.message && (
                                    <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                        {errors.product?.message as string}
                                    </div>
                                )}
                            </div>
                            <div className="relative">
                                <div className='flex gap-1'>
                                    <Label text={t("Specific Company")} /><div className='font-inter-medium text-sm text-theme mb-[8px]'>({t('optional')})</div>
                                </div>
                                <Controller
                                    name="product_company"
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <>
                                            <CreatableSelect<any, false>
                                                {...field}
                                                placeholder={t("Choose Company Name")}
                                                options={data?.partners.map((partner: any) => {
                                                    return {
                                                        value: partner.name,
                                                        label: partner.name,
                                                        id: partner.id
                                                    }
                                                })}
                                                value={
                                                    field?.value
                                                        ? {
                                                            value: field.value,
                                                            label: t(field.value),
                                                        }
                                                        : null
                                                }
                                                isDisabled={watch('product') === ''}
                                                className="cursor-pointer font-inter-regular react-select-container"
                                                classNamePrefix="react-select"
                                                onChange={(newValue: any, actionMeta: any) => {
                                                    if (
                                                        actionMeta.action === "select-option" &&
                                                        newValue?.value
                                                    ) {
                                                        field.onChange(newValue.value);
                                                        setPartnerParams({ ...PartnerParams, partner_id: newValue.id })
                                                        setValue('specific_product', '')
                                                    }
                                                }}
                                                styles={customStyles}
                                                isValidNewOption={() => false}
                                                menuPosition={"fixed"}
                                                menuPortalTarget={document.body}
                                            />

                                        </>
                                    )}
                                />
                                {errors.product_company?.message && (
                                    <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                        {errors.product_company?.message as string}
                                    </div>
                                )}
                            </div>
                            <div className="relative">
                                <div className='flex gap-1'>
                                    <Label text={t("Specific Product")} /><div className='font-inter-medium text-sm text-theme mb-[8px]'>({t('optional')})</div>
                                </div>
                                <Controller
                                    name="specific_product"
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <CreatableSelect<any, false>
                                            {...field}
                                            placeholder={t("Choose Company Name")}
                                            options={partnerProductsData?.map((product: any) => {
                                                return {
                                                    value: product.product,
                                                    label: product.product,
                                                }
                                            })}
                                            isDisabled={!partnerProductsData || partnerProductsData?.length === 0}
                                            value={
                                                field?.value
                                                    ? {
                                                        value: field?.value,
                                                        label: t(field?.value),
                                                    }
                                                    : null
                                            }
                                            className="cursor-pointer font-inter-regular react-select-container"
                                            classNamePrefix="react-select"
                                            onChange={(newValue: any, actionMeta: any) => {
                                                if (
                                                    actionMeta.action === "select-option" &&
                                                    newValue?.value
                                                ) {
                                                    field.onChange(newValue.value);
                                                }
                                            }}
                                            styles={customStyles}
                                            isValidNewOption={() => false}
                                            menuPosition={"fixed"}
                                            menuPortalTarget={document.body}
                                        />
                                    )}
                                />
                                {errors.specific_product?.message && (
                                    <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                        {errors.specific_product?.message as string}
                                    </div>
                                )}
                            </div>
                            <Input
                                {...register(`target_value`, {
                                    required: t("This field is required"),
                                })}
                                placeholder={watch('category') === 'Contracts' ? '0' : '0.00 CHF'}
                                label={watch('category') === 'Contracts' ? 'Contracts Number' : 'Premium Volume'}
                                type="text"
                                className=""
                                error={errors?.target_value}
                            />
                            <Controller
                                control={control}
                                name="valid_from"
                                rules={{ required: `${t("This field is required")}` }}
                                render={({ field: { onChange, value } }) => (
                                    <PrimaryDatePicker
                                        label={t("Valid From")}
                                        startDate={value}
                                        {...register("valid_from")}
                                        setStartDate={(date: string) => onChange(date)}
                                        error={errors.valid_from}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="valid_to"
                                rules={{ required: `${t("This field is required")}` }}
                                render={({ field: { onChange, value } }) => (
                                    <PrimaryDatePicker
                                        label={t("Valid To")}
                                        startDate={value}
                                        {...register("valid_to")}
                                        setStartDate={(date: string) => onChange(date)}
                                        error={errors.valid_to}
                                    />
                                )}
                            />
                            <div className='col-span-2'>
                                <div className="flex gap-1"><Label text={t("Description")} /><div className='font-inter-medium text-sm text-theme mb-[8px]'>({t('optional')})</div></div>
                                <Input
                                    {...register(`description`, {
                                        required: false,

                                    })}
                                    placeholder={t("Enter the goal description..")}
                                    type="text"
                                    error={errors?.description}
                                />
                            </div>
                        </>

                    )}
                </div>
            </div>
            <div className="pt-5 flex justify-end">
                <button className={`flex items-center justify-end gap-2 cursor-pointer`} >
                    <Icon iconType={IconType.PLUS_ICON} color='var(--theme)' />
                    <span className='text-theme font-inter-medium text-sm'>
                        {t('Add Goal')}
                    </span>
                </button>
            </div>
            <div className="flex flex-row justify-end gap-5 pt-10">
                <IconButton className="max-w-[120px] w-full" onClick={() => {
                    reset();
                    setAllData({ ...AllData, goals: undefined });
                    setPartnerParams({ base_product: null, partner_id: null })
                }}
                    type='button'
                    secondary>
                    {t(`Reset`)}
                </IconButton>
                <IconButton
                    className=""
                    type='button'
                    onClick={() => setCurrentStep(7)}
                >
                    <div className='flex items-center gap-2'>
                        {t(`Next Step`)}
                        <Icon iconType={IconType.RIGHT_ARROW_ICON} />
                    </div>
                </IconButton>
            </div>
        </Form>
    )
}

export default Step6AddGoals