import { getUsersByPermission } from 'api/User';
import IconButton from 'components/atoms/button/IconButton';
import Form from 'components/atoms/form/Form';
import Label from 'components/atoms/input/input-label/InputLabel';
import Table from 'components/molecules/table/Table';
import { customStyles } from 'lib/helpers/configs/customStyles';
import { FeedbackStatus } from 'lib/helpers/constants/columns/leadsTableHeaders';
import withModal from 'lib/helpers/hoc/withModal'
import { useAssignLeads } from 'lib/hooks/mutations/Leads/useAssignLeads';
import { ILead } from 'lib/types/leads.types';
import React, { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Creatable from "react-select/creatable";

export interface IAssignLeadsProps {
    onConfirm: () => void;
    themeColors: {
        primary: string;
    };
    selectedLeads: any[];

}
const AssignLeads: FC<IAssignLeadsProps> = ({ onConfirm, themeColors, selectedLeads }) => {
    // const [tableData, setTableData] = useState(selectedLeads || []);

    // const handleRemove = (index: number) => {
    //     const newData = tableData.filter((_, i) => i !== index);
    //     setTableData(newData);
    // };
    const {t} = useTranslation();

    const leadsTableHeaders = [
        // {
        //     Header: "Delete",
        //     accessor: "delete",
        //     Cell: ({ row }: any) => (
        //         <button
        //             type="button"
        //             onClick={() => handleRemove(row.index)}
        //             className="text-red-600 hover:text-red-800"
        //         >
        //             <Icon iconType={IconType.DELETE_ICON} color="#7D8592" />
        //         </button>
        //     ),
        // },
        {
            id: 1,
            Header: `${t('Name')}`,
            accessor: (row: ILead) =>
                row.info?.first_name && row.info?.last_name
                    ? `${row.info.first_name} ${row.info.last_name}`
                    : `${t('Not available')}`,
            Cell: ({ row }: any) => (
                <div>
                    {row.original.info?.first_name && row.original.info?.last_name
                        ? `${row.original.info.first_name} ${row.original.info.last_name}`
                        : "Not available"}
                </div>
            ),
            isChecked: true
        },
        {
            Header: `${t('Source')}`,
            accessor: (row: ILead) => row?.source?.name || `${t('Not available')}`,
            Cell: ({ row }: any) => (
                <div>
                    {row?.original?.source?.name || `${t('Not available')}`}
                </div>
            ),
            id: 2,
            isChecked: true
        },
        {
            Header: `${t('Campaign')}`,
            accessor: (row: any) => row?.campaign?.name || `${t('Not available')}`,
            Cell: ({ row }: any) => (
                <div>
                    {row?.original?.campaign?.name || `${t('Not available')}`}
                </div>
            ),
            id: 8,
            isChecked: true
        },
        {
            Header: `${t('Email')}`,
            accessor: (row: ILead) => row?.info?.email || `${t('Not available')}`,
            Cell: ({ row }: any) => (
                <div>
                    {row?.original?.info?.email || `${t('Not available')}`}
                </div>
            ),
            id: 3,
            isChecked: true
        },
        {
            Header: `${t('Phone')}`,
            accessor: (row: ILead) => row?.info?.phone || `${t('Not available')}`,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row?.original?.info?.phone || `${t('Not available')}`}
                </div>
            ),
            id: 4,
            isChecked: true
        },
        {
            Header: `${t('Status')}`,
            accessor: (row: ILead) => row?.feedback_status || `${t('Not available')}`,
            Cell: ({ row, value }: any) => (
                <div>
                    <FeedbackStatus value={value} />
                </div>
            ),
            id: 5,
            isChecked: true
        },



    ];

    const [fetchedUsers, setFetchedUsers] = useState<Array<string>>([]);

    useEffect(() => {

        getUsersByPermission({
            permissions: ['lead_view_view_own'],
        })
        .then((res) => {
            setFetchedUsers(res.users);
        })
        .catch(() => { });
        // eslint-disable-next-line
    }, []);

    const { control,
        reset,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            assigned_to: null,
        }
    });

    const { mutateAsync: assignLead } = useAssignLeads();

    const onSubmit = (data: any) => {

        const id = selectedLeads.map((lead) => lead.id);
        assignLead({
            lead_ids: id,
            assigned_to: data.assigned_to,
        }).then(() => {
            onConfirm();

        })
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-0 flex flex-col gap-7">
                <div className="text-[14px] font-inter-bold">
                    {t('1 - Please confirm the list of leads you wish to assign to a worker')}
                </div>
                <div
                    className="rounded-[20px] p-4 w-full "
                    style={{
                        backgroundColor: `${themeColors.primary}0D`,
                    }}
                >
                    <div className="overflow-auto horizontalOverflowScrollStyle w-full h-full px-5 ">

                        <Table
                            style={{ backgroundColor: `${themeColors.primary}0D` }}
                            classes={{ subContainer: "!p-0 !rounded-none" }}
                            columns={leadsTableHeaders}
                            data={selectedLeads || []}
                            search={""}
                            isColumnSorted={true}
                        />

                    </div>
                </div>
                <div className="text-[14px] font-inter-bold">
                    {t('2- Choose the assigned worker')}
                </div>
                <div
                    className="rounded-[20px] p-4 max-w-[450px] w-full "
                    style={{
                        backgroundColor: `${themeColors.primary}0D`,
                    }}
                >
                    <div className="relative">

                        <Label text={t('Assigned Worker')}/>
                        <Controller
                            name="assigned_to"
                            control={control}
                            rules={{ required: `${t('Worker is required')}` }}
                            render={({ field }) => (
                                <Creatable<any, false>
                                    {...field}
                                    placeholder={t('Choose Worker')}
                                    options={
                                        (
                                            fetchedUsers as unknown as Array<{
                                                name: string;
                                                id: string;
                                            }>
                                        )?.map?.((user) => ({
                                            label: user?.name,
                                            value: user?.id,
                                        })) ?? []
                                    }
                                    value={
                                        field?.value
                                            ? {
                                                value: field?.value,
                                                label:
                                                    (
                                                        fetchedUsers?.find?.(
                                                            (user: any) => user.id === field.value
                                                        ) as { name: string } | undefined
                                                    )?.name || field.value,
                                            }
                                            : null
                                    }
                                    className="cursor-pointer font-inter-regular react-select-container"
                                    classNamePrefix="react-select"
                                    onChange={(selectedOption) => {
                                        const selectedValue = selectedOption
                                            ? selectedOption.value
                                            : null;
                                        field.onChange(selectedValue);

                                    }}
                                    styles={customStyles}
                                    isValidNewOption={() => false}
                                    menuPosition={"fixed"}
                                    menuPortalTarget={document.body}
                                />
                            )}
                        />
                        {errors.assigned_to?.message && (
                            <>
                                <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                    <>{errors.assigned_to?.message}</>
                                </div>
                            </>
                        )}
                    </div>

                </div>
                <div className="pt-5">
                    <div className="flex flex-row justify-end gap-5">
                        <IconButton
                            secondary={true}
                            className="w-full max-w-[150px]"
                            onClick={() => { reset() }}
                        >
                            {t('Reset')}
                        </IconButton>
                        <IconButton className="w-full max-w-[150px]" type='submit'>
                            {t('Confirm')}
                        </IconButton>
                    </div>
                </div>
            </div>
        </Form>
    )
}

export default withModal(AssignLeads)