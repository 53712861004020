import { FC, useState } from "react";
import Button from "components/atoms/button/Button";
import Accordion from "../accordion/Accordion";
import AccordionTitle from "../accordion/AccordionTitle/AccordionTitle";
import ToggleSwitch from "../toggle-switch/ToggleSwitch";
import AccordionBody from "../accordion/AccordionBody/AccordionBody";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import { useSignupCustomHooks } from "lib/hooks/shared/useSignupCustomHooks";
import { useModules } from "lib/hooks/queries/Module/useModules";
import { usePermissionsModule } from "lib/hooks/queries/PermissionsModule/usePermissionsModule";
import { Fragment } from "react/jsx-runtime";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useTranslation } from "react-i18next";
import Checkbox from "components/atoms/checkbox/Checkbox";
import Table from "../table/Table";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";

export interface IModuleListProps {
  selectedUser: { id: number } | null;
  selectedUserPermissions: any;
  setSelectedUserPermissions: any;
}

const ModuleList: FC<IModuleListProps> = ({
  selectedUser,
  selectedUserPermissions,
  setSelectedUserPermissions
}) => {
  const [currentModuleName, setCurrentModuleName] = useState<any>();
  const { confirm, setCurrentStep, formData } = useSignupCustomHooks({
    initalStep: undefined
  });
  const { data: modules } = useModules();
  const { data: permissions, isLoading } = usePermissionsModule(currentModuleName);

  const { t } = useTranslation();

  const handleClickModal = async (currentModuleName: string) => {
    try {
      await confirm({
        title: `${t('Unlock')} ${currentModuleName}`,
        showCloseButton: true,
        classes: { button: "absolute right-[43px] top-[30px]" },
        description: `${t('You can upgrade your plan to benefit with the')} ${currentModuleName} ${t('and more.')}`,
        customComponent: (props) => {
          return (
            <div className="flex justify-end items-center gap-3 mt-[61px]">
              <Button
                type="submit"
                onClick={() => props.onCancel()}
                className="w-[168px] p-[13px] bg-[#F4F9FD] text-[#91929E] text-[13px] font-inter-bold rounded-[8px]"
              >
                {t('Cancel')}
              </Button>
              <Button
                type="button"
                onClick={() => {
                  props.onConfirm();
                  setCurrentStep(8);
                }}
                className="w-[168px] p-[13px]  bg-bluebutton text-white text-[13px] font-inter-bold rounded-[8px] flex items-center justify-center gap-[4px]"
              >
                {t('Upgrade Plan')}
                <Icon iconType={IconType.UPGRADE_ICON} />
              </Button>
            </div>
          );
        }
      });
    } catch (err) {
      return null;
    }
  };

  console.log('selectedUserPermissions', selectedUserPermissions);

  const handlePermissionSelect = (permission: any) => {
    if (selectedUser?.id !== undefined) {
      setSelectedUserPermissions((prevPermissions: { [key: number]: any }) => {
        const updatedPermissions = prevPermissions[selectedUser.id]?.includes(
          permission
        )
          ? prevPermissions[selectedUser.id].filter(
            (id: any) => id !== permission
          )
          : [...(prevPermissions[selectedUser.id] || []), permission];

        // Update local storage after updating the state
        const storedData = JSON.parse(
          localStorage.getItem("multi_step_form_state") || "{}"
        );
        storedData?.grouproles?.forEach((group: any) => {
          if (group?.id === selectedUser.id) {
            group.permission = updatedPermissions;
          }
        });
        localStorage.setItem(
          "multi_step_form_state",
          JSON.stringify(storedData)
        );

        return {
          ...prevPermissions,
          [selectedUser.id]: updatedPermissions
        };
      });
    }
  };

  const findPermissionByName = (permissions: any[], name: string) => {
    return permissions?.find((m: any) => m.name.split('_')[m.name.split('_').length - 1] === name)
  }
  // Check if a permission is selected for a user
  const isPermissionChecked = (permission: any) => {
    // AllData.permission.includes(findPermissionByName(row.original?.permission, 'view')?.id)
    if (Array.isArray(selectedUserPermissions[selectedUser?.id as any])) {
      return selectedUserPermissions[selectedUser?.id as any].includes(
        permission?.id
      );
    } else {
      return false;
    }
  };




  const TableHeaders = [
    {
      id: 1,
      Header: <div className='font-inter-bold text-primaryTextColor text-left w-full'>{t('Functionalities')}</div>,
      accessor: "Functionalities",
      Cell: ({ row }: any) => (
        <div className='max-w-[350px] w-full'>
          <div className='flex flex-col gap-2'>
            <span className='text-sm font-inter-semibold'>{row.original?.name}:</span>
            <span className='text-sm font-inter-regular'>{row.original?.description}</span>
          </div>
        </div>
      ),
      isChecked: true
    },
    {
      id: 2,
      Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('View All')}</span>,
      accessor: "View",
      Cell: ({ row }: any) => (
        <div className='flex justify-center'>
          {JSON.parse(row.original?.allowed_permission_types).filter((item: any) => item.label === 'View').length > 0 ? (
            <Checkbox
              onChange={(e) => {
                handlePermissionSelect(findPermissionByName(row.original?.permission, 'view')?.id)
              }}

              checked={isPermissionChecked(findPermissionByName(row.original?.permission, 'view'))}
            />
          ) : (
            <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
          )}
        </div>
      ),
      isChecked: true
    },
    {
      id: 8,
      Header: <div className='font-inter-bold text-primaryTextColor text-center -ml-6'>{t('/Own')}</div>,
      accessor: "View",
      Cell: ({ row }: any) => (
        <div className='flex justify-center -ml-6'>
          {row.original?.allowed_permission_types.includes('ViewOwn') ? (
            <Checkbox
              checked={isPermissionChecked(findPermissionByName(row.original?.permission, 'own'))}
              onChange={(e) => {
                handlePermissionSelect(findPermissionByName(row.original?.permission, 'own')?.id)
              }}
            />
          ) : (
            <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
          )}
        </div>
      ),
      isChecked: true
    },
    {
      id: 3,
      Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Edit')}</span>,
      accessor: "Edit",
      Cell: ({ row }: any) => (
        <div className='flex justify-center'>
          {row.original?.allowed_permission_types.includes('Edit') ? (
            <Checkbox
              checked={isPermissionChecked(findPermissionByName(row.original?.permission, 'edit'))}
              onChange={(e) => {
                handlePermissionSelect(findPermissionByName(row.original?.permission, 'edit')?.id)
              }}
            />
          ) : (
            <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
          )}
        </div>
      ),
      isChecked: true
    },
    {
      id: 4,
      Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Create')}</span>,
      accessor: "Create",
      Cell: ({ row }: any) => (
        <div className='flex justify-center'>
          {row.original?.allowed_permission_types.includes('Create') ? (
            <Checkbox
              checked={isPermissionChecked(findPermissionByName(row.original?.permission, 'create'))}
              onChange={(e) => {
                handlePermissionSelect(findPermissionByName(row.original?.permission, 'create')?.id)
              }}
            />
          ) : (
            <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
          )}
        </div>
      ),
      isChecked: true
    },
    {
      id: 5,
      Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Delete')}</span>,
      accessor: "Delete",
      Cell: ({ row }: any) => (
        <div className='flex justify-center'>
          {row.original?.allowed_permission_types.includes('Delete') ? (
            <Checkbox
              checked={isPermissionChecked(findPermissionByName(row.original?.permission, 'delete'))}
              onChange={(e) => {
                handlePermissionSelect(findPermissionByName(row.original?.permission, 'delete')?.id)
              }}
            />
          ) : (
            <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
          )}
        </div>
      ),
      isChecked: true
    },
    {
      id: 6,
      Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Assign')}</span>,
      accessor: "Assign",
      Cell: ({ row }: any) => (
        <div className='flex justify-center'>
          {row.original?.allowed_permission_types.includes('Assign') ? (
            <Checkbox
              checked={isPermissionChecked(findPermissionByName(row.original?.permission, 'assign'))}
              onChange={(e) => {

                handlePermissionSelect(findPermissionByName(row.original?.permission, 'assign')?.id)
              }}
            />
          ) : (
            <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
          )}
        </div>
      ),
      isChecked: true
    },
    {
      id: 7,
      Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Export')}</span>,
      accessor: "Export",
      Cell: ({ row }: any) => (
        <div className='flex justify-center'>
          {row.original?.allowed_permission_types.includes('Export') ? (
            <Checkbox
              checked={isPermissionChecked(findPermissionByName(row.original?.permission, 'export'))}
              onChange={(e) => {

                handlePermissionSelect(findPermissionByName(row.original?.permission, 'export')?.id)
              }}
            />
          ) : (
            <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
          )}

        </div>
      ),
      isChecked: true

    }
  ]

  return (
    <div className="border-[1px] border-[#D0D5DD] rounded-[20px] w-full">
      <div className="grid grid-cols-2 p-[20px]  border-[#D0D5DD] pb-[22px] border-b-[1px] border-[#]">
        <h1 className="font-inter-regular text-primaryTextColor text-[14px]">
          {t('Permissions')}
        </h1>
        <Button
          type="button"
          onClick={() => setSelectedUserPermissions([])}
          className={`font-inter-regular text-[#D0D5DD] underline text-[14px] text-right ${selectedUser ? "cursor-pointer !text-[#50B8E7]" : ""
            }`}
        >
          {t('Reset All')}
        </Button>
      </div>
      <div className="p-[35px] flex  flex-col gap-[14px]">
        {selectedUser ? (
          <Fragment>
            {modules?.modules?.map(
              (item: { id: number; name: string }, i: number) => {
                const isSelected = formData?.modules?.includes(String(item.id));
                return (

                  <Accordion
                    onClick={() => {
                      setCurrentModuleName(!isSelected ? "" : item?.name);
                      if (!isSelected) {
                        handleClickModal(item?.name);
                      }
                    }}
                    classes={{ wrapper: 'relative w-full !rounded-2xl !p-0 !border-[#A2A1A833]', title: '!bg-[#F6FBFE] !px-4 !py-3 !rounded-2xl', body: '!px-4 !mt-0' }}
                    key={`module-key-${item.id}`}
                  >
                    <AccordionTitle subTitleClass={'font-inter-semibold text-primaryTextColor'} customComponent={
                      <ToggleSwitch
                        classes={{ wrapper: "!absolute right-[50px] top-[10px]" }}
                        disabled={true}
                        initialValue={isSelected}
                      />
                    }>{item.name}</AccordionTitle>
                    <AccordionBody>
                      <div className='pb-4 mt-4 w-full'>
                        {
                          isLoading ? (
                            <div className="relative py-10">
                              <LoadingScreen />
                            </div>
                          ) : (

                            <Table
                              columns={TableHeaders}
                              data={permissions?.parentPermission || []}
                              search={""}
                              isColumnSorted={true}
                              classes={{ headerClasses: '!border-b-0' }}
                            />
                          )
                        }

                      </div>
                    </AccordionBody>
                  </Accordion>
                );
              }
            )}
          </Fragment>
        ) : (
          <EmptyEntity classes={{ childrenclass: "text-center" }}>
            {t('Select a user group to show the modules and functionalities')}
          </EmptyEntity>
        )}
      </div>
    </div>
  );
};

export default ModuleList;
