import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen'
import LeadsList from 'components/organisms/leads/leads-list/LeadsList'
import { lastTenLeads } from 'lib/helpers/constants/widget-headers/lastTenLeads'
import { lastTenPendings } from 'lib/helpers/constants/widget-headers/lastTenPendings'
import { leadsList } from 'lib/helpers/constants/widget-headers/leadsList'
import { recentCustomers } from 'lib/helpers/constants/widget-headers/recentCustomers'
import { unassignedAppointments } from 'lib/helpers/constants/widget-headers/unassignedAppointments'
import { useWidgetData } from 'lib/hooks/queries/Dashboard/useWidgetData'
import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    api: string
    name: string
}

const ListWidget: FC<Props> = ({ api, name }) => {

    const {t} = useTranslation()

    const { data, isLoading } = useWidgetData({ endpoint: api, params: {}, type: name })

    const headers = useMemo(
        () => {
            switch (name) {
                case 'Lead Latest Activities':
                    return lastTenLeads;
                case 'Lead Latest List':
                    return leadsList;
                case 'Unassigned Appointments':
                    return unassignedAppointments;
                case 'Customers Latest List':
                    return recentCustomers;
                case 'Pendings Latest List':
                    return lastTenPendings;
                default:
                    break;
            }
        },
        [name],
    )


    if (isLoading) return <LoadingScreen />

    return (
        <div className='w-full h-full bg-white border border-[#F2F4F7] rounded-2xl p-5'>
            <div className="pb-5">
                <div className='text-primaryTextColor font-inter-bold xl:text-lg 2xl:text-xl'>
                    {t(name)}
                </div>
            </div>
            <div className='h-[calc(100%-50px)] overflow-auto'>
                <LeadsList
                    dataCols={headers}
                    data={(data as any)?.data}
                />
            </div>
        </div>
    )
}

export default ListWidget