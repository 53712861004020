import Headline from "components/atoms/headline/Headline";
import { FC, useEffect, useState } from "react";
import LeadStatistics from "./Leads/LeadStatistics";
import AppointmentStatistics from "./Appointments/AppointmentStatistics";
import CustomerStatistic from "./Customers/CustomerStatistic";
import { getUsersByPermission } from "api/User";
import Leaderboard from "./Leaderboard/Leaderboard";
import NumberOfLeadsAppCust from "./GeneralStatistics/NumberOfLeadsAppCust";
import ConfirmedAppointments from "./Appointments/ConfirmedAppointments";


export interface IStatisticsProps { }

const Statistics: FC<IStatisticsProps> = () => {

    const [fetchedUsers, setFetchedUsers] = useState<Array<string>>([]);


    useEffect(() => {

        getUsersByPermission({
            permissions: ['lead_view_view_own'],

        })
            .then((res) => {
                setFetchedUsers(res.users);
            })
            .catch(() => { });
        // eslint-disable-next-line
    }, []);

    return (
        <>

            <Headline title={'Statistics'} />

            <div className="flex gap-4 flex-col xl:flex-row mb-4">
                <div className="w-full xl:max-w-[300px] 2xl:max-w-[400px]">
                    <NumberOfLeadsAppCust />
                </div>
                <div className={`flex flex-col gap-4 sm:flex-1`}>
                    <ConfirmedAppointments />
                </div>


            </div>
            <div className="flex gap-4 flex-col xl:flex-row">



                <div className={`flex flex-col gap-4  basis-full xl:basis-3/4`}>
                    <LeadStatistics fetchedUsers={fetchedUsers} />
                    <AppointmentStatistics fetchedUsers={fetchedUsers} />
                    <CustomerStatistic fetchedUsers={fetchedUsers} />

                </div>
                <div className="basis-full xl:basis-1/4">
                    <Leaderboard />
                </div>
            </div>
        </>
    );
};

export default Statistics;
