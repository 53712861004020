import EmptyEntity from 'components/atoms/empty-entity/EmptyEntity'
import Input from 'components/atoms/input/Input'
import Accordion from 'components/molecules/accordion/Accordion'
import AccordionBody from 'components/molecules/accordion/AccordionBody/AccordionBody'
import AccordionTitle from 'components/molecules/accordion/AccordionTitle/AccordionTitle'
import React from 'react'

const OtherBonuses = ({ data }: any) => {


    if (data?.length === 0 || data === undefined) {
        return (
            <EmptyEntity name='Bonuses' />
        )
    }
    return (
        <div className='space-y-7'>
            {data.map((item: any) => (
                <Accordion classes={{ wrapper: '!rounded-[20px] !p-5' }} key={item.id}>
                    <AccordionTitle subTitleClass="!text-xl !font-inter-bold !text-primaryTextColor">
                        <div>
                            Bonus: {item.name}
                        </div>
                    </AccordionTitle>
                    <AccordionBody>
                        <div className='flex-1 pt-5'>

                            <div className="grid grid-cols-2 gap-4">
                                <Input
                                    label="Receiving Period"
                                    classes={{
                                        label:
                                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px] !mb-0",
                                    }}
                                    className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                                    value={item.receiving_period}
                                    disabled={true}
                                />
                                <Input
                                    label="Amount"
                                    classes={{
                                        label:
                                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px] !mb-0",
                                    }}
                                    className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                                    value={item.bonus_value + ' CHF'}
                                    disabled={true}
                                />
                                <Input
                                    label="Description"
                                    classes={{
                                        label:
                                            "text-[#A2A1A8] font-inet-regular text-[14px] leading-[22px] !mb-0",
                                    }}
                                    className="!border-0 !border-b border-b-[#A2A1A81A] rounded-none !indent-0 !pl-0 disabled:bg-transparent"
                                    value={item.description || '-'}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </AccordionBody>

                </Accordion>
            ))}
        </div>
    )
}

export default OtherBonuses