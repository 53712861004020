import React, { FC } from 'react'
import { INewEmployeeStepsProps } from './Step1EmployeeInfo'
import IconButton from 'components/atoms/button/IconButton'
import { useTranslation } from 'react-i18next'
import Icon from 'components/atoms/icon/Icon'
import { IconType } from 'lib/helpers/constants/iconTypes'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import Input from 'components/atoms/input/Input'
import Label from 'components/atoms/input/input-label/InputLabel'
import CreatableSelect from 'react-select/creatable'
import { customStyles } from 'lib/helpers/configs/customStyles'
import RadioButton from 'components/atoms/radio-button/RadioButton'
import Form from 'components/atoms/form/Form'

const Step4AddExpenses: FC<INewEmployeeStepsProps> = ({ setCurrentStep, setAllData, AllData }) => {

    const { t } = useTranslation()

    const {
        control,
        formState: { errors },
        handleSubmit,
        register,
        watch,
        clearErrors,
        reset,
    } = useForm({
        defaultValues: {
            expenses: [{
                name: '',
                type: '',
                amount: '',
                measurement_type: '%'
            }]
        }
    })

    const {
        fields,
    } = useFieldArray<any>({ control, name: 'expenses' })

    const SubmitForm = (data: any) => {
        setAllData({
            ...AllData,
            expenses: [...(AllData.expenses || []), data.expenses[0]]
        });
        reset()
    }

    return (
        <Form onSubmit={handleSubmit(SubmitForm)}>
            <div className='mb-4 border border-border rounded-2xl'>
                <div className="px-4 py-2 bg-[#F6FBFE] rounded-t-2xl">
                    <div className="grid grid-cols-7 gap-3 font-inter-semibold text-primaryTextColor">
                        <div className="col-span-4">
                            {t('Expense Name')}
                        </div>
                        <div className="col-span-2">
                            {t('Amount/Percentage')}
                        </div>
                        <div className="col-span-1">
                            {t('Action')}
                        </div>
                    </div>
                </div>
                <div className="p-4">
                    <div className="grid grid-cols-7 gap-3 items-center">
                        {AllData?.expenses?.length > 0 ? (
                            <>
                                {AllData?.expenses?.filter((item: any) => item.type === 'Social Insurance')?.length > 0 && (
                                    <>
                                        <div className="col-span-4 font-inter-semibold text-primaryTextColor">
                                            {t('Social Insurance')}
                                        </div>
                                        <div className="col-span-2 "> </div>
                                        <div className="col-span-1"> </div>
                                    </>
                                )}

                                {AllData?.expenses?.map((expense: any, index: number) => (
                                    expense.type === 'Social Insurance' ? (
                                        <React.Fragment key={index}>
                                            <div className="col-span-4 text-primaryTextColor">
                                                {expense.name}
                                            </div>
                                            <div className="col-span-2 text-primaryTextColor">
                                            {expense.amount + expense.measurement_type}
                                            </div>
                                            <div className="col-span-1 text-primaryTextColor">
                                                <div className="cursor-pointer" onClick={() => {
                                                    setAllData({
                                                        ...AllData,
                                                        expenses: AllData.expenses?.filter((item: any) => item.name !== expense.name)
                                                    })
                                                }}>
                                                    <Icon iconType={IconType.DELETE_ICON} />
                                                </div>
                                            </div>
                                        </React.Fragment>) : null
                                ))}
                                {AllData?.expenses?.filter((item: any) => item.type === 'Other Expenses')?.length > 0 && (
                                    <>
                                        <div className="col-span-4 font-inter-semibold text-primaryTextColor">
                                            {t('Other Expenses')}
                                        </div>
                                        <div className="col-span-2 "> </div>
                                        <div className="col-span-1"> </div>
                                    </>
                                )}
                                {AllData?.expenses?.map((expense: any, index: number) => (
                                    expense.type === 'Other Expenses' ? (
                                        <React.Fragment key={index}>
                                            <div className="col-span-4 text-primaryTextColor">
                                                {expense.name}
                                            </div>
                                            <div className="col-span-2 text-primaryTextColor">
                                                {expense.amount + expense.measurement_type}
                                            </div>
                                            <div className="col-span-1 text-primaryTextColor">

                                                <div className="cursor-pointer" onClick={() => {
                                                    setAllData({
                                                        ...AllData,
                                                        expenses: AllData.expenses?.filter((item: any) => item.name !== expense.name)
                                                    })
                                                }}>
                                                    <Icon iconType={IconType.DELETE_ICON} />
                                                </div>
                                            </div>
                                        </React.Fragment>) : null
                                ))}
                            </>
                        ) : (
                            <>
                                <div className="col-span-4 text-primaryTextColor">-</div>
                                <div className="col-span-2 text-primaryTextColor">-</div>
                                <div className="col-span-1 text-primaryTextColor">-</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className='rounded-[20px] bg-[#F6FBFE] p-4 space-y-5'>
                {fields.map((field, index) => (
                    <div key={field.id} className='grid grid-cols-1 sm:grid-cols-2 gap-5'>
                        <Input
                            {...register(`expenses.${index}.name`, {
                                required: t("This field is required"),
                                validate: (value) => {
                                    if (!AllData?.expenses) {
                                        return true
                                    }
                                    if (AllData?.expenses.some((expense: any) => expense.name === value && expense.type === watch(`expenses.${index}.type`))) {
                                        return t("This expense already exists");
                                    }
                                    return true;
                                }
                            })}
                            placeholder={t("Enter expense name")}
                            label='Expense Name'
                            type="text"
                            className=""
                            error={errors?.expenses?.[index]?.name}
                        />
                        <div className="relative">
                            <Label text={t("Expense Type")} />
                            <Controller
                                name={`expenses.${index}.type`}
                                rules={{ required: t("This field is required") }}
                                control={control}
                                render={({ field }) => (
                                    <CreatableSelect<any, false>
                                        {...field}
                                        placeholder={t("Expense Type")}
                                        options={[
                                            { label: t("Social Insurance"), value: "Social Insurance" },
                                            { label: t("Other Expenses"), value: "Other Expenses" },
                                        ]}
                                        value={
                                            field?.value
                                                ? {
                                                    value: field?.value,
                                                    label: t(field?.value),
                                                }
                                                : null
                                        }
                                        className="cursor-pointer font-inter-regular react-select-container"
                                        classNamePrefix="react-select"
                                        onChange={(newValue: any, actionMeta: any) => {
                                            if (
                                                actionMeta.action === "select-option" &&
                                                newValue?.value
                                            ) {
                                                field.onChange(newValue?.value);

                                            }
                                        }}
                                        styles={customStyles}
                                        isValidNewOption={() => false}
                                        menuPosition={"fixed"}
                                        menuPortalTarget={document.body}
                                    />
                                )}
                            />

                            {errors?.expenses && (
                                <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                    {(errors as any)?.expenses[index]?.type?.message as string}
                                </div>
                            )}
                        </div>
                        <div className='grid grid-cols-4 gap-3 items-end'>
                            <div className="col-span-4">
                                <Label>Expense Amount</Label>
                            </div>
                            <div className='col-span-1'>
                                <Controller
                                    name={`expenses.${index}.measurement_type`}
                                    control={control}
                                    render={({ field }) => (
                                        <RadioButton
                                            label={t('%')}
                                            isChecked={field.value === '%'}
                                            value={'%'}
                                            onChange={() => { field.onChange('%'); clearErrors(`expenses.${index}.amount`) }}
                                            labelClassName="h-[41px] 2xl:h-[46px] items-center flex bg-white"
                                            iconWrapperClassName="!top-[19px] 2xl:!top-[22px] !right-[6px]"
                                            className="right-[8px]"
                                        />
                                    )}
                                />
                            </div>
                            <div className='col-span-1'>
                                <Controller
                                    name={`expenses.${index}.measurement_type`}
                                    control={control}
                                    render={({ field }) => (
                                        <RadioButton
                                            label={t('CHF')}
                                            isChecked={field.value === 'CHF'}
                                            value={'CHF'}
                                            onChange={() => { field.onChange('CHF'); clearErrors(`expenses.${index}.amount`) }}
                                            labelClassName="h-[41px] 2xl:h-[46px] items-center flex bg-white"
                                            iconWrapperClassName="!top-[19px] 2xl:!top-[22px] !right-[6px]"
                                            className="right-[8px]"
                                        />
                                    )}
                                />
                            </div>
                            <div className='col-span-2'>
                                <Input
                                    {...register(`expenses.${index}.amount`,
                                        watch(`expenses.${index}.measurement_type`) === '%'
                                            ? {
                                                min: { value: 0, message: t('Please enter a valid percentage') },
                                                max: { value: 100, message: t('Please enter a valid percentage') },
                                                required: t('This field is required')
                                            }
                                            : {
                                                required: t('This field is required'),
                                                max: { value: 9999999, message: t('Please enter a valid number') },
                                                min: { value: 0, message: t('Please enter a valid number') },
                                            }
                                    )}
                                    placeholder={watch(`expenses.${index}.measurement_type`) === '%' ? '0%' : t("0 CHF")}
                                    
                                    type="number"
                                    step="any"
                                    error={errors?.expenses?.[index]?.amount}
                                />
                            </div>
                        </div>

                    </div>
                ))}
            </div>
            <div className="pt-5 flex justify-end">
                <button className={`flex items-center justify-end gap-2 cursor-pointer`} >
                    <Icon iconType={IconType.PLUS_ICON} color='var(--theme)' />
                    <span className='text-theme font-inter-medium text-sm'>
                        {t('Add Expense')}
                    </span>
                </button>
            </div>
            <div className="flex flex-row justify-end gap-5 pt-10">
                <IconButton className="max-w-[120px] w-full" onClick={() => { reset(); setAllData({ ...AllData, expenses: undefined }) }} type='button' secondary>
                    {t(`Reset`)}
                </IconButton>
                <IconButton
                    className=""
                    type='button'
                    onClick={() => setCurrentStep(5)}
                >
                    <div className='flex items-center gap-2'>
                        {t(`Next Step`)}
                        <Icon iconType={IconType.RIGHT_ARROW_ICON} />
                    </div>
                </IconButton>
            </div>
        </Form>
    )
}
export default Step4AddExpenses