import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useWidgetData } from "lib/hooks/queries/Dashboard/useWidgetData";
import useScreenPercentage from "lib/hooks/shared/useScreenPercentage";
import React, { FC, useMemo } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";


interface SemiCircleChartProps {
  value?: number;
  total?: number;
  name: string;
  icon?: IconType;
  color?: string;
  status: string;
  api: string;
}

const SemiCircleChart: FC<SemiCircleChartProps> = ({ name, icon, color, status, api }) => {

  const { t } = useTranslation()

  const { data } = useWidgetData({ endpoint: api, params: {}, type: name })

  const formattedStatus = useMemo(() => {

    return status.split(" ").join("_").toLowerCase();

  }, [status]);

  const percentage = useMemo(() => {
    if (!data) return 0;

    const total = parseInt((data as any).data.total);
    const statusCount = parseInt((data as any).data[formattedStatus]);


    return (statusCount / total) * 100;

  }, [data, formattedStatus]);


  const options = {
    chart: {
      type: "radialBar",
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        offsetY: -30, // Adjust offset to avoid unnecessary space
        startAngle: -120,
        endAngle: 120,
        hollow: {
          size: "60%", // Adjust inner circle size if needed
        },
        track: {
          background: color + '10',
          strokeWidth: "40%",
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,

          },
        },
      },
    },
    fill: {
      colors: [color],
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Progress"],
  };

  const { width } = useScreenPercentage();


  return (
    <div className="w-full h-full bg-white border border-[#F2F4F7] rounded-2xl p-5">
      <div className="relative w-full h-full flex justify-center items-center">
        <div className="absolute top-0 right-0">
          <Icon iconType={IconType.CALENDAR_ICON} color="#282D46" className="h-5 w-5" />
        </div>
        <Chart options={options as any} series={[percentage]} type="radialBar" width={width} />
        <div className="absolute bottom-[10%] 2xl:bottom-[20%] text-center flex justify-center items-center flex-col gap-3">
          <div className={`h-[40px] w-[40px] bg-[${color}] bg-opacity-10 rounded-full flex justify-center items-center`} >
            {icon && (
              // @ts-ignore
              <Icon iconType={IconType[icon]} color={color} />
            )}
          </div>
          <div className="text-[#282D46] text-xl font-bold leading-none">{(data as any)?.data[formattedStatus]} {t(name)}</div>
          <div className="text-[#282D46] text-sm font-bold leading-none">{t(status)}</div>

        </div>
      </div>
    </div>
  );
};

export default SemiCircleChart;
