import Form from "components/atoms/form/Form";
import { Dispatch, FC, SetStateAction  } from "react";
import { Controller, useForm } from "react-hook-form";
import Icon from "components/atoms/icon/Icon";
import IconButton from "components/atoms/button/IconButton";
import { IconType } from "lib/helpers/constants/iconTypes";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import Button from "components/atoms/button/Button";
import Input from "components/atoms/input/Input";
import { UseMutateFunction } from "react-query";
import { useTranslation } from "react-i18next";

export interface IUploadFileProps<T> {
  userData: T;
  documentData: T;
  product: T;
  setViewFileUrl: any;
  setCurrentUpdatePending: Dispatch<SetStateAction<number>>;
  uploadedFile: any;
  setUploadedFile: any;
  storeDocument: UseMutateFunction<unknown, unknown, any, unknown>;
  fileName: any;
  setFileName: any;
  setFileSize:any;
  viewFileUrl: string | null;
  fileSize: any;
  isStoreLoading: boolean;
}

const UploadFile: FC<IUploadFileProps<any>> = ({
  documentData,
  userData,
  product,
  setViewFileUrl,
  setCurrentUpdatePending,
  setUploadedFile,
  viewFileUrl,
  uploadedFile,
  storeDocument,
  fileName,
  setFileName,
  setFileSize,
  fileSize,
  isStoreLoading
}) => {

  const {
    control,
    handleSubmit,
    register,
    reset
  } = useForm();

  const onSubmit = async (): Promise<void> => {
    const formData = new FormData();
    formData.append("document", uploadedFile);
    formData.append("product", product.product);
    formData.append("document_type", documentData?.name);
    formData.append('pending_id', userData.data.id);
    try {
      await storeDocument(formData);
      setCurrentUpdatePending(1);
      setUploadedFile("");
      setFileName("");
      setFileSize("");
      reset()
    } catch (error) {
      setUploadedFile("");
      setFileName("");
      setFileSize("");
    }
  };
  const {t} = useTranslation();
  
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-[25px]"
    >
      <div className="flex items-center gap-[14px] bg-[#F4F9FD] rounded-[8px]  px-[13px] py-[5px] w-[fit-content]">
        <h1 className="font-inter-bold whitespace-nowrap">{product?.product}</h1>
        <img
          src="https://s3-alpha-sig.figma.com/img/b289/0674/48a04ad5d50e901746f1dbf58c847070?Expires=1723420800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=homfpsmA2tY7yNvxumVAqAsJnoXFETyqzZ~kL5KF44FgRNELfpSqaRMQ4lZDipp9yUFFD9Gr0YQ4ittew6qxGldOEu7qmTAg-OaJ25ooho5PEqPARAXwgV3ltZbrT29rA8XCXSBozVrtRFTGZjGIFeDCKWVpOydWt6sNHi2qJwgHlnWJDVgEzv~BgF-SgGUbyGakMWMITWM3cS3PwPPz~NPSivXH865YjhSL7tLbtGlFUiMcMghgThTgKnL6GZziNOW34wY1sR1ef5uQ-e3KTN1YFwAl~tk9Y8Mu6A4VKp6BrjeTt72x9Jfj7LX-pcj1H0quGcBCJfyvNnGQvzM0oQ__"
          alt="img"
          className="w-[24px] h-[24px] rounded-full object-cover"
        />
        <p className="font-inter-regular text-[#282D46]">{product?.company}</p>
      </div>
      <Input
        placeholder={t("Document Type")}
        label={t("Document Type")}
        {...register("file_name")}
        readOnly
        disabled
        value={t(documentData?.name)}
        onChange={(e) => e.preventDefault()}
      />
      <Controller
        control={control}
        name="document"
        defaultValue={""}
        render={({ field: { onChange } }) => (
          <>
            <ConditionalRender condition={!fileName}>
              <div className="text-[14px] font-inter-medium">{t("Upload file")}</div>
              <div className={`flex justify-center relative`}>
                <div className="flex items-center justify-center w-full">
                  <label
                    className={`flex flex-col-reverse cursor-pointer w-full h-[133px] border-[1px] rounded-[10px] border-border border-dashed bg-white relative`}
                  >
                    <div className="flex flex-col items-center justify-center">
                      <div className="bg-[--theme] w-[40px] absolute top-[20%] h-[40px] rounded-[10px] flex items-center justify-center">
                        <Icon iconType={IconType.UPLOAD_FILE_ICON} />
                      </div>
                      <div className="mb-3 text-center">
                        <p className="text-primaryTextColor text-[14px] font-inter-regular mb-1">
                          {t("Drag & Drop or")}{" "}
                          <span className={`text-[--theme] underline`}>
                            {t("choose file")}
                          </span>{" "}
                          {t("to upload")}
                        </p>
                        <p className="text-[#A2A1A8] text-[11px] font-inter-regular">
                          {t("Supported formats: pdf, doc, docx, csv. Maximum size: 10 MB")}
                        </p>
                      </div>
                    </div>
                    <Input
                      type="file"
                      onChange={(e) => {
                        if (e.target?.files) {
                          const file = e.target.files[0];
                          const fileUrl = URL.createObjectURL(file);
                          setViewFileUrl(fileUrl);
                          setUploadedFile(file as any);
                          setFileName(file.name);
                          onChange(file);
                          if (file) {
                            const size = file.size / 1024;
                            if (size < 1024) {
                              setFileSize(`${size.toFixed(2)} KB`);
                            } else {
                              setFileSize(`${(size / 1024).toFixed(2)} MB`);
                            }
                          }
                        }
                      }}
                      accept={".pdf"}
                      className="!z-[-11]"
                      classes={{ container: "!z-[-22]" }}
                    />
                  </label>
                </div>
              </div>
            </ConditionalRender>
            <ConditionalRender condition={fileName as any}>
              <div className="mt-2  flex flex-col gap-[25px]">
                <div className="text-[14px] font-inter-medium">
                  {t("Uploaded File")}
                </div>
                <div className="flex items-center justify-between cursor-pointer">
                  <div
                    role="button"
                    onClick={() => {
                      setViewFileUrl(viewFileUrl);
                      setCurrentUpdatePending(3);
                    }}
                    className="flex items-center gap-5"
                  >
                    <Icon iconType={IconType.PDFUPLOAD_ICON} />
                    <div>
                      <h1 className="font-inter-medium text-[#282D46] text-[14px] capitalize">
                        {fileName ?? ""}
                      </h1>
                      <p className="uppercase text-[#6C737F] text-[14px] font-inter-regular">
                        {fileSize ?? ""}
                      </p>
                    </div>
                  </div>
                  <Button
                   type="button"
                    onClick={() => {
                      onChange(null);
                      reset();
                      setUploadedFile("");
                      setFileName("");
                    }}
                  >
                    <Icon iconType={IconType.DELETE_ICON} color="#7D8592" />
                  </Button>
                </div>     
              </div>
            </ConditionalRender>
          </>
        )}
      />
      <div className="flex justify-end gap-[26px] mt-5">
        <IconButton
          type="submit"
          disabled={isStoreLoading}
          icon={<Icon iconType={IconType.UPLOAD_FILE_ICON} />}
        >
          {isStoreLoading ? t('Uploading...') : t('Upload')}
        </IconButton>
      </div>
    </Form>
  );
};

export default UploadFile;
