import { useQuery } from "react-query"
import { getSalaryBonuses } from "api/Employee";
import { usePermissions } from "lib/hooks/shared/usePermissions";


export const useSalaryBonuses = (
    id: any
) => {
    const permissions = usePermissions(['employee_management_view']);

    return useQuery<Array<any> | any>(
        'salary-goals',
        () => getSalaryBonuses(permissions ? id : null),
        {
            onError(error: any) {
                console.error(error)
            }
        }
    )
}