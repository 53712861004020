import React, { FC } from 'react'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Label from '../input/input-label/InputLabel';
import { useTranslation } from 'react-i18next';

type RangeSliderProps = {
    value: number;
    onChange: (value: number | number[]) => void;
    leftLabel?: string;
    rightLabel?: string;
    step?: number;
    min?: number;
    max?: number;
}

const RangeSlider: FC<RangeSliderProps> = ({ value, onChange, leftLabel, rightLabel, step = 5,min,max }) => {

    const { t } = useTranslation()

    return (
        <div className='flex flex-col gap-2'>
            {
                (leftLabel && rightLabel) && (
                    <div className='flex items-center justify-between'>
                        {
                            leftLabel && <Label>{t(leftLabel)}</Label>
                        }
                        {
                            rightLabel && <Label>{t(rightLabel)}</Label>
                        }

                    </div>
                )
            }

            <div className='flex items-center gap-[0.6rem] '>
                <div className="flex-none text-xs text-primaryTextColor font-inter-regular w-[30px] flex justify-end">
                    <span>{value}%</span>
                </div>
                <Slider
                    styles={{
                        track: { background: 'var(--theme)' },
                        handle: {
                            borderColor: 'var(--theme)',
                            background: 'var(--theme)',
                            opacity: 1,
                            boxShadow: 'none',
                        },

                        rail: { background: '#EDF1F3' }
                    }}
                    step={step}
                    min={min || 0}
                    max={max|| 100}
                    onChange={onChange}
                    value={value}
                />
                <div className="flex-none text-xs text-primaryTextColor font-inter-regular w-[30px] justify-start flex">
                    <span>{100 - value}%</span>
                </div>
            </div>
        </div>
    )
}

export default RangeSlider