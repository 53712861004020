import { IconType } from "./iconTypes";

export interface ISideNavLinks {
  id: string;
  label: string;
  path: string;
  icon?: string;
  doesntNeedPermission?: boolean;
  children?: ISideNavLinks[];
  permissions?: Array<string>;
}

export const sideNavLinks: Array<ISideNavLinks> = [
  {
    id: "1",
    label: "Home",
    path: "/dashboard",
    doesntNeedPermission: true,
    permissions: [],
    icon: IconType.HOME_ICON,
  },
  {
    id: "2",
    label: "HR",
    path: "/hr",
    icon: IconType.HR_ICON,
    permissions: [
      'employee_management_view',
      'employee_management_view_view_own',
      'roles_management_view',
      'onboarding_management_view',
      'onboarding_management_view_view_own',
      'offboarding_management_view',
      'offboarding_management_view_view_own'
    ],
    children: [
      {
        id: "222",
        label: "Roles",
        path: "/hr/roles",
        permissions: ['roles_management_view'],
        icon: IconType.HR_ICON,
      },
    ],
  },
  // {
  //   id: "3",
  //   label: "Projects",
  //   path: "/projects",
  //   permissions: [],
  //   icon: IconType.PROJECT_ICON,
  // },
  // {
  //   id: "4",
  //   label: "Team",
  //   path: "/team",
  //   permissions: [],
  //   icon: IconType.TEAM_ICON,
  // },
  // {
  //   id: "5",
  //   label: "Calendar",
  //   path: "/calendar",
  //   permissions: [],
  //   icon: IconType.CALENDAR_ICON,
  // },
  {
    id: "6",
    label: "Appointments",
    path: "/appointments",
    icon: IconType.EVENTS_ICON,
    permissions: [
      'apointment_view_view', 'apointment_view_view_own'
    ]
  },
  {
    id: "7",
    label: "Customers",
    path: "/customers",
    permissions: ['customers_view_view', 'customers_view_view_own'],
    icon: IconType.CUSTOMERS_ICON,
    children: [
      {
        id: "11123",
        label: "Security",
        path: "/customers/security",
        permissions: ['export_customers_view'],
        icon: IconType.CUSTOMERS_ICON,
      },
    ],
  },
  {
    id: "8",
    label: "Leads",
    path: "/leads",
    permissions: ['lead_view_view_own'],
    icon: IconType.LEADS_ICON,
    children: [
      {
        id: "122",
        label: "Sources",
        path: "/leads/source",
        permissions: [
          'source_management_view_own', 'source_management_view'
        ],
        icon: IconType.LEADS_ICON,
      },
      {
        id: "2213",
        label: "Campaigns",
        path: "/leads/campaign",
        permissions: [
          'campaign_management_view', 'campaign_management_view_own',
        ],
        icon: IconType.LEADS_ICON,
      },
      {
        id: "3213",
        label: "Security",
        path: "/leads/security",
        permissions: ['export_leads_view'],
        icon: IconType.LEADS_ICON,
      },
    ],
  },
  {
    id: "19",
    label: "External Source",
    path: "/external-source",
    permissions: ['lead_view_view_own'],
    icon: IconType.API_TOKEN_ICON,
  },
  {
    id: "9",
    label: "Partners",
    path: "/partners",
    icon: IconType.PARTNERS_ICON,
    permissions: ['manage_partners_view'],
  },
  {
    id: "11",
    label: "Pendings",
    path: "/pendings",
    permissions: [
      'pending_hr_view_view',
      'pending_hr_view_view_own',
      'pending_customer_view_view',
      'pending_customer_view_view_own',
      'pending_myown_view_view',
      'pending_other_view_view',
      'pending_other_view_view_own'
    ],
    icon: IconType.PENDINGS_ICON,
  },
  // {
  //   id: "12",
  //   label: "Health",
  //   path: "/health",
  //   permissions: [],
  //   icon: IconType.INSURANCE_ICON,
  // },
  // {
  //   id: "13",
  //   label: "Chat",
  //   path: "/chat",
  //   permissions: [],
  //   icon: IconType.CHAT_ICON,
  // },
  // {
  //   id: "14",
  //   label: "Settings",
  //   path: "/settings",
  //   permissions: [],
  //   icon: IconType.SETTINGS_ICON,
  // },
  {
    id: "16",
    label: "Statistics",
    path: "/statistics",
    doesntNeedPermission: true,
    permissions: [],
    icon: IconType.STATISTIC_ICON,
    children: [
      {
        id: "161",
        label: "Counter Tool",
        path: "/statistics/counter-tool",
        permissions: ['export_customers_view'],
        icon: IconType.CUSTOMERS_ICON,
      },
    ],
  },
  {
    id: "17",
    label: "Documents",
    path: "/insurance-documents",
    permissions: ['manage_resources_view', 'manage_templates_view'],
    icon: IconType.DOCUMENT_MENU_ICON,
  },
  {
    id: "18",
    label: "Finances",
    path: "/finances",
    permissions: [
      'cost_management_view',
      'performance_tracking_view',
      'cost_analysis_view',
      'planned_actual_soll_ist_view',
      'commission_models_view',
      'salaries_view'
    ],
    icon: IconType.FINANCES_ICON,
    children: [
      {
        id: "181",
        label: "Statement Of Costs",
        path: "/finances",
        permissions: ['cost_management_view'],
        icon: IconType.FINANCES_ICON,
      },
      {
        id: "181",
        label: "Success",
        path: "/finances/success",
        permissions: ['performance_tracking_view'],
        icon: IconType.FINANCES_ICON,
      },
      {
        id: "182",
        label: "Analysis",
        path: "/finances/analysis",
        permissions: ['cost_analysis_view'],
        icon: IconType.FINANCES_ICON,
      },
      {
        id: "183",
        label: "Comparison",
        path: "/finances/comparison",
        permissions: ['planned_actual_soll_ist_view'],
        icon: IconType.FINANCES_ICON,
      },
      {
        id: "184",
        label: "Commission",
        path: "/finances/commission",
        permissions: ['commission_models_view'],
        icon: IconType.FINANCES_ICON,
      },
      {
        id: "185",
        label: "Salaries",
        path: "/finances/salaries",
        permissions: ['salaries_view'],
        icon: IconType.FINANCES_ICON,
      },
    ],
  }

];
