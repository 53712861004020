import withModal from 'lib/helpers/hoc/withModal'
import React, { useState } from 'react'
import Step3ApiInfo from '../new-api-modal/steps/Step3ApiInfo'
import Step2Headers from '../new-api-modal/steps/Step2Headers'
import Label from 'components/atoms/input/input-label/InputLabel'
import ToggleSwitch from 'components/molecules/toggle-switch/ToggleSwitch'
import { useToggleExternalSource } from 'lib/hooks/mutations/ExternalSources/useToggleExternalSource'

const ViewApiModal = ({ data, headers }: any) => {

    const { mutateAsync } = useToggleExternalSource()

    const [IsActive, setIsActive] = useState(data.is_active)

    const ChangeStatus = () => {

        const updatedActiveState = IsActive === 1 ? false : true

        mutateAsync({ id: data.id, is_active: updatedActiveState }).then(res => {
            setIsActive(updatedActiveState)
        })
    }

    return (
        <div className='space-y-5'>
            <div className='absolute top-[46px] left-40'>
                <div className='flex gap-2 items-center'>
                    <div className='font-inter-regular '>
                        API Status
                    </div>
                    <ToggleSwitch checked={IsActive} onChange={ChangeStatus} classes={{label: '!mb-0'}} />
                </div>
            </div>
            <div>
                <Label>Fields Overview</Label>
                <Step2Headers Headers={data.module === 'Lead' ? headers.lead_headers : headers.app_headers} />
            </div>
            <div>
                <Label>View Access Details</Label>
                <Step3ApiInfo ApiInfo={data} />
            </div>
        </div>
    )
}

export default withModal(ViewApiModal)