import React, { useState } from "react";
import { useTranslation } from "react-i18next";


export const lastTenPendings = [
  {
    Header: "Created At",
    accessor: (row: any) =>
      new Date(row?.created_at).toLocaleDateString('de') || "Not available",
    id: 1,
    isChecked: true,
  },
  {
    Header: "Deadline",
    accessor: (row: any) =>
      new Date(row?.deadline).toLocaleDateString('de') || "Not available",
    id: 2,
    isChecked: true,
  },
  {
    Header: "Category",
    accessor: (row: any) =>
      row.category || "Not available",
    id: 3,
    isChecked: true,
  },
  {
    Header: "Title",
    accessor: (row: any) =>
      row.title || "Not available",
    id: 4,
    isChecked: true,
  },

  {
    id: 5,
    Header: `Status`,
    accessor: (row: any) => row?.status || `Not available`,
    Cell: ({ row, value }: any) => (
      <div className="cursor-pointer">
        <FeedbackStatus value={value} />
      </div>
    ),
    isChecked: true
  },
]


const FeedbackStatus = ({ value }: any) => {
  const [Color, setColor] = useState<string>("");
  const { t } = useTranslation();

  React.useEffect(() => {
    switch (value) {
      case 'Processing':
        setColor("#50B8E7");
        break;
      case 'Pending Backoffice':
        setColor("#EFBE12");
        break;
      case 'Pending Sales':
        setColor("#EFBE12");
        break;
      case 'Rejected File':
        setColor("#DC4D4D");
        break;
      case 'Done':
        setColor("#2DE28B");
        break;
    }

  }, [value, t]);

  return (
    <span
      className={`text-[12px] bg-opacity-10 font-inter-light px-[8px] py-[3px] rounded-[4px] whitespace-nowrap`}
      style={{ background: Color + "1A", color: Color }}
    >
      {value}
    </span>
  );
};