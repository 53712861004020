import IconButton from 'components/atoms/button/IconButton'
import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender'
import FilterBorderedWrapper from 'components/atoms/filter-bordered-wrapper/FilterBorderedWrapper'
import Headline from 'components/atoms/headline/Headline'
import Icon from 'components/atoms/icon/Icon'
import DashboardBoxTitle from 'components/atoms/title/DashboardBoxTitle'
import DynamicDropdown from 'components/molecules/dynamic-dropdown/DynamicDropdown'
import DashboardBoxLayout from 'components/templates/dashboard-box-layout/DashboardBoxLayout'
import { IconType } from 'lib/helpers/constants/iconTypes'
import React, { useEffect, useState } from 'react'
import ConfigureTableDropdown from '../leads/dropdowns/ConfigureTableDropdown'
import Input from 'components/atoms/input/Input'
import { useTranslation } from 'react-i18next'
import NoDataBoundary from 'components/atoms/no-data-boundary/NoDataBoundary'
import EmptyEntity from 'components/atoms/empty-entity/EmptyEntity'
import RolesTable from './roles-table/RolesTable'
import { useRolesOfCompany } from 'lib/hooks/queries/Employee/useRolesOfCompany'
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen'
import EditRole from './edit-role/EditRole'
import CreateRole from './create-role/CreateRole'

const EmployeeRolesView = () => {

  const { t } = useTranslation();



  const [configureTableOpen, setConfigureTableOpen] = useState<boolean>(false);

  const { data, isLoading } = useRolesOfCompany()

  const [cardsOrder, setCardsOrder] = useState<any>([]);

  useEffect(() => {

    if (data) {

      setCardsOrder([
        {
          id: 1,
          Header: `${t('Creation Date')}`,
          accessor: (row: any) =>
            row?.created_at,
          Cell: ({ row }: any) => (
            <div className="cursor-pointer" onClick={() => { setEditRoleModalData({ id: row.original?.id, name: row.original?.name }) }}>
              {new Date(row.original?.created_at).toLocaleDateString('de')}
            </div>
          ),
          isChecked: true
        },
        {
          id: 2,
          Header: `${t('Role Name')}`,
          accessor: (row: any) =>
            row?.name,
          Cell: ({ row }: any) => (
            <div className="cursor-pointer" onClick={() => { setEditRoleModalData({ id: row.original?.id, name: row.original?.name }) }}>
              {t(row.original?.name)}
            </div>
          ),
          isChecked: true
        },
        {
          id: 3,
          Header: `${t('Assigned Workers')}`,
          accessor: (row: any) =>
            row?.name,
          Cell: ({ row }: any) => (
            <div className="cursor-pointer flex" onClick={() => { setEditRoleModalData({ id: row.original?.id, name: row.original?.name }) }}>
              {row.original?.users?.length > 0 ? (
                row.original?.users.map((user: any, index: number) => (
                  index < 5 && (
                    user.user_information?.profil_picture !== null ? (
                      <img src={'https://devmainhub.insusales.com/api/profile-picture/' + user.user_information?.profil_picture} alt=""
                        className={`${index !== 0 && '-ml-3'} w-[35px] h-[35px] rounded-full`} key={index} />
                    ) : (
                      <img src={'https://st3.depositphotos.com/9998432/13335/v/450/depositphotos_133351928-stock-illustration-default-placeholder-man-and-woman.jpg'}
                        alt="" className={`${index !== 0 && '-ml-3'} w-[35px] h-[35px] rounded-full flex-none`} key={index} />

                    )
                  )
                ))

              ) : (
                t('Not available')
              )}

              {(row.original?.users?.length > 5) && (
                <div className="w-[35px] h-[35px] rounded-full -ml-3 bg-[--theme-bg-opacity] border-2 border-white text-[--theme] grid place-items-center font-inter-medium text-xs">
                  {row.original?.users.length - 5}
                </div>
              )}
            </div>
          ),
          isChecked: true
        },
        ...(data as any)?.modules.map((module: any, index: number) => {
          return {
            id: index + 4,
            Header: module?.module.name,
            accessor: (row: any) =>
              t(module?.module.name),
            Cell: ({ row }: any) => (
              <div className="cursor-pointer" onClick={() => { setEditRoleModalData({ id: row.original?.id, name: row.original?.name }) }}>
                {row.original?.modules?.find((m: any) => m.name === module?.module.name)?.is_active === 'Yes' ? (
                  <div>
                    <Icon iconType={IconType.CHECKBOX_TICK_ICON} className='w-[18px] h-[18px]' color='#2CC95B' />
                  </div>
                ) : (
                  <div className='ps-[3px]'>
                    <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#7D8592' />
                  </div>
                )}
              </div>
            ),
            isChecked: true
          }
        }),
        {
          Header: <span className="sr-only"> {t('Actions')} </span>,
          accessor: "actions",
          id: 100,
          Cell: ({ row }: any) => (
            <div className='cursor-pointer' onClick={() => { setEditRoleModalData({ id: row.original?.id, name: row.original?.name }) }}>
              <Icon iconType={IconType.OPTIONS_ICON} />
            </div>
          ),
          isChecked: true
        }
      ])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const [EditRoleModalData, setEditRoleModalData] = useState<{ name: string, id: number }>()

  const [EditModalStep, setEditModalStep] = useState(1)

  const [CreateRoleModalOpen, setCreateRoleModalOpen] = useState(false)

  return (
    <div>
      <Headline title={t('Role Management')} />
      <DashboardBoxLayout>
        <div className='flex gap-4 items-center justify-between mb-5'>
          <DashboardBoxTitle title='Roles List' />
          <IconButton icon={<Icon iconType={IconType.PLUS_ICON} />} onClick={() => { setCreateRoleModalOpen(true) }}>
            {t('Create Role')}
          </IconButton>
        </div>
        <div className="flex flex-col gap-3 items-center mb-5 md:flex-row">
          <div className="flex-1">
            <Input
              icon={IconType.SEARCH_ICON}
              className=" border-0 !border-[#F2F4F7] indent-[35px] h-[48px] !rounded-[8px] text-[#7D8592] font-inter-regular shadow-customsecondary"
              classes={{ icon: "left-4 !top-[30%]" }}
              placeholder={t("Search")}

              maxLength={20}
            />
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div className="flex-none">
              <div className={`relative`}>
                <FilterBorderedWrapper
                  className="cursor-pointer h-[48px] w-[48px]"
                  onClick={() => {
                    setConfigureTableOpen(!configureTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_BARS_ICON} />
                </FilterBorderedWrapper>
                <ConditionalRender condition={configureTableOpen}>
                  <DynamicDropdown
                    setIsOpen={setConfigureTableOpen}
                    showCloseButton={true}
                    title={t('Configure Table')}
                  >
                    <ConfigureTableDropdown
                      setCardsOrder={setCardsOrder}
                      cardsOrder={cardsOrder}
                    />
                  </DynamicDropdown>
                </ConditionalRender>
              </div>
            </div>
            {/* <div className="flex-none">
              <div className={`${filterTableOpen && "relative"}`}>
                <FilterBorderedWrapper
                  className="h-[48px] w-[48px] cursor-pointer"
                  onClick={() => {
                    setFilterTableOpen(!filterTableOpen);
                  }}
                >
                  <Icon iconType={IconType.FILTER_ICON} />
                </FilterBorderedWrapper>
                <ConditionalRender condition={filterTableOpen}>
                  <DynamicDropdown
                    setIsOpen={setFilterTableOpen}
                    showCloseButton={true}
                    title={t('Filter')}
                    className="sm:!w-[350px]"
                  >
                    <FilterTableDropdown
                      setFilterData={setFilterData}
                      FilterData={filterData}
                      setIsOpen={setFilterTableOpen}
                      isAssignedLeads={isAssignLeadsActive === 'Assigned Leads'}
                    />
                  </DynamicDropdown>
                </ConditionalRender>
              </div>
            </div> */}

          </div>
        </div>
        <div>
          {isLoading ? <div className='py-10 relative'><LoadingScreen /></div>
            : (
              <NoDataBoundary
                condition={true}
                fallback={<EmptyEntity name="Roles" />}
              >
                <RolesTable
                  data={(data as any)?.roles || []}
                  dataCols={cardsOrder}
                />
              </NoDataBoundary>
            )
          }

        </div>
      </DashboardBoxLayout>

      <EditRole
        data={EditRoleModalData}
        clearData={true}

        hocCurrentStep={EditModalStep}
        setHocCurrentStep={setEditModalStep}
        onConfirm={() => { setEditRoleModalData(undefined); setEditModalStep(1) }}
        setFormCurrentStep={setEditModalStep}
        currentStep={EditModalStep}
        hocisOpen={EditRoleModalData !== undefined}
        hocToggleModal={() => { setEditRoleModalData(undefined); setEditModalStep(1) }}
        hocTitle={
          {
            1: EditRoleModalData?.name,
            2: t('Edit') + ': ' + EditRoleModalData?.name
          }[EditModalStep]
        }
        hocClasses={{
          modalClassName: "w-full md:max-w-[1100px]",
          titleClassName: EditModalStep !== 1 ? "ml-[35px]" : ""
        }}
      />
      <CreateRole
        hocCurrentStep={1}
        onConfirm={() => { setCreateRoleModalOpen(false) }}
        hocisOpen={CreateRoleModalOpen}
        hocToggleModal={() => { setCreateRoleModalOpen(false) }}
        hocTitle={'Create Role'}
        hocClasses={{
          modalClassName: "w-full md:max-w-[1100px]",
        }}
      />
    </div>
  )
}

export default EmployeeRolesView