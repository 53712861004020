import { Dispatch, FC, useEffect, useState } from "react";
import { Controller, FieldErrorsImpl, useForm } from "react-hook-form";
import Button from "components/atoms/button/Button";
import IconButton from "components/atoms/button/IconButton";
import EmptyEntity from "components/atoms/empty-entity/EmptyEntity";
import Form from "components/atoms/form/Form";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import NoDataBoundary from "components/atoms/no-data-boundary/NoDataBoundary";
import Accordion from "components/molecules/accordion/Accordion";
import AccordionBody from "components/molecules/accordion/AccordionBody/AccordionBody";
import AccordionTitle from "components/molecules/accordion/AccordionTitle/AccordionTitle";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import InputsFolder from "components/molecules/inputs-folder/InputsFolder";
import { IconType } from "lib/helpers/constants/iconTypes";
import { usePartners } from "lib/hooks/queries/Partners/usePartnersForFilter";
import { useUpdateCustomerProduct } from "lib/hooks/mutations/Customers/useUpdateCustomerProduct";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import Label from "components/atoms/input/input-label/InputLabel";
import { customStyles } from "lib/helpers/configs/customStyles";
import Creatable from "react-select/creatable";
import { useTranslation } from "react-i18next";
import { usePermissions } from "lib/hooks/shared/usePermissions";

export interface IChooseProductsProps<T> {
  data?: T;
  setFormStep?: (value: number) => void;
  setFormDataOfProducts?: Dispatch<any>;
  isLoading: boolean;
  id: any;
  from_user_id: any;
  refetchProducts: () => void;
  themeColors: {
    primary: string;
  };
}

const UpdateChooseProducts: FC<IChooseProductsProps<any>> = ({
  setFormStep,
  data,
  setFormDataOfProducts,
  isLoading,
  id,
  from_user_id,
  refetchProducts,
  themeColors,
}) => {
  const {
    formState: { errors },
    handleSubmit,
    control,
    register,
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      products: data?.data?.map?.((product: any) => ({
        id: product?.id,
        creation_date: product?.creation_date,
        company_name: product?.company_name,
        product_name: product?.product_name,
        name: product?.name,
        status: product?.status,
        last_update: product?.updated_at,
        commission: product?.commission || 0,
        commission_date: product?.commission_date,
        commission_expected: product?.commission_expected,
        premium_per_month: product?.premium_per_month || "",
      })),
    },
  });




  const [commissionValues, setCommissionValues] = useState<{
    [key: number]: string;
  }>(() => {
    const initialValues: { [key: number]: string } = {};
    data?.data?.forEach((product: any, index: number) => {
      initialValues[index] = `CHF ${product.commission || ""}`; // Initialize with formatted value

    });

    return initialValues;
  });

  const [premiumPerMonthValues, setPremiumPerMonthValues] = useState<{ [key: number]: string; }>('');

  useEffect(() => {
    if (isLoading) {
      return
    }

    setPremiumPerMonthValues(() => {
      const initialValues: { [key: number]: string } = {};
      data?.data?.forEach((product: any, index: number) => {

        initialValues[index] = `CHF ${product?.premium_per_month || ""}`; // Initialize with formatted value

      });

      return initialValues;
    })

  }, [isLoading, data?.data])


  useEffect(() => {
    reset({
      products: data?.data?.map?.((product: any) => ({
        id: product?.id,
        creation_date: product?.creation_date,
        company_name: product?.company_name,
        product_name: product?.product_name,
        name: product?.name,
        status: product?.status,
        last_update: product?.updated_at,
        commission: product?.commission || "",
        commission_date: product?.commission_date,
        commission_expected: product?.commission_expected,
        premium_per_month: product?.premium_per_month || "",
      })),
    });
  }, [data, reset]);

  const { data: partnersData } = usePartners();
  const { mutate: updateProduct } = useUpdateCustomerProduct();
  const { t } = useTranslation();

  const hasPermissionToEditProduct = usePermissions([
    "manage_customers_products_edit",
  ]);
  const hasPermissionToCreateProduct = usePermissions([
    "manage_customers_products_create",
  ]);



  const onSubmit = (formData: any) => {
    const updatedProducts = data?.data?.reduce(
      (acc: any, product: any, index: number) => {
        const productData = formData.products[index];
        if (productData) {
          acc.push({
            product_id: product.id,
            creation_date: productData.creation_date || "",
            company_name: productData.company_name || "",
            product_name: productData.product_name || "",
            name: productData.name || "",
            status: productData.status || "",
            last_update: productData.last_update || "",
            commission: productData.commission || "",
            commission_date: productData.commission_date || "",
            commission_expected: productData.commission_expected || "",
            premium_per_month: productData.premium_per_month || "",
          });
        }
        return acc;
      },
      []
    );

    updateProduct({
      costumer_id: id,
      products: updatedProducts,
      from_user_id: from_user_id
    });
    refetchProducts();
  };

  const formatDate = (dateString: string) => {
    if (!dateString) {
      return t("Not Available");
    }
    const datePart = dateString.split("T")[0];
    const [year, month, day] = datePart.split("-");
    return `${day}/${month}/${year}`;
  };

  const formatCurrency = (value: string) => {
    // Ensure value is a string
    value = value.toString();

    // Remove any non-numeric characters except the decimal point
    const cleanedValue = value.replace(/[^0-9.]/g, "");

    // Split the value into whole and decimal parts
    const [whole, decimal] = cleanedValue.split(".");

    // Format the whole part with commas
    const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Rebuild the formatted value
    const formattedValue =
      decimal !== undefined
        ? `${formattedWhole}.${decimal.slice(0, 2)}`
        : formattedWhole;

    return `CHF ${formattedValue}`;
  };

  const handleInputChange = (
    e: { target: { value: string } },
    index: number,
    setInputValue: (value: any) => null,
    name: string
  ) => {
    const rawValue = e.target.value.replace("CHF ", "").replace(/,/g, ""); // Strip CHF and commas
    const formattedValue = formatCurrency(rawValue);

    // Update state for the specific input
    setInputValue((prevState: any) => ({
      ...prevState,
      [index]: formattedValue,
    }));

    setValue(`products.${index}.${name}`, rawValue); // Set raw value without formatting for backend
  };


  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <NoDataBoundary
        condition={data?.data?.length >= 1}
        fallback={<EmptyEntity name="Products" />}
      >
        <InputsFolder
          title={t("Products")}
          childrenClassName="flex flex-col gap-5"
        >
          <>
            {data?.data?.map?.((product: any, index: number) => {
              return (
                <Accordion
                  key={index}
                  classes={{ wrapper: "bg-white", title: "!w-full" }}
                >
                  <AccordionTitle subTitleClass="w-full">
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center gap-[10px]">
                        <Icon iconType={IconType.KVG_ICON} className="flex-none" />
                        <span className="text-[#282D46] font-inter-medium capitalize max-w-[400px]">
                          {product.name} {product?.product_name} (
                          {product.company_name})
                        </span>
                      </div>
                      <div className="flex items-center gap-5 mr-[10px]">
                        <p className="text-[#979797] text-[14px] font-inter-regular">
                          {t("Created")}: {formatDate(product.creation_date)}
                        </p>{" "}
                        <p
                          className={`font-inter-medium text-[12px] min-w-[101px] capitalize p-2 border-[1px] rounded-[8px] ${product?.status === "Open (Consultant)"
                            ? "border-[#FFC107] text-[#FFC107]"
                            : product?.status === "Open (Office)"
                              ? "border-[#FFC107] text-[#FFC107]"
                              : product?.status === "Submitted"
                                ? "border-[#9F9F9F] text-[#9F9F9F]"
                                : product?.status === "Accepted"
                                  ? "border-[#64C764] text-[#64C764]"
                                  : product?.status === "Provisioned"
                                    ? "border-[#198754] text-[#198754]"
                                    : product?.status === "Rejected"
                                      ? "border-[#DC3545] text-[#DC3545]"
                                      : product?.status === "Retreat"
                                        ? "border-[#9E0707] text-[#9E0707]"
                                        : product?.status === "Canceled"
                                          ? "border-[#E91D1D] text-[#E91D1D]"
                                          : product?.status === "Quality"
                                            ? "border-[#50B8E7] text-[#50B8E7]"
                                            : ""
                            }`}
                        >
                          {t(product?.status) ?? t("Pending")}
                        </p>
                      </div>
                    </div>
                  </AccordionTitle>
                  <AccordionBody>
                    <div className="grid grid-cols-2 w-full gap-5 pb-5">
                      <input
                        type="hidden"
                        {...register(`products.${index}.id`)}
                        defaultValue={product.id}
                      />
                      <Controller
                        control={control}
                        name={`products.${index}.creation_date`}
                        render={({ field: { onChange, value } }) => (
                          <PrimaryDatePicker
                            label={t("Creation Date")}
                            startDate={value}
                            {...register(`products.${index}.creation_date`)}
                            setStartDate={(date: string) => onChange(date)}
                            defaultValue={product.creation_date}
                          />
                        )}
                      />
                      <div className="relative">
                        <Label text={t("Company")} />
                        <Controller
                          name={`products.${index}.company_name`}
                          control={control}
                          render={({ field }) => (
                            <Creatable<any, false>
                              {...field}
                              placeholder={t("Choose company")}
                              options={partnersData?.partners?.map?.(
                                (partner: any) => ({
                                  value: partner.name,
                                  label: partner.name,
                                })
                              )}
                              value={
                                field?.value
                                  ? {
                                    value: field?.value,
                                    label: field.value,
                                  }
                                  : null
                              }
                              className="cursor-pointer font-inter-regular react-select-container"
                              classNamePrefix="react-select"
                              onChange={(newValue: any, actionMeta: any) => {
                                if (
                                  actionMeta.action === "select-option" &&
                                  newValue?.value
                                ) {
                                  field.onChange(newValue.value);
                                }
                              }}
                              styles={customStyles}
                              isValidNewOption={() => false}
                              menuPosition={"fixed"}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                      </div>
                      <Input
                        placeholder={t("Product Name")}
                        {...register(`products.${index}.product_name`)}
                        // error={errors.product_nam as any}
                        name={`products.${index}.product_name`}
                        label={t("Product Name")}
                      />

                      <div className="relative">
                        <Label text={t("Status")} />
                        <Controller
                          name={`products.${index}.status`}
                          control={control}
                          render={({ field }) => (
                            <Creatable<any, false>
                              {...field}
                              placeholder={t("Choose status")}
                              options={
                                product?.status === "Open (Consultant)"
                                  ? [
                                    {
                                      value: "Open (Consultant)",
                                      label: t("Open (Consultant)"),
                                    },
                                    {
                                      value: "Open (Office)",
                                      label: t("Open (Office)"),
                                    },
                                    {
                                      value: "Submitted",
                                      label: t("Submitted"),
                                    },
                                    { value: "Retreat", label: t("Retreat") },
                                  ]
                                  : product?.status === "Open (Office)"
                                    ? [
                                      {
                                        value: "Open (Office)",
                                        label: t("Open (Office)"),
                                      },
                                      {
                                        value: "Open (Consultant)",
                                        label: t("Open (Consultant)"),
                                      },
                                      {
                                        value: "Submitted",
                                        label: t("Submitted"),
                                      },
                                      { value: "Retreat", label: t("Retreat") },
                                    ]
                                    : product?.status === "Submitted"
                                      ? [
                                        {
                                          value: "Submitted",
                                          label: t("Submitted"),
                                        },
                                        {
                                          value: "Accepted",
                                          label: t("Accepted"),
                                        },
                                        {
                                          value: "Rejected",
                                          label: t("Rejected"),
                                        },
                                        { value: "Retreat", label: t("Retreat") },
                                      ]
                                      : product?.status === "Accepted"
                                        ? [
                                          {
                                            value: "Accepted",
                                            label: t("Accepted"),
                                          },
                                          {
                                            value: "Provisioned",
                                            label: t("Provisioned"),
                                          },
                                          { value: "Retreat", label: t("Retreat") },
                                        ]
                                        : product?.status === "Rejected"
                                          ? [
                                            {
                                              value: "Rejected",
                                              label: t("Rejected"),
                                            },
                                          ]
                                          : product?.status === "Retreat"
                                            ? [{ value: "Retreat", label: t("Retreat") }]
                                            : product?.status === "Provisioned"
                                              ? [
                                                {
                                                  value: "Provisioned",
                                                  label: t("Provisioned"),
                                                },
                                                {
                                                  value: "Canceled",
                                                  label: t("Canceled"),
                                                },
                                              ]
                                              : product?.status === "Canceled"
                                                ? [
                                                  {
                                                    value: "Canceled",
                                                    label: t("Canceled"),
                                                  },
                                                ]
                                                : []
                              }
                              value={
                                field?.value
                                  ? {
                                    value: field?.value,
                                    label: t(field.value),
                                  }
                                  : null
                              }
                              className="cursor-pointer font-inter-regular react-select-container"
                              classNamePrefix="react-select"
                              onChange={(newValue: any, actionMeta: any) => {
                                if (
                                  actionMeta.action === "select-option" &&
                                  newValue?.value
                                ) {
                                  field.onChange(newValue.value);
                                }
                              }}
                              styles={customStyles}
                              isValidNewOption={() => false}
                              menuPosition={"fixed"}
                              menuPortalTarget={document.body}
                            />
                          )}
                        />
                      </div>

                      <Input
                        placeholder={t("Premium per month")}
                        error={
                          errors.products &&
                          (
                            errors.products as unknown as FieldErrorsImpl<any>[]
                          )[index]?.premium_per_month
                        }
                        name={`products.${index}.premium_per_month`}
                        label={t("Premium per month")}
                        type="text"
                        classes={{ icon: "right-[40px] bg-white" }}
                        step="0.01"
                        value={
                          premiumPerMonthValues[index] || `CHF ${product.premium_per_month}`
                        }
                        onChange={(e) =>
                          handleInputChange(e, index, setPremiumPerMonthValues as any, 'premium_per_month')
                        }
                      />

                      <Input
                        placeholder={t("Provision expected")}
                        {...register(`products.${index}.commission_expected`)}
                        // error={errors.product_nam as any}
                        name={`products.${index}.commission_expected`}
                        label={t("Provision expected")}
                      />



                      <ConditionalRender
                        condition={watch(`products.${index}.status`) === 'Provisioned' || product?.status === "Provisioned" || product?.status === "Canceled"}
                      >
                        <Input
                          placeholder={t("Total Provision")}
                          error={
                            errors.products &&
                            (
                              errors.products as unknown as FieldErrorsImpl<any>[]
                            )[index]?.commission
                          }
                          name={`products.${index}.commission`}
                          label={t("Total Provision")}
                          type="text"
                          classes={{ icon: "right-[40px] bg-white" }}
                          step="0.01"
                          disabled={product.commission !== null}
                          value={
                            commissionValues[index] ||
                            `CHF ${product.commission}`
                          }
                          onChange={(e) => handleInputChange(e, index, setCommissionValues as any, 'commission')}
                        />
                        <Controller
                          control={control}
                          name={`products.${index}.commission_date`}
                          render={({ field: { onChange, value } }) => (
                            <PrimaryDatePicker
                              label={t("Provision Date")}
                              startDate={value}
                              isDisabled={product.commission_date !== null}
                              {...register(`products.${index}.commission_date`)}
                              setStartDate={(date: string) => onChange(date)}
                            />
                          )}
                        />
                      </ConditionalRender>
                    </div>
                  </AccordionBody>
                </Accordion>
              );
            })}
          </>
        </InputsFolder>
      </NoDataBoundary>
      <div className="flex justify-end gap-[26px] mt-5">
        <ConditionalRender condition={hasPermissionToCreateProduct}>
          <Button
            onClick={() => setFormStep && setFormStep(2)}
            type="button"
            className={`bg-transparent flex items-center gap-2 font-inter-medium text-[14px] text-[--theme]`}
          >
            <Icon iconType={IconType.PLUS_ICON} color={themeColors.primary} />
            {t("Add Product")}
          </Button>
        </ConditionalRender>
        <ConditionalRender condition={hasPermissionToEditProduct}>
          <IconButton type="submit">{t("Save Changes")}</IconButton>
        </ConditionalRender>
      </div>
    </Form>
  );
};

export default UpdateChooseProducts;
