import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen'
import { customStyles } from 'lib/helpers/configs/customStyles'
import { useWidgetData } from 'lib/hooks/queries/Dashboard/useWidgetData'
import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'

interface Props {
    name: string
    api: string
    type: string
}

const TopSourceWidget: FC<Props> = ({ name, api, type }) => {

    const { t } = useTranslation()

    const [Filter, setFilter] = useState('This week')

    const { data, isLoading } = useWidgetData({ endpoint: api, params: { filter_on: Filter }, type: name })


    const Colors = useMemo(() => {
        return [
            "#50B8E7", "#2CC95B", "#884DFF", "#FF8F0D", "#F64E60"
        ]
    }, [])


    if (isLoading) return <LoadingScreen />

    return (
        <div className='w-full h-full bg-white border border-[#F2F4F7] rounded-2xl p-5'>
            <div className='pb-5 flex items-center gap-4 justify-between'>
                <div className='text-primaryTextColor font-inter-bold xl:text-lg 2xl:text-xl'>
                    {t(name)}
                </div>
                <div>
                    <CreatableSelect<any, false>
                        options={[
                            { value: "Today", label: t("Today") },
                            { value: "This week", label: t("This week") },
                            { value: "This month", label: t("This month") },
                        ]}
                        value={{
                            value: Filter,
                            label: t(Filter)
                        }}
                        className="cursor-pointer font-inter-regular react-select-container 3xl:min-w-[150px]"
                        classNamePrefix="react-select"
                        onChange={(newValue: any, actionMeta: any) => {
                            if (
                                actionMeta.action === "select-option" &&
                                newValue?.value
                            ) {
                                setFilter(newValue.value)
                            }
                        }}
                        styles={customStyles}
                        isValidNewOption={() => false}
                        menuPosition={"fixed"}
                        menuPortalTarget={document.body}
                    />
                </div>
            </div>
            <div className='flex gap-4 border-b border-b-[#EDF2F6] pb-4 mb-4 text-secondaryTextColor text-sm font-inter-regular'>
                <div className='basis-4'>#</div>
                <div className='w-14 truncate '>{t('Source')}</div>
                <div className='flex-1'>{type} {t('Percentage')}</div>
                <div className='w-full max-w-[90px]'>{t(type)}</div>
            </div>

            <div className='overflow-y-auto h-[calc(100%-150px)]'>
                {data && (data as any)?.data.map((item: any, index: number) => {
                    return (
                        <div key={index} className='flex items-center gap-4 border-b border-b-[#EDF2F6] pb-4 mb-4 text-primaryTextColor text-sm font-inter-regular'>
                            <div className='basis-4'>01</div>
                            <div className='w-14 truncate '>{item.source_name}</div>
                            <div className='flex-1 text-xs flex items-center gap-2'>
                                <div className='w-full h-1  bg-opacity-10 rounded-full' style={{
                                    backgroundColor: Colors[index % Colors.length] + '10',
                                }}>
                                    <div className='h-full rounded-full' style={{
                                        backgroundColor: Colors[index % Colors.length],
                                        width: item.percentage + '%'
                                    }}></div>
                                </div>
                                <div className='text-primary'>{parseInt(item.percentage) | 0}%</div>
                            </div>
                            <div
                                className='w-full max-w-[90px] h-full text-xs py-1 border rounded-lg flex justify-center items-center'
                                style={{
                                    borderColor: Colors[index % Colors.length],
                                    color: Colors[index % Colors.length],
                                    backgroundColor: Colors[index % Colors.length] + '10'
                                }}
                            >
                                {parseInt(item.count) | 0}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default TopSourceWidget