import React from 'react'

const PlaceholderLayout3 = () => {
    return (
        <div className={`grid  grid-cols-4 aspect-[1/0.8] gap-2`}>
            <div className="col-span-3">
                <div className={`flex flex-wrap h-full gap-2`}>
                    <div className="bg-themeOpacity flex-1 rounded-xl">

                    </div>
                    <div className="bg-themeOpacity flex-1 rounded-xl">

                    </div>
                    <div className="bg-themeOpacity flex-1 rounded-xl">

                    </div>
                    <div className="basis-full bg-themeOpacity rounded-xl">

                    </div>
                    <div className="basis-full bg-themeOpacity rounded-xl">

                    </div>
                </div>
            </div>
            <div className={`col-span-1 flex flex-col gap-2`}>
                <div className="bg-themeOpacity flex-1 rounded-xl">

                </div>

            </div>

        </div>
    )
}

export default PlaceholderLayout3