import Icon from "components/atoms/icon/Icon";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import DashboardBoxTitle from "components/atoms/title/DashboardBoxTitle";
import DashboardBoxLayout from "components/templates/dashboard-box-layout/DashboardBoxLayout";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useTTAppointments } from "lib/hooks/queries/Statistics/TodayTomorrowAppointments/useTTAppointments";
import { FC } from "react";
import { useTranslation } from "react-i18next";

const ConfirmedAppointments: FC = () => {
    const { t } = useTranslation();

    const { data, isLoading } = useTTAppointments()

    if (isLoading) return (
        <div className="h-full relative py-10">
            <LoadingScreen />
        </div>
    )

    return (
        <DashboardBoxLayout className="h-full">
            <div className="flex flex-col sm:flex-row gap-4">
                <div className="flex-1">
                    <div className=" mb-5">
                        <DashboardBoxTitle title={`Confirmed Appointments (today)`} />
                    </div>
                    <div className="border border-border rounded-xl">

                        <div className={`flex gap-3 items-center p-4 
                             ${(data as any)?.todayByUser.length === 0 ? '' : 'border-b border-b-border'}`
                        }>
                            <div className="flex-none ">
                                <div className="bg-themeOpacity rounded h-[40px] w-[40px] grid place-items-center">
                                    <Icon iconType={IconType.APPOINTMENT_CALENDAR_ICON} color='var(--theme)' />
                                </div>
                            </div>
                            <div className='flex-1'>
                                <div className="text-primaryTextColor font-inter-semibold">{t('Total')}</div>
                            </div>
                            <div className="flex-none">
                                <div className="text-sm text-primaryTextColor font-inter-medium">{(data as any)?.today}</div>
                            </div>
                        </div>
                        {(data as any)?.todayByUser.map((user: any, index: number) => {
                            return (
                                <div className={`flex gap-3 items-center p-4 
                                ${index === (data as any)?.todayByUser.length - 1
                                        ? ''
                                        : 'border-b border-b-border'
                                    }`}
                                    key={index} >
                                    <div className="flex-none ">
                                        <div className="bg-themeOpacity rounded h-[40px] w-[40px] grid place-items-center">
                                            <Icon iconType={IconType.CUSTOMERS_ICON} color='var(--theme)' />
                                        </div>
                                    </div>
                                    <div className='flex-1'>
                                        <div className="text-primaryTextColor font-inter-semibold">{user.user_id}</div>

                                    </div>
                                    <div className="flex-none">
                                        <div className="text-sm text-primaryTextColor font-inter-medium">{user.appointment}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="flex-1">
                    <div className=" mb-5">
                        <DashboardBoxTitle title={`Confirmed Appointments (Tomorrow)`} />
                    </div>
                    <div className="border border-border rounded-xl">
                        <div className={`flex gap-3 items-center p-4 
                             ${(data as any)?.tomorrowByUser.length === 0 ? '' : 'border-b border-b-border'}`
                        }>
                            <div className="flex-none ">
                                <div className="bg-themeOpacity rounded h-[40px] w-[40px] grid place-items-center">
                                    <Icon iconType={IconType.APPOINTMENT_CALENDAR_ICON} color='var(--theme)' />
                                </div>
                            </div>
                            <div className='flex-1'>
                                <div className="text-primaryTextColor font-inter-semibold">{t('Total')}</div>
                            </div>
                            <div className="flex-none">
                                <div className="text-sm text-primaryTextColor font-inter-medium">{(data as any)?.tomorrow}</div>
                            </div>
                        </div>
                        {(data as any)?.tomorrowByUser.map((user: any, index: number) => {
                            return (
                                <div className={`flex gap-3 items-center p-4 
                                ${index === (data as any)?.todayByUser.length - 1
                                        ? ''
                                        : 'border-b border-b-border'
                                    }`}
                                    key={index} >
                                    <div className="flex-none ">
                                        <div className="bg-themeOpacity rounded h-[40px] w-[40px] grid place-items-center">
                                            <Icon iconType={IconType.CUSTOMERS_ICON} color='var(--theme)' />
                                        </div>
                                    </div>
                                    <div className='flex-1'>
                                        <div className="text-primaryTextColor font-inter-semibold">{user.user_id}</div>

                                    </div>
                                    <div className="flex-none">
                                        <div className="text-sm text-primaryTextColor font-inter-medium">{user.appointment}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </DashboardBoxLayout>
    );
};

export default ConfirmedAppointments;
