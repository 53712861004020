import IconButton from "components/atoms/button/IconButton";
import React, { Dispatch, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Creatable from "react-select/creatable";
import { customStyles } from "lib/helpers/configs/customStyles";
import Label from "components/atoms/input/input-label/InputLabel";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import { useTranslation } from "react-i18next";

export interface IFilterProps {
    month?: string;
}

export interface IFilterTableDropdownProps {
    setFilterData?: (args: any) => void;
    FilterData?: IFilterProps;
    setIsOpen?: Dispatch<React.SetStateAction<boolean>>;

}

const CommissionDropdownFilter: React.FC<IFilterTableDropdownProps> = ({
    setFilterData,
    FilterData,
    setIsOpen,
}) => {

    const { t } = useTranslation();

    const { control, reset } = useForm({
        defaultValues: {
            month: FilterData?.month || "",
        },
    });
    const [InsideData, setInsideData] = useState<IFilterProps>({
        month: "",
    });

    const [isLoading, setIsLoading] = useState(false);

    const handleButtonClick = () => {
        setIsLoading(true);
        if (setFilterData) {
            setFilterData(InsideData);
        }
        setIsOpen && setIsOpen(false);
        setIsLoading(false);
    };

    const ResetData = () => {
        reset({
            month: "",
        });
        setInsideData({ month: "" });

    };

    const monthOptions = useMemo(() => {
        const data = new Date()

        let month = data.getMonth() + 1
        let year = data.getFullYear()

        let options = []
        for (let i = 0; year >= 2025; i++) {

            if (month === 0) {
                month = 12
                year--
                continue
            }

            const padMonth = month < 10 ? "0" + month : month

            options.push({
                value: year + "-" + padMonth,
                label: year + "-" + padMonth
            })

            month--


        }
        return options

    }, [])

    return (
        <div className="flex flex-col gap-3">
            {isLoading && <LoadingScreen />}
            <div>
                <Label text={t('Month')} />
                <Controller
                    name="month"
                    control={control}
                    render={({ field }) => (
                        <Creatable<any, false>
                            {...field}
                            placeholder={t('Choose month')}
                            options={monthOptions}
                            value={
                                field.value
                                    ? {
                                        value: field.value,
                                        label: field.value,
                                    }
                                    : null
                            }
                            className="cursor-pointer font-inter-regular react-select-container"
                            classNamePrefix="react-select"
                            onChange={(selectedOption) => {
                                const selectedValue = selectedOption
                                    ? selectedOption.value
                                    : null;
                                field.onChange(selectedValue);
                                setInsideData((prevState) => ({
                                    ...prevState,
                                    month: selectedValue,
                                }));
                            }}
                            styles={customStyles}
                            isValidNewOption={() => false}
                            menuPosition={"fixed"}
                            menuPortalTarget={document.body}
                        />
                    )}
                />
            </div>


            <div className="pt-5">
                <div className="flex flex-row justify-end gap-3">
                    <IconButton
                        secondary={true}
                        className="w-[110px]"
                        onClick={ResetData}
                    >
                        {t('Reset')}
                    </IconButton>
                    <IconButton className="w-[110px]" onClick={handleButtonClick}>
                        {t('Confirm')}
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

export default CommissionDropdownFilter;
