import Icon from 'components/atoms/icon/Icon'
import { IconType } from 'lib/helpers/constants/iconTypes'
import React, { FC } from 'react'

interface Props {
    data: any
    setAddWidgetModalData: any
}

const EmptyWidget: FC<Props> = ({ data, setAddWidgetModalData }) => {
    return (
        <div className="border p-4 border-dashed border-theme h-full flex-1 rounded-xl flex flex-col items-center justify-center gap-4 cursor-pointer"
            onClick={() => {
                setAddWidgetModalData({ id: data.id, position: data.position, size: data.size })
            }}
        >
            <div className='h-14 w-14 grid place-items-center rounded-full bg-themeOpacity'>
                <Icon iconType={IconType.PLUS_ICON} color='var(--theme)' className='h-5 w-5' />
            </div>
            <div className='text-primaryTextColor font-inter-medium leading-none'>
                Add New Widget
            </div>
            <div className='text-secondaryTextColor font-inter-medium text-center'>
                Simply create a new widget.
                Just click on the button
            </div>
        </div>
    )
}

export default EmptyWidget