import { FC, useEffect, useState } from "react";
import Form from "../../../atoms/form/Form";
import { Controller, FieldArrayPath, useFieldArray } from "react-hook-form";
import { IconType } from "../../../../lib/helpers/constants/iconTypes";
import CounterControl from "../../../molecules/counter-control-append/CounterControlAppend";
import DefaultContainer from "../../../templates/default-container/DefaultContainer";
import FormStepNavigationButtons from "../../../molecules/form-step-navigation-buttons/FormStepNavigationButtons";
import Paragraph from "../../../atoms/paragraph/Paragraph";
import { useSendEmailVerificationRequest } from "lib/hooks/mutations/SendEmailVerificationRequest/useSendEmailVerificationRequest";
import { colorChooseOptions } from "lib/helpers/constants/colorChooseOptions";
import { useSignupCustomHooks } from "lib/hooks/shared/useSignupCustomHooks";
import ImageDropZone from "components/molecules/image-dropzone/ImageDropZone";
import Icon from "components/atoms/icon/Icon";
import Button from "components/atoms/button/Button";
import AutocompleteInput from "components/atoms/input/autocomplet-input/AutoCompletInput";
import ErrorState from "components/atoms/error-state/ErrorState";
import { businessDirections } from "lib/helpers/constants/businessDirection";
import Creatable from "react-select/creatable";
import { customStyles } from "lib/helpers/configs/customStyles";
import Label from "components/atoms/input/input-label/InputLabel";
import axios from "axios";
import AutoCompletInput from "components/atoms/input/autocomplet-input/AutoCompletInput";

export interface IQuestionsStepProps { }

type Keyword = {
  id: string;
  keyword: string;
};

const QuestionsStep: FC<IQuestionsStepProps> = () => {
  const {
    mutate: sendEmailVerificationRequest,
    isLoading,
    isError
  } = useSendEmailVerificationRequest();
  const [hexCode, setHexCode] = useState(colorChooseOptions[0]?.color);
  const [data, setData] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputKey, setInputKey] = useState(0);

  const {
    currentStep,
    errors,
    formData,
    control,
    getValues,
    updateFormData,
    handleSubmit,
    register,
    setValue,
    store
  } = useSignupCustomHooks({
    initalStep: 2
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "other_location"
  });

  const {
    fields: keywords,
    append: appendKeyword,
    remove: removeKeyword
  } = useFieldArray<Keyword>({
    control,
    name: "keywords" as FieldArrayPath<Keyword>
  });

  const onSubmit = () => {
    const data = getValues();
    delete data.keyword;
    data.theme_color = hexCode || "#50B8E7";
    updateFormData(data);
    sendEmailVerificationRequest({
      email: (formData as { email: string }).email
    });
  };
  const handleKeywordKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e && e.target && (e.target as HTMLInputElement).value !== undefined) {
      appendKeyword({ keyword: (e.target as HTMLInputElement).value });
      setInputKey(inputKey + 1);
    } else {
    }
  };

  const options = businessDirections.map((direction) => ({
    value: direction.value,
    label: direction.label
  }));

  const otherLocationError = errors?.other_location?.message;

  useEffect(() => {
    const getAddresses = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ADDRESS_API}krankenkasse/regions`
        );
        const flatData = response.data.map(
          (item: { plz: any; ort: any; commune: any }) =>
            `${item.plz}, ${item.ort}, ${item.commune ?? ""}`
        );
        setData(flatData);
      } catch (e) {

      }
    };
    getAddresses();
  }, []);

  useEffect(() => {
    if (inputValue) {
      const filtered = data.filter((item) =>
        item.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredData(filtered.slice(0, 100) as any);
    } else {
      setFilteredData([]);
    }
  }, [inputValue, data]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="flex justify-between flex-col h-full"
    >
      <div>
        <h1 className="text-bluebutton uppercase font-bold text-[14px] text-center mt-[40px] mb-[8px]">
          STEP {currentStep}/6
        </h1>
        <span className="text-[#282D46] font-inter-bold text-[25px] flex justify-center">
          Company informations
        </span>
      </div>
      <DefaultContainer className="flex flex-col gap-[15px] 2xl:gap-[24px]">
        <ImageDropZone
          className="mx-auto"
          {...register("company_logo")}
          control={control}
          name="company_logo"
          error={errors?.company_logo}
          defaultValue={store.company_logo ?? ""}
          classes={{ label: "!w-[100px] h-[100px]" }}
        />
        <Paragraph
          paragraph="Upload your company logo"
          className="mx-auto text-[14px] font-inter-semibold !text-[#282D46]"
        />
        <div>
          <Paragraph
            paragraph="Choose your color theme"
            className="mx-auto text-[14px] font-inter-semibold !text-[#282D46]"
          />
          <div className="flex items-center justify-between  ">
            <label
              htmlFor="color"
              className="flex items-center gap-1 mt-0  2xl:mt-5"
            >
              <div
                style={{
                  borderColor: hexCode || "#D0D5DD"
                }}
                className={`${hexCode ? "border-[2px]" : "border-[1px]"
                  }  rounded-full p-[2px] cursor-pointer `}
              >
                <div
                  className={`w-[14px] p-[8px] rounded-full bg-[${hexCode}]`}
                  style={
                    hexCode ? { background: hexCode } : { background: "#000" }
                  }
                ></div>
              </div>
              <input
                type="color"
                id="color"
                onChange={(e) => setHexCode(e.target.value)}
                className={"invisible w-0 h-0"}
              />
              <p className="text-[14px] text-[#667085] font-inter-regular capitalize">
                Choose
              </p>
            </label>
            {colorChooseOptions.map((item, index) => {
              return (
                <div
                  key={`color-options-key-${index}`}
                  className="flex items-center gap-2 mt-0  2xl:mt-5"
                >
                  <div
                    onClick={() => {
                      setHexCode(item.color);
                    }}
                    style={{
                      borderColor:
                        hexCode === item.color ? item.color : "#D0D5DD"
                    }}
                    className={`${hexCode === item.color ? "border-[2px]" : "border-[1px]"
                      }  rounded-full p-[2px] cursor-pointer `}
                  >
                    <p
                      style={{ background: `${item.color}` }}
                      className={`w-[14px] p-[8px] rounded-full`}
                    ></p>
                  </div>
                  <p className="text-[14px] text-[#667085] font-inter-regular capitalize">
                    {item.name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="relative">
          <Label text="Business direction" />
          <Controller
            name="business_direction"
            control={control}
            render={({ field }) => (
              <Creatable<any, false>
                {...field}
                placeholder=""
                options={options}
                value={
                  options.find((option) => option.value === field.value) || options[0]
                }
                defaultValue={options[0]}
                className="cursor-pointer font-inter-regular react-select-container "
                classNamePrefix="react-select"
                onChange={(newValue: any, actionMeta: any) => {
                  if (
                    actionMeta.action === "select-option" &&
                    newValue?.value
                  ) {
                    field.onChange(newValue.value);
                  }
                }}
                styles={customStyles}
                isValidNewOption={() => false}
              />
            )}
          />
          {errors.business_direction?.message && (
            <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
              <>{errors.business_direction?.message}</>
            </div>
          )}
        </div>
        <AutoCompletInput
          key={inputKey}
          name="keyword"
          suggestions={[
            "IT",
            "Software",
            "Hardware",
            "Marketing",
            "Sales",
            "Finance",
            "Accounting",
            "HR",
            "Management",
            "Consulting",
            "Legal",
            "Healthcare",
            "Education",
            "Real Estate",
            "Construction",
            "Manufacturing",
            "Retail",
            "Hospitality",
            "Tourism",
            "Transportation",
            "Logistics",
            "Media",
            "Entertainment",
            "Arts",
            "Sports",
            "Agriculture",
            "Forestry",
            "Fishing",
            "Mining",
            "Utilities",
            "Telecommunications",
            "Insurance",
            "Banking",
            "Investment",
            "Automotive",
            "Aerospace",
            "Defense",
            "Pharmaceutical",
            "Biotechnology",
            "Chemical",
            "Food",
            "Beverage",
            "Tobacco",
            "Textile",
            "Apparel",
            "Footwear",
            "Cosmetics",
            "Jewelry",
            "Furniture",
            "Electronics",
            "Appliances",
            "Renewable Energy",
            "Environment",
            "Waste Management",
            "Recycling",
            "Government",
            "Nonprofit",
            "Other"
          ]}
          placeholder="Keywords"
          onKeyDown={handleKeywordKeyPress}
          error={errors.keyword}
        />
        <ul className="flex flex-wrap gap-2">
          {keywords?.map((keyword, index) => (
            <li
              key={`keyword-key-${index}`}
              className="font-inter-bold pr-[25px] capitalize bg-[#F4F9FD] text-[#7D8592] relative text-center rounded-[8px] text-[14px] w-auto p-1"
            >
              {(keyword as Keyword).keyword}
              <Button
                type="button"
                className="absolute top-[7px] right-[5px]"
                onClick={() => removeKeyword(Number(keyword.id))}
              >
                <Icon
                  iconType={IconType.CLOSE_ICON}
                  className="p-1"
                  color="#7D8592"
                />
              </Button>
            </li>
          ))}
        </ul>
        <div className="relative">
          <CounterControl
            count={fields.length}
            onIncrement={() => append(null)}
            onDecrement={() => remove(fields.length - 1)}
            label="How many branches do you have?"
          />
          {otherLocationError && (
            <div className=" font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
              {otherLocationError as any}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-[15px] 2xl:gap-[24px]">
          {fields.map((field, index) => {
            //@ts-ignore
            const fieldError = (errors?.other_location?.[index] as FieldError)
              ?.location;
            return (
              <AutocompleteInput
                name={`other_location[${index}].location`}
                key={`other_location-key-${field.id}`}
                suggestions={filteredData}
                setValue={(name, value) => {
                  setValue(name, value);
                  setInputValue(value);
                }}
                className="block"
                error={fieldError}
                value={store?.other_location?.[index]?.location ?? null}
                placeholder="Bern, Switzerland 7100"
                icon={IconType.LOCATION_ICON}
                onChange={handleInputChange}
              />
            );
          })}
        </div>
        <ErrorState error={isError}>
          You can send another request after 2 Minutes
        </ErrorState>
      </DefaultContainer>
      <FormStepNavigationButtons isLoading={isLoading} />
    </Form>
  );
};
export default QuestionsStep;
