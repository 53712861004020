import { addLayout } from "api/Dashboard";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export const useAddLayout = () => {
    const queryClient = useQueryClient();

    return useMutation(addLayout, {
        onSuccess: () => {
            toast.success('Added Successfully')
            queryClient.invalidateQueries('dashboardLayout');

        },
        onError: (error: any) => {
            toast.error(error.response.data.error || 'Something went wrong')
        }
    });
};
