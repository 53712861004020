import { apiRequest } from "./Api";

export const getLayouts = () =>
  apiRequest<any, any>("GET", "dashboard/layouts", null, undefined, undefined);

export const addLayout = (data: any) =>
  apiRequest<FormData, any>("POST", `dashboard/layouts/add-layout-to-dashboard`, data);


export const getWidgetData = (endpoint: string, data: any) =>
  apiRequest<any, any>("GET", endpoint, null, undefined, data);

export const getWidgedsBySize = (data: any) =>
  apiRequest<any, any>("GET", 'dashboard/widgets', null, undefined, data);

export const addWidgetToLayout = (data: any) =>
  apiRequest<FormData, any>("POST", `dashboard/widgets/add-to-layout`, data);

export const deleteWidgetFromLayout = (data: any) =>
  apiRequest<FormData, any>("POST", `dashboard/widgets/delete-from-layout`, data);