import Icon from 'components/atoms/icon/Icon'
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen'
import { IconType } from 'lib/helpers/constants/iconTypes'
import { useWidgetData } from 'lib/hooks/queries/Dashboard/useWidgetData'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    name: string
    api: string
    type: string
}

const ReminderWidget: FC<Props> = ({ name, api, type }) => {

    const { t } = useTranslation()

    const { data, isLoading } = useWidgetData({ endpoint: api, params: {}, type: name })

    if (isLoading) return <LoadingScreen />

    return (
        <div className='w-full h-full bg-white border border-[#F2F4F7] rounded-2xl p-5'>
            <div className='pb-5 flex items-center gap-4 justify-between'>
                <div className='text-primaryTextColor font-inter-bold xl:text-lg 2xl:text-xl'>
                    {t(name)}
                </div>
            </div>
            <div className='overflow-y-auto h-[calc(100%-50px)]'>
                <div className='flex flex-col gap-4 px-2 pt-2'>
                    {(data as any)?.data?.map((item: any, index: number) => (

                        <div className='p-3 flex rounded-2xl gap-2 items-center' style={{ boxShadow: '0px 2px 12px 0px #0000001A' }}>
                            <Icon iconType={IconType.PENDING_REMINDER_ICON} className='flex-none' />
                            <div className='space-y-1 flex-1 break-all'>
                                <div className='text-sm text-primaryTextColor font-inter-medium'>
                                    {item.first_name} {item.last_name}
                                </div>
                                <div className='text-xs text-secondaryTextColor'>
                                    {new Date(item.birthdate).toLocaleDateString('de')}
                                </div>
                            </div>
                            <div className='py-1 px-3 bg-[#F45B69] rounded text-xs font-inter-bold text-white'>
                                {item.days_left === 0
                                    ? (t('Today'))
                                    : (item.days_left + ' ' + t('Days Left'))
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ReminderWidget