import React, { InputHTMLAttributes } from "react";
import Icon from "../icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";

export interface RadioButtonProps
  extends InputHTMLAttributes<HTMLInputElement> {
  lid?: string;
  isChecked?: boolean;
  label?: string;
  onChange?: (isChecked: any) => void;
  labelClassName?: string;
  iconWrapperClassName?: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  onChange,
  isChecked,
  label,
  labelClassName,
  iconWrapperClassName,
  ...rest
}) => {
  return (
    <div>
      <label
        htmlFor={id}
        className={`${labelClassName} relative border font-inter-regular text-[14px] leading-[16.9px] ${
          isChecked
            ? ` border-[--theme] text-[--theme] `
            : "border-[#D0D5DD] text-[#282D46]"
        } flex flex-row p-4 rounded-[8px] cursor-pointer`}
      >
        {label}
        <input
          type="radio"
          className={`${rest.className} peer absolute right-[12px] h-5 w-5 cursor-pointer appearance-none rounded-full  border-[3px] border-blue-gray-200 transition-all  checked:border-[--theme]  checked:before:border-[--theme] hover:before:opacity-10`}
          id={id}
          checked={isChecked}
          name={rest?.name || "bordered-radio"}
          onChange={onChange}
        />
        <span
          className={`${iconWrapperClassName} absolute text-white transition-opacity opacity-0 pointer-events-none top-[26px] right-[10px] -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100`}
        >
          <Icon
            iconType={IconType.RADIO_BUTTON_ICON}
            className="h-2.6 w-2.6"
            color={`var(--theme)`}
          />
        </span>
      </label>
    </div>
  );
};

export default RadioButton;
