import Input from 'components/atoms/input/Input'
import Label from 'components/atoms/input/input-label/InputLabel'
import RadioButton from 'components/atoms/radio-button/RadioButton'
import React, { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'


interface Props {
    control: any;
    errors: any;
}
const Step1GenerateToken: FC<Props> = ({ control, errors }) => {

    const { t } = useTranslation()

    return (

        <div className='rounded-[20px] bg-[#F6FBFE] p-4 pb-[30px]'>
            <div className="grid grid-cols-2 gap-6">
                <Controller
                    name='company'
                    rules={{ required: t('This field is required') }}
                    control={control}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label='Company'
                            placeholder={t('Enter company name')}
                            error={errors.name}
                        />
                    )}
                />
                <Controller
                    name='name'
                    rules={{ required: t('This field is required') }}
                    control={control}
                    render={({ field }) => (
                        <Input
                            {...field}
                            label='API Token Name'
                            placeholder={t('API Token Name')}
                            error={errors.name}
                        />
                    )}
                />
                <div>
                    <Label>
                        Preferred Module
                    </Label>
                    <div className='grid grid-cols-2 gap-3'>
                        <Controller
                            name='module'
                            control={control}
                            render={({ field }) => (
                                <RadioButton
                                    label={t('Appointment')}
                                    isChecked={field.value === 'Appointment'}
                                    value={'Appointment'}
                                    onChange={() => field.onChange('Appointment')}
                                    labelClassName="h-[44px] items-center flex bg-white"
                                    iconWrapperClassName="!top-[21px] !right-[6px]"
                                    className="right-[8px]"
                                />
                            )}
                        />

                        <Controller
                            name='module'
                            control={control}
                            render={({ field }) => (
                                <RadioButton
                                    label={t('Lead')}
                                    isChecked={field.value === 'Lead'}
                                    value={'Lead'}
                                    onChange={() => field.onChange('Lead')}
                                    labelClassName="h-[44px] items-center flex bg-white"
                                    iconWrapperClassName="!top-[21px] !right-[6px]"
                                    className="right-[8px]"
                                />
                            )}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Step1GenerateToken