import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getExternalSources } from "api/ExternalSource";


interface FiltersProps {
    search?: string
}

export const useExternalSources = (
    filters?: FiltersProps
) => {


    const { data, isLoading } = useQuery<Array<any>>(
        ['external-sources', filters?.search],
        () => {
            return (
                getExternalSources({
                    search: filters?.search
                })
            )
        }, {
        onError() {
            toast.error(`Something went wrong. Please try again later.`);
        },
    }
    );

    return {
        data,
        isLoading,
    };
};
