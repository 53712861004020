import Label from 'components/atoms/input/input-label/InputLabel';
import { customStyles } from 'lib/helpers/configs/customStyles';
import React, { FC } from 'react'
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';

interface Props {
    control: any;
    errors: any;
}

const Step1SelectProduct: FC<Props> = ({ control, errors }) => {

    const { t } = useTranslation()

    return (
        <div className='rounded-[20px] bg-[#F6FBFE] p-4'>
            <div className="grid grid-cols-1 gap-4">
                <div className="relative">
                    <Label text={t('Product')} />
                    <Controller
                        name="base_product"
                        rules={{ required: t('This field is required') }}

                        control={control}
                        render={({ field }) => (
                            <CreatableSelect<any, false>
                                {...field}
                                placeholder={t('Choose product')}
                                options={[
                                    { value: "KVG", label: t("KVG") },
                                    { value: "VVG", label: t("VVG") },
                                    { value: "Life", label: t("Life") },
                                    { value: "Lawprotection", label: t("Lawprotection") },
                                    { value: "Business", label: t("Business") },
                                    { value: "Auto", label: t("Auto") },
                                ]}
                                value={
                                    field?.value
                                        ? {
                                            value: field?.value,
                                            label: t(field?.value)
                                        }
                                        : null
                                }
                                className="cursor-pointer font-inter-regular react-select-container"
                                classNamePrefix="react-select"
                                onChange={(newValue: any, actionMeta: any) => {
                                    if (
                                        actionMeta.action === "select-option" &&
                                        newValue?.value
                                    ) {
                                        field.onChange(newValue.value);
                                    }
                                }}
                                styles={customStyles}
                                isValidNewOption={() => false}
                                menuPosition={"fixed"}
                                menuPortalTarget={document.body}
                            />
                        )}
                    />
                    {errors.base_product?.message && (
                        <>
                            <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px]  left-[2px]">
                                <>{errors.base_product?.message}</>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Step1SelectProduct