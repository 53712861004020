import { memo } from "react";

interface Props {
    className?: string;
    color?: string;
}

function PendingReminderIcon({ className, color }: Props) {
    return (
        <svg className={className} width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_297_5541)">
                <rect x="4" y="2" width="44" height="44" rx="22" fill="white" shapeRendering="crispEdges" />
                <path d="M33.9066 28.8703C33.5603 28.6127 33.071 28.6847 32.8134 29.0308C32.6718 29.2212 32.5202 29.4079 32.3629 29.5859C32.0772 29.9091 32.1075 30.4029 32.4308 30.6886C32.5794 30.82 32.764 30.8846 32.9479 30.8846C33.164 30.8846 33.3792 30.7954 33.5335 30.6208C33.7198 30.41 33.8993 30.1889 34.0671 29.9634C34.3246 29.6172 34.2528 29.1278 33.9066 28.8703Z" fill={color || "#15C0E6"} />
                <path d="M35.2134 25.2065C34.7921 25.1148 34.3757 25.3821 34.2839 25.8037C34.2334 26.0356 34.1724 26.268 34.1024 26.4944C33.975 26.9066 34.206 27.3441 34.6182 27.4714C34.695 27.4951 34.7727 27.5064 34.8491 27.5064C35.1827 27.5064 35.4916 27.291 35.5953 26.9556C35.6783 26.6869 35.7507 26.4112 35.8107 26.136C35.9024 25.7145 35.635 25.2983 35.2134 25.2065Z" fill={color || "#15C0E6"} />
                <path d="M30.146 31.3727C29.9386 31.4885 29.7238 31.5967 29.5077 31.6943C29.1145 31.8719 28.9397 32.3346 29.1173 32.7278C29.2477 33.0167 29.5321 33.1877 29.8297 33.1877C29.9372 33.1877 30.0465 33.1654 30.1508 33.1183C30.4072 33.0025 30.6618 32.8742 30.9077 32.7369C31.2845 32.5266 31.4194 32.0507 31.209 31.6739C30.9987 31.2973 30.5228 31.1624 30.146 31.3727Z" fill={color || "#15C0E6"} />
                <path d="M25.2186 17.75V23.6764L22.3538 26.5412C22.0487 26.8463 22.0487 27.3409 22.3538 27.646C22.5064 27.7986 22.7063 27.8748 22.9063 27.8748C23.1062 27.8748 23.3062 27.7986 23.4587 27.646L26.5523 24.5524C26.6988 24.4059 26.7811 24.2072 26.7811 24V17.75C26.7811 17.3185 26.4313 16.9688 25.9999 16.9688C25.5684 16.9688 25.2186 17.3185 25.2186 17.75Z" fill={color || "#15C0E6"} />
                <path d="M35.2188 15.6797C34.7873 15.6797 34.4375 16.0295 34.4375 16.4609V18.6303C32.6191 15.7784 29.4434 14 26 14C23.3289 14 20.8177 15.0402 18.9289 16.9289C17.0402 18.8177 16 21.3289 16 24C16 26.6711 17.0402 29.1823 18.9289 31.0711C20.8177 32.9598 23.3289 34 26 34C26.0066 34 26.013 33.9992 26.0195 33.999C26.0261 33.9992 26.0325 34 26.0391 34C26.3206 34 26.605 33.9881 26.8843 33.9648C27.3142 33.9287 27.6336 33.551 27.5976 33.1211C27.5616 32.6911 27.1843 32.3716 26.7539 32.4077C26.5178 32.4275 26.2773 32.4375 26.0391 32.4375C26.0325 32.4375 26.0261 32.4383 26.0195 32.4385C26.013 32.4383 26.0066 32.4375 26 32.4375C21.3475 32.4375 17.5625 28.6525 17.5625 24C17.5625 19.3475 21.3475 15.5625 26 15.5625C28.9984 15.5625 31.7561 17.1607 33.2631 19.7031H31.115C30.6835 19.7031 30.3337 20.0529 30.3337 20.4844C30.3337 20.9159 30.6835 21.2656 31.115 21.2656H33.5C33.9758 21.2656 34.4209 21.1319 34.7999 20.9003C34.8243 20.8864 34.8477 20.8714 34.87 20.8553C35.55 20.408 36 19.6386 36 18.7656V16.4609C36 16.0295 35.6502 15.6797 35.2188 15.6797Z" fill={color || "#15C0E6"} />
            </g>
            <defs>
                <filter id="filter0_d_297_5541" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.575 0 0 0 0 0.563021 0 0 0 0 0.563021 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_297_5541" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_297_5541" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}
export default memo(PendingReminderIcon);
