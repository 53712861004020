import Headline from "components/atoms/headline/Headline";
import TabsSwitch from "components/molecules/tabs/TabsSwitch";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SalaryDetails from "./SalaryDetails";
import CreatableSelect from "react-select/creatable";
import { customStyles } from "lib/helpers/configs/customStyles";
import SalarySlip from "./SalarySlip";
import { useParams } from "react-router-dom";
import { useSalary } from "lib/hooks/queries/Employee/useSalary";

const Salary = () => {

    const { t } = useTranslation();
    const [selectedTabSwitch, setSelectedTabSwitch] = useState<number>(0);

    const [Month, setMonth] = useState()

    const { id } = useParams()

    const { data: salaryData, isLoading: salaryLoading } = useSalary({ id, month: Month });

    const monthOptions = useMemo(() => {
        const data = new Date()

        let month = data.getMonth() + 1
        let year = data.getFullYear()

        let options = []
        for (let i = 0; year >= 2025; i++) {

            if (month === 0) {
                month = 12
                year--
                continue
            }

            const padMonth = month < 10 ? "0" + month : month

            options.push({
                value: year + "-" + padMonth,
                label: year + "-" + padMonth
            })

            month--


        }
        return options

    }, [])

    return (
        <div>
            <Headline title={t("Salary")} className="!mt-0 !mb-[15px]" />
            <TabsSwitch
                ActiveTab={selectedTabSwitch}
                setSelectedTab={(id) => setSelectedTabSwitch(id)}
                classes={{
                    iconAndTextWrapperClass:
                        "text-[13px] lg:text-[14px] xl:text-[16px] flex items-center",
                    text: "!pl-1 !pb-[8px]",
                    unActiveTabClass: "!pl-1 !pb-[8px] w-full",
                    tabsClass: 'gap-4'
                }}
                tabs={[
                    {
                        id: 0,
                        name: "Salary Details",
                    },

                ]}
            >
                <div className="flex gap-4 items-center">
                    {(!salaryLoading && salaryData?.fixed_salary !== undefined) && (

                        <SalarySlip data={salaryData} />
                    )}

                    <CreatableSelect<any, false>
                        placeholder={t('Choose month')}
                        options={monthOptions}
                        value={
                            Month
                                ? {
                                    value: Month,
                                    label: Month,
                                }
                                : null
                        }
                        className="cursor-pointer font-inter-regular react-select-container"
                        classNamePrefix="react-select"
                        onChange={(selectedOption) => {
                            const selectedValue = selectedOption
                                ? selectedOption.value
                                : null;
                            setMonth(selectedValue);
                        }}
                        styles={customStyles}
                        isValidNewOption={() => false}
                        menuPosition={"fixed"}
                        menuPortalTarget={document.body}
                    />
                </div>
            </TabsSwitch>

            <SalaryDetails salaryData={salaryData} salaryLoading={salaryLoading} />,
        </div>
    );
};

export default Salary;
