import ConditionalRender from 'components/atoms/conditional-render/ConditionalRender';
import FilterBorderedWrapper from 'components/atoms/filter-bordered-wrapper/FilterBorderedWrapper';
import Icon from 'components/atoms/icon/Icon';
import DashboardBoxTitle from 'components/atoms/title/DashboardBoxTitle'
import CounterToolTable from 'components/organisms/statistics-counter-tool/counter-tool-table/CounterToolTable';
import { IconType } from 'lib/helpers/constants/iconTypes';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import DynamicDropdown from '../dynamic-dropdown/DynamicDropdown';
import Filter from 'components/organisms/statistics/Dropdowns/Filter';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { customStylesLightBorder } from 'lib/helpers/configs/customStyles';
import { useCounterTool } from 'lib/hooks/queries/Statistics/CounterTool/useCounterTool';
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen';
import { usePartners } from 'lib/hooks/queries/Partners/usePartnersForFilter';

const CounterTool = () => {

    const { t } = useTranslation()
    const [Filters, setFilters] = useState({
        insurance_company: [] as any,
        source: 'Lead',
        filter_on: 'Today',
        from_date_of_creation: undefined,
        to_date_of_creation: undefined,
    })

    const { data: partners } = usePartners()


    const { data, isLoading } = useCounterTool(Filters)

    const TableHeaders = [

        {
            id: 1,
            Header: `${t('Company')}`,
            accessor: (row: any) =>
                row?.company
                    ? `${row.company}`
                    : `${t('Not available')}`,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.company
                        ? `${row.original.company}`
                        : `${t('Not available')}`}
                </div>
            ),
            isChecked: true
        },

        {
            id: 2,
            Header: `${t('Total')}`,
            accessor: (row: any) =>
                row?.total,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.total}
                </div>
            ),
            isChecked: true
        },
        {
            id: 3,
            Header: `${t('Open')}`,
            accessor: (row: any) =>
                row.open,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.open}
                </div>
            ),
            isChecked: true
        },
        {
            id: 4,
            Header: `${t('KVG+VVG')}`,
            accessor: (row: any) =>
                row.kvg_vvg,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.kvg_vvg}
                </div>
            ),
            isChecked: true
        },
        {
            id: 5,
            Header: `${t('VVG')}`,
            accessor: (row: any) =>
                row.vvg,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.vvg}
                </div>
            ),
            isChecked: true
        },
        {
            id: 6,
            Header: `${t('KVG')}`,
            accessor: (row: any) =>
                row.kvg,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.kvg}
                </div>
            ),
            isChecked: true
        },

        {
            id: 7,
            Header: `${t('Legal protection')}`,
            accessor: (row: any) =>
                row.law_protection,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.law_protection}
                </div>
            ),
            isChecked: true
        },
        {
            id: 8,
            Header: `${t('Auto')}`,
            accessor: (row: any) =>
                row.auto,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.auto}
                </div>
            ),
            isChecked: true
        },
        {
            id: 9,
            Header: `${t('Life')}`,
            accessor: (row: any) =>
                row.life,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.life}
                </div>
            ),
            isChecked: true
        },
        {
            id: 10,
            Header: `${t('Business')}`,
            accessor: (row: any) =>
                row.bussiness,
            Cell: ({ row }: any) => (
                <div className="cursor-pointer">
                    {row.original?.bussiness}
                </div>
            ),
            isChecked: true
        },

    ];


    const [filterTableOpen, setFilterTableOpen] = useState<boolean>(false);

    return (
        <div className='border border-border rounded-[20px] bg-white '>
            <div className='rounded-t-[19px] bg-themeOpacity p-5 flex items-center gap-3'>
                <DashboardBoxTitle title='Customer Counter Tool' className='flex-1' />
                <div className="flex-none">

                    <CreatableSelect<any, false>
                        options={[
                            { value: t('Lead'), label: 'Lead' },
                            { value: t('Appointment'), label: 'Appointment' },
                        ]}
                        value={{
                            value: Filters.source,
                            label: Filters.source,
                        }}
                        className="cursor-pointer font-inter-regular react-select-container min-w-[170px]"
                        classNamePrefix="react-select"
                        onChange={(newValue: any) => {

                            setFilters((prevFilters) => ({
                                ...prevFilters,
                                source: newValue.value,
                            }));
                        }}
                        styles={customStylesLightBorder}
                        isValidNewOption={() => false}
                        menuPosition={"fixed"}
                        menuPortalTarget={document.body}
                    />
                </div>
                <div className="flex-none max-w-xs">
                    {console.log(partners?.partners) as any }
                    <Select
                        isMulti
                        options={

                            partners?.partners.map((partner: any) => ({ value: partner.name, label: partner.name }))
                        }
                        value={Filters.insurance_company.map((company: any) => ({ value: company, label: company }))}
                        placeholder={t('Choose Company')}
                        classNamePrefix="react-select-multipleSecondStyle"
                        onChange={(newValue: any) => {
                            setFilters((prevFilters) => ({
                                ...prevFilters,
                                insurance_company: newValue.map((company: any) => company.value),
                            }));
                        }}
                    />
                </div>
                <div className="flex-none">
                    <div className={`${filterTableOpen && "relative"}`}>
                        <FilterBorderedWrapper
                            className="h-[42px] w-[42px] 2xl:h-[47px] 2xl:w-[47px] cursor-pointer bg-white"
                            onClick={() => {
                                setFilterTableOpen(!filterTableOpen);
                            }}
                        >
                            <Icon iconType={IconType.FILTER_ICON} />
                        </FilterBorderedWrapper>
                        <ConditionalRender condition={filterTableOpen}>
                            <DynamicDropdown
                                setIsOpen={setFilterTableOpen}
                                showCloseButton={true}
                                title={t('Filter')}
                                className="sm:!w-[350px]"
                            >
                                <Filter
                                    setIsOpen={setFilterTableOpen}
                                    setFilterData={setFilters}
                                    FilterData={Filters}
                                />
                            </DynamicDropdown>
                        </ConditionalRender>
                    </div>
                </div>
            </div>
            <div className='rounded-b-[20px] p-5 '>
                {isLoading ? (
                    <div className='py-10 relative'>
                        <LoadingScreen />
                    </div>
                ) : (
                    <CounterToolTable
                        data={data}
                        dataCols={TableHeaders}
                    />
                )}

            </div>
        </div>
    )
}

export default CounterTool