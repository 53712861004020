import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getLayouts } from "api/Dashboard";



export const useLayouts = () => {

    const { data, isLoading } = useQuery<Array<any>>(
        ['dashboardLayout'],
        () => {
            return getLayouts();
        }, {
        onError(error: any) {
            toast.error(error?.response?.data?.message || 'Something went wrong. Please try again later.');
        },
    }
    );

    return {
        data,
        isLoading,
    };
};
