import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const commissionsTableHeaders = [
    {
        Header: "Commission Month",
        accessor: (row: any) =>
            row?.commission_month ? row?.commission_month.split('-')[1] + '.' + row?.commission_month.split('-')[0] : "Not available",
        id: 1,
        isChecked: true,
    },
    {
        Header: "Canceled Date",
        accessor: (row: any) =>
            row?.product?.canceled_commission_date ? row?.canceled_month.split('-')[1] + '.' + row?.canceled_month.split('-')[0] : "Not canceled",
        id: 2,
        isChecked: true,
    },
    {
        Header: "Customer Name",
        accessor: (row: any) =>
            row?.product?.costumer?.first_name + ' ' + row?.product?.costumer?.last_name,
        id: 3,
        isChecked: true,
    },
    {
        Header: "Company",
        accessor: (row: any) =>
            row?.product?.company_name,
        id: 4,
        isChecked: true,
    },
    {
        Header: "Product",
        accessor: (row: any) =>
            `${row?.product?.name} (${row?.product?.product_name})`,
        id: 5,
        isChecked: true,
    },
    {
        Header: "Amount",
        accessor: (row: any) =>
            `${row?.worker_commission} CHF`,
        id: 6,
        isChecked: true,
    },
    {
        id: 7,
        Header: `Status`,
        accessor: (row: any) => row?.product?.status || `Not available`,
        Cell: ({ row, value }: any) => (
            <div className="cursor-pointer">
                <Status value={value} />
            </div>
        ),
        isChecked: true
    },
];


const Status = ({ value }: any) => {
    const [Color, setColor] = useState<string>("");
    const { t } = useTranslation();

    React.useEffect(() => {
        switch (value) {
            case t('Canceled'):
                setColor("#DC4D4D");
                break;
            case t('Provisioned'):
                setColor("#2DE28B");
                break;
        }

    }, [value, t]);

    return (
        <span
            className={`text-[12px] bg-opacity-10 font-inter-light px-[8px] py-[3px] rounded-[4px] whitespace-nowrap`}
            style={{ background: Color + "1A", color: Color }}
        >
            {value}
        </span>
    );
};

