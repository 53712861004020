export const unassignedAppointments = [
    {
        Header: "Date",
        accessor: (row: any) =>
            new Date(row.date).toLocaleDateString('de') || "Not available",
        id: 1,
        isChecked: true,
    },
    {
        Header: "Time",
        accessor: (row: any) =>
            row?.time || "Not available",
        id: 2,
        isChecked: true,
    },
    {
        id: 3,
        Header: "Name",
        accessor: (row: any) =>
            row.info.first_name + ' ' + row.info.last_name,
        isChecked: true,
    },
    {
        id: 4,
        Header: "Phone",
        accessor: (row: any) =>
            row.info.phone_number,
        isChecked: true,
    },
    {
        id: 5,
        Header: "City",
        accessor: (row: any) =>
            row.info.city || "Not available",
        isChecked: true,
    },
    {
        id: 6,
        Header: "Address",
        accessor: (row: any) =>
            row.info.address || "Not available",
        isChecked: true,
    }
]