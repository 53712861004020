import React from 'react'

const PlaceholderLayout5 = () => {
    return (
        <div className={`grid  grid-cols-4  aspect-[1/0.8] gap-2`}>
            <div className="col-span-1 row-span-1 bg-themeOpacity flex-1 rounded-xl">
                
            </div>
            <div className="col-span-1 row-span-1 bg-themeOpacity flex-1 rounded-xl">
                
            </div>
            <div className="col-span-1 row-span-1 bg-themeOpacity flex-1 rounded-xl">
                
            </div>
            <div className="col-span-1 row-span-1 bg-themeOpacity flex-1 rounded-xl">
                
            </div>
            <div className="col-span-3 row-span-1 bg-themeOpacity flex-1 rounded-xl">
                
            </div>
            <div className="col-span-1 row-span-2 bg-themeOpacity flex-1 rounded-xl">
                
            </div>
            <div className="col-span-3 row-span-1 bg-themeOpacity flex-1 rounded-xl">
                
            </div>

        </div>
    )
}

export default PlaceholderLayout5