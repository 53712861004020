import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import { FeedbackStatus } from "../columns/leadsTableHeaders";


export const lastTenLeads = [

    {
        Header: "Feedback Date",
        accessor: (row: any) =>
            new Date(row?.feedback_status_updated_at).toLocaleDateString('de') || "Not available",
        id: 1,
        isChecked: true,
    },
    {
        Header: "Name",
        accessor: (row: any) =>
            row.info.first_name + ' ' + row.info.last_name,
        id: 3,
        isChecked: true,
    },
    {
        Header: "Birthdate",
        accessor: (row: any) =>
            row.info.birthdate,
        id: 2,
        isChecked: true,
    },
    {
        Header: "Address",
        accessor: (row: any) =>
            row.info.address,
        id: 6,
        isChecked: true,
    },
    {
        id: 4,
        Header: "Source",
        accessor: (row: any) =>
            row?.source?.name || row?.source?.logo ? (
                <div className="flex flex-row items-center gap-2 " >
                    <ConditionalRender condition={row.source?.logo?.length > 0}>
                        <img
                            loading="lazy"
                            className="object-fit rounded-full w-[30px] h-[30px]"
                            src={`https://devmainhub.insusales.com/api/leads/sources/get-logo/${row.source.logo}`}
                            alt="Logo"
                        />
                    </ConditionalRender>
                    <div className="" > {row.source.name} </div>
                </div>
            ) : (
                "Not available"
            ),
        isChecked: true,
    },
    {
        Header: `Status`,
        accessor: (row: any) => row?.feedback_status || `Not available`,
        Cell: ({ row, value }: any) => (
            <div className="cursor-pointer">
                <FeedbackStatus value={value} />
            </div>
        ),
        id: 5,
        isChecked: true
    },
]
