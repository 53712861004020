import React, { useLayoutEffect, useRef, useState } from "react";
import IconButton from "components/atoms/button/IconButton";
import Label from "components/atoms/input/input-label/InputLabel";
import { FC } from "react";
import CloseXsmIcon from "components/atoms/icon/icon-type/CloseXsmIcon";
import { useUpdateSourceAccess } from "lib/hooks/mutations/Sources/useUpdateSourceAccess";
import { useUsersByPermission } from "lib/hooks/mutations/UsersByPermission/useUsersByPermission";
import { useTranslation } from "react-i18next";

export interface IAccessSourceProps<T> {
  onConfirm: () => void;
  source: T;
  themeColors: {
    primary: string;
  };
}

const AccessSource: FC<IAccessSourceProps<any>> = ({
  onConfirm,
  source,
  themeColors,
}) => {
  const { mutateAsync: getUsersByPermission } = useUsersByPermission();

  const [FetchedUsers, setFetchedUsers] = useState<Array<any>>([]);
  useLayoutEffect(() => {
    getUsersByPermission({
      permissions: ['lead_view_view_own'],
    }).then((res) => {
      setFetchedUsers(res.users);
    }).catch(() => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const inputRef = useRef<any>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState<any[]>(
    source.access_users ? source.access_users : []
  );

  const filterData = (input: string) => {
    // filter already selected users FetchedUsers
    const selectedUsersIds = new Set(selectedData.map((item) => item.id));
    const NotSelectedUser = FetchedUsers.filter(
      (item) => !selectedUsersIds.has(item.id)
    );

    const filtered = NotSelectedUser.filter((item: any) => {
      // Check if the user's name or last_name contains the input and if the user's id is not in the selectedNames set
      return (
        item.name.toLowerCase().includes(input.toLowerCase()) ||
        (item.last_name &&
          item.last_name.toLowerCase().includes(input.toLowerCase()))
      );
    });

    // Make sure this function or state setter is correctly defined to update your state with the filtered data
    setFilteredData(filtered);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    if (value === "") {
      setFilteredData([]);
    } else {
      filterData(value);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && filteredData.length > 0) {
      const topName = `${filteredData[0].name} ${
        filteredData[0].last_name ? filteredData[0].last_name : ""
      }`;
      handleSelect(topName, filteredData[0].id);
    }
  };

  const handleSelect = (name: string, id: string) => {
    if (!selectedData.includes(name)) {
      setSelectedData([...selectedData, { name, id }]);
      setInputValue("");
      setFilteredData([]);
    }
  };

  const handleRemove = (id: string) => {
    const updatedSelectedData = selectedData.filter((item) => item.id !== id);

    setSelectedData(updatedSelectedData);
    // Re-filter the data to include this now available option
    filterData(inputValue);
  };

  const [IsFoucused, setIsFoucused] = useState(false);

  const { mutate: updateSourceAccess } = useUpdateSourceAccess();

  const HandleSubmit = () => {
    var output = [];
    for (var i = 0; i < selectedData.length; ++i) {
      output.push(selectedData[i]["id"]);
    }

    let data = { accessbilitys: output, source_id: source.id };

    updateSourceAccess(data);
    onConfirm();
  };
  const { t } = useTranslation()

  const Reset = () => {
    setSelectedData(source.access_users ? source.access_users : []);
  };

  return (
    <div className="mb-0 flex flex-col gap-5">
      <div>
        <div>
          <Label>{t('Who can access')}?</Label>
        </div>
        <div className="flex flex-col p-[7px] lg:p-[10px] border rounded-[10px]">
          <div className="placeholder:font-inter-medium text-[16px] text-primaryTextColor placeholder:text-[#667085] leading-[20px]">
            <input
              className="outline-none font-inter-normal w-full"
              placeholder={t('Enter customer name')}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onFocus={() => {
                setIsFoucused(true);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setIsFoucused(false);
                }, 100);
              }}
              ref={inputRef}
            />
          </div>

          {IsFoucused &&
            inputRef.current.value !== "" &&
            filteredData.map((item) => (
              <div
                className="py-1 mt-2 font-inter-medium text-[16px] text-[#282D46] leading-[24px] cursor-pointer"
                key={item.id}
                onClick={() =>
                  handleSelect(
                    `${item.name} ${item.last_name ? item.last_name : ""}`,
                    item.id
                  )
                }
              >
                {`${item.name} ${item.last_name ? item.last_name : ""}`}
              </div>
            ))}
        </div>
        <div className="flex flex-row gap-3 py-4 flex-wrap">
          {selectedData.map((data, index) => (
            <div
              className="flex flex-row items-center border border-[#F4F9FD] w-fit py-1 px-2 bg-[#F4F9FD] rounded-[20px] font-inter-medium text-[14px] text-[#282D46] leading-[22px]"
              key={index}
            >
              <div>
                {data.name} {data.last_name ? data.last_name : ""}
              </div>
              <div
                className="pl-2 cursor-pointer"
                onClick={() => {
                  handleRemove(data.id);
                  setIsFoucused(false);
                }}
              >
                <CloseXsmIcon />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex gap-5 justify-end">
        <div>
          <IconButton
            secondary={true}
            type="button"
            onClick={Reset}
            className={`!text-[${themeColors.primary}]`}
          >
            {t('Reset')}
          </IconButton>
        </div>
        <div>
          <IconButton
            type="button"
            onClick={HandleSubmit}
            className={`bg-[${themeColors.primary}] border-[${themeColors.primary}]`}
          >
            {t('Confirm')}
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default AccessSource;
