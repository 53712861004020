import { useQuery } from "react-query"
import { getPartnerProducts } from "api/Partners";


export const usePartnerProducts = (params: string) => {
    return useQuery<Array<any> | any>(
        'partnersProducts',
        () => getPartnerProducts(params),
        {
            onError(error: any) {
                console.error(error)
            }
        }
    )
}