import AddNewIcon from "components/atoms/icon/icon-type/AddNewIcon";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface INewCardProps {
    title?: string;
    desc?: string;
    onClick?: () => void;
}

const NewCard: FC<INewCardProps> = ({
    title,
    desc,
    onClick
}) => {
    const { t } = useTranslation()
    return (
        <div className="w-full flex flex-col justify-center items-center max-w-screen-sm border border-dashed border-[#50B8E7] rounded-2xl p-3 cursor-pointer" onClick={onClick}>
            <div className="p-2">
                <AddNewIcon />
            </div>
            <div className="text-center text-sm/[16px] font-inter-medium p-2 leading-[19.2px] text-primaryTextColor">
                {t(title || '')}
            </div>
            <div className="w-full max-w-[220px] text-center text-sm/[12px] leading-[16.8px] p-2 font-inter-regular text-secondaryTextColor">
                {t(desc || '')}
            </div>
        </div>
    );
};

export default NewCard;
