import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getWidgetData } from "api/Dashboard";



interface WidgetDataProps {
    endpoint: string;
    params: any;
    type?: string;
}

export const useWidgetData = ({ endpoint, params, type }: WidgetDataProps) => {
    
    const { data, isLoading } = useQuery<Array<any>>(
        ['widgetData', type, params],
        () => {
            return getWidgetData(endpoint, params);
        }, {
        onError(error: any) {
            toast.error(error?.response?.data?.message || 'Something went wrong. Please try again later.');
        },
    }
    );

    return {
        data,
        isLoading,
    };
};
