import IconButton from 'components/atoms/button/IconButton'
import withModal from 'lib/helpers/hoc/withModal'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAddLayout } from 'lib/hooks/mutations/Dashboard/useAddLayout'
import PlaceholderLayout1 from '../placeholder-layouts/PlaceholderLayout1'
import PlaceholderLayout2 from '../placeholder-layouts/PlaceholderLayout2'
import PlaceholderLayout3 from '../placeholder-layouts/PlaceholderLayout3'
import PlaceholderLayout5 from '../placeholder-layouts/PlaceholderLayout5'
import ConfirmModal from '../confirm-modal/ConfirmModal'

interface Props {
    onConfirm: () => void
    activeLayout?: string
}

const EditLayout: FC<Props> = ({ onConfirm, activeLayout }) => {

    const { t } = useTranslation()

    const [ActiveLayout, setActiveLayout] = useState(activeLayout?.split(' ')[1] || '1')

    const { mutateAsync } = useAddLayout()

    const [IsConfirmOpen, setIsConfirmOpen] = useState(false)

    const ChangeLayout = () => {

        mutateAsync({ layout_id: ActiveLayout }).then(() => {
            onConfirm()
        })

    }

    return (
        <>
            <div className='p-6 border border-border rounded-2xl'>
                <div className="pb-5 flex items-center">
                    <div className="flex-none">

                    </div>
                    <div className="flex-1">
                        <span className='text-primaryTextColor font-inter-semibold'>{t('Dashboard Layout')}</span>
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-6">
                    <div
                        className={`shadow-lg rounded-xl p-4 border ${ActiveLayout === '1' ? 'border-theme' : 'border-[#F2F4F7] cursor-pointer'} `}
                        onClick={() => setActiveLayout('1')} >
                        <div className='pb-3 font-inter-bold text-primaryTextColor'>
                            <span>Layout 1</span>
                        </div>
                        <PlaceholderLayout1 />
                    </div>
                    <div className={`shadow-lg rounded-xl p-4 border ${ActiveLayout === '2' ? 'border-theme' : 'border-[#F2F4F7] cursor-pointer'}`}
                        onClick={() => setActiveLayout('2')}   >
                        <div className='pb-3 font-inter-bold text-primaryTextColor'>
                            <span>Layout 2</span>
                        </div>
                        <PlaceholderLayout2 />
                    </div>
                    <div className={`shadow-lg rounded-xl p-4 border ${ActiveLayout === '3' ? 'border-theme' : 'border-[#F2F4F7] cursor-pointer'}`}
                        onClick={() => setActiveLayout('3')}  >
                        <div className='pb-3 font-inter-bold text-primaryTextColor'>
                            <span>Layout 3</span>
                        </div>
                        <PlaceholderLayout3 />
                    </div>
                    {/* <div className={`shadow-lg rounded-xl p-4 border ${ActiveLayout === '4' ? 'border-theme' : 'border-[#F2F4F7] cursor-pointer'}`}
                        onClick={() => setActiveLayout('4')}>
                        <div className='pb-3 font-inter-bold text-primaryTextColor'>
                            <span>Layout 4</span>
                        </div>
                        <PlaceholderLayout4 />
                    </div> */}
                    <div className={`shadow-lg rounded-xl p-4 border ${ActiveLayout === '4' ? 'border-theme' : 'border-[#F2F4F7] cursor-pointer'}`}
                        onClick={() => setActiveLayout('4')} >
                        <div className='pb-3 font-inter-bold text-primaryTextColor'>
                            <span>Layout 4</span>
                        </div>
                        <PlaceholderLayout5 />
                    </div>
                    {/* <div className={`shadow-lg rounded-xl p-4 border ${ActiveLayout === '6' ? 'border-theme' : 'border-[#F2F4F7] cursor-pointer'}`}
                        onClick={() => setActiveLayout('6')}  >
                        <div className='pb-3 font-inter-bold text-primaryTextColor'>
                            <span>Layout 6</span>
                        </div>
                        <PlaceholderLayout6 />
                    </div> */}

                </div>

            </div>
            <div className="flex justify-end gap-4 mt-[40px]">
                <IconButton
                    type="button"
                    className={`w-full max-w-[150px] text-white !border-[--theme]`}
                    onClick={() => setIsConfirmOpen(true)}
                >
                    {t("Confirm")}
                </IconButton>
            </div>
            <ConfirmModal
                title="Are you sure you want to change the layout? Your current layout will be lost."
                confirmFunction={ChangeLayout}
                reject={() => { setIsConfirmOpen(false) }}
                hocTitle='Confirm Layout Change'
                hocisOpen={IsConfirmOpen}
                hocToggleModal={() => setIsConfirmOpen(!IsConfirmOpen)}
                hocClasses={{
                    modalClassName: `max-w-[500px] w-full`
                }}
                hocCurrentStep={1}
            />
        </>
    )
}

export default withModal(EditLayout)