import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import { format } from "date-fns";
import { useWidgetData } from "lib/hooks/queries/Dashboard/useWidgetData";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Props {
  name: string;
  api: string;
  type: string;
}

const CalendarWidget: FC<Props> = ({ name, api, type }) => {
  const { t } = useTranslation();

  const [FilterDate, setFilterDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );

  const { data, isLoading } = useWidgetData({
    endpoint: api,
    params: { for_date: FilterDate },
    type: name,
  });

  if (isLoading) return <LoadingScreen />;

  return (
    <div className="w-full h-full bg-white border border-[#F2F4F7] rounded-2xl p-5">
      <div className="pb-5">
        <div className="text-primaryTextColor font-inter-bold xl:text-lg 2xl:text-xl">
          {t(name)}
        </div>
      </div>
      <div className="pb-0">
        <PrimaryDatePicker
          startDate={FilterDate}
          setStartDate={setFilterDate}
          wrapperClassName="!-top-7 no-shadow !relative"
          className="!hidden"
          open={true}
        />
      </div>
      <div className="space-y-2 overflow-auto h-[calc(100vh-370px)] pr-1 -top-7 relative">
        {(data as any).data.length > 0 ? (
          (data as any).data.map((item: any) => (
            <Link to={`/appointments`} className="w-full block" key={item.id}>
              <div className="flex gap-2">
                <div className="w-[40px] bg-white">
                  <span className="font-inter-medium text-xs text-[#72767C]">
                    {item.time.substring(0, 5)}
                  </span>
                </div>
                <div className="flex-1">
                  <div className="p-2 rounded-[10px] bg-theme flex flex-wrap justify-between">
                    <div className="pb-2 text-sm text-white font-inter-medium basis-full">
                      {item.info.first_name} {item.info.last_name}
                    </div>
                    <div>
                      <span className="text-xs text-white font-inter-medium">
                        {item.info.address}, {item.info.city}{" "}
                        {item.info.postal_code}
                      </span>
                    </div>
                    <div>
                      <span className="text-xs text-white font-inter-medium">
                        {item.time.substring(0, 5)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div className="flex items-center justify-center h-full">
            <span className="text-sm text-[#72767C] font-inter-medium">
              {t("No appointments in this date")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarWidget;
