import Button from 'components/atoms/button/Button';
import IconButton from 'components/atoms/button/IconButton';
import Checkbox from 'components/atoms/checkbox/Checkbox';
import Form from 'components/atoms/form/Form';
import Icon from 'components/atoms/icon/Icon';
import Input from 'components/atoms/input/Input';
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen';
import Accordion from 'components/molecules/accordion/Accordion';
import AccordionBody from 'components/molecules/accordion/AccordionBody/AccordionBody';
import AccordionTitle from 'components/molecules/accordion/AccordionTitle/AccordionTitle';
import Table from 'components/molecules/table/Table';
import Tooltip from 'components/molecules/tooltip/Tooltip';
import { IconType } from 'lib/helpers/constants/iconTypes';
import { useCreateRole } from 'lib/hooks/mutations/Employee/useCreateRole';
import { useCompanyPermissions } from 'lib/hooks/queries/PermissionsModule/useCompanyPermissions';
import { useHover } from 'lib/hooks/shared/useHover';
import React, { FC } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
interface IStep2CreateRoleProps {
    setCurrentStep?: (step: number) => void;
    setAllData: (data: any) => void;
    AllData: any;
    withEmployee?: boolean;
    onConfirm?: () => void;
}

const Step2CreateRole: FC<IStep2CreateRoleProps> = ({ setCurrentStep, AllData, setAllData, withEmployee = true, onConfirm }) => {

    const { t } = useTranslation();

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        defaultValues: {
            role: AllData?.role || '',
        }
    })

    const { data, isLoading } = useCompanyPermissions()

    const { isHovered, onMouseEnter, onMouseLeave } = useHover();

    const handleCheckboxChange = (id: any, e: any) => {
        if (e.target.checked) {
            setAllData({ ...AllData, permission: [...AllData.permission, id] })
        }
        else {
            setAllData({ ...AllData, permission: AllData.permission.filter((permission: any) => permission !== id) })
        }
    }

    const handleReset = () => {
        setAllData({
            permission: [],
        })
        reset()
    }

    const { mutateAsync: createUser, isLoading: isLoadingCreate } = useCreateRole()

    const handleFormSubmit = (data: any) => {

        createUser({ ...AllData, ...data }).then((res) => {
            if (withEmployee) {

                setCurrentStep && setCurrentStep(2)
                setAllData({ ...AllData, user_id: res.id })

            } else {

                onConfirm && onConfirm()
            }
        })

    }

    const findPermissionByName = (permissions: any[], name: string) => {
        return permissions?.find((m: any) => m.name.split('_')[m.name.split('_').length - 1] === name)
    }

    const TableHeaders = [
        {
            id: 1,
            Header: <div className='font-inter-bold text-primaryTextColor text-left w-full'>{t('Functionalities')}</div>,
            accessor: "Functionalities",
            Cell: ({ row }: any) => (
                <div className='max-w-[350px] w-full'>
                    <div className='flex flex-col gap-2'>
                        <span className='text-sm font-inter-semibold'>{t(row.original?.name)}:</span>
                        <span className='text-sm font-inter-regular'>{t(row.original?.description)}</span>
                    </div>
                </div>
            ),
            isChecked: true
        },
        {
            id: 2,
            Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('View all')}</span>,
            accessor: "View",
            Cell: ({ row }: any) => (
                <div className='flex justify-center'>
                    {JSON.parse(row.original?.allowed_permission_types).filter((item: any) => item.label === 'View').length > 0 ? (
                        <Checkbox
                            onChange={(e) => {
                                handleCheckboxChange(findPermissionByName(row.original?.permission, 'view')?.id, e)
                            }}

                            checked={AllData.permission.includes(findPermissionByName(row.original?.permission, 'view')?.id)}
                        />
                    ) : (
                        <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
                    )}
                </div>
            ),
            isChecked: true
        },
        {
            id: 8,
            Header: <div className='font-inter-bold text-primaryTextColor text-center -ml-6'>{t('/Own')}</div>,
            accessor: "View",
            Cell: ({ row }: any) => (
                <div className='flex justify-center -ml-6'>
                    {row.original?.allowed_permission_types.includes('ViewOwn') ? (
                        <Checkbox checked={AllData.permission.includes(findPermissionByName(row.original?.permission, 'own')?.id)}
                            onChange={(e) => {
                                handleCheckboxChange(findPermissionByName(row.original?.permission, 'own')?.id, e)
                            }}
                        />
                    ) : (
                        <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
                    )}
                </div>
            ),
            isChecked: true
        },
        {
            id: 3,
            Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Edit')}</span>,
            accessor: "Edit",
            Cell: ({ row }: any) => (
                <div className='flex justify-center'>
                    {row.original?.allowed_permission_types.includes('Edit') ? (
                        <Checkbox
                            checked={AllData.permission.includes(findPermissionByName(row.original?.permission, 'edit')?.id)}
                            onChange={(e) => {
                                handleCheckboxChange(findPermissionByName(row.original?.permission, 'edit')?.id, e)
                            }}
                        />
                    ) : (
                        <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
                    )}
                </div>
            ),
            isChecked: true
        },
        {
            id: 4,
            Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Create')}</span>,
            accessor: "Create",
            Cell: ({ row }: any) => (
                <div className='flex justify-center'>
                    {row.original?.allowed_permission_types.includes('Create') ? (
                        <Checkbox
                            checked={AllData.permission.includes(findPermissionByName(row.original?.permission, 'create')?.id)}
                            onChange={(e) => {
                                handleCheckboxChange(findPermissionByName(row.original?.permission, 'create')?.id, e)
                            }}
                        />
                    ) : (
                        <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
                    )}
                </div>
            ),
            isChecked: true
        },
        {
            id: 5,
            Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Delete')}</span>,
            accessor: "Delete",
            Cell: ({ row }: any) => (
                <div className='flex justify-center'>
                    {row.original?.allowed_permission_types.includes('Delete') ? (
                        <Checkbox
                            checked={AllData.permission.includes(findPermissionByName(row.original?.permission, 'delete')?.id)}
                            onChange={(e) => {
                                handleCheckboxChange(findPermissionByName(row.original?.permission, 'delete')?.id, e)
                            }}
                        />
                    ) : (
                        <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
                    )}
                </div>
            ),
            isChecked: true
        },
        {
            id: 6,
            Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Assign')}</span>,
            accessor: "Assign",
            Cell: ({ row }: any) => (
                <div className='flex justify-center'>
                    {row.original?.allowed_permission_types.includes('Assign') ? (
                        <Checkbox
                            checked={AllData.permission.includes(findPermissionByName(row.original?.permission, 'assign')?.id)}
                            onChange={(e) => {

                                handleCheckboxChange(findPermissionByName(row.original?.permission, 'assign')?.id, e)
                            }}
                        />
                    ) : (
                        <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
                    )}
                </div>
            ),
            isChecked: true
        },
        {
            id: 7,
            Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Export')}</span>,
            accessor: "Export",
            Cell: ({ row }: any) => (
                <div className='flex justify-center'>
                    {row.original?.allowed_permission_types.includes('Export') ? (
                        <Checkbox
                            checked={AllData.permission.includes(findPermissionByName(row.original?.permission, 'export')?.id)}
                            onChange={(e) => {

                                handleCheckboxChange(findPermissionByName(row.original?.permission, 'export')?.id, e)
                            }}
                        />
                    ) : (
                        <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
                    )}

                </div>
            ),
            isChecked: true

        }
    ]

    const PendingsTableHeaders = [
        {
            id: 1,
            Header: <div className='font-inter-bold text-primaryTextColor text-left w-full'>{t('Functionalities')}</div>,
            accessor: "Functionalities",
            Cell: ({ row }: any) => (
                <div className='max-w-[350px] w-full'>
                    <div className='flex flex-col gap-2'>
                        <span className='text-sm font-inter-semibold'>{t(row.original?.name)}:</span>
                        <span className='text-sm font-inter-regular'>{t(row.original?.description)}</span>
                    </div>
                </div>
            ),
            isChecked: true
        },
        {
            id: 2,
            Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('View all')}</span>,
            accessor: "View",
            Cell: ({ row }: any) => (
                <div className='flex justify-center'>
                    {JSON.parse(row.original?.allowed_permission_types).filter((item: any) => item.label === 'View').length > 0 ? (
                        <Checkbox
                            onChange={(e) => {
                                handleCheckboxChange(findPermissionByName(row.original?.permission, 'view')?.id, e)
                            }}

                            checked={AllData.permission.includes(findPermissionByName(row.original?.permission, 'view')?.id)}
                        />
                    ) : (
                        <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
                    )}
                </div>
            ),
            isChecked: true
        },
        {
            id: 8,
            Header: <div className='font-inter-bold text-primaryTextColor text-center -ml-6'>{t('/Own')}</div>,
            accessor: "View",
            Cell: ({ row }: any) => (
                <div className='flex justify-center -ml-6'>
                    {row.original?.allowed_permission_types.includes('ViewOwn') ? (
                        <Checkbox checked={AllData.permission.includes(findPermissionByName(row.original?.permission, 'own')?.id)}
                            onChange={(e) => {
                                handleCheckboxChange(findPermissionByName(row.original?.permission, 'own')?.id, e)
                            }}
                        />
                    ) : (
                        <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
                    )}
                </div>
            ),
            isChecked: true
        },
        {
            id: 3,
            Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Edit')}</span>,
            accessor: "Edit",
            Cell: ({ row }: any) => (
                <div className='flex justify-center'>
                    {row.original?.allowed_permission_types.includes('Edit') ? (
                        <Checkbox
                            checked={AllData.permission.includes(findPermissionByName(row.original?.permission, 'edit')?.id)}
                            onChange={(e) => {
                                handleCheckboxChange(findPermissionByName(row.original?.permission, 'edit')?.id, e)
                            }}
                        />
                    ) : (
                        <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
                    )}
                </div>
            ),
            isChecked: true
        },
        {
            id: 4,
            Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Create')}</span>,
            accessor: "Create",
            Cell: ({ row }: any) => (
                <div className='flex justify-center'>
                    {row.original?.allowed_permission_types.includes('Create') ? (
                        <Checkbox
                            checked={AllData.permission.includes(findPermissionByName(row.original?.permission, 'create')?.id)}
                            onChange={(e) => {
                                handleCheckboxChange(findPermissionByName(row.original?.permission, 'create')?.id, e)
                            }}
                        />
                    ) : (
                        <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
                    )}
                </div>
            ),
            isChecked: true
        },
        {
            id: 5,
            Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Delete')}</span>,
            accessor: "Delete",
            Cell: ({ row }: any) => (
                <div className='flex justify-center'>
                    {row.original?.allowed_permission_types.includes('Delete') ? (
                        <Checkbox
                            checked={AllData.permission.includes(findPermissionByName(row.original?.permission, 'delete')?.id)}
                            onChange={(e) => {
                                handleCheckboxChange(findPermissionByName(row.original?.permission, 'delete')?.id, e)
                            }}
                        />
                    ) : (
                        <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
                    )}
                </div>
            ),
            isChecked: true
        },
        {
            id: 6,
            Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Assign')}</span>,
            accessor: "Assign",
            Cell: ({ row }: any) => (
                <div className='flex justify-center'>
                    {row.original?.allowed_permission_types.includes('Assign') ? (
                        <Checkbox
                            checked={AllData.permission.includes(findPermissionByName(row.original?.permission, 'assign')?.id)}
                            onChange={(e) => {

                                handleCheckboxChange(findPermissionByName(row.original?.permission, 'assign')?.id, e)
                            }}
                        />
                    ) : (
                        <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
                    )}
                </div>
            ),
            isChecked: true
        },
        {
            id: 7,
            Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Export')}</span>,
            accessor: "Export",
            Cell: ({ row }: any) => (
                <div className='flex justify-center'>
                    {row.original?.allowed_permission_types.includes('Export') ? (
                        <Checkbox
                            checked={AllData.permission.includes(findPermissionByName(row.original?.permission, 'export')?.id)}
                            onChange={(e) => {

                                handleCheckboxChange(findPermissionByName(row.original?.permission, 'export')?.id, e)
                            }}
                        />
                    ) : (
                        <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
                    )}

                </div>
            ),
            isChecked: true

        }, {
            id: 9,
            Header: <span className='font-inter-bold text-primaryTextColor text-center'>{t('Feedback File')}</span>,
            accessor: "Feedback File",
            Cell: ({ row }: any) => (

                <div className='flex justify-center'>
                    {row.original?.allowed_permission_types.includes('FeedbackFile') ? (
                        <Checkbox
                            checked={AllData.permission.includes(findPermissionByName(row.original?.permission, 'feedbackfile')?.id)}
                            onChange={(e) => {

                                handleCheckboxChange(findPermissionByName(row.original?.permission, 'feedbackfile')?.id, e)
                            }}
                        />
                    ) : (
                        <Icon iconType={IconType.CLOSE_ICON} className='w-[12px] h-[12px]' color='#F45B69' />
                    )}

                </div>
            ),
            isChecked: true

        }]



    if (isLoading) {
        return <LoadingScreen />
    }

    return (
        <Form onSubmit={handleSubmit(handleFormSubmit)}>

            <div className='space-y-4'>
                <div>
                    <div className='pb-3'>
                        <div className="flex items-center gap-2">
                            <span className='font-inter-semibold text-primaryTextColor'>{t("Role Details")}</span>

                        </div>
                    </div>
                    <div className='flex gap-5 lg:gap-6 p-4 bg-[#F6FBFE] rounded-3xl'>
                        <Input
                            {...register(`role`, { required: 'This field is required' })}
                            label={t(`Role name`)}
                            placeholder={t(`Enter role name`)}
                            error={errors.role}
                        />

                    </div>
                </div>
                <div>
                    <div className='pb-3'>
                        <div className="flex items-center gap-2">
                            <span className='font-inter-semibold text-primaryTextColor'>{t("Role Permissions")}</span>
                            <div className="relative leading-none" onMouseLeave={onMouseLeave}>
                                <Button onMouseEnter={onMouseEnter}>
                                    <Icon iconType={IconType.TOOLTIP_INFO_ICON} className='cursor-pointer' />
                                </Button>
                                <Tooltip
                                    isOpen={isHovered}
                                    className="absolute right-0 w-[250px] lg:w-[550px] h-auto bg-white p-[10px] rounded-[8px] shadow-[0_6px_58px_0px_rgba(121,145,173,0.2)] top-[0px] left-[30px] !z-[99999]"
                                    content={
                                        <ul className="list-disc ml-4 font-inter-medium text-[#667085] text-xs">
                                            <li>
                                                {t('Role Permissions include all modules and their associated functionalities that the user can access')}
                                            </li>
                                            <li>
                                                {t("You can easily view the last updated date for each functionality and the user's access rights")}
                                            </li>
                                            <li>
                                                {t('Additional functionalities can be added or edited at any time')}
                                            </li>
                                        </ul>
                                    }
                                />

                            </div>
                        </div>
                    </div>
                    <div className='space-y-4'>

                        {(data as any)?.map((item: any) => (
                            <Accordion classes={{ wrapper: '!rounded-2xl !p-0 !border-[#A2A1A833]', title: '!bg-[#F6FBFE] !px-4 !py-3 !rounded-2xl', body: '!px-4 !mt-0' }} key={item.module_id}>
                                <AccordionTitle subTitleClass={'font-inter-semibold text-primaryTextColor'}>{t(item.module.name)}</AccordionTitle>
                                <AccordionBody>
                                    <div className='pb-4 mt-4 w-full'>
                                        <Table
                                            columns={item.module.name === 'Pendings'
                                                ? PendingsTableHeaders
                                                : TableHeaders
                                            }
                                            data={item?.parent_permission || []} search={""}
                                            isColumnSorted={true}
                                            classes={{ headerClasses: '!border-b-0' }}
                                        />

                                    </div>
                                </AccordionBody>
                            </Accordion>
                        ))}

                    </div>
                </div>
            </div>
            <div className="flex flex-row justify-end gap-5 pt-10">
                <IconButton className="max-w-[120px] w-full" secondary onClick={handleReset} disabled={isLoadingCreate}>
                    {t(`Reset`)}
                </IconButton>

                <IconButton className="" type='submit' disabled={AllData.permission.length === 0 || isLoadingCreate}>
                    <div className='flex items-center gap-2'>
                        {withEmployee ? (
                            <>
                                {t(`Next Step`)}
                                <Icon iconType={IconType.RIGHT_ARROW_ICON} />
                            </>
                        ) : (
                            t(`Create Role`)
                        )}

                    </div>
                </IconButton>
            </div>
        </Form>
    )
}

export default Step2CreateRole