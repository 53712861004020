export interface ILanguageData {
    value: string;
    label: string;
  }
  
  export const systemLanguage: Array<ILanguageData> = [
    {
      value: "en",
      label: "English",
    },
    {
      value: "de",
      label: "Deutsch",
    },
   
  ];
  
  
  