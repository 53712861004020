import IconButton from "components/atoms/button/IconButton";
import ConditionalRender from "components/atoms/conditional-render/ConditionalRender";
import RadioButton from "components/atoms/radio-button/RadioButton";
import PrimaryDatePicker from "components/molecules/datepicker/PrimaryDatePicker";
import React, { Dispatch, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IFilterProps {
    filter_on?: string;
    from_date_of_creation?: any;
    to_date_of_creation?: any;
}



interface IFilterDropdownProps {
    setFilterData?: (args: any) => void;
    FilterData?: IFilterProps;
    setIsOpen: Dispatch<React.SetStateAction<boolean>>;
    isAssignedLeads?: boolean;
}

const initialState: IFilterProps = {
    filter_on: "Today",
    from_date_of_creation: undefined,
    to_date_of_creation: undefined
};

const Filter: React.FC<IFilterDropdownProps> = ({
    setFilterData,
    FilterData,
    setIsOpen,
}) => {

    const [FromDateOfCreation, setFromDateOfCreation] = useState<Date | null>(
        FilterData?.from_date_of_creation || null
    );

    const [ToDateOfCreation, setToDateOfCreation] = useState<Date | null>(
        FilterData?.to_date_of_creation || null
    );

    const [InsideData, setInsideData] = useState<IFilterProps>(
        FilterData || initialState
    );

    useEffect(() => {
        setInsideData((prevFormData: any) => ({
            ...prevFormData,
            from_date_of_creation: FromDateOfCreation
        }));
    }, [FromDateOfCreation]);

    useEffect(() => {
        setInsideData((prevFormData: any) => ({
            ...prevFormData,
            to_date_of_creation: ToDateOfCreation
        }));
    }, [ToDateOfCreation]);

    const handleButtonClick = () => {
        if (setFilterData) {
            setFilterData(InsideData);
        }

        setIsOpen(false);
    };

    const ResetData = () => {
        setInsideData(initialState);
        setFromDateOfCreation(null);
        setToDateOfCreation(null);
    };


    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-3">
            <div className="grid grid-cols-2 gap-3">
                <RadioButton
                    label={t('Today')}
                    isChecked={InsideData.filter_on === "Today"}
                    onChange={() => setInsideData((prevFilters: any) => ({
                        ...prevFilters,
                        filter_on: 'Today',
                    }))}
                    labelClassName="h-[44px] items-center flex"
                    iconWrapperClassName="!top-[21px] !right-[6px]"
                    className="right-[8px]"
                />
                <RadioButton
                    label={t('This week')}
                    isChecked={InsideData.filter_on === "This week"}
                    onChange={() => setInsideData((prevFilters: any) => ({
                        ...prevFilters,
                        filter_on: 'This week',
                    }))}
                    labelClassName="h-[44px] items-center flex"
                    iconWrapperClassName="!top-[21px] !right-[6px]"
                    className="right-[8px]"
                />
                <RadioButton
                    label={t('This month')}
                    isChecked={InsideData.filter_on === "This month"}
                    onChange={() => setInsideData((prevFilters: any) => ({
                        ...prevFilters,
                        filter_on: 'This month',
                    }))}
                    labelClassName="h-[44px] items-center flex"
                    iconWrapperClassName="!top-[21px] !right-[6px]"
                    className="right-[8px]"
                />
                <RadioButton
                    label={t('Individual')}
                    isChecked={InsideData.filter_on === "Individual"}
                    onChange={() => setInsideData((prevFilters: any) => ({
                        ...prevFilters,
                        filter_on: 'Individual',
                    }))}
                    labelClassName="h-[44px] items-center flex"
                    iconWrapperClassName="!top-[21px] !right-[6px]"
                    className="right-[8px]"
                />
            </div>
            <ConditionalRender condition={InsideData.filter_on === 'Individual'}>

                <PrimaryDatePicker
                    label={t('From date of creation')}
                    setStartDate={setFromDateOfCreation}
                    startDate={FromDateOfCreation}
                    wrapperClassName="!left-[2px]"
                />
                <PrimaryDatePicker
                    label={t('To date of creation')}
                    setStartDate={setToDateOfCreation}
                    startDate={ToDateOfCreation}
                    wrapperClassName="!left-[2px]"
                />
            </ConditionalRender>

            <div className="pt-5">
                <div className="flex flex-row justify-end gap-3">
                    <IconButton
                        secondary={true}
                        className="w-[120px]"
                        onClick={ResetData}>
                        {t('Reset')}
                    </IconButton>
                    <IconButton className="w-[120px]" onClick={handleButtonClick}>
                        {t('Confirm')}
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

export default Filter;