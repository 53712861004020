import IconButton from 'components/atoms/button/IconButton'
import LoadingScreen from 'components/atoms/loading-screen/LoadingScreen'
import TabsSwitch from 'components/molecules/tabs/TabsSwitch'
import withModal from 'lib/helpers/hoc/withModal'
import { useAddWidgetToLayout } from 'lib/hooks/mutations/Dashboard/useAddWidgetToLayout'
import { useWidgetBySize } from 'lib/hooks/queries/Dashboard/useWidgetBySize'
import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'


interface Props {
    onConfirm: () => void
    data?: any
}

const AddWidgets: FC<Props> = ({ onConfirm, data }) => {

    const { t } = useTranslation()

    const [ActiveWidget, setActiveWidget] = useState(null)

    const { data: widgetData, isLoading } = useWidgetBySize(data?.size)

    const memoWidgets = useMemo(() => {
        return widgetData
    }, [widgetData])

    const [ActiveTab, setActiveTab] = useState<any>('Leads')

    const gridSize = useMemo(() => {
        if (data?.size === '25x25') {
            return 'grid-cols-4'
        } else if (data?.size === '25x50') {
            return 'grid-cols-3'
        } else if (data?.size === '75x25') {
            return 'grid-cols-2'
        }
        else if (data?.size === '25x100') {
            return 'grid-cols-4'
        }
    }, [data])


    const { mutateAsync } = useAddWidgetToLayout()

    const SubmitWidget = () => {

        mutateAsync({ widget_id: ActiveWidget, id: data.id, position: data.position }).then(() => {
            onConfirm()
        })

    }

    if (isLoading) {
        return <LoadingScreen />
    }

    return (
        <>
            <div className='p-6 border border-border rounded-2xl'>
                <div className="pb-5 flex items-center">
                    <div className="flex-none">

                    </div>
                    <div className="flex-1">
                        <span className='text-primaryTextColor font-inter-semibold'>{t('Dashboard Widgets')}</span>
                    </div>
                </div>
                <TabsSwitch
                    ActiveTab={ActiveTab}
                    setSelectedTab={setActiveTab}
                    classes={{
                        unActiveTabClass: '!px-3 !py-3 !me-4 !border-b-0',
                        activeTabClass: 'bg-themeOpacity rounded-lg !border-b-0 !font-inter-medium',
                        container: 'border-b border-themeOpacity !mb-10',
                    }}
                    tabs={[
                        { id: 'Leads' as any, name: 'Leads' },
                        { id: 'Appointments', name: 'Appointments' },
                        { id: 'Customers', name: 'Customers' },
                        { id: 'Pendings', name: 'Pendings' },
                        { id: 'Statistics', name: 'Statistics' },
                        { id: 'Personal', name: 'Personal' },
                    ]}
                />
                <div className={`grid ${gridSize} gap-4`}>
                    {memoWidgets?.map((widget: any
                        
                    ) => (
                        widget.type === ActiveTab ? (
                            <img src={`${process.env.REACT_APP_API_URL}dashboard/widgets/thumbnail/${widget.thumbnail}`} alt="icon"
                                key={widget.id}
                                className={`border object-contain border-border rounded-2xl w-full h-full ${ActiveWidget === widget.id ? "border-theme" : "cursor-pointer"}`}
                                onClick={() => setActiveWidget(widget.id)}
                            />
                        ) : null
                    ))}
                </div>

            </div>
            <div className="flex justify-end gap-4 mt-[40px]">
                <IconButton
                    type="button"
                    className={`w-full max-w-[150px] text-white !border-[--theme]`}
                    onClick={SubmitWidget}
                >
                    {t("Confirm")}
                </IconButton>
            </div>
        </>
    )
}

export default withModal(AddWidgets)