import TabsSwitch from "components/molecules/tabs/TabsSwitch";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Tab1UpdateInfo from "./tabs/Tab1UpdateInfo";
import Tab2AddProduct from "./tabs/Tab2AddProduct";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";

export interface IUpdateSourceProps {
  onConfirm: () => void;
  data?: any;
  themeColors: {
    primary: string;
  };
}

const UpdatePartner: FC<IUpdateSourceProps> = ({
  onConfirm,
  data,
}) => {


  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(0)

  const [IsAddingProduct, setIsAddingProduct] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)

  const handleBack = () => {
    if (currentStep === 1) {
      setIsAddingProduct(false)
    }
    else {
      setCurrentStep(currentStep - 1)
    }
  }

  return (
    <>
      <button
        type="button"
        className="absolute top-[26px] sm:top-[31px] md:top-[45px]"
        onClick={handleBack}
      >
        <Icon
          iconType={IconType.BACK_BUTTON_MODAL}
          color={"var(--theme)"}
        />
      </button>
      <TabsSwitch
        ActiveTab={selectedTab}
        setSelectedTab={(id) => setSelectedTab(id)}
        tabs={[
          { id: 0, name: `${t('General Information')}` },
          { id: 1, name: `${t('Products')}` }
        ]}
        classes={{ unActiveTabClass: '!px-0 !me-5 !pb-2' }}
      />
      {
        {
          0: <Tab1UpdateInfo data={data} onConfirm={onConfirm} />,
          1: <Tab2AddProduct
            IsAddingProduct={IsAddingProduct} setIsAddingProduct={setIsAddingProduct}
            currentStep={currentStep} setCurrentStep={setCurrentStep}
            onConfirm={onConfirm} data={data}
          />
        }[selectedTab]
      }
    </>

  );
};

export default UpdatePartner;
