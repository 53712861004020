import Button from "components/atoms/button/Button";
import Icon from "components/atoms/icon/Icon";
import Input from "components/atoms/input/Input";
import Label from "components/atoms/input/input-label/InputLabel";
import RadioButton from "components/atoms/radio-button/RadioButton";
import Tooltip from "components/molecules/tooltip/Tooltip";
import { customStyles } from "lib/helpers/configs/customStyles";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useHover } from "lib/hooks/shared/useHover";
import React, { FC, Fragment } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";

interface Props {
  control: any;
  errors: any;
}

const Step2TargetPerformance: FC<Props> = ({ control, errors }) => {
  const { t } = useTranslation();
  const { isHovered, onMouseEnter, onMouseLeave } = useHover();

  return (
    <div className="rounded-[20px] bg-[#F6FBFE] p-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <div className="flex gap-2 items-center relative">
            <Label >Salary Type</Label>
            <Button className="mb-2" onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
              <Icon
                iconType={IconType.ALERT_ICON}
                color="var(--theme)"
              />
            </Button>
            <Tooltip
              isOpen={isHovered}
              className="absolute right-0 w-[250px] h-auto bg-white p-[10px] rounded-[25px] shadow-custom  top-[-10px] left-[110px] !z-[99999]"
              content={
                <Fragment>
                  <ul className="list-disc text-[14px]  ml-4 font-inter-regular">
                    <li>
                      {t(
                        "The base salary is in gross"
                      )}.
                    </li>
                  </ul>
                </Fragment>
              }
            />
          </div>
          <div className="grid grid-cols-2 gap-3">
            <Controller
              name="salary_type"
              control={control}
              render={({ field }) => (
                <RadioButton
                  label={t("Fixed")}
                  isChecked={field.value === "fixed"}
                  value={"fixed"}
                  name={"salary_type"}
                  onChange={() => field.onChange("fixed")}
                  labelClassName="h-[41px] 2xl:h-[44px] items-center flex bg-white"
                  iconWrapperClassName="!top-[20px] 2xl:!top-[21px] !right-[6px]"
                  className="right-[8px]"
                />
              )}
            />
            <Controller
              name="salary_type"
              control={control}
              render={({ field }) => (
                <RadioButton
                  label={t("Hourly")}
                  isChecked={field.value === "hourly"}
                  value={"hourly"}
                  name={"salary_type"}
                  onChange={() => field.onChange("hourly")}
                  labelClassName="h-[41px] 2xl:h-[44px] items-center flex bg-white"
                  iconWrapperClassName="!top-[20px] 2xl:!top-[21px] !right-[6px]"
                  className="right-[8px]"
                />
              )}
            />
          </div>
        </div>

        <div className="flex gap-4 items-end">
          {/* Left side - 70% width */}
          <div className="w-[70%]">
            <Controller
              name="effective_salary"
              rules={{
                required: t("This field is required"),
                pattern: {
                  value: /^[0-9]+$/,
                  message: t("Only numbers are allowed"),
                },
              }}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Effective Salary (100%)"
                  placeholder={t("Enter Effective Salary (100%)")}
                  error={errors.effective_salary}
                />
              )}
            />
          </div>
          <div className="w-[30%] relative">
            <Controller
              name="salary_frequency"
              rules={{ required: t("This field is required") }}
              control={control}
              render={({ field }) => (
                <CreatableSelect<any, false>
                  {...field}
                  options={[
                    { value: "monthly", label: t("Monthly") },
                    { value: "yearly", label: t("Yearly") },
                    { value: "weekly", label: t("Weekly") },
                    { value: "daily", label: t("Daily") },
                  ]}
                  value={
                    field?.value
                      ? {
                          value: field?.value,
                          label: t(field?.value),
                        }
                      : null
                  }
                  className="cursor-pointer font-inter-regular react-select-container"
                  classNamePrefix="react-select"
                  onChange={(newValue: any, actionMeta: any) => {
                    if (
                      actionMeta.action === "select-option" &&
                      newValue?.value
                    ) {
                      field.onChange(newValue.value);
                    }
                  }}
                  styles={customStyles}
                  isValidNewOption={() => false}
                  menuPosition={"fixed"}
                  menuPortalTarget={document.body}
                />
              )}
            />
            {errors.salary_frequency?.message && (
              <div className="font-inter-regular text-xs text-red-600 w-max absolute bottom-[-16px] 2xl:bottom-[-20px] left-[2px]">
                {errors.salary_frequency?.message}
              </div>
            )}
          </div>
        </div>

        <div>
          <Label>13th Month Salary</Label>
          <div className="grid grid-cols-2 gap-3">
            <Controller
              name="thirteenth_month_salary"
              control={control}
              render={({ field }) => (
                <RadioButton
                  label={t("Yes")}
                  isChecked={field.value === "yes"}
                  value={"yes"}
                  onChange={() => field.onChange("yes")}
                  labelClassName="h-[41px] 2xl:h-[44px] items-center flex bg-white"
                  iconWrapperClassName="!top-[20px] 2xl:!top-[21px] !right-[6px]"
                  className="right-[8px]"
                />
              )}
            />

            <Controller
              name="thirteenth_month_salary"
              control={control}
              render={({ field }) => (
                <RadioButton
                  label={t("No")}
                  isChecked={field.value === "no"}
                  value={"no"}
                  onChange={() => field.onChange("no")}
                  labelClassName="h-[41px] 2xl:h-[44px] items-center flex bg-white"
                  iconWrapperClassName="!top-[20px] 2xl:!top-[21px] !right-[6px]"
                  className="right-[8px]"
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2TargetPerformance;
