import SemiCircleChart from 'components/molecules/widgets/small/SemiCircleChart'
import React, { FC, useCallback, useMemo, useState } from 'react'
import AddWidgets from '../add-widgets/AddWidgets'
import EmptyWidget from '../empty-widget/EmptyWidget'
import DeleteWidget from '../delete-widget/DeleteWidget'
import ListWidget from 'components/molecules/widgets/medium/ListWidget'
import TopSourceWidget from 'components/molecules/widgets/medium/TopSourceWidget'
import BarChartWidget from 'components/molecules/widgets/medium/BarChartWidget'
import DoubleBarWidget from 'components/molecules/widgets/medium/DoubleBarWidget'
import ReminderWidget from 'components/molecules/widgets/medium/ReminderWidget'

export interface DashboardLayoutProps {
    isEditing?: boolean
    data?: any
}

const Layout1: FC<DashboardLayoutProps> = ({ isEditing, data }) => {

    const [AddWidgetModalData, setAddWidgetModalData] = useState<any>(null)

    const widgets = useMemo(() => {

        return data?.widgets
    }, [data])


    const getWidgetFromPosition = useCallback(
        (position: number) => {
            return widgets?.find((widget: any) => widget.position === position)
        },
        [widgets]
    );




    return (
        <div className={`grid grid-cols-4 min-h-screen gap-5`}>

            <div className="col-span-2 2xl:col-span-3">
                <div className={`flex flex-col 2xl:flex-row flex-wrap h-full gap-5`}>

                    <div className='flex-1 2xl:w-[31.8%] 3xl:w-[32.1%] relative h-[26%]'>
                        {widgets && getWidgetFromPosition(1) ? (
                            <>

                                <DeleteWidget isEditing={isEditing} data={{ id: data.id, widget_id: getWidgetFromPosition(1).widget_id, position: 1 }} />
                                <SemiCircleChart
                                    api={getWidgetFromPosition(1).api_endpoint}
                                    icon={getWidgetFromPosition(1).icon}
                                    color={getWidgetFromPosition(1).color}
                                    status={getWidgetFromPosition(1).widget_name}
                                    name={getWidgetFromPosition(1).type}
                                />
                            </>
                        ) : (
                            <EmptyWidget
                                setAddWidgetModalData={setAddWidgetModalData}
                                data={{ id: data.id, position: 1, size: '25x25' }}
                            />

                        )}

                    </div>

                    <div className="flex-1 2xl:w-[31.8%] 3xl:w-[32.1%] relative h-[26%]">
                        {widgets && getWidgetFromPosition(2) ? (
                            <>
                                <DeleteWidget isEditing={isEditing} data={{ id: data.id, widget_id: getWidgetFromPosition(2).widget_id, position: 2 }} />
                                <SemiCircleChart
                                    api={getWidgetFromPosition(2).api_endpoint}
                                    icon={getWidgetFromPosition(2).icon}
                                    color={getWidgetFromPosition(2).color || '#50B8E7'}
                                    status={getWidgetFromPosition(2).widget_name}
                                    name={getWidgetFromPosition(2).type}
                                />
                            </>
                        ) : (
                            <EmptyWidget
                                setAddWidgetModalData={setAddWidgetModalData}
                                data={{ id: data.id, position: 2, size: '25x25' }}
                            />
                        )}
                    </div>
                    <div className="flex-1 2xl:w-[31.8%] 3xl:w-[32.1%] relative h-[26%] ">
                        {widgets && getWidgetFromPosition(3) ? (
                            <>
                                <DeleteWidget isEditing={isEditing} data={{ id: data.id, widget_id: getWidgetFromPosition(3).widget_id, position: 3 }} />
                                <SemiCircleChart
                                    api={getWidgetFromPosition(3).api_endpoint}
                                    icon={getWidgetFromPosition(3).icon}
                                    color={getWidgetFromPosition(3).color || '#50B8E7'}
                                    status={getWidgetFromPosition(3).widget_name}
                                    name={getWidgetFromPosition(3).type}
                                />
                            </>
                        ) : (
                            <EmptyWidget
                                setAddWidgetModalData={setAddWidgetModalData}
                                data={{ id: data.id, position: 3, size: '25x25' }}
                            />
                        )}
                    </div>
                    <div className="w-full 2xl:basis-full flex-1 relative h-[35%]">
                        {widgets && getWidgetFromPosition(4) ? (
                            <>
                                <DeleteWidget isEditing={isEditing} data={{ id: data.id, widget_id: getWidgetFromPosition(4).widget_id, position: 4 }} />
                                {
                                    (getWidgetFromPosition(4).widget_name === 'Appointments Converted To Customer' ||
                                        getWidgetFromPosition(4).widget_name === 'Pendings Done On Periods'
                                    ) ? (

                                        <DoubleBarWidget
                                            api={getWidgetFromPosition(4).api_endpoint}
                                            name={getWidgetFromPosition(4).widget_name}
                                            type={getWidgetFromPosition(4).type}
                                        />


                                    ) : (
                                        <ListWidget
                                            api={getWidgetFromPosition(4).api_endpoint}
                                            name={getWidgetFromPosition(4).widget_name}
                                        />
                                    )
                                }

                            </>
                        ) : (
                            <EmptyWidget
                                setAddWidgetModalData={setAddWidgetModalData}
                                data={{ id: data.id, position: 4, size: '75x25' }}
                            />
                        )}
                    </div>
                    <div className="w-full 2xl:basis-full flex-1 relative h-[35%]">
                        {widgets && getWidgetFromPosition(5) ? (
                            <>
                                <DeleteWidget isEditing={isEditing} data={{ id: data.id, widget_id: getWidgetFromPosition(5).widget_id, position: 5 }} />
                                {
                                    (
                                        getWidgetFromPosition(5).widget_name === 'Appointments Converted To Customer' ||
                                        getWidgetFromPosition(5).widget_name === 'Pendings Done On Periods'
                                    ) ? (
                                        <DoubleBarWidget
                                            api={getWidgetFromPosition(5).api_endpoint}
                                            name={getWidgetFromPosition(5).widget_name}
                                            type={getWidgetFromPosition(5).type}

                                        />
                                    ) : (
                                        <ListWidget
                                            api={getWidgetFromPosition(5).api_endpoint}
                                            name={getWidgetFromPosition(5).widget_name}
                                        />
                                    )
                                }

                            </>
                        ) : (
                            <EmptyWidget
                                setAddWidgetModalData={setAddWidgetModalData}
                                data={{ id: data.id, position: 5, size: '75x25' }} />
                        )}
                    </div>
                </div>
            </div>
            <div className={`col-span-2 2xl:col-span-1 flex flex-col gap-5`}>
                <div className="flex-1 relative h-1/2">
                    {widgets && getWidgetFromPosition(6) ? (
                        <>
                            <DeleteWidget isEditing={isEditing} data={{ id: data.id, widget_id: getWidgetFromPosition(6).widget_id, position: 6 }} />
                            {/* <ReminderWidget
                                api={getWidgetFromPosition(6).api_endpoint}
                                name={getWidgetFromPosition(6).widget_name}
                                type={getWidgetFromPosition(6).type}
                            /> */}
                            {
                                (
                                    getWidgetFromPosition(6).widget_name === 'Top Sources By Leads' ||
                                    getWidgetFromPosition(6).widget_name === 'Top Sources By Appointments'
                                ) && (
                                    <TopSourceWidget
                                        api={getWidgetFromPosition(6).api_endpoint}
                                        name={getWidgetFromPosition(6).widget_name}
                                        type={getWidgetFromPosition(6).type}
                                    />
                                )
                            }
                            {
                                (getWidgetFromPosition(6).widget_name === 'Leads Created On Periods' ||
                                    getWidgetFromPosition(6).widget_name === 'Appointments Created On Periods' ||
                                    getWidgetFromPosition(6).widget_name === 'Customers Created On Periods' ||
                                    getWidgetFromPosition(6).widget_name === 'Pendings Created On Periods'
                                ) && (
                                    <BarChartWidget
                                        api={getWidgetFromPosition(6).api_endpoint}
                                        name={getWidgetFromPosition(6).widget_name}
                                        type={getWidgetFromPosition(6).type}
                                    />
                                )
                            }
                            {
                                (getWidgetFromPosition(6).widget_name === 'Customers Birthday Reminder' ||
                                    getWidgetFromPosition(6).widget_name === 'Pendings Reminder'
                                ) && (
                                    <ReminderWidget
                                        api={getWidgetFromPosition(6).api_endpoint}
                                        name={getWidgetFromPosition(6).widget_name}
                                        type={getWidgetFromPosition(6).type}
                                    />
                                )
                            }

                        </>
                    ) : (
                        <EmptyWidget
                            setAddWidgetModalData={setAddWidgetModalData}
                            data={{ id: data.id, position: 6, size: '25x50' }}
                        />
                    )}
                </div>


                <div className="flex-1 relative h-1/2">
                    {widgets && getWidgetFromPosition(7) ? (
                        <>
                            <DeleteWidget isEditing={isEditing} data={{ id: data.id, widget_id: getWidgetFromPosition(7).widget_id, position: 7 }} />
                            {
                                (
                                    getWidgetFromPosition(7).widget_name === 'Top Sources By Leads' ||
                                    getWidgetFromPosition(7).widget_name === 'Top Sources By Appointments'
                                ) && (
                                    <TopSourceWidget
                                        api={getWidgetFromPosition(7).api_endpoint}
                                        name={getWidgetFromPosition(7).widget_name}
                                        type={getWidgetFromPosition(7).type}
                                    />
                                )
                            }
                            {
                                (getWidgetFromPosition(7).widget_name === 'Leads Created On Periods' ||
                                    getWidgetFromPosition(7).widget_name === 'Appointments Created On Periods' ||
                                    getWidgetFromPosition(7).widget_name === 'Customers Created On Periods' ||
                                    getWidgetFromPosition(7).widget_name === 'Pendings Created On Periods'
                                ) && (
                                    <BarChartWidget
                                        api={getWidgetFromPosition(7).api_endpoint}
                                        name={getWidgetFromPosition(7).widget_name}
                                        type={getWidgetFromPosition(7).type}
                                    />
                                )
                            }
                            {
                                (
                                    getWidgetFromPosition(7).widget_name === 'Customers Birthday Reminder' ||
                                    getWidgetFromPosition(7).widget_name === 'Pendings Reminder'
                                ) && (
                                    <ReminderWidget
                                        api={getWidgetFromPosition(7).api_endpoint}
                                        name={getWidgetFromPosition(7).widget_name}
                                        type={getWidgetFromPosition(7).type}
                                    />
                                )
                            }
                        </>
                    ) : (
                        <EmptyWidget
                            setAddWidgetModalData={setAddWidgetModalData}
                            data={{ id: data.id, position: 7, size: '25x50' }} />
                    )}
                </div>
            </div>
            <AddWidgets
                data={AddWidgetModalData}
                hocisOpen={AddWidgetModalData !== null}
                hocToggleModal={() => setAddWidgetModalData(null)}
                hocCurrentStep={1}
                hocClasses={{
                    modalClassName: `sm:max-w-[900px] sm:w-full w-full `,
                }}
                hocTitle="Edit Dashboard"
                onConfirm={() => setAddWidgetModalData(null)}
            />
        </div>

    )
}

export default Layout1