import React, { useMemo } from "react";
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Font, Image } from "@react-pdf/renderer";
import { useAuthContext } from "lib/context/AuthContextContext/AuthContext";
import { useTranslation } from "react-i18next";

// Styles
const styles = StyleSheet.create({
    page: { padding: 40, fontSize: 10, fontFamily: "InterMedium", gap: 24 },
    row: { flexDirection: "row", justifyContent: "space-between", color: '#282D46', fontWeight: 500, gap: 10 },
    bold: { fontFamily: "InterBold", color: '#282D46', },
    title: { fontSize: 17, fontFamily: "InterBold", color: '#282D46' },
    line: { borderBottom: "1px solid #DCDCDC" },
});

Font.register({
    family: 'InterMedium',
    src: '/font/Inter-Medium.ttf',
})

Font.register({
    family: 'InterBold',
    src: '/font/Inter-Bold.ttf',
})

Font.register({
    family: 'InterSemiBold',
    src: '/font/Inter-SemiBold.ttf',
})




// PDF Component
const SalarySlipPDF = ({ salaryData, user, t }: { salaryData: any, user: any, t: any }) => (

    <Document>
        <Page style={styles.page}>
            <View style={{ position: 'absolute', top: 40, right: 40 }}>
                <Image style={{ width: 100, height: 100, objectFit: 'contain', objectPositionY: 0 }} src={`https://devmainhub.insusales.com/api/company-logo/${user.company.logo}`} />
            </View>
            <View style={{ gap: 4, color: '#282D46', fontWeight: 500 }}>
                <Text>{user.company.name} GmbH</Text>
                <Text>{user.company.address}</Text>

            </View>
            <Text style={styles.title}>{salaryData.title}</Text>


            <View style={{ gap: 4, color: '#282D46', fontWeight: 500 }}>
                <Text>Mr. {salaryData.name}</Text>
                <Text>{salaryData.address}</Text>
                <Text style={{ marginTop: 5, fontFamily: 'InterSemiBold' }}>AHV Nr. {salaryData.ahvNumber}</Text>
            </View>
            <View style={{ gap: 7 }}>
                <View style={styles.row}>
                    <Text>Fixed Salary:</Text>
                    <Text style={{ marginLeft: 'auto' }}>
                        CHF
                    </Text>
                    <Text style={{ width: 50, alignItems: 'flex-end', textAlign: 'right' }}>{salaryData.fixedSalary}</Text>
                </View>
                <View style={styles.row}>
                    <Text>Commission:</Text>
                    <Text style={{ marginLeft: 'auto' }}>
                        CHF
                    </Text>
                    <Text style={{ width: 50, alignItems: 'flex-end', textAlign: 'right' }}>{salaryData.commissions.toFixed(2)}</Text>
                </View>
                {salaryData?.bonuse !== 0 && (
                    <View style={styles.row}>
                        <Text>Bonuse:</Text>
                        <Text style={{ marginLeft: 'auto' }}>
                            CHF
                        </Text>
                        <Text style={{ width: 50, alignItems: 'flex-end', textAlign: 'right' }}>{salaryData.bonuse.toFixed(2)}</Text>
                    </View>
                )}
                <View style={styles.row}>
                    <Text style={styles.bold}>Brute Salary:</Text>
                    <Text style={{ marginLeft: 'auto' }}>
                        CHF
                    </Text>
                    <Text style={{ width: 50, alignItems: 'flex-end', textAlign: 'right' }}>{salaryData.baseSalary}</Text>
                </View>


                <View style={{ marginVertical: 5 }}>
                    <Text style={{ fontFamily: 'InterSemiBold', color: '#282D46' }}>Social Insurances Expenses</Text>
                </View>
                {salaryData.socialInsurances.map((item: any, index: any) => (
                    <View style={styles.row} key={index}>
                        <Text>{item.name}:</Text>
                        <Text style={{ marginLeft: 'auto' }}>
                            {item.percentage}
                        </Text>
                        <Text>
                            CHF
                        </Text>
                        <Text style={{ width: 50, alignItems: 'flex-end', textAlign: 'right' }}>{item.amount}</Text>
                    </View>
                ))}

                <View style={styles.row}>
                    <Text style={styles.bold}>Total Social Insurances:</Text>
                    <Text style={{ marginLeft: 'auto' }}>
                        CHF
                    </Text>
                    <Text style={{ width: 50, alignItems: 'flex-end', textAlign: 'right' }}>{salaryData.totalSocialInsurance}</Text>
                </View>
            </View>
            <View style={{ gap: 7 }}>
                <View style={styles.row}>
                    <Text>Net Salary:</Text>
                    <Text style={{ marginLeft: 'auto' }}>
                        -
                    </Text>
                    <Text >
                        CHF
                    </Text>
                    <Text style={{ width: 50, alignItems: 'flex-end', textAlign: 'right' }}>{salaryData.netSalary.toFixed(2)}</Text>
                </View>
                <View style={styles.row}>
                    <Text>Compensation:</Text>
                    <Text style={{ marginLeft: 'auto' }}>
                        -
                    </Text>
                    <Text >
                        CHF
                    </Text>
                    <Text style={{ width: 50, alignItems: 'flex-end', textAlign: 'right' }}>{salaryData.compensation}</Text>
                </View>

                <View style={styles.row}>
                    <Text style={styles.bold}>Net Salary + Compensations:</Text>
                    <Text style={{ marginLeft: 'auto' }}>
                        -
                    </Text>
                    <Text >
                        CHF
                    </Text>
                    <Text style={{ width: 50, alignItems: 'flex-end', textAlign: 'right' }}>{salaryData.netSalaryWithCompensation}</Text>
                </View>
            </View>
            <View style={{ gap: 7 }}>
                {salaryData.otherExpenses.length > 0 && (

                    <View style={{ paddingBottom: 5, color: '#282D46', fontFamily: 'InterSemiBold' }}>
                        <Text>Other Expenses:</Text>
                    </View>
                )}
                {salaryData.otherExpenses.map((item: any, index: any) => (
                    <View style={styles.row} key={index}>
                        <Text>{item.name}:</Text>
                        <Text style={{ marginLeft: 'auto' }}>
                            {item.percentage}
                        </Text>
                        <Text>
                            CHF
                        </Text>
                        <Text style={{ width: 50, alignItems: 'flex-end', textAlign: 'right' }}>{item.amount}</Text>
                    </View>
                ))}
            </View>
            <View style={styles.line}></View>
            <View style={styles.row}>
                <Text style={styles.bold}>Total Paid Salary:</Text>
                <Text style={{ marginLeft: 'auto' }}>
                    CHF
                </Text>

                <Text style={{ width: 50, alignItems: 'flex-end', textAlign: 'right' }}>{salaryData.totalPaid}</Text>
            </View>

            {/* <View style={{ gap: 7 }}>
                <Text style={[styles.bold, { fontSize: 13 }]}>Notice:</Text>
                <Text style={{ color: '#282D46' }}>
                    Please review your salary statement and report any discrepancies to buchhaltung@dl-finance.ch. Changes to address, salary account, or marital status must be reported.
                </Text>
            </View> */}
            <View style={{ gap: 7 }}>
                <Text style={[styles.bold, { fontSize: 13 }]}>Payment Details:</Text>
                <View style={{ color: '#282D46', flexDirection: 'row' }}>
                    <Text style={{ fontFamily: 'InterSemiBold' }}>Amount:</Text>
                    <Text>CHF {salaryData.totalPaid}</Text>

                </View>
                <View style={{ color: '#282D46', flexDirection: 'row' }}>
                    <Text style={[styles.bold]}>Bank Account:</Text>
                    <Text>CHF {salaryData.bankAccount}</Text>
                </View>
            </View>

            <Text style={[styles.title, { textAlign: "center" }]}>Fair. Competent. Local.</Text>
        </Page>
    </Document >
);



// Main Component
const SalarySlip = ({ data }: { data: any }) => {

      const FixedSalary = useMemo(() => {
        if (data.salary_frequency !== "monthly") {
          return 0;
        }
    
        return data.fixed_salary;
      }, [data]);

    const { t } = useTranslation()

    const { user } = useAuthContext();

    const totalBruteSalary = FixedSalary + data.totalAmountOtherBonusesMonthly + data.provisioned_total - data.canceled_total;

    let totalSocialInsurance = 0;  // Initialize the variable outside of the map

    const socialInsurances = data.expenses
        .filter((item: any) => item.type === "Social Insurance")
        .map((item: any) => {
            const amount = item.measurement_type === 'CHF'
                ? item.amount
                : (totalBruteSalary * (item.amount / 100)).toFixed(2);

            // Update totalSocialInsurance
            totalSocialInsurance += parseFloat(amount);

            return {
                name: item.name,
                percentage: item.measurement_type === '%' ? item.amount + '%' : '-',
                amount: amount,
            };
        });

    let totalOtherExpenses = 0;

    const otherExpenses = data.expenses
        .filter((item: any) => item.type === "Other Expenses")
        .map((item: any) => {
            const amount = item.measurement_type === 'CHF'
                ? parseFloat(item.amount)
                : ((totalBruteSalary - totalSocialInsurance + data.total_compensation_amount) * (parseFloat(item.amount) / 100)).toFixed(2);


            totalOtherExpenses += parseFloat(amount as any);

            return {
                name: item.name,
                percentage: item.measurement_type === '%' ? item.amount + '%' : '-',
                amount: amount,
            };
        });

    const salaryData = {
        title: t('Monthly Salary') + ' - ' + t("February") + 2025,
        name: "Gobbi Malik",
        address: "Lerchenstrasse 6, 8853 Lachen",
        ahvNumber: "756.9696.5696.02",
        fixedSalary: FixedSalary.toFixed(2),
        commissions: data.provisioned_total - data.canceled_total,
        bonuse: data.totalAmountOtherBonusesMonthly,
        baseSalary: totalBruteSalary.toFixed(2),
        socialInsurances: socialInsurances,
        totalSocialInsurance: totalSocialInsurance.toFixed(2),
        netSalary: totalBruteSalary - totalSocialInsurance,
        compensation: data.total_compensation_amount.toFixed(2),
        netSalaryWithCompensation: (totalBruteSalary - totalSocialInsurance + data.total_compensation_amount).toFixed(2),
        otherExpenses: otherExpenses,
        totalPaid: (totalBruteSalary - totalSocialInsurance + data.total_compensation_amount - totalOtherExpenses).toFixed(2),
        bankAccount: "CHXX XXXX XXXX XXXX XXXX X, Aargauische Kantonalbank",
    };


    

    return (
        <div>

            <PDFDownloadLink document={<SalarySlipPDF salaryData={salaryData} user={user} t={t} />} fileName="Salary_Slip.pdf">
                Download PDF
            </PDFDownloadLink>
        </div>
    );
};

export default SalarySlip;
