import IconButton from 'components/atoms/button/IconButton'
import Input from 'components/atoms/input/Input'
import Label from 'components/atoms/input/input-label/InputLabel'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

const Step3ApiInfo = ({ ApiInfo }: any) => {

    const { t } = useTranslation()


    const CopyToClipboard = (value: string) => {

        navigator.clipboard.writeText(value);

        toast.success("Copied to Clipboard!");
    }

    return (
        <div>
            <div className='rounded-[20px] bg-[#F6FBFE] p-4 pb-[30px] space-y-4'>
                <div>
                    <Label>
                        {t('Your API URL')}
                    </Label>
                    <div className="flex gap-3">
                        <Input
                            disabled
                            className='disabled:bg-white h-full'
                            classes={{ container: 'flex-1' }}
                            value={ApiInfo.api}
                        />
                        <IconButton className='flex-1' onClick={() => { CopyToClipboard(ApiInfo.api) }}>
                            Copy API URL
                        </IconButton>
                    </div>
                </div>
                <div>
                    <Label>
                        {t('Your API Secret Code')}
                    </Label>
                    <div className="flex gap-3">
                        <Input
                            disabled
                            className='disabled:bg-white h-full'
                            classes={{ container: 'flex-1' }}
                            value={ApiInfo.api_key}
                            type='password'
                        />
                        <IconButton className='flex-1' onClick={() => { CopyToClipboard(ApiInfo.api_key) }}>
                            Copy Secret Code
                        </IconButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Step3ApiInfo