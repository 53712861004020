import { useQuery } from "react-query"
import { getSalaryGoals } from "api/Employee";
import { usePermissions } from "lib/hooks/shared/usePermissions";


export const useSalaryGoals = (
    id: any
) => {
    const permissions = usePermissions(['employee_management_view']);
    
    return useQuery<Array<any> | any>(
        'salary-bonuses',
        () => getSalaryGoals(permissions ? id : null),
        {
            onError(error: any) {
                console.error(error)
            }
        }
    )
}