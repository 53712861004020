import IconButton from "components/atoms/button/IconButton";
import Headline from "components/atoms/headline/Headline";
import Icon from "components/atoms/icon/Icon";
import { IconType } from "lib/helpers/constants/iconTypes";
import { useTranslation } from "react-i18next";
import Layout2 from "./overview-Layouts/Layout2";
import EditLayout from "./edit-layout/EditLayout";
import { useState } from "react";
import { useLayouts } from "lib/hooks/queries/Dashboard/useLayouts";
import LoadingScreen from "components/atoms/loading-screen/LoadingScreen";
import Layout1 from "./overview-Layouts/Layout1";
import Layout3 from "./overview-Layouts/Layout3";
import Layout5 from "./overview-Layouts/Layout5";

const OverviewView = () => {

  const { t } = useTranslation();

  const [IsEditLayoutOpen, setIsEditLayoutOpen] = useState(false)

  const { data, isLoading } = useLayouts()

  const [IsEditing, setIsEditing] = useState(false)


  const SubmitLayout = () => {
    setIsEditing(false)
  }

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <div className="">
      <div className="flex justify-between items-center gap-4 mt-[46px] mb-[33px]">
        <Headline title={t("Dashboard")} className="!my-[0px]" />
        {IsEditing && (
          <IconButton className="ml-auto" icon={<Icon iconType={IconType.EDIT_ICON} color="white" />} onClick={() => setIsEditLayoutOpen(true)} >
            {t("Change Layout")}
          </IconButton>
        )}
        <IconButton icon={<Icon iconType={IconType.EDIT_ICON} color="white" />}
          onClick={() => {
            IsEditing
              ? SubmitLayout()
              : setIsEditing(true)
          }}
        >
          {IsEditing ? t("Save Changes") : t("Edit Dashboard")}
        </IconButton>
      </div>
      <div className="space-y-5">
        {
          {
            'Layout 1': <Layout1 isEditing={IsEditing} data={data} />,
            'Layout 2': <Layout2 isEditing={IsEditing} data={data} />,
            'Layout 3': <Layout3 isEditing={IsEditing} data={data} />,
            'Layout 4': <Layout5 isEditing={IsEditing} data={data} />,
            // 'Layout 5': <Layout5 isEditing={IsEditing} data={data} />,
            // 'Layout 6': <Layout6 isEditing={IsEditing} data={data} />,
          }[((data as any)?.layout_id) as string]
        }


      </div>


      <EditLayout
        activeLayout={(data as any)?.layout_id}
        hocisOpen={IsEditLayoutOpen}
        hocToggleModal={() => setIsEditLayoutOpen(!IsEditLayoutOpen)}
        hocCurrentStep={1}
        hocClasses={{
          modalClassName: `sm:max-w-[900px] sm:w-full w-full `,
        }}
        hocTitle="Edit Dashboard"
        onConfirm={() => setIsEditLayoutOpen(false)}
      />
    </div >
  );
};

export default OverviewView;
